import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';

import { IconButton, CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { SocketProvider } from 'contexts/SocketContext';
import { GlobalProvider } from 'contexts/GlobalContext';
import { ImageOfflineQueueProvider } from 'contexts/ImageOfflineQueue';

import Loading from './ui/components/loading';

import NotFound from './modules/not-found';

import GlobalCss from './styles/GlobalCss';
import apolloClient from './apollo-client';

import './app-i18n';

import themes from './styles/app-themes';

import NotificationSystem from 'ui/components/notification-system';
import NotificationItem from 'ui/components/notification-system/notification-item';
import { PhysicalCardProvider } from 'contexts/PhysicalCard';
import store from './store/redux';
import { Provider } from 'react-redux';
import Router from './router/Router';
import StoreInitializer from './store/StoreInitializer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter future={{ v7_startTransition: true }} basename="/">
        <ErrorBoundary FallbackComponent={NotFound}>
          <Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={themes.hgregoire}>
                  <GlobalCss />
                  <CssBaseline />

                  <StyledEngineProvider injectFirst>
                    <SnackbarProvider
                      maxSnack={5}
                      ref={notistackRef}
                      action={(key: SnackbarKey) => (
                        <IconButton
                          color="primary"
                          aria-label="close"
                          component="span"
                          style={{ color: 'white' }}
                          onClick={onClickDismiss(key)}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      Components={{ warning: NotificationItem }}
                    >
                      <StoreInitializer>
                        <SocketProvider>
                          <GlobalProvider>
                            <PhysicalCardProvider>
                              <ImageOfflineQueueProvider>
                                <NotificationSystem>
                                  <Router />
                                </NotificationSystem>
                              </ImageOfflineQueueProvider>
                            </PhysicalCardProvider>
                          </GlobalProvider>
                        </SocketProvider>
                      </StoreInitializer>
                    </SnackbarProvider>
                  </StyledEngineProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </ApolloProvider>
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
