import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};



export type AcodeIcon = {
  __typename?: 'AcodeIcon';
  value: Scalars['String'];
  logic: Scalars['String'];
};

export type ActionInput = {
  damageId: Scalars['String'];
  name: Scalars['String'];
  labels: LabelInput;
  deval_low: Scalars['Float'];
  deval_high: Scalars['Float'];
  repair_low: Scalars['Float'];
  repair_high: Scalars['Float'];
  userId?: Maybe<Scalars['String']>;
};

export type AddDeductListItem = {
  __typename?: 'AddDeductListItem';
  auto?: Maybe<Scalars['String']>;
  avg?: Maybe<Scalars['String']>;
  clean?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resid12?: Maybe<Scalars['String']>;
  resid24?: Maybe<Scalars['String']>;
  resid30?: Maybe<Scalars['String']>;
  resid36?: Maybe<Scalars['String']>;
  resid42?: Maybe<Scalars['String']>;
  resid48?: Maybe<Scalars['String']>;
  resid60?: Maybe<Scalars['String']>;
  resid72?: Maybe<Scalars['String']>;
  rough?: Maybe<Scalars['String']>;
  uoc?: Maybe<Scalars['String']>;
  xclean?: Maybe<Scalars['String']>;
};

export type AdjustedByInput = {
  Grade: Scalars['String'];
  odometer: Scalars['Int'];
};

export type AdjustedByType = {
  __typename?: 'AdjustedByType';
  Grade: Scalars['String'];
  Odometer: Scalars['Int'];
};

export type AdjustedPricingInput = {
  wholesale: ManheimItemWholesaleInput;
  adjustedBy: AdjustedByInput;
};

export type AdjustedPricingType = {
  __typename?: 'AdjustedPricingType';
  wholesale: ManheimItemWholesaleType;
  adjustedBy: AdjustedByType;
};

export type AnalitycMethod = {
  __typename?: 'AnalitycMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<Subscription>;
};

export type Application = {
  __typename?: 'Application';
  name: Scalars['String'];
  hostname: Scalars['String'];
  version: Scalars['String'];
};

export type ApplicationInput = {
  name: Scalars['String'];
  hostname: Scalars['String'];
  version: Scalars['String'];
};

export type Appraisal = {
  __typename?: 'Appraisal';
  _id: Scalars['String'];
  id: Scalars['Int'];
  vAutoId: Scalars['String'];
  carfax?: Maybe<CarfaxType>;
  appraisalValue: Scalars['Int'];
  location: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  vin: Scalars['String'];
  licenseState: Scalars['String'];
  licensePlate: Scalars['String'];
  isPurchased: Scalars['Boolean'];
  origin: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['String'];
  styleId: Scalars['String'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  engineCylinderLabel: Scalars['String'];
  bodyType: Scalars['String'];
  transmissionType: Scalars['String'];
  originDate: Scalars['String'];
  traction: Scalars['String'];
  exteriorColor: Scalars['String'];
  interiorColor: Scalars['String'];
  vehicleType: Scalars['String'];
  vehicleReport: Array<Maybe<VehicleReportType>>;
  noticeByManagers: Scalars['String'];
  trimName: Scalars['String'];
  custFirstName: Scalars['String'];
  custLastName: Scalars['String'];
  custEmail: Scalars['String'];
  custHomePhone: Scalars['String'];
  custAddress: Scalars['String'];
  custCity: Scalars['String'];
  custPostalCode: Scalars['String'];
  custState: Scalars['String'];
  isPhotosCertifyUser?: Maybe<Scalars['Boolean']>;
  disclosures: Array<Scalars['String']>;
  disclosuresUS: Array<DisclosuresUsType>;
  offerAsSeen: Scalars['Boolean'];
  offerPrinted: Scalars['Boolean'];
  offeredValue: Scalars['String'];
  offeredPrice: Scalars['String'];
  formulaPrice: Scalars['String'];
  custAmountOwed: Scalars['String'];
  custDriverLicense: Scalars['String'];
  options: Array<Maybe<Scalars['String']>>;
  attachments: Array<Attachment>;
  saleType: Scalars['String'];
  stock: Scalars['String'];
  photos: Array<AppraisalPhoto>;
  conditionReportLink: Scalars['String'];
  inspectionReportLinkOtherService?: Maybe<Scalars['String']>;
  conditionReportStatus: Scalars['String'];
  conditioningStarted: Scalars['String'];
  conditions: Array<AppraisalCondition>;
  optionsVinDecode: Array<SubOptionsList>;
  optionsVinDecodeBB: Array<DecodeBbItemType>;
  monkaiInspectionId: Scalars['String'];
  monkaiInspectionStatus: Scalars['String'];
  photosMonkaiInspections: Array<MonkaiImage>;
  manheimData?: Maybe<ManheimType>;
  drivlyDataOffers?: Maybe<OffersByVin>;
  blackbookData?: Maybe<BbInfoValuesType>;
  drivlyDataGurus?: Maybe<CarGurusByVin>;
  userEvaluationId?: Maybe<Scalars['String']>;
  odometerReplaced: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  commerciallyUsed: Scalars['String'];
  commonId: Scalars['Int'];
  disclosuresWork: Array<AppraisalCondition>;
  emissionSystemIntact: Scalars['String'];
  eraInvoiceAmount: Scalars['Int'];
  eraSalesCode: Scalars['String'];
  exitStrategyType: Scalars['Int'];
  factoryBuyback: Scalars['String'];
  floodDamage: Scalars['String'];
  hasConditionReport: Scalars['String'];
  inspectionReportLink: Scalars['String'];
  isConditionReportComplete: Scalars['String'];
  leaseReturn: Scalars['Boolean'];
  parkingZoneSegmentation: Scalars['String'];
  previouslyDamaged: Scalars['Boolean'];
  publicId: Scalars['String'];
  recall: Scalars['String'];
  salesperson: Scalars['String'];
  salvaged: Scalars['String'];
  source: Scalars['String'];
  appraiser: Scalars['String'];
  submitted: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  expired: Scalars['String'];
  owner: Scalars['String'];
  ownerId: Scalars['Int'];
  started: Scalars['String'];
  startedById: Scalars['String'];
  completed: Scalars['String'];
  completedById: Scalars['Int'];
  approved: Scalars['String'];
  approvedById: Scalars['String'];
  assessingStarted: Scalars['String'];
  assessed: Scalars['String'];
  assessedById: Scalars['Int'];
  authorized: Scalars['String'];
  authorizedById: Scalars['String'];
  closer: Scalars['String'];
  closerId: Scalars['String'];
  paveSessionId: Scalars['String'];
  paveSessionStatus: Scalars['String'];
  challenged: Scalars['String'];
  challengedById: Scalars['String'];
  challengedByLocation: Scalars['String'];
  challengeResolved: Scalars['String'];
  rep: Scalars['String'];
  repId: Scalars['String'];
  inventory?: Maybe<AppraisalInventory>;
  tireThread: AppraisalTireThread;
  sourceNameReport: Scalars['String'];
  comments: AppraisalComments;
  application?: Maybe<Application>;
};

export type AppraisalComments = {
  __typename?: 'AppraisalComments';
  client: Scalars['String'];
  document: Scalars['String'];
  appraiser: Scalars['String'];
};

export type AppraisalCommentsInput = {
  client: Scalars['String'];
  document: Scalars['String'];
  appraiser: Scalars['String'];
};

export type AppraisalCondition = {
  __typename?: 'AppraisalCondition';
  name: Scalars['String'];
  label: Scalars['String'];
  translationLabel?: Maybe<TranslationLabelType>;
  photos: Array<AppraisalPhoto>;
  text: Scalars['String'];
  order: Scalars['Int'];
  todo: Scalars['Boolean'];
  pin: Pin;
  detail: Detail;
  isArbitrageDamege?: Maybe<Scalars['Boolean']>;
  pending: Scalars['Boolean'];
  workStarted: Scalars['String'];
  workStartedById?: Maybe<Scalars['Int']>;
  conditioned: Scalars['String'];
  conditionedById?: Maybe<Scalars['Int']>;
  mapped?: Maybe<Scalars['Boolean']>;
};

export type AppraisalConditionInput = {
  conditioned: Scalars['String'];
  conditionedById?: Maybe<Scalars['Int']>;
  detail: DetailInput;
  isArbitrageDamege?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  pending: Scalars['Boolean'];
  photos: Array<AppraisalPhotoInput>;
  pin: PinInput;
  text: Scalars['String'];
  todo: Scalars['Boolean'];
  translationLabel?: Maybe<TranslationLabelInput>;
  workStarted: Scalars['String'];
  workStartedById?: Maybe<Scalars['Int']>;
};

export type AppraisalConditions = {
  __typename?: 'AppraisalConditions';
  _id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  created: Scalars['String'];
  updated: Scalars['String'];
  location: Scalars['String'];
  source: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  origin: Scalars['String'];
  vehicleType: Scalars['String'];
  vin: Scalars['String'];
  stock: Scalars['String'];
  saleType: Scalars['String'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['String'];
  bodyType: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  engineCylinderLabel: Scalars['String'];
  transmissionType: Scalars['String'];
  traction: Scalars['String'];
  licenseState: Scalars['String'];
  licensePlate: Scalars['String'];
  owner: Scalars['String'];
  ownerId: Scalars['Int'];
  closer: Scalars['String'];
  closerId: Scalars['String'];
  rep: Scalars['String'];
  repId: Scalars['String'];
  offerPrinted: Scalars['String'];
  submitted: Scalars['String'];
  started: Scalars['String'];
  startedById: Scalars['String'];
  completed: Scalars['String'];
  completedById: Scalars['Int'];
  approved: Scalars['String'];
  approvedById: Scalars['String'];
  assessingStarted: Scalars['String'];
  assessed: Scalars['String'];
  assessedById: Scalars['Int'];
  authorized: Scalars['String'];
  authorizedById: Scalars['String'];
  conditioningStarted: Scalars['String'];
  challenged: Scalars['String'];
  challengedById: Scalars['String'];
  challengedByLocation: Scalars['String'];
  challengeResolved: Scalars['String'];
  salesperson: Scalars['String'];
  appraiser: Scalars['String'];
  leaseReturn: Scalars['String'];
  custFirstName: Scalars['String'];
  custLastName: Scalars['String'];
  custHomePhone: Scalars['String'];
  custEmail: Scalars['String'];
  custAddress: Scalars['String'];
  custCity: Scalars['String'];
  custState: Scalars['String'];
  custPostalCode: Scalars['String'];
  custDriverLicense: Scalars['String'];
  custAmountOwed: Scalars['String'];
  vAutoId: Scalars['String'];
  appraisalValue: Scalars['Int'];
  offeredValue: Scalars['String'];
  offerAsSeen: Scalars['String'];
  conditions: Array<AppraisalCondition>;
  annotations: Scalars['String'];
  disclosures: Array<Scalars['String']>;
  disclosuresWork: Array<AppraisalCondition>;
  vehicleReport: Array<Maybe<VehicleReportType>>;
  photos: Scalars['String'];
  tireThread: AppraisalTireThread;
  parkingZoneSegmentation: Scalars['String'];
  hasConditionReport: Scalars['String'];
  conditionReportStatus: Scalars['String'];
  isConditionReportComplete: Scalars['String'];
  conditionReportLink: Scalars['String'];
  inspectionReportLinkOtherService?: Maybe<Scalars['String']>;
  inspectionReportLink: Scalars['String'];
  styleId: Scalars['String'];
  exteriorColor: Scalars['String'];
  interiorColor: Scalars['String'];
};

export type AppraisalConditionsInput = {
  location: Scalars['String'];
  source: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  stock: Scalars['String'];
  saleType?: Maybe<Scalars['String']>;
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  odometer?: Maybe<Scalars['Int']>;
  odometerMiles?: Maybe<Scalars['Int']>;
  bodyType?: Maybe<Scalars['String']>;
  engineCylinderCount?: Maybe<Scalars['String']>;
  engineCylinderLabel?: Maybe<Scalars['String']>;
  transmissionType?: Maybe<Scalars['String']>;
  traction?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  closer?: Maybe<Scalars['String']>;
  closerId?: Maybe<Scalars['String']>;
  rep?: Maybe<Scalars['String']>;
  repId?: Maybe<Scalars['String']>;
  offerPrinted?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['String']>;
  startedById?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['String']>;
  completedById?: Maybe<Scalars['Int']>;
  approved?: Maybe<Scalars['String']>;
  approvedById?: Maybe<Scalars['String']>;
  assessingStarted: Scalars['String'];
  assessed?: Maybe<Scalars['String']>;
  assessedById: Scalars['Int'];
  authorized?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  conditioningStarted?: Maybe<Scalars['String']>;
  challenged?: Maybe<Scalars['String']>;
  challengedById?: Maybe<Scalars['String']>;
  challengedByLocation?: Maybe<Scalars['String']>;
  challengeResolved?: Maybe<Scalars['String']>;
  salesperson?: Maybe<Scalars['String']>;
  appraiser?: Maybe<Scalars['String']>;
  leaseReturn?: Maybe<Scalars['String']>;
  custFirstName?: Maybe<Scalars['String']>;
  custLastName?: Maybe<Scalars['String']>;
  custHomePhone?: Maybe<Scalars['String']>;
  custEmail?: Maybe<Scalars['String']>;
  custAddress?: Maybe<Scalars['String']>;
  custCity?: Maybe<Scalars['String']>;
  custState?: Maybe<Scalars['String']>;
  custPostalCode?: Maybe<Scalars['String']>;
  custDriverLicense?: Maybe<Scalars['String']>;
  custAmountOwed?: Maybe<Scalars['String']>;
  vAutoId?: Maybe<Scalars['String']>;
  appraisalValue?: Maybe<Scalars['Int']>;
  offeredValue?: Maybe<Scalars['String']>;
  offerAsSeen?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<AppraisalConditionInput>>>;
  annotations?: Maybe<Scalars['String']>;
  disclosures?: Maybe<Array<Maybe<Scalars['String']>>>;
  disclosuresWork?: Maybe<Array<Maybe<AppraisalConditionInput>>>;
  vehicleReport?: Maybe<Array<Maybe<VehicleReportInput>>>;
  photos?: Maybe<Scalars['String']>;
  tireThread?: Maybe<AppraisalTireThreadInput>;
  parkingZoneSegmentation?: Maybe<Scalars['String']>;
  hasConditionReport?: Maybe<Scalars['String']>;
  conditionReportStatus?: Maybe<Scalars['String']>;
  isConditionReportComplete?: Maybe<Scalars['String']>;
  conditionReportLink?: Maybe<Scalars['String']>;
  inspectionReportLinkOtherService?: Maybe<Scalars['String']>;
  inspectionReportLink?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  interiorColor?: Maybe<Scalars['String']>;
};

export type AppraisalConditionsResponse = {
  __typename?: 'AppraisalConditionsResponse';
  operation?: Maybe<Scalars['String']>;
  result: AppraisalConditions;
};

export type AppraisalInput = {
  _id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  vAutoId: Scalars['String'];
  appraisalValue: Scalars['Int'];
  carfax?: Maybe<CarfaxInput>;
  location: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  vin: Scalars['String'];
  licenseState: Scalars['String'];
  licensePlate: Scalars['String'];
  isPurchased: Scalars['Boolean'];
  origin: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['String'];
  styleId: Scalars['String'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  engineCylinderLabel: Scalars['String'];
  bodyType: Scalars['String'];
  transmissionType: Scalars['String'];
  originDate: Scalars['String'];
  traction: Scalars['String'];
  exteriorColor: Scalars['String'];
  interiorColor: Scalars['String'];
  vehicleType: Scalars['String'];
  vehicleReport: Array<Maybe<VehicleReportInput>>;
  noticeByManagers: Scalars['String'];
  saleType: Scalars['String'];
  trimName: Scalars['String'];
  custFirstName: Scalars['String'];
  custLastName: Scalars['String'];
  custEmail: Scalars['String'];
  custHomePhone: Scalars['String'];
  custAddress: Scalars['String'];
  custCity: Scalars['String'];
  custPostalCode: Scalars['String'];
  custState: Scalars['String'];
  isPhotosCertifyUser?: Maybe<Scalars['Boolean']>;
  disclosures: Array<Scalars['String']>;
  disclosuresUS: Array<DisclosuresUsInput>;
  offerAsSeen: Scalars['Boolean'];
  offerPrinted: Scalars['Boolean'];
  offeredValue: Scalars['String'];
  offeredPrice: Scalars['String'];
  formulaPrice: Scalars['String'];
  custAmountOwed: Scalars['String'];
  custDriverLicense: Scalars['String'];
  options: Array<Maybe<Scalars['String']>>;
  attachments: Array<AttachmentInput>;
  photos: Array<AppraisalPhotoInput>;
  conditionReportLink: Scalars['String'];
  inspectionReportLinkOtherService?: Maybe<Scalars['String']>;
  conditionReportStatus: Scalars['String'];
  conditioningStarted: Scalars['String'];
  conditions: Array<AppraisalConditionInput>;
  optionsVinDecode?: Maybe<Array<Maybe<SubOptionsListInput>>>;
  optionsVinDecodeBB: Array<DecodeBbItemInput>;
  monkaiInspectionId: Scalars['String'];
  monkaiInspectionStatus: Scalars['String'];
  photosMonkaiInspections: Array<MonkaiImageInput>;
  manheimData?: Maybe<ManheimInput>;
  drivlyDataOffers?: Maybe<OffersByVinInput>;
  blackbookData?: Maybe<BbInfoValuesInput>;
  drivlyDataGurus?: Maybe<CarGurusByVinInput>;
  userEvaluationId?: Maybe<Scalars['String']>;
  odometerReplaced: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  commerciallyUsed: Scalars['String'];
  commonId: Scalars['Int'];
  disclosuresWork: Array<AppraisalConditionInput>;
  emissionSystemIntact: Scalars['String'];
  eraInvoiceAmount: Scalars['Int'];
  eraSalesCode: Scalars['String'];
  exitStrategyType: Scalars['Int'];
  factoryBuyback: Scalars['String'];
  floodDamage: Scalars['String'];
  hasConditionReport: Scalars['String'];
  inspectionReportLink: Scalars['String'];
  isConditionReportComplete: Scalars['String'];
  leaseReturn: Scalars['Boolean'];
  parkingZoneSegmentation: Scalars['String'];
  previouslyDamaged: Scalars['Boolean'];
  publicId: Scalars['String'];
  recall: Scalars['String'];
  salesperson: Scalars['String'];
  salvaged: Scalars['String'];
  source: Scalars['String'];
  appraiser: Scalars['String'];
  submitted: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  expired: Scalars['String'];
  stock?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  ownerId: Scalars['Int'];
  started: Scalars['String'];
  startedById: Scalars['String'];
  completed: Scalars['String'];
  completedById: Scalars['Int'];
  approved: Scalars['String'];
  approvedById: Scalars['String'];
  assessingStarted: Scalars['String'];
  assessed: Scalars['String'];
  assessedById: Scalars['Int'];
  authorized: Scalars['String'];
  authorizedById: Scalars['String'];
  closer: Scalars['String'];
  closerId: Scalars['String'];
  paveSessionId: Scalars['String'];
  paveSessionStatus: Scalars['String'];
  challenged: Scalars['String'];
  challengedById: Scalars['String'];
  challengedByLocation: Scalars['String'];
  challengeResolved: Scalars['String'];
  rep: Scalars['String'];
  repId: Scalars['String'];
  tireThread?: Maybe<AppraisalTireThreadInput>;
  sourceNameReport: Scalars['String'];
  comments: AppraisalCommentsInput;
  application?: Maybe<ApplicationInput>;
};

export type AppraisalInventory = {
  __typename?: 'AppraisalInventory';
  Stock: Scalars['String'];
  dashboardUrl: Scalars['String'];
};

export type AppraisalMethod = {
  __typename?: 'AppraisalMethod';
  operation?: Maybe<Scalars['String']>;
  result: Appraisal;
};

export type AppraisalPhoto = {
  __typename?: 'AppraisalPhoto';
  id: Scalars['Int'];
  src: Scalars['String'];
  zone: Scalars['String'];
};

export type AppraisalPhotoInput = {
  id: Scalars['Int'];
  src: Scalars['String'];
  zone: Scalars['String'];
};

export type AppraisalTireThread = {
  __typename?: 'AppraisalTireThread';
  frontLeft?: Maybe<Scalars['Int']>;
  frontRight?: Maybe<Scalars['Int']>;
  rearLeft?: Maybe<Scalars['Int']>;
  rearRight?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['String']>;
  mountedOn?: Maybe<Scalars['String']>;
  secondSet?: Maybe<AppraisalTireThreadSecond>;
  photos?: Maybe<Array<Maybe<AppraisalPhoto>>>;
};

export type AppraisalTireThreadInput = {
  frontLeft?: Maybe<Scalars['Int']>;
  frontRight?: Maybe<Scalars['Int']>;
  rearLeft?: Maybe<Scalars['Int']>;
  rearRight?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['String']>;
  mountedOn?: Maybe<Scalars['String']>;
  secondSet?: Maybe<AppraisalTireThreadSecondInput>;
  photos?: Maybe<Array<Maybe<AppraisalPhotoInput>>>;
};

export type AppraisalTireThreadSecond = {
  __typename?: 'AppraisalTireThreadSecond';
  frontLeft: Scalars['Int'];
  frontRight: Scalars['Int'];
  rearLeft: Scalars['Int'];
  rearRight: Scalars['Int'];
  season: Scalars['String'];
  mountedOn: Scalars['String'];
};

export type AppraisalTireThreadSecondInput = {
  frontLeft?: Maybe<Scalars['Int']>;
  frontRight?: Maybe<Scalars['Int']>;
  rearLeft?: Maybe<Scalars['Int']>;
  rearRight?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['String']>;
  mountedOn?: Maybe<Scalars['String']>;
};

export type AppraisalUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  vAutoId?: Maybe<Scalars['String']>;
  carfax?: Maybe<CarfaxInput>;
  appraisalValue?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Int']>;
  odometerMiles?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  engineCylinderCount?: Maybe<Scalars['String']>;
  engineCylinderLabel?: Maybe<Scalars['String']>;
  bodyType?: Maybe<Scalars['String']>;
  transmissionType?: Maybe<Scalars['String']>;
  originDate?: Maybe<Scalars['String']>;
  traction?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  interiorColor?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  vehicleReport?: Maybe<Array<Maybe<VehicleReportInput>>>;
  noticeByManagers?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  trimName?: Maybe<Scalars['String']>;
  custFirstName?: Maybe<Scalars['String']>;
  custLastName?: Maybe<Scalars['String']>;
  custEmail?: Maybe<Scalars['String']>;
  custHomePhone?: Maybe<Scalars['String']>;
  custAddress?: Maybe<Scalars['String']>;
  custCity?: Maybe<Scalars['String']>;
  custPostalCode?: Maybe<Scalars['String']>;
  custState?: Maybe<Scalars['String']>;
  isPhotosCertifyUser?: Maybe<Scalars['Boolean']>;
  disclosures?: Maybe<Array<Maybe<Scalars['String']>>>;
  disclosuresUS?: Maybe<Array<Maybe<DisclosuresUsInput>>>;
  offerAsSeen?: Maybe<Scalars['Boolean']>;
  offerPrinted?: Maybe<Scalars['Boolean']>;
  offeredValue?: Maybe<Scalars['String']>;
  offeredPrice?: Maybe<Scalars['String']>;
  formulaPrice?: Maybe<Scalars['String']>;
  custAmountOwed?: Maybe<Scalars['String']>;
  custDriverLicense?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
  photos?: Maybe<Array<Maybe<AppraisalPhotoInput>>>;
  conditionReportLink?: Maybe<Scalars['String']>;
  inspectionReportLinkOtherService?: Maybe<Scalars['String']>;
  conditionReportStatus?: Maybe<Scalars['String']>;
  conditioningStarted?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<AppraisalConditionInput>>>;
  optionsVinDecode?: Maybe<Array<Maybe<SubOptionsListInput>>>;
  optionsVinDecodeBB?: Maybe<Array<Maybe<DecodeBbItemInput>>>;
  monkaiInspectionId?: Maybe<Scalars['String']>;
  monkaiInspectionStatus?: Maybe<Scalars['String']>;
  photosMonkaiInspections?: Maybe<Array<Maybe<MonkaiImageInput>>>;
  drivlyDataOffers?: Maybe<OffersByVinInput>;
  blackbookData?: Maybe<BbInfoValuesInput>;
  drivlyDataGurus?: Maybe<CarGurusByVinInput>;
  userEvaluationId?: Maybe<Scalars['String']>;
  odometerReplaced?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<Maybe<Scalars['String']>>>;
  commerciallyUsed?: Maybe<Scalars['String']>;
  commonId?: Maybe<Scalars['Int']>;
  disclosuresWork?: Maybe<Array<Maybe<AppraisalConditionInput>>>;
  emissionSystemIntact?: Maybe<Scalars['String']>;
  eraInvoiceAmount?: Maybe<Scalars['Int']>;
  eraSalesCode?: Maybe<Scalars['String']>;
  exitStrategyType?: Maybe<Scalars['Int']>;
  factoryBuyback?: Maybe<Scalars['String']>;
  floodDamage?: Maybe<Scalars['String']>;
  hasConditionReport?: Maybe<Scalars['String']>;
  inspectionReportLink?: Maybe<Scalars['String']>;
  isConditionReportComplete?: Maybe<Scalars['String']>;
  leaseReturn?: Maybe<Scalars['Boolean']>;
  parkingZoneSegmentation?: Maybe<Scalars['String']>;
  previouslyDamaged?: Maybe<Scalars['Boolean']>;
  publicId?: Maybe<Scalars['String']>;
  recall?: Maybe<Scalars['String']>;
  salesperson?: Maybe<Scalars['String']>;
  salvaged?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  appraiser?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  started?: Maybe<Scalars['String']>;
  startedById?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['String']>;
  completedById?: Maybe<Scalars['Int']>;
  approved?: Maybe<Scalars['String']>;
  approvedById?: Maybe<Scalars['String']>;
  assessingStarted?: Maybe<Scalars['String']>;
  assessed?: Maybe<Scalars['String']>;
  assessedById?: Maybe<Scalars['Int']>;
  authorized?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  closerId?: Maybe<Scalars['String']>;
  paveSessionId?: Maybe<Scalars['String']>;
  paveSessionStatus?: Maybe<Scalars['String']>;
  challenged?: Maybe<Scalars['String']>;
  challengedById?: Maybe<Scalars['String']>;
  challengedByLocation?: Maybe<Scalars['String']>;
  challengeResolved?: Maybe<Scalars['String']>;
  rep?: Maybe<Scalars['String']>;
  repId?: Maybe<Scalars['String']>;
  tireThread?: Maybe<AppraisalTireThreadInput>;
  sourceNameReport?: Maybe<Scalars['String']>;
  comments?: Maybe<AppraisalCommentsInput>;
  application?: Maybe<ApplicationInput>;
  isPurchased?: Maybe<Scalars['Boolean']>;
};

export type AppraisalUpdateWithSocketInput = {
  change: AppraisalUpdateInput;
  socket?: Maybe<Scalars['Boolean']>;
};

export type AppraisalVAuto = {
  __typename?: 'AppraisalVAuto';
  Appraiser?: Maybe<AppraiserVAuto>;
};

export type AppraisalVehByVinMethod = {
  __typename?: 'AppraisalVehByVinMethod';
  last?: Maybe<Scalars['Boolean']>;
  result?: Maybe<AppraisalVehByVinResult>;
};

export type AppraisalVehByVinResult = {
  __typename?: 'AppraisalVehByVinResult';
  virtual: Array<VirtualByVinType>;
  physical: Array<AppraisalVehByVinType>;
  condition: Array<AppraisalVehByVinType>;
};

export type AppraisalVehByVinType = {
  __typename?: 'AppraisalVehByVinType';
  _id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  appraisalValue?: Maybe<Scalars['Int']>;
  offeredValue: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  custFirstName?: Maybe<Scalars['String']>;
  custLastName?: Maybe<Scalars['String']>;
  custEmail?: Maybe<Scalars['String']>;
  custHomePhone?: Maybe<Scalars['String']>;
  custAddress?: Maybe<Scalars['String']>;
  custCity?: Maybe<Scalars['String']>;
  custPostalCode?: Maybe<Scalars['String']>;
  custState?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['String']>;
  isPurchased?: Maybe<Scalars['Boolean']>;
  created: Scalars['String'];
};

export type AppraisalsConditionMethod = {
  __typename?: 'AppraisalsConditionMethod';
  operation?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Boolean']>;
  result: Array<AppraisalConditions>;
};

export type AppraisalsCountMethod = {
  __typename?: 'AppraisalsCountMethod';
  operation?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Int']>;
};

export type AppraisalsCounts = {
  __typename?: 'AppraisalsCounts';
  pending: AppraisalsCountMethod;
  evaluation: AppraisalsCountMethod;
  evaluating: AppraisalsCountMethod;
  assessment: AppraisalsCountMethod;
  claim: AppraisalsCountMethod;
};

export type AppraisalsMethod = {
  __typename?: 'AppraisalsMethod';
  operation?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Boolean']>;
  result: Array<Appraisal>;
};

export type AppraiserVAuto = {
  __typename?: 'AppraiserVAuto';
  Email: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  UserName: Scalars['String'];
};

export type AppriselVehicleForAnalyticsMethod = {
  __typename?: 'AppriselVehicleForAnalyticsMethod';
  last?: Maybe<Scalars['Boolean']>;
  result: Array<AppriselVehicleForAnalyticsType>;
};

export type AppriselVehicleForAnalyticsType = {
  __typename?: 'AppriselVehicleForAnalyticsType';
  _id: Scalars['String'];
  id: Scalars['Int'];
  vin: Scalars['String'];
  created: Scalars['String'];
  completed: Scalars['String'];
  appraisalValue: Scalars['Int'];
  offeredValue: Scalars['String'];
  formulaPrice: Scalars['String'];
  purchased: Scalars['Int'];
  inventoryDate?: Maybe<Scalars['String']>;
  drivlyDataOffers?: Maybe<OffersByVin>;
  blackbookData?: Maybe<BbInfoValuesType>;
  drivlyDataGurus?: Maybe<CarGurusByVin>;
};

export type AppriselVehicleInput = {
  interiorColor?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Int']>;
  odometerMiles?: Maybe<Scalars['Int']>;
  modelYear?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  bodyType?: Maybe<Scalars['String']>;
  engineCylinderCount?: Maybe<Scalars['String']>;
  engineCylinderLabel?: Maybe<Scalars['String']>;
  transmissionType?: Maybe<Scalars['String']>;
  traction?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  custFirstName?: Maybe<Scalars['String']>;
  custLastName?: Maybe<Scalars['String']>;
  custHomePhone?: Maybe<Scalars['String']>;
  custEmail?: Maybe<Scalars['String']>;
  custAddress?: Maybe<Scalars['String']>;
  custCity?: Maybe<Scalars['String']>;
  custState?: Maybe<Scalars['String']>;
  custPostalCode?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  disclosuresUS?: Maybe<Array<Maybe<DisclosuresInput>>>;
  optionsVinDecodeBB?: Maybe<Array<Maybe<OptionsVinDecodeBbInput>>>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  physicalEvaluationId?: Maybe<Scalars['String']>;
  physicalEvaluationDate?: Maybe<Scalars['String']>;
  isPurchased?: Maybe<Scalars['Boolean']>;
};

export type AppriselVehicleMethod = {
  __typename?: 'AppriselVehicleMethod';
  last?: Maybe<Scalars['Boolean']>;
  result: Array<AppriselVehicleType>;
};

export type AppriselVehicleType = {
  __typename?: 'AppriselVehicleType';
  _id: Scalars['String'];
  interiorColor: Scalars['String'];
  location: Scalars['String'];
  vin: Scalars['String'];
  origin: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['Int'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  bodyType: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  engineCylinderLabel: Scalars['String'];
  transmissionType: Scalars['String'];
  traction: Scalars['String'];
  exteriorColor: Scalars['String'];
  custFirstName: Scalars['String'];
  custLastName: Scalars['String'];
  custHomePhone: Scalars['String'];
  custEmail: Scalars['String'];
  custAddress: Scalars['String'];
  custCity: Scalars['String'];
  custState: Scalars['String'];
  custPostalCode: Scalars['String'];
  styleId: Scalars['String'];
  disclosuresUS: Array<DisclosuresType>;
  optionsVinDecodeBB: Array<OptionsVinDecodeBb>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
  status: Scalars['String'];
  physicalEvaluationId?: Maybe<Scalars['String']>;
  physicalEvaluationDate?: Maybe<Scalars['String']>;
  userEvaluationId?: Maybe<Scalars['String']>;
  isPurchased: Scalars['Boolean'];
};

export type Attachment = {
  __typename?: 'Attachment';
  created: Scalars['Date'];
  type: Scalars['Int'];
  src: Scalars['String'];
  text: Scalars['String'];
  name: Scalars['String'];
  user_id: Scalars['Int'];
};

export type AttachmentInput = {
  created?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['Int']>;
  src?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type Auth = {
  __typename?: 'Auth';
  token: Scalars['String'];
};

export type AuthUser = {
  __typename?: 'AuthUser';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  country: Countries;
  dealership: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['Int']>>>;
  permissions?: Maybe<Array<Scalars['String']>>;
};

export type BadgeListSmallType = {
  __typename?: 'BadgeListSmallType';
  BadgeName: Scalars['String'];
  BadgeType: Scalars['Int'];
  BadgeImageUrl: Scalars['String'];
};

export type BadgesListType = {
  __typename?: 'BadgesListType';
  BadgeList: Array<BadgeListSmallType>;
  BadgesImageUrl: Scalars['String'];
  HasBadge: Scalars['String'];
  VhrReportUrl: Scalars['String'];
  VIN: Scalars['String'];
  ResultCode: Scalars['Int'];
  ResultMessage: Scalars['String'];
};

export type BasePriceItem = {
  __typename?: 'BasePriceItem';
  unknown: Scalars['Boolean'];
  invoice: Scalars['Int'];
  msrp: Scalars['Int'];
  destination: Scalars['Int'];
};

export type BbInfoValuesInput = {
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  reatailPrices?: Maybe<ReatailPricesInput>;
  series?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  tradeinPrices?: Maybe<TradeinPricesInput>;
  vin?: Maybe<Scalars['String']>;
  wholeSalePrices?: Maybe<WholeSalePricesInput>;
};

export type BbInfoValuesMethod = {
  __typename?: 'BbInfoValuesMethod';
  operation?: Maybe<Scalars['String']>;
  result: BbInfoValuesType;
};

export type BbInfoValuesType = {
  __typename?: 'BbInfoValuesType';
  reatailPrices?: Maybe<ReatailPricesType>;
  wholeSalePrices?: Maybe<WholeSalePricesType>;
  tradeinPrices?: Maybe<TradeinPricesType>;
};

export type BodyTypeItem = {
  __typename?: 'BodyTypeItem';
  value: Scalars['String'];
  id: Scalars['Int'];
  primary: Scalars['Boolean'];
};

export type CarGurusByVin = {
  __typename?: 'CarGurusByVIN';
  vin?: Maybe<Scalars['String']>;
  retail?: Maybe<Scalars['String']>;
  privateParty?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type CarGurusByVinInput = {
  vin?: Maybe<Scalars['String']>;
  retail?: Maybe<Scalars['String']>;
  privateParty?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type CarPart = {
  __typename?: 'CarPart';
  id: Scalars['String'];
  name: Scalars['String'];
  labels: Label;
  top: Scalars['Float'];
  bottom: Scalars['Float'];
  left: Scalars['Float'];
  right: Scalars['Float'];
};

export type CarSide = {
  __typename?: 'CarSide';
  id: Scalars['String'];
  side: Scalars['String'];
  image: Scalars['String'];
  parts: Array<CarPart>;
};

export type CarfaxInput = {
  link?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type CarfaxType = {
  __typename?: 'CarfaxType';
  link?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type Carproof = {
  __typename?: 'Carproof';
  url?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type CarproofMethod = {
  __typename?: 'CarproofMethod';
  operation?: Maybe<Scalars['String']>;
  result: Carproof;
};

export type CarproofVpn = {
  __typename?: 'CarproofVPN';
  AccountNumber: Scalars['Int'];
  BadgesList: Array<BadgesListType>;
  Language: Scalars['String'];
  LogoUrl: Scalars['String'];
  ResultCode: Scalars['Int'];
  ResultMessage: Scalars['String'];
};

export type CarproofVpnMethod = {
  __typename?: 'CarproofVPNMethod';
  operation?: Maybe<Scalars['String']>;
  result: CarproofVpn;
};

export type CarstoryByVinType = {
  __typename?: 'CarstoryByVINType';
  carstory: CarstoryType;
  error?: Maybe<Scalars['String']>;
};

export type CarstoryType = {
  __typename?: 'CarstoryType';
  vehicleInfo: VehicleInfoType;
};

export type ChromeDivision = {
  __typename?: 'ChromeDivision';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ChromeModelYear = {
  __typename?: 'ChromeModelYear';
  id: Scalars['Int'];
  name: Scalars['Int'];
};

export type ChromeModels = {
  __typename?: 'ChromeModels';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ChromeStyles = {
  __typename?: 'ChromeStyles';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CloneAppraisalInput = {
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CommentsInput = {
  appraiser?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
};

export type CompleteVehicleUserEvaluationInput = {
  evaluationId: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  event?: Maybe<Scalars['String']>;
};

export type CompleteVehicleUserEvaluationMethod = {
  __typename?: 'CompleteVehicleUserEvaluationMethod';
  evaluationId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export enum Countries {
  US = 'US',
  CA = 'CA'
}

export type CreateMonkaiInspectionInput = {
  vehId: Scalars['Int'];
};

export type CreateMonkaiInspectionResponse = {
  __typename?: 'CreateMonkaiInspectionResponse';
  operation?: Maybe<Scalars['String']>;
  result: CreateMonkaiInspectionResult;
};

export type CreateMonkaiInspectionResult = {
  __typename?: 'CreateMonkaiInspectionResult';
  id?: Maybe<Scalars['String']>;
  object_type?: Maybe<Scalars['String']>;
};

export type CreatePaveSessionInput = {
  vehId: Scalars['Int'];
  phone: Scalars['String'];
};

export type CreatePhysicalEvaluationMethod = {
  __typename?: 'CreatePhysicalEvaluationMethod';
  result: Appraisal;
  exists?: Maybe<Scalars['Boolean']>;
  modified?: Maybe<Scalars['Boolean']>;
};

export type Damage = {
  __typename?: 'Damage';
  id: Scalars['String'];
  side: Scalars['String'];
  labels: Label;
  bucket: Scalars['String'];
  actions: Array<DamageAction>;
};

export type DamageAction = {
  __typename?: 'DamageAction';
  id: Scalars['String'];
  name: Scalars['String'];
  labels: Label;
  deval_low: Scalars['Float'];
  deval_high: Scalars['Float'];
  repair_low: Scalars['Float'];
  repair_high: Scalars['Float'];
};

export type DamageInput = {
  labels: LabelInput;
  side: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  part: Scalars['String'];
  variant: Scalars['String'];
  bucket: Scalars['String'];
};

export type DashStatisticsListMethod = {
  __typename?: 'DashStatisticsListMethod';
  operation: Scalars['String'];
  result: Array<VehicleListDashStatistics>;
};

export type DashStatisticsMethod = {
  __typename?: 'DashStatisticsMethod';
  operation: Scalars['String'];
  result: Array<DashStatisticsType>;
};

export type DashStatisticsType = {
  __typename?: 'DashStatisticsType';
  user: UserStaisticDataType;
  purchased: Scalars['Int'];
  purchasedIds: Array<Maybe<Scalars['Int']>>;
  appraisalIds: Array<Maybe<Scalars['Int']>>;
  priceToMarket: Scalars['Int'];
  costToMarket: Scalars['Int'];
  average: Scalars['Int'];
  count: Scalars['Int'];
};

export type DataFullCurrentStyleElement = {
  __typename?: 'DataFullCurrentStyleElement';
  currentStyleId: Scalars['Int'];
  drivetrain: Scalars['String'];
  modelYear: Scalars['String'];
  bestModelName: Scalars['String'];
  bestMakeName: Scalars['String'];
  bestStyleName: Scalars['String'];
  bestTrimName: Scalars['String'];
  cylinders: Scalars['Int'];
  bodyStyle: Scalars['String'];
  transmissionTypeId: Scalars['String'];
  transmissionDesc: Scalars['String'];
  drivetrainDesc: Scalars['String'];
  exteriorColor: Array<ExteriorColor>;
  interiorColor: Array<InteriorColor>;
};


export type Dealership = {
  __typename?: 'Dealership';
  id: Scalars['Int'];
  slug: Scalars['String'];
  name: Scalars['String'];
  country: Scalars['String'];
};

export type DecodeBbItemInput = {
  auto?: Maybe<Scalars['String']>;
  avg?: Maybe<Scalars['String']>;
  clean?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resid12?: Maybe<Scalars['String']>;
  resid24?: Maybe<Scalars['String']>;
  resid30?: Maybe<Scalars['String']>;
  resid36?: Maybe<Scalars['String']>;
  resid42?: Maybe<Scalars['String']>;
  resid48?: Maybe<Scalars['String']>;
  resid60?: Maybe<Scalars['String']>;
  resid72?: Maybe<Scalars['String']>;
  rough?: Maybe<Scalars['String']>;
  uoc?: Maybe<Scalars['String']>;
  xclean?: Maybe<Scalars['String']>;
};

export type DecodeBbItemType = {
  __typename?: 'DecodeBBItemType';
  auto?: Maybe<Scalars['String']>;
  avg?: Maybe<Scalars['String']>;
  clean?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resid12?: Maybe<Scalars['String']>;
  resid24?: Maybe<Scalars['String']>;
  resid30?: Maybe<Scalars['String']>;
  resid36?: Maybe<Scalars['String']>;
  resid42?: Maybe<Scalars['String']>;
  resid48?: Maybe<Scalars['String']>;
  resid60?: Maybe<Scalars['String']>;
  resid72?: Maybe<Scalars['String']>;
  rough?: Maybe<Scalars['String']>;
  uoc?: Maybe<Scalars['String']>;
  xclean?: Maybe<Scalars['String']>;
};

export type DecodeVinBbResult = {
  __typename?: 'DecodeVinBBResult';
  makeName: Scalars['String'];
  modelName: Scalars['String'];
  modelYear: Scalars['String'];
  bestTrimName: Scalars['String'];
  styles: Array<Scalars['Int']>;
  trims: Array<DefoltElementDecode>;
  engines: Array<DefoltElementDecode>;
  bodyStyles: Array<DefoltElementDecode>;
  tractions: Array<DefoltElementDecode>;
  transmissions: Array<DefoltElementDecode>;
  exteriorColors: Array<DefoltElementColorDecode>;
  interiorColors: Array<DefoltElementColorDecode>;
  options: Array<OptionsElementBb>;
};

export type DecodeVinBlackBookMethod = {
  __typename?: 'DecodeVinBlackBookMethod';
  operation: Scalars['String'];
  result: DecodeVinBbResult;
};

export type DecodeVinMethod = {
  __typename?: 'DecodeVinMethod';
  operation: Scalars['String'];
  result: DecodeVinResult;
};

export type DecodeVinNewMethod = {
  __typename?: 'DecodeVinNewMethod';
  operation: Scalars['String'];
  result: DecodeVinNewResult;
};

export type DecodeVinNewResult = {
  __typename?: 'DecodeVinNewResult';
  makeName: Scalars['String'];
  modelName: Scalars['String'];
  modelYear: Scalars['String'];
  bestTrimName: Scalars['String'];
  styles: Array<Scalars['Int']>;
  trims: Array<DefoltElementDecode>;
  engines: Array<DefoltElementDecode>;
  bodyStyles: Array<DefoltElementDecode>;
  tractions: Array<DefoltElementDecode>;
  transmissions: Array<DefoltElementDecode>;
  exteriorColors: Array<DefoltElementColorDecode>;
  interiorColors: Array<DefoltElementColorDecode>;
  options: Array<OptionsElement>;
};

export type DecodeVinResult = {
  __typename?: 'DecodeVinResult';
  countStyles: Scalars['Int'];
  styles: Array<StyleItem>;
  dataFullCurrentStyle: DataFullCurrentStyleElement;
};

export type DefoltElementBbDecode = {
  __typename?: 'DefoltElementBBDecode';
  label: Scalars['String'];
  value: Scalars['String'];
  styles: Array<Scalars['String']>;
};

export type DefoltElementColorDecode = {
  __typename?: 'DefoltElementColorDecode';
  code: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
  rgbValue: Scalars['String'];
  styles: Array<Scalars['Int']>;
};

export type DefoltElementDecode = {
  __typename?: 'DefoltElementDecode';
  label: Scalars['String'];
  value: Scalars['String'];
  styles: Array<Scalars['Int']>;
};

export type Detail = {
  __typename?: 'Detail';
  name: Scalars['String'];
  label: Scalars['String'];
  mustAttach: Scalars['Boolean'];
  order: Scalars['Int'];
  value: Scalars['Int'];
};

export type DetailInput = {
  name: Scalars['String'];
  label: Scalars['String'];
  mustAttach: Scalars['Boolean'];
  order: Scalars['Int'];
  value: Scalars['Int'];
};

export type Device = {
  __typename?: 'Device';
  category: Scalars['String'];
  mobile_brand_name: Scalars['String'];
  mobile_model_name: Scalars['String'];
  mobile_marketing_name: Scalars['String'];
  mobile_os_hardware_model: Scalars['String'];
  operating_system: Scalars['String'];
  operating_system_version: Scalars['String'];
  vendor_id: Scalars['String'];
  advertising_id: Scalars['String'];
  language: Scalars['String'];
  is_limited_ad_tracking: Scalars['String'];
  time_zone_offset_seconds: Scalars['String'];
  browser: Scalars['String'];
  browser_version: Scalars['String'];
  web_info: WebInfo;
};

export type DisclosuresInput = {
  name: Scalars['String'];
  text: Scalars['String'];
  value: Scalars['String'];
  children: Array<Scalars['String']>;
};

export type DisclosuresType = {
  __typename?: 'DisclosuresType';
  name: Scalars['String'];
  text: Scalars['String'];
  value: Scalars['String'];
  children: Array<Scalars['String']>;
};

export type DisclosuresUsInput = {
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DisclosuresUsType = {
  __typename?: 'DisclosuresUSType';
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Distances {
  km = 'km',
  mi = 'mi'
}

export type DivisionsMethod = {
  __typename?: 'DivisionsMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ChromeDivision>;
};

export type DrivErrorInput = {
  error?: Maybe<Scalars['String']>;
};

export type DrivErrorType = {
  __typename?: 'DrivErrorType';
  error?: Maybe<Scalars['String']>;
};

export type DrivlyEmailInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  link: Scalars['String'];
};

export type EventParams = {
  __typename?: 'EventParams';
  key: Scalars['String'];
  value: EventParamsValue;
};

export type EventParamsType = {
  __typename?: 'EventParamsType';
  key: Scalars['String'];
  value: EventParamsValue;
};

export type EventParamsValue = {
  __typename?: 'EventParamsValue';
  string_value: Scalars['String'];
  int_value: Scalars['String'];
  float_value: Scalars['String'];
  double_value: Scalars['String'];
};

export type EventParamsValueType = {
  __typename?: 'EventParamsValueType';
  string_value: Scalars['String'];
  int_value: Scalars['Int'];
};

export type ExteriorColor = {
  __typename?: 'ExteriorColor';
  genericColor: Array<ExteriorColorElement>;
  styleId: Array<Scalars['Int']>;
  installed: Scalars['String'];
  colorCode: Scalars['String'];
  colorName: Scalars['String'];
  rgbValue: Scalars['String'];
};

export type ExteriorColorElement = {
  __typename?: 'ExteriorColorElement';
  installed: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type FeatureForMyResponse = {
  __typename?: 'FeatureForMyResponse';
  operation: Scalars['String'];
  result: Array<TurnOnForMy>;
};

export type FeatureInputResponse = {
  __typename?: 'FeatureInputResponse';
  operation: Scalars['String'];
  result: ListiFeatureResult;
};

export type FeatureResponse = {
  __typename?: 'FeatureResponse';
  operation: Scalars['String'];
  result: ListiFeatureResult;
};

export type FilterQueryInput = {
  module: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  locations: Array<Scalars['String']>;
  saleTypes: Array<Scalars['String']>;
  origins: Array<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

export type FollowUp = {
  __typename?: 'FollowUp';
  created: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['Int'];
  user_id: Scalars['String'];
};

export type FollowUpInput = {
  created: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Geo = {
  __typename?: 'Geo';
  continent: Scalars['String'];
  country: Scalars['String'];
  region: Scalars['String'];
  city: Scalars['String'];
  sub_continent: Scalars['String'];
  metro: Scalars['String'];
};

export type GeoType = {
  __typename?: 'GeoType';
  continent: Scalars['String'];
  country: Scalars['String'];
  region: Scalars['String'];
  city: Scalars['String'];
  sub_continent: Scalars['String'];
  metro: Scalars['String'];
};

export type GetAppraisalWithPricingDataByVinResult = {
  __typename?: 'GetAppraisalWithPricingDataByVinResult';
  Appraisal: AppraisalVAuto;
};

export type GetCarproofMethod = {
  __typename?: 'GetCarproofMethod';
  operation?: Maybe<Scalars['String']>;
  result: GetCarproofType;
};

export type GetCarproofType = {
  __typename?: 'GetCarproofType';
  InvoicedTo: Scalars['String'];
  Make: Scalars['String'];
  MobileReportLink: Scalars['String'];
  MobileReportLinkHideLiens: Scalars['String'];
  Model: Scalars['String'];
  Reference: Scalars['String'];
  ReportLink: Scalars['String'];
  ReportLinkHideLiens: Scalars['String'];
  ReportNumber: Scalars['Int'];
  ReportType: Scalars['Int'];
  ReportTypeName: Scalars['String'];
  Vin: Scalars['String'];
  Year: Scalars['String'];
};

export type GetCarstoryByVinMethod = {
  __typename?: 'GetCarstoryByVINMethod';
  operation?: Maybe<Scalars['String']>;
  result: CarstoryByVinType;
};

export type GetListForBedStartCompleteMethod = {
  __typename?: 'GetListForBedStartCompleteMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ListProblemStartAndComplete>;
};

export type GetListForBedStartCompleteOne = {
  __typename?: 'GetListForBedStartCompleteOne';
  event_params: Array<EventParamsType>;
  geo: GeoType;
};

export type GetListForBedStartCompleteOneMethod = {
  __typename?: 'GetListForBedStartCompleteOneMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<GetListForBedStartCompleteOne>;
};

export type GetListForBedVinDecodeMethod = {
  __typename?: 'GetListForBedVinDecodeMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ListProblemStartAndComplete>;
};

export type GetMonkaiInspectionResponse = {
  __typename?: 'GetMonkaiInspectionResponse';
  operation?: Maybe<Scalars['String']>;
  result: GetMonkaiInspectionResult;
};

export type GetMonkaiInspectionResult = {
  __typename?: 'GetMonkaiInspectionResult';
  _id: Scalars['String'];
  vehId: Scalars['Int'];
  created: Scalars['String'];
  monkaiInspectionId?: Maybe<Scalars['String']>;
  monkaiInspectionStatus?: Maybe<Scalars['String']>;
  photosMonkaiInspections: Array<MonkaiImage>;
  monkaiVehicleInfo: Array<Maybe<MonkaiVehicleInfoType>>;
};

export type GetMonkaiTokenResponse = {
  __typename?: 'GetMonkaiTokenResponse';
  operation?: Maybe<Scalars['String']>;
  result: Scalars['String'];
};

export type GlaEvalItem = {
  __typename?: 'GlaEvalItem';
  count: Scalars['Int'];
  diff: Scalars['Int'];
  total: Scalars['Int'];
  name: Scalars['String'];
};

export type GlaEvalResponse = {
  __typename?: 'GlaEvalResponse';
  operation: Scalars['String'];
  result: Array<GlaEvalItem>;
};

export type GlaFollowItem = {
  __typename?: 'GlaFollowItem';
  booked: Scalars['Int'];
  called: Scalars['Int'];
  count: Scalars['Int'];
  none: Scalars['Int'];
  priced: Scalars['Int'];
  smsSent: Scalars['Int'];
  name: Scalars['String'];
};

export type GlaFollowResponse = {
  __typename?: 'GlaFollowResponse';
  operation: Scalars['String'];
  result: Array<GlaFollowItem>;
};

export type GlaItem = {
  __typename?: 'GlaItem';
  totalAppraisal: Scalars['Int'];
  totalAsking: Scalars['Int'];
  totalGap: Scalars['Int'];
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type GlaResponse = {
  __typename?: 'GlaResponse';
  operation: Scalars['String'];
  result: Array<GlaItem>;
};

export type InfoForUserAnalitycMethod = {
  __typename?: 'InfoForUserAnalitycMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<Subscription>;
};

export type InteriorColor = {
  __typename?: 'InteriorColor';
  genericColor?: Maybe<Scalars['String']>;
  styleId: Array<Scalars['Int']>;
  installed: Scalars['String'];
  colorCode: Scalars['String'];
  colorName: Scalars['String'];
  rgbValue: Scalars['String'];
};

export type ItemSubscriptionResult = {
  __typename?: 'ItemSubscriptionResult';
  active: Scalars['Boolean'];
  emitter: Scalars['String'];
  event: Scalars['String'];
  medium: Scalars['String'];
  options: Scalars['String'];
  subscriber: Scalars['String'];
};

export type JsonAppraisalInput = {
  jsonString?: Maybe<Scalars['String']>;
  isSendSocket?: Maybe<Scalars['Boolean']>;
};

export type Label = {
  __typename?: 'Label';
  en: Scalars['String'];
  fr: Scalars['String'];
};

export type LabelInput = {
  en: Scalars['String'];
  fr: Scalars['String'];
};

export enum Languages {
  fr = 'fr',
  en = 'en'
}

export type Licenseplate = {
  __typename?: 'Licenseplate';
  vin?: Maybe<Scalars['String']>;
};

export type LicenseplateMethod = {
  __typename?: 'LicenseplateMethod';
  operation?: Maybe<Scalars['String']>;
  result: Licenseplate;
};

export type ListProblemStartAndComplete = {
  __typename?: 'ListProblemStartAndComplete';
  user_id: Scalars['String'];
  problem_with_start_vehicle?: Maybe<Scalars['Int']>;
  problem_with_complete_vehicle?: Maybe<Scalars['Int']>;
};

export type ListiFeatureResponse = {
  __typename?: 'ListiFeatureResponse';
  operation: Scalars['String'];
  result: Array<ListiFeatureResult>;
};

export type ListiFeatureResult = {
  __typename?: 'ListiFeatureResult';
  _id: Scalars['String'];
  name: Scalars['String'];
  activate: Scalars['Boolean'];
  description: Scalars['String'];
  userIds: Array<Scalars['String']>;
  created: Scalars['String'];
  updated: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type ListiUsersFeatureResponse = {
  __typename?: 'ListiUsersFeatureResponse';
  operation: Scalars['String'];
  result: Array<ListiUsersFeatureResult>;
};

export type ListiUsersFeatureResult = {
  __typename?: 'ListiUsersFeatureResult';
  _id: Scalars['String'];
  id: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  type: Scalars['String'];
  googleId: Scalars['String'];
  teams: Array<Scalars['String']>;
  roles: Array<Scalars['String']>;
  segmentation: Scalars['String'];
  samAccountName: Scalars['String'];
  deleted: Scalars['String'];
  enableFeature: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
};

export type Listing = {
  __typename?: 'Listing';
  id: Scalars['Int'];
  segmentation: Scalars['String'];
  formulaPrice: Scalars['String'];
  completedById: Scalars['Int'];
  startedById: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['String'];
  series: Scalars['String'];
  origin: Scalars['String'];
  originSource: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['String'];
  vin: Scalars['String'];
  referenceId: Scalars['String'];
  referenceLink: Scalars['String'];
  referenceTitle: Scalars['String'];
  askingValue: Scalars['String'];
  custName: Scalars['String'];
  custSmsNumber: Scalars['String'];
  followUps: Array<FollowUp>;
  disclosures: Array<Scalars['String']>;
  comments: ListingComments;
  vAutoId: Scalars['String'];
  appraisalValue: Scalars['String'];
  preliminaryValue: Scalars['String'];
  location: Scalars['String'];
  status: Scalars['String'];
  submitted: Scalars['String'];
  completed: Scalars['String'];
  started: Scalars['String'];
  updated: Scalars['String'];
  created: Scalars['String'];
  owner: Scalars['String'];
  ownerId: Scalars['Int'];
  completedBy: Scalars['String'];
  offeredValue: Scalars['String'];
  autoSmsSent: Scalars['Boolean'];
  previouslyDamaged: Scalars['Boolean'];
};

export type ListingComments = {
  __typename?: 'ListingComments';
  client: Scalars['String'];
  appraiser: Scalars['String'];
  document: Scalars['String'];
};

export type ListingCommentsInput = {
  client: Scalars['String'];
  appraiser: Scalars['String'];
};

export type ListingInput = {
  id?: Maybe<Scalars['Int']>;
  segmentation?: Maybe<Scalars['String']>;
  formulaPrice?: Maybe<Scalars['String']>;
  completedById?: Maybe<Scalars['Int']>;
  startedById?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  originSource?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Int']>;
  odometerMiles?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceLink?: Maybe<Scalars['String']>;
  referenceTitle?: Maybe<Scalars['String']>;
  askingValue?: Maybe<Scalars['String']>;
  custName?: Maybe<Scalars['String']>;
  custSmsNumber?: Maybe<Scalars['String']>;
  followUps?: Maybe<Array<Maybe<FollowUpInput>>>;
  disclosures?: Maybe<Array<Scalars['String']>>;
  comments?: Maybe<ListingCommentsInput>;
  vAutoId?: Maybe<Scalars['String']>;
  appraisalValue?: Maybe<Scalars['String']>;
  preliminaryValue?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  completedBy?: Maybe<Scalars['String']>;
  offeredValue?: Maybe<Scalars['String']>;
  autoSmsSent?: Maybe<Scalars['Boolean']>;
  previouslyDamaged?: Maybe<Scalars['Boolean']>;
};

export type ListingMethod = {
  __typename?: 'ListingMethod';
  operation?: Maybe<Scalars['String']>;
  result: Listing;
};

export type ListingStatusResult = {
  __typename?: 'ListingStatusResult';
  modified: Scalars['Boolean'];
};

export type ListingsCountMethod = {
  __typename?: 'ListingsCountMethod';
  operation?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Int']>;
};

export type ListingsCreateResult = {
  __typename?: 'ListingsCreateResult';
  result: Listing;
};

export type ListingsMethod = {
  __typename?: 'ListingsMethod';
  operation?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Boolean']>;
  result: Array<Listing>;
};

export type Location = {
  __typename?: 'Location';
  _id: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  approbation: Scalars['String'];
  brandingPath: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  description: Scalars['String'];
  estimationAutoSmsContent: Scalars['String'];
  nomadGroup: Array<Scalars['String']>;
  postalCode: Scalars['String'];
  segmentation: Scalars['String'];
  state: Scalars['String'];
  periodPrice: Array<PeriodPriceType>;
};

export type LocationInput = {
  _id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  approbation?: Maybe<Scalars['String']>;
  brandingPath?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimationAutoSmsContent?: Maybe<Scalars['String']>;
  nomadGroup?: Maybe<Array<Scalars['String']>>;
  postalCode?: Maybe<Scalars['String']>;
  segmentation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  periodPrice?: Maybe<Array<PeriodPriceInput>>;
};

export type LocationMethod = {
  __typename?: 'LocationMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<Location>;
};

export type ManheimDataByVinMethod = {
  __typename?: 'ManheimDataByVinMethod';
  result: ManheimDataByVinResult;
};

export type ManheimDataByVinResult = {
  __typename?: 'ManheimDataByVinResult';
  href?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  items: Array<ManheimVehicleType>;
};

export type ManheimDescriptionType = {
  __typename?: 'ManheimDescriptionType';
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  subSeries: Scalars['String'];
};

export type ManheimInput = {
  href: Scalars['String'];
  count: Scalars['Int'];
  items: Array<Maybe<ManheimItemInput>>;
};

export type ManheimItemDescriptionInput = {
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  subSeries: Scalars['String'];
};

export type ManheimItemDescriptionType = {
  __typename?: 'ManheimItemDescriptionType';
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  subSeries: Scalars['String'];
};

export type ManheimItemInput = {
  href: Scalars['String'];
  extendedCoverage: Scalars['String'];
  description: ManheimItemDescriptionInput;
  wholesale: ManheimItemWholesaleInput;
  averageOdometer?: Maybe<Scalars['Int']>;
  odometerUnits?: Maybe<Scalars['String']>;
  averageGrade?: Maybe<Scalars['Int']>;
  adjustedPricing?: Maybe<AdjustedPricingInput>;
  currency?: Maybe<Scalars['String']>;
  sampleSize?: Maybe<Scalars['String']>;
  requestedDate?: Maybe<Scalars['String']>;
  returnedDate?: Maybe<Scalars['String']>;
};

export type ManheimItemType = {
  __typename?: 'ManheimItemType';
  href: Scalars['String'];
  extendedCoverage: Scalars['String'];
  description: ManheimItemDescriptionType;
  wholesale: ManheimItemWholesaleType;
  averageOdometer?: Maybe<Scalars['Int']>;
  odometerUnits?: Maybe<Scalars['String']>;
  adjustedPricing: AdjustedPricingType;
  averageGrade?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  sampleSize?: Maybe<Scalars['String']>;
  requestedDate?: Maybe<Scalars['String']>;
  returnedDate?: Maybe<Scalars['String']>;
};

export type ManheimItemWholesaleInput = {
  above?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Int']>;
  below?: Maybe<Scalars['Int']>;
};

export type ManheimItemWholesaleType = {
  __typename?: 'ManheimItemWholesaleType';
  above?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Int']>;
  below?: Maybe<Scalars['Int']>;
};

export type ManheimType = {
  __typename?: 'ManheimType';
  href: Scalars['String'];
  count: Scalars['Int'];
  items: Array<ManheimItemType>;
};

export type ManheimVehicleType = {
  __typename?: 'ManheimVehicleType';
  href?: Maybe<Scalars['String']>;
  extendedCoverage?: Maybe<Scalars['Boolean']>;
  description: ManheimDescriptionType;
  wholesale: WholesaleType;
  averageOdometer: Scalars['Int'];
  averageGrade: Scalars['Int'];
  adjustedPricing: AdjustedPricingType;
  odometerUnits: Scalars['String'];
  currency: Scalars['String'];
  sampleSize: Scalars['String'];
  requestedDate: Scalars['String'];
  returnedDate: Scalars['String'];
  bestMatch?: Maybe<Scalars['Boolean']>;
};

export type Messaage = {
  __typename?: 'Messaage';
  _id: Scalars['String'];
  channel: Scalars['String'];
  created: Scalars['String'];
  read_by?: Maybe<Array<Scalars['String']>>;
  text: Scalars['String'];
  type: Scalars['String'];
  user_id: Scalars['String'];
  user_name: Scalars['String'];
};

export type MessaagesMethod = {
  __typename?: 'MessaagesMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<Messaage>;
};

export type ModelYearsMethod = {
  __typename?: 'ModelYearsMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ChromeModelYear>;
};

export type ModelsMethod = {
  __typename?: 'ModelsMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ChromeModels>;
};

export type MonkaiImage = {
  __typename?: 'MonkaiImage';
  id: Scalars['Int'];
  src: Scalars['String'];
  srcMonkai: Scalars['String'];
};

export type MonkaiImageInput = {
  id?: Maybe<Scalars['Int']>;
  src?: Maybe<Scalars['String']>;
  srcMonkai?: Maybe<Scalars['String']>;
};

export type MonkaiVehicleInfoType = {
  __typename?: 'MonkaiVehicleInfoType';
  brand: Scalars['String'];
  car_registration?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  date_of_circulation?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  duplicate_keys?: Maybe<Scalars['String']>;
  expertise_requested?: Maybe<Scalars['String']>;
  exterior_cleanliness?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  interior_cleanliness?: Maybe<Scalars['String']>;
  market_value?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  object_type?: Maybe<Scalars['String']>;
  owner_info?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  repair_estimate?: Maybe<Scalars['String']>;
  trade_in_offer?: Maybe<Scalars['String']>;
  vehicle_quotation?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bbInfoValues: BbInfoValuesMethod;
  cloneAppraisal: AppraisalMethod;
  completeVehicleUserEvaluation: CompleteVehicleUserEvaluationMethod;
  createAppraisal: AppraisalMethod;
  createAppraisalConditions: AppraisalConditionsResponse;
  createDamage: Damage;
  createDamageAction: Damage;
  createEmptyMonkaiInspection?: Maybe<CreateMonkaiInspectionResponse>;
  createListing: ListingsCreateResult;
  createMonkaiInspection?: Maybe<CreateMonkaiInspectionResponse>;
  createPaveSession: AppraisalMethod;
  createPhysicalEvaluation: CreatePhysicalEvaluationMethod;
  dashStatistics: DashStatisticsMethod;
  deleteDamage: Damage;
  deleteDamageAction: Damage;
  featureUpdate: FeatureInputResponse;
  getCarstoryByVIN: GetCarstoryByVinMethod;
  notificationRead: NotificationReadMethod;
  restartAppraisal: AppraisalMethod;
  sentEmail: SentEmailMethod;
  setSubscription: SetSubscriptionResult;
  signIn?: Maybe<Auth>;
  signUp?: Maybe<Auth>;
  startMonkaiInspection?: Maybe<CreateMonkaiInspectionResponse>;
  updateAppraisal: AppraisalMethod;
  updateAppraisalConditions: AppraisalConditionsResponse;
  updateDamage: Damage;
  updateDamageAction: Damage;
  updateListing: UpdateListingMethod;
  updateListingStatus: ListingStatusResult;
  updateLocations: LocationMethod;
  updatePhysicalEvaluation: UpdatePhysicalEvaluationMethod;
  updateStatus: UpdateStatusResponse;
  updateVehicleUserEvaluation: AppriselVehicleType;
  uploadAppraisalPhoto: PhotoMethod;
  uploadAppraisalPhotoReactive: PhotoMethod;
  userUpdate: User;
};


export type MutationBbInfoValuesArgs = {
  input: BbInfoValuesInput;
};


export type MutationCloneAppraisalArgs = {
  input: JsonAppraisalInput;
};


export type MutationCompleteVehicleUserEvaluationArgs = {
  id: Scalars['String'];
  input: CompleteVehicleUserEvaluationInput;
};


export type MutationCreateAppraisalArgs = {
  input: JsonAppraisalInput;
};


export type MutationCreateAppraisalConditionsArgs = {
  input: AppraisalConditionsInput;
};


export type MutationCreateDamageArgs = {
  input: DamageInput;
};


export type MutationCreateDamageActionArgs = {
  input: ActionInput;
};


export type MutationCreateEmptyMonkaiInspectionArgs = {
  input: CreateMonkaiInspectionInput;
};


export type MutationCreateListingArgs = {
  input: ListingInput;
};


export type MutationCreateMonkaiInspectionArgs = {
  input: CreateMonkaiInspectionInput;
};


export type MutationCreatePaveSessionArgs = {
  input?: Maybe<CreatePaveSessionInput>;
};


export type MutationCreatePhysicalEvaluationArgs = {
  input: AppraisalUpdateInput;
};


export type MutationDashStatisticsArgs = {
  input?: Maybe<FilterQueryInput>;
};


export type MutationDeleteDamageArgs = {
  id: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDeleteDamageActionArgs = {
  id: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationFeatureUpdateArgs = {
  id: Scalars['String'];
  input: FeatureInput;
};


export type MutationGetCarstoryByVinArgs = {
  input: UrlTypeInput;
};


export type MutationNotificationReadArgs = {
  input?: Maybe<NotificationReadInput>;
};


export type MutationRestartAppraisalArgs = {
  input?: Maybe<Restart>;
};


export type MutationSentEmailArgs = {
  input: SentEmailInput;
};


export type MutationSetSubscriptionArgs = {
  input?: Maybe<SetSubscriptionInput>;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationStartMonkaiInspectionArgs = {
  input: StartMonkaiInspectionInput;
};


export type MutationUpdateAppraisalArgs = {
  input: JsonAppraisalInput;
};


export type MutationUpdateAppraisalConditionsArgs = {
  id: Scalars['Int'];
  input: AppraisalConditionsInput;
};


export type MutationUpdateDamageArgs = {
  id: Scalars['String'];
  input: DamageInput;
};


export type MutationUpdateDamageActionArgs = {
  id: Scalars['String'];
  input: ActionInput;
};


export type MutationUpdateListingArgs = {
  input: UpdateListingInput;
  id: Scalars['Int'];
};


export type MutationUpdateListingStatusArgs = {
  input: UpdateListingStatusInput;
  id: Scalars['Int'];
};


export type MutationUpdateLocationsArgs = {
  id: Scalars['String'];
  input: LocationInput;
};


export type MutationUpdatePhysicalEvaluationArgs = {
  id: Scalars['String'];
  input: AppraisalUpdateWithSocketInput;
};


export type MutationUpdateStatusArgs = {
  id: Scalars['String'];
  input: UpdateStatusInput;
};


export type MutationUpdateVehicleUserEvaluationArgs = {
  id: Scalars['String'];
  input: AppriselVehicleInput;
};


export type MutationUploadAppraisalPhotoArgs = {
  input: Scalars['Upload'];
};


export type MutationUploadAppraisalPhotoReactiveArgs = {
  id: Scalars['Int'];
  input: Scalars['Upload'];
};


export type MutationUserUpdateArgs = {
  id: Scalars['Int'];
  input: UserInput;
};

export type NotificationCountMethod = {
  __typename?: 'NotificationCountMethod';
  operation: Scalars['String'];
  result: Scalars['Int'];
};

export type NotificationListItem = {
  __typename?: 'NotificationListItem';
  _id: Scalars['String'];
  id: Scalars['Int'];
  created: Scalars['String'];
  group: Scalars['String'];
  text: Scalars['String'];
  source: NotificationListItemSource;
  read_by: Array<Scalars['String']>;
  received_by: Array<Scalars['String']>;
};

export type NotificationListItemSource = {
  __typename?: 'NotificationListItemSource';
  type: Scalars['String'];
  id: Scalars['Int'];
};

export type NotificationListMethod = {
  __typename?: 'NotificationListMethod';
  operation: Scalars['String'];
  result: Array<NotificationListItem>;
};

export type NotificationNameMethod = {
  __typename?: 'NotificationNameMethod';
  operation: Scalars['String'];
  result: Scalars['String'];
};

export type NotificationReadInput = {
  id: Scalars['Int'];
};

export type NotificationReadMethod = {
  __typename?: 'NotificationReadMethod';
  operation: Scalars['String'];
  result: Scalars['Boolean'];
};

export type OffersByVin = {
  __typename?: 'OffersByVIN';
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  vroomPrice?: Maybe<Scalars['Int']>;
  vroomGrade?: Maybe<Scalars['String']>;
  vroomUrl?: Maybe<Scalars['String']>;
  vroomError?: Maybe<DrivErrorType>;
  carvanaPrice?: Maybe<Scalars['Int']>;
  carvanaUrl?: Maybe<Scalars['String']>;
  carvanaError?: Maybe<DrivErrorType>;
  carmaxPrice?: Maybe<Scalars['Int']>;
  carmaxUrl?: Maybe<Scalars['String']>;
  carmaxDeclineReason?: Maybe<Scalars['String']>;
  carmaxError?: Maybe<DrivErrorType>;
  retry?: Maybe<Scalars['String']>;
};

export type OffersByVinInput = {
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  vroomPrice?: Maybe<Scalars['Int']>;
  vroomGrade?: Maybe<Scalars['String']>;
  vroomUrl?: Maybe<Scalars['String']>;
  vroomError?: Maybe<DrivErrorInput>;
  carvanaPrice?: Maybe<Scalars['Int']>;
  carvanaUrl?: Maybe<Scalars['String']>;
  carvanaError?: Maybe<DrivErrorInput>;
  carmaxPrice?: Maybe<Scalars['Int']>;
  carmaxUrl?: Maybe<Scalars['String']>;
  carmaxDeclineReason?: Maybe<Scalars['String']>;
  carmaxError?: Maybe<DrivErrorInput>;
  retry?: Maybe<Scalars['String']>;
};

export type OptionsElement = {
  __typename?: 'OptionsElement';
  nameGroup: Scalars['String'];
  subOptions: Array<SubOptionsList>;
};

export type OptionsElementBb = {
  __typename?: 'OptionsElementBB';
  addDeductList: Array<Maybe<AddDeductListItem>>;
  styles: Array<Maybe<Scalars['String']>>;
};

export type OptionsElementInput = {
  nameGroup: Scalars['String'];
  subOptions: Array<SubOptionsListInput>;
};

export type OptionsVinDecodeBb = {
  __typename?: 'OptionsVinDecodeBB';
  auto: Scalars['String'];
  avg: Scalars['Int'];
  clean: Scalars['Int'];
  name: Scalars['String'];
  resid12: Scalars['Int'];
  resid24: Scalars['Int'];
  resid30: Scalars['Int'];
  resid36: Scalars['Int'];
  resid42: Scalars['Int'];
  resid48: Scalars['Int'];
  resid60: Scalars['Int'];
  resid72: Scalars['Int'];
  rough: Scalars['Int'];
  uoc: Scalars['String'];
  xclean: Scalars['Int'];
};

export type OptionsVinDecodeBbInput = {
  auto: Scalars['String'];
  avg: Scalars['Int'];
  clean: Scalars['Int'];
  name: Scalars['String'];
  resid12: Scalars['Int'];
  resid24: Scalars['Int'];
  resid30: Scalars['Int'];
  resid36: Scalars['Int'];
  resid42: Scalars['Int'];
  resid48: Scalars['Int'];
  resid60: Scalars['Int'];
  resid72: Scalars['Int'];
  rough: Scalars['Int'];
  uoc: Scalars['String'];
  xclean: Scalars['Int'];
};

export type Package = {
  __typename?: 'Package';
  style: PackageStyle;
};

export type PackageMethod = {
  __typename?: 'PackageMethod';
  operation?: Maybe<Scalars['String']>;
  result: Package;
};

export type PackageStyle = {
  __typename?: 'PackageStyle';
  styleId: Scalars['String'];
  trimName: Scalars['String'];
  modelYear: Scalars['String'];
  divisionName: Scalars['String'];
  consumerFriendlyBodyType?: Maybe<Scalars['String']>;
  consumerFriendlyDrivetrain?: Maybe<Scalars['String']>;
  consumerFriendlyStyleName: Scalars['String'];
  consumerFriendlyModelName: Scalars['String'];
};

export type PeriodPriceInput = {
  percent: Scalars['Int'];
  startPrice: Scalars['Int'];
  endPrice: Scalars['Int'];
};

export type PeriodPriceType = {
  __typename?: 'PeriodPriceType';
  percent: Scalars['Int'];
  startPrice: Scalars['Int'];
  endPrice: Scalars['Int'];
};

export type PermissionsType = {
  __typename?: 'PermissionsType';
  slug: Scalars['String'];
  module: Scalars['String'];
  action: Scalars['String'];
  resources: Scalars['String'];
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['Int'];
  src: Scalars['String'];
  zone: Scalars['String'];
};

export type PhotoMethod = {
  __typename?: 'PhotoMethod';
  operation?: Maybe<Scalars['String']>;
  result: Photo;
};

export type Pin = {
  __typename?: 'Pin';
  x: Scalars['Int'];
  y: Scalars['Int'];
  mapName: Scalars['String'];
};

export type PinInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
  mapName: Scalars['String'];
};

export type PrivacyInfo = {
  __typename?: 'PrivacyInfo';
  analytics_storage: Scalars['String'];
  ads_storage: Scalars['String'];
  uses_transient_token: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  analytic: AnalitycMethod;
  appraisal: AppraisalMethod;
  appraisalVehByIdCore: AppraisalMethod;
  appraisalVehByIdWithInventoryCore: AppraisalMethod;
  appraisalVehByVin: AppraisalVehByVinMethod;
  appraisals: AppraisalsMethod;
  appraisalsCondition: AppraisalsConditionMethod;
  appraisalsCore: AppraisalsMethod;
  appraisalsCount: AppraisalsCounts;
  appraisalsNus: AppraisalsMethod;
  assessmentAppraisalsCount: AppraisalsCountMethod;
  carSides: Array<CarSide>;
  carproof: CarproofMethod;
  claimAppraisalsCount: AppraisalsCountMethod;
  damages: Array<Damage>;
  dashStatisticsBoard: DashStatisticsMethod;
  dashStatisticsPrice: DashStatisticsListMethod;
  dealerships: Array<Dealership>;
  decodeVin: DecodeVinMethod;
  decodeVinBlackBook: DecodeVinBlackBookMethod;
  decodeVinNew: DecodeVinNewMethod;
  divisions: DivisionsMethod;
  evaluatingAppraisalsCount: AppraisalsCountMethod;
  evaluatingListingsCount: ListingsCountMethod;
  evaluationAppraisalsCount: AppraisalsCountMethod;
  evaluationListingsCount: ListingsCountMethod;
  feature: FeatureResponse;
  featureForMy: FeatureForMyResponse;
  getAppraisalConditions: AppraisalConditionsResponse;
  getAuthUser: AuthUser;
  getCarGurusByVIN: CarGurusByVin;
  getCarfax: GetCarproofMethod;
  getCarfaxVPN: CarproofVpnMethod;
  getListForBedStartComplete?: Maybe<GetListForBedStartCompleteMethod>;
  getListForBedStartCompleteOne?: Maybe<GetListForBedStartCompleteOneMethod>;
  getMonkaiInspection: GetMonkaiInspectionResponse;
  getMonkaiToken: GetMonkaiTokenResponse;
  getOffersByVIN: OffersByVin;
  getOneVehicleUserEvaluation: AppriselVehicleType;
  getPropertyCount: StatusCountResponse;
  getSlaEval: GlaEvalResponse;
  getSlaFollow: GlaFollowResponse;
  getSlaGap: GlaResponse;
  getStatusCount: StatusCountResponse;
  getSubscriptions: SubscriptionMethod;
  getUserEvaluationList: AppriselVehicleMethod;
  getUserEvaluationListForAnalytics: AppriselVehicleForAnalyticsMethod;
  infoForUserAnalityc: InfoForUserAnalitycMethod;
  licenseplate?: Maybe<LicenseplateMethod>;
  listUsersAnalytic: UserListAnalitycMethod;
  listiFeature: ListiFeatureResponse;
  listiUsersFeature: ListiUsersFeatureResponse;
  listing: ListingMethod;
  listings: ListingsMethod;
  locations: LocationMethod;
  manheimDataByVin?: Maybe<ManheimDataByVinMethod>;
  messaages: MessaagesMethod;
  modelYears: ModelYearsMethod;
  models: ModelsMethod;
  newBlackBookDecoder: NewBlackBookDecoderMethod;
  newBlackBookDetailDecoder: NewBlackBookDetailDecoderMethod;
  notificationCount: NotificationCountMethod;
  notificationList: NotificationListMethod;
  notificationTitle: NotificationNameMethod;
  package: PackageMethod;
  pendingAppraisalsCount: AppraisalsCountMethod;
  roles: Array<Roles>;
  styles: StylesMethod;
  user: User;
  userById: UserByIdMethod;
  users: Array<User>;
  vAutoCheckCore: VAutoCheckMethod;
  vin: VinMethod;
  vinDetails: VinDetailsMethod;
  vinStyle: VinStyleMethod;
  virtualVehByVin: VirtualVehByVinMethod;
};


export type QueryAnalyticArgs = {
  eventName: Scalars['String'];
  date: Scalars['String'];
};


export type QueryAppraisalArgs = {
  id: Scalars['String'];
  withInventory: Scalars['Boolean'];
};


export type QueryAppraisalVehByIdCoreArgs = {
  id: Scalars['String'];
};


export type QueryAppraisalVehByIdWithInventoryCoreArgs = {
  id: Scalars['String'];
};


export type QueryAppraisalVehByVinArgs = {
  vin: Scalars['String'];
};


export type QueryAppraisalsArgs = {
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
};


export type QueryAppraisalsConditionArgs = {
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
};


export type QueryAppraisalsCoreArgs = {
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
  purchased?: Maybe<Scalars['Boolean']>;
};


export type QueryAppraisalsNusArgs = {
  ids: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
};


export type QueryCarSidesArgs = {
  variant: Scalars['String'];
};


export type QueryCarproofArgs = {
  vin: Scalars['String'];
};


export type QueryDamagesArgs = {
  variant: Scalars['String'];
  part: Scalars['String'];
  side: Scalars['String'];
};


export type QueryDashStatisticsBoardArgs = {
  select: Scalars['String'];
};


export type QueryDashStatisticsPriceArgs = {
  ids: Scalars['String'];
  select: Scalars['String'];
};


export type QueryDecodeVinArgs = {
  vin: Scalars['String'];
  country: Countries;
};


export type QueryDecodeVinBlackBookArgs = {
  vin: Scalars['String'];
  country: Countries;
};


export type QueryDecodeVinNewArgs = {
  vin: Scalars['String'];
  country?: Maybe<Countries>;
};


export type QueryDivisionsArgs = {
  modelYearId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
};


export type QueryEvaluatingListingsCountArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryEvaluationListingsCountArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryFeatureArgs = {
  id: Scalars['String'];
};


export type QueryGetAppraisalConditionsArgs = {
  id: Scalars['String'];
};


export type QueryGetCarGurusByVinArgs = {
  vin: Scalars['String'];
  mileage?: Maybe<Scalars['String']>;
};


export type QueryGetCarfaxArgs = {
  vin: Scalars['String'];
};


export type QueryGetCarfaxVpnArgs = {
  vin: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryGetListForBedStartCompleteArgs = {
  date: Scalars['String'];
};


export type QueryGetListForBedStartCompleteOneArgs = {
  date: Scalars['String'];
  email: Scalars['String'];
};


export type QueryGetMonkaiInspectionArgs = {
  id: Scalars['String'];
};


export type QueryGetMonkaiTokenArgs = {
  input?: Maybe<Scalars['String']>;
};


export type QueryGetOffersByVinArgs = {
  vin: Scalars['String'];
  mileage: Scalars['String'];
  zipCode: Scalars['String'];
};


export type QueryGetOneVehicleUserEvaluationArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetPropertyCountArgs = {
  service: Scalars['String'];
  filter: Scalars['String'];
  prop: Scalars['String'];
  value: Scalars['String'];
};


export type QueryGetSlaEvalArgs = {
  service: Scalars['String'];
  filter: Scalars['String'];
};


export type QueryGetSlaFollowArgs = {
  service: Scalars['String'];
  filter: Scalars['String'];
};


export type QueryGetSlaGapArgs = {
  service: Scalars['String'];
  filter: Scalars['String'];
};


export type QueryGetStatusCountArgs = {
  service: Scalars['String'];
  status: Scalars['String'];
  filter: Scalars['String'];
};


export type QueryGetUserEvaluationListArgs = {
  location: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  select: Scalars['String'];
  search: Scalars['String'];
  statuses: Scalars['String'];
};


export type QueryGetUserEvaluationListForAnalyticsArgs = {
  location: Scalars['String'];
  limit: Scalars['Int'];
  select: Scalars['String'];
  statuses: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryInfoForUserAnalitycArgs = {
  eventName: Scalars['String'];
  date: Scalars['String'];
  email: Scalars['String'];
};


export type QueryLicenseplateArgs = {
  plate: Scalars['String'];
  state: Scalars['String'];
};


export type QueryListUsersAnalyticArgs = {
  eventName: Scalars['String'];
  date: Scalars['String'];
};


export type QueryListiUsersFeatureArgs = {
  id: Scalars['String'];
};


export type QueryListingArgs = {
  id: Scalars['Int'];
};


export type QueryListingsArgs = {
  statuses: Scalars['String'];
  location: Scalars['String'];
  search: Scalars['String'];
  size: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryManheimDataByVinArgs = {
  vin: Scalars['String'];
  odometer: Scalars['Int'];
  grade: Scalars['String'];
};


export type QueryMessaagesArgs = {
  channel: Scalars['String'];
};


export type QueryModelsArgs = {
  modelYearId?: Maybe<Scalars['Int']>;
  divisionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
};


export type QueryNewBlackBookDecoderArgs = {
  vin: Scalars['String'];
};


export type QueryNewBlackBookDetailDecoderArgs = {
  id: Scalars['String'];
  series?: Maybe<Scalars['String']>;
};


export type QueryNotificationTitleArgs = {
  id: Scalars['Int'];
  source: Scalars['String'];
};


export type QueryPackageArgs = {
  styleId: Scalars['String'];
  lang?: Maybe<Languages>;
  country: Countries;
};


export type QueryStylesArgs = {
  modelId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryVAutoCheckCoreArgs = {
  vin: Scalars['String'];
};


export type QueryVinArgs = {
  vin: Scalars['String'];
  lang?: Maybe<Languages>;
  country: Countries;
};


export type QueryVinDetailsArgs = {
  vin: Scalars['String'];
};


export type QueryVinStyleArgs = {
  id: Scalars['Int'];
  country: Countries;
};


export type QueryVirtualVehByVinArgs = {
  vin: Scalars['String'];
};

export type ReatailPricesInput = {
  adjustedRetailAvg?: Maybe<Scalars['Int']>;
  adjustedRetailClean?: Maybe<Scalars['Int']>;
  adjustedRetailRough?: Maybe<Scalars['Int']>;
  adjustedRetailXclean?: Maybe<Scalars['Int']>;
  baseRetailAvg?: Maybe<Scalars['Int']>;
  baseRetailClean?: Maybe<Scalars['Int']>;
  baseRetailRough?: Maybe<Scalars['Int']>;
  baseRetailXclean?: Maybe<Scalars['Int']>;
};

export type ReatailPricesType = {
  __typename?: 'ReatailPricesType';
  adjustedRetailAvg?: Maybe<Scalars['Int']>;
  adjustedRetailClean?: Maybe<Scalars['Int']>;
  adjustedRetailRough?: Maybe<Scalars['Int']>;
  adjustedRetailXclean?: Maybe<Scalars['Int']>;
  baseRetailAvg?: Maybe<Scalars['Int']>;
  baseRetailClean?: Maybe<Scalars['Int']>;
  baseRetailRough?: Maybe<Scalars['Int']>;
  baseRetailXclean?: Maybe<Scalars['Int']>;
};

export type RefreshTokenInput = {
  version?: Maybe<Scalars['String']>;
};

export type RefreshTokenMethod = {
  __typename?: 'RefreshTokenMethod';
  operation?: Maybe<Scalars['String']>;
  result: TokenResult;
};

export type RequestDateTimeType = {
  __typename?: 'RequestDateTimeType';
  date?: Maybe<Scalars['String']>;
  timezone_type?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
};

export type Restart = {
  id: Scalars['Int'];
  vin: Scalars['String'];
};

export type Roles = {
  __typename?: 'Roles';
  id: Scalars['Int'];
  slug: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<PermissionsType>;
};

export type SentEmailInput = {
  subject: Scalars['String'];
  email: Scalars['String'];
  price: Scalars['Int'];
  link: Scalars['String'];
  vin: Scalars['String'];
  drivly: Array<Maybe<DrivlyEmailInput>>;
};

export type SentEmailMethod = {
  __typename?: 'SentEmailMethod';
  modified: Scalars['Boolean'];
};

export type SetSubscriptionInput = {
  emitter: Scalars['String'];
  event: Scalars['String'];
  subscriber: Scalars['String'];
  medium: Scalars['String'];
  active: Scalars['Boolean'];
};

export type SetSubscriptionResult = {
  __typename?: 'SetSubscriptionResult';
  operation?: Maybe<Scalars['String']>;
  result: Array<ItemSubscriptionResult>;
};

export type SignInInput = {
  credential: Scalars['String'];
};

export type SignInMethod = {
  __typename?: 'SignInMethod';
  operation?: Maybe<Scalars['String']>;
  result?: Maybe<TokenResult>;
};

export type SignInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpInput = {
  country: Scalars['String'];
  dealership: Scalars['String'];
};

export type StartMonkaiInspectionInput = {
  vehId: Scalars['Int'];
  monkaiInspectionId: Scalars['String'];
};

export type StatusCountResponse = {
  __typename?: 'StatusCountResponse';
  operation: Scalars['String'];
  result: Scalars['Int'];
};

export type StockImageItem = {
  __typename?: 'StockImageItem';
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  filename: Scalars['String'];
};

export type StyleElementItem = {
  __typename?: 'StyleElementItem';
  value: Scalars['String'];
  id: Scalars['Int'];
};

export type StyleItem = {
  __typename?: 'StyleItem';
  division: StyleElementItem;
  subdivision: StyleElementItem;
  model: StyleElementItem;
  basePrice: BasePriceItem;
  bodyType: Array<BodyTypeItem>;
  marketClass: StyleElementItem;
  stockImage: StockImageItem;
  mediaGallery?: Maybe<Scalars['String']>;
  acode: Array<Maybe<AcodeIcon>>;
  id: Scalars['Int'];
  modelYear: Scalars['String'];
  name: Scalars['String'];
  nameWoTrim: Scalars['String'];
  trim: Scalars['String'];
  mfrModelCode: Scalars['String'];
  fleetOnly: Scalars['Boolean'];
  modelFleet: Scalars['Boolean'];
  passDoors: Scalars['Int'];
  altModelName: Scalars['String'];
  altStyleName: Scalars['String'];
  altBodyType: Scalars['String'];
  drivetrain: Scalars['String'];
};

export type StylesMethod = {
  __typename?: 'StylesMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<ChromeStyles>;
};

export type StylesNewBlackBook = {
  __typename?: 'StylesNewBlackBook';
  value: Scalars['String'];
  label: Scalars['String'];
};

export type SubOptionsList = {
  __typename?: 'SubOptionsList';
  styleId: Array<Scalars['Int']>;
  price: SubOptionsPrice;
  optionKindId: Scalars['Int'];
  chromeCode: Scalars['String'];
  description: Array<Scalars['String']>;
};

export type SubOptionsListInput = {
  styleId: Array<Scalars['Int']>;
  price?: Maybe<SubOptionsPriceInput>;
  optionKindId: Scalars['Int'];
  chromeCode: Scalars['String'];
  description: Array<Scalars['String']>;
};

export type SubOptionsPrice = {
  __typename?: 'SubOptionsPrice';
  invoiceMax: Scalars['Int'];
  invoiceMin: Scalars['Int'];
  msrpMax: Scalars['Int'];
  msrpMin: Scalars['Int'];
  unknown: Scalars['Boolean'];
};

export type SubOptionsPriceInput = {
  invoiceMax: Scalars['Int'];
  invoiceMin: Scalars['Int'];
  msrpMax: Scalars['Int'];
  msrpMin: Scalars['Int'];
  unknown: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeMediums: Array<Scalars['String']>;
  app_info: Scalars['String'];
  device: Device;
  ecommerce: Scalars['String'];
  emitter: Scalars['String'];
  event: Scalars['String'];
  event_bundle_sequence_id: Scalars['Int'];
  event_date: Scalars['String'];
  event_dimensions: Scalars['String'];
  event_name: Scalars['String'];
  event_params: Array<EventParams>;
  event_previous_timestamp: Scalars['Int'];
  event_server_timestamp_offset: Scalars['Int'];
  event_timestamp: Scalars['Int'];
  event_value_in_usd: Scalars['String'];
  geo: Geo;
  items: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  platform: Scalars['String'];
  privacy_info: PrivacyInfo;
  stream_id: Scalars['String'];
  traffic_source: TrafficSource;
  user_first_touch_timestamp: Scalars['Int'];
  user_id: Scalars['String'];
  user_ltv: UserLtv;
  user_properties: Array<UserProperties>;
  user_pseudo_id: Scalars['Int'];
  user_rules: Array<Scalars['String']>;
  user_types: Array<Scalars['String']>;
};

export type SubscriptionMethod = {
  __typename?: 'SubscriptionMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<Subscription>;
};

export type TokenResult = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
};

export type TradeinPricesInput = {
  adjustedTradeinAvg?: Maybe<Scalars['Int']>;
  adjustedTradeinClean?: Maybe<Scalars['Int']>;
  adjustedTradeinRough?: Maybe<Scalars['Int']>;
  adjustedTradeinXclean?: Maybe<Scalars['Int']>;
  baseTradeinAvg?: Maybe<Scalars['Int']>;
  baseTradeinClean?: Maybe<Scalars['Int']>;
  baseTradeinRough?: Maybe<Scalars['Int']>;
  baseTradeinXclean?: Maybe<Scalars['Int']>;
};

export type TradeinPricesType = {
  __typename?: 'TradeinPricesType';
  adjustedTradeinAvg?: Maybe<Scalars['Int']>;
  adjustedTradeinClean?: Maybe<Scalars['Int']>;
  adjustedTradeinRough?: Maybe<Scalars['Int']>;
  adjustedTradeinXclean?: Maybe<Scalars['Int']>;
  baseTradeinAvg?: Maybe<Scalars['Int']>;
  baseTradeinClean?: Maybe<Scalars['Int']>;
  baseTradeinRough?: Maybe<Scalars['Int']>;
  baseTradeinXclean?: Maybe<Scalars['Int']>;
};

export type TrafficSource = {
  __typename?: 'TrafficSource';
  name: Scalars['String'];
  medium: Scalars['String'];
  source: Scalars['String'];
};

export type TranslationLabelInput = {
  en: Scalars['String'];
  fr: Scalars['String'];
};

export type TranslationLabelType = {
  __typename?: 'TranslationLabelType';
  en: Scalars['String'];
  fr: Scalars['String'];
};

export type TrimsNewBlackBook = {
  __typename?: 'TrimsNewBlackBook';
  value: Scalars['String'];
  label: Scalars['String'];
  styles: Array<StylesNewBlackBook>;
};

export type TurnOnForMy = {
  __typename?: 'TurnOnForMy';
  _id: Scalars['String'];
  name: Scalars['String'];
  activate: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
};

export type UpdateListingInput = {
  change: ListingInput;
};

export type UpdateListingMethod = {
  __typename?: 'UpdateListingMethod';
  modified: Scalars['Boolean'];
  virtual: Listing;
};

export type UpdateListingStatusInput = {
  status: Scalars['String'];
};

export type UpdatePhysicalEvaluationMethod = {
  __typename?: 'UpdatePhysicalEvaluationMethod';
  evaluation: Appraisal;
  modified: Scalars['Boolean'];
};

export type UpdateStatusInput = {
  status: Scalars['String'];
  offerAsSeen?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentsInput>;
};

export type UpdateStatusResponse = {
  __typename?: 'UpdateStatusResponse';
  modified?: Maybe<Scalars['Boolean']>;
};


export type UrlTypeInput = {
  url?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  roles: Array<Scalars['String']>;
  dealership: Scalars['String'];
  onlineAt: Scalars['Int'];
};

export type UserById = {
  __typename?: 'UserById';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UserByIdMethod = {
  __typename?: 'UserByIdMethod';
  operation?: Maybe<Scalars['String']>;
  result: UserById;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  roles: Array<Scalars['String']>;
  dealership: Scalars['String'];
};

export type UserListAnalityc = {
  __typename?: 'UserListAnalityc';
  user_id: Scalars['String'];
};

export type UserListAnalitycMethod = {
  __typename?: 'UserListAnalitycMethod';
  operation?: Maybe<Scalars['String']>;
  result: Array<UserListAnalityc>;
};

export type UserLtv = {
  __typename?: 'UserLtv';
  revenue: Scalars['Int'];
  currency: Scalars['String'];
};

export type UserProperties = {
  __typename?: 'UserProperties';
  key: Scalars['String'];
  value: UserPropertiesValues;
};

export type UserPropertiesValues = {
  __typename?: 'UserPropertiesValues';
  string_value: Scalars['String'];
  int_value: Scalars['String'];
  float_value: Scalars['String'];
  double_value: Scalars['String'];
  set_timestamp_micros: Scalars['Int'];
};

export type UserStaisticDataType = {
  __typename?: 'UserStaisticDataType';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  dealership: Scalars['String'];
  roles: Array<Scalars['String']>;
  country: Scalars['String'];
  onlineAt: Scalars['Int'];
};

export type VAutoCheckMethod = {
  __typename?: 'VAutoCheckMethod';
  GetAppraisalWithPricingDataByVinResult: GetAppraisalWithPricingDataByVinResult;
};

export type VehicleInfoType = {
  __typename?: 'VehicleInfoType';
  bedStyle?: Maybe<Scalars['String']>;
  bodyStyle?: Maybe<Scalars['String']>;
  cabStyle?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  conditionTimestamp?: Maybe<Scalars['Int']>;
  confidenceScore4?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  driveType?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  engineDescription?: Maybe<Scalars['String']>;
  engineDisplacement?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  interiorColor?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileageTimestamp?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  mpg?: Maybe<Scalars['Int']>;
  mpgCity?: Maybe<Scalars['Int']>;
  mpgHighway?: Maybe<Scalars['Int']>;
  subGeneration?: Maybe<Scalars['Int']>;
  subModel?: Maybe<Scalars['String']>;
  subTrim?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  transmissionDetails?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type VehicleListDashStatistics = {
  __typename?: 'VehicleListDashStatistics';
  id: Scalars['Int'];
  vin: Scalars['String'];
  location: Scalars['String'];
  created: Scalars['String'];
  ownerId: Scalars['Int'];
  submitted: Scalars['String'];
  completedById: Scalars['Int'];
  completed: Scalars['String'];
  appraisalValue: Scalars['Int'];
  offeredValue: Scalars['String'];
  odometer: Scalars['Int'];
  odometerMiles: Scalars['String'];
  status: Scalars['String'];
  origin: Scalars['String'];
  photos: Array<AppraisalPhoto>;
  paveSessionStatus: Scalars['String'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  traction: Scalars['String'];
  transmissionType: Scalars['String'];
  assessingStarted: Scalars['String'];
  assessedById: Scalars['Int'];
  eraSalesCode: Scalars['String'];
  isPurchased: Scalars['Boolean'];
};

export type VehicleReportImage = {
  __typename?: 'VehicleReportImage';
  id: Scalars['Int'];
  src: Scalars['String'];
  zone: Scalars['String'];
};

export type Vin = {
  __typename?: 'Vin';
  bodyType: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['String'];
  msrp: Scalars['String'];
  series: Scalars['String'];
  style: Array<VinStyle>;
  transmissionType: Scalars['String'];
};

export type VinMethod = {
  __typename?: 'VinMethod';
  operation?: Maybe<Scalars['String']>;
  result: Vin;
};

export type VinStyle = {
  __typename?: 'VinStyle';
  division: VinStyleDivision;
  subdivision: VinStyleSubdivision;
  model: VinStyleModel;
  basePrice: VinStyleBasePrice;
  bodyType?: Maybe<VinStyleBodyType>;
  marketClass?: Maybe<VinStyleMarketClass>;
  id: Scalars['String'];
  modelYear: Scalars['String'];
  name: Scalars['String'];
  nameWoTrim?: Maybe<Scalars['String']>;
  trim: Scalars['String'];
  mfrModelCode: Scalars['String'];
  fleetOnly: Scalars['String'];
  modelFleet: Scalars['String'];
  passDoors: Scalars['String'];
  altBodyType: Scalars['String'];
  drivetrain?: Maybe<Scalars['String']>;
};

export type VinStyleBasePrice = {
  __typename?: 'VinStyleBasePrice';
  unknown: Scalars['String'];
  msrp: Scalars['String'];
  destination: Scalars['String'];
};

export type VinStyleBodyType = {
  __typename?: 'VinStyleBodyType';
  id: Scalars['String'];
  primary: Scalars['String'];
  value: Scalars['String'];
};

export type VinStyleDivision = {
  __typename?: 'VinStyleDivision';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type VinStyleMarketClass = {
  __typename?: 'VinStyleMarketClass';
  id: Scalars['String'];
  primary: Scalars['String'];
  value: Scalars['String'];
};

export type VinStyleMethod = {
  __typename?: 'VinStyleMethod';
  operation: Scalars['String'];
  result: VinStyleResult;
};

export type VinStyleModel = {
  __typename?: 'VinStyleModel';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type VinStyleResult = {
  __typename?: 'VinStyleResult';
  dataFullCurrentStyle: DataFullCurrentStyleElement;
};

export type VinStyleStyle = {
  __typename?: 'VinStyleStyle';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type VinStyleSubdivision = {
  __typename?: 'VinStyleSubdivision';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type VirtualByVinType = {
  __typename?: 'VirtualByVinType';
  _id: Scalars['String'];
  id: Scalars['Int'];
  vin: Scalars['String'];
  location: Scalars['String'];
  modelYear: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  series: Scalars['String'];
  offeredValue: Scalars['String'];
  created: Scalars['String'];
  appraisalValue: Scalars['Int'];
};

export type VirtualVehByVinMethod = {
  __typename?: 'VirtualVehByVinMethod';
  last?: Maybe<Scalars['Boolean']>;
  result: Array<VirtualVehByVinType>;
};

export type VirtualVehByVinType = {
  __typename?: 'VirtualVehByVinType';
  _id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  appraisalValue?: Maybe<Scalars['Int']>;
  offeredValue?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  custFirstName?: Maybe<Scalars['String']>;
  custLastName?: Maybe<Scalars['String']>;
  custEmail?: Maybe<Scalars['String']>;
  custHomePhone?: Maybe<Scalars['String']>;
  custAddress?: Maybe<Scalars['String']>;
  custCity?: Maybe<Scalars['String']>;
  custPostalCode?: Maybe<Scalars['String']>;
  custState?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['String']>;
  isPurchased?: Maybe<Scalars['Boolean']>;
};

export type WebInfo = {
  __typename?: 'WebInfo';
  browser: Scalars['String'];
  browser_version: Scalars['String'];
  hostname: Scalars['String'];
};

export type WholeSalePricesInput = {
  adjustedWholeAvg?: Maybe<Scalars['Int']>;
  adjustedWholeClean?: Maybe<Scalars['Int']>;
  adjustedWholeRough?: Maybe<Scalars['Int']>;
  adjustedWholeXclean?: Maybe<Scalars['Int']>;
  baseWholeAvg?: Maybe<Scalars['Int']>;
  baseWholeClean?: Maybe<Scalars['Int']>;
  baseWholeRough?: Maybe<Scalars['Int']>;
  baseWholeXclean?: Maybe<Scalars['Int']>;
};

export type WholeSalePricesType = {
  __typename?: 'WholeSalePricesType';
  adjustedWholeAvg?: Maybe<Scalars['Int']>;
  adjustedWholeClean?: Maybe<Scalars['Int']>;
  adjustedWholeRough?: Maybe<Scalars['Int']>;
  adjustedWholeXclean?: Maybe<Scalars['Int']>;
  baseWholeAvg?: Maybe<Scalars['Int']>;
  baseWholeClean?: Maybe<Scalars['Int']>;
  baseWholeRough?: Maybe<Scalars['Int']>;
  baseWholeXclean?: Maybe<Scalars['Int']>;
};

export type WholesaleType = {
  __typename?: 'WholesaleType';
  above: Scalars['Int'];
  average: Scalars['Int'];
  below: Scalars['Int'];
};

export type FeatureInput = {
  name: Scalars['String'];
  activate: Scalars['Boolean'];
  description: Scalars['String'];
  userIds: Array<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type NewBlackBookDecoderMethod = {
  __typename?: 'newBlackBookDecoderMethod';
  makeName: Scalars['String'];
  modelName: Scalars['String'];
  modelYear: Scalars['String'];
  trims: Array<TrimsNewBlackBook>;
};

export type NewBlackBookDetailDecoderMethod = {
  __typename?: 'newBlackBookDetailDecoderMethod';
  makeName: Scalars['String'];
  modelName: Scalars['String'];
  modelYear: Scalars['String'];
  engine: Scalars['String'];
  engineCylinderCount: Scalars['String'];
  traction: Scalars['String'];
  transmission: Scalars['String'];
  style: Scalars['String'];
  trims: Array<TrimsNewBlackBook>;
  exteriorColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  interiorColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VehicleReportInput = {
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
  todo: Scalars['Boolean'];
  icon: Scalars['String'];
  description: Scalars['String'];
  status: Scalars['String'];
  select?: Maybe<Array<Maybe<VehicleReportSelectInput>>>;
};

export type VehicleReportSelectInput = {
  name: Scalars['String'];
  value: Scalars['String'];
  description: Scalars['String'];
};

export type VehicleReportSelectType = {
  __typename?: 'vehicleReportSelectType';
  name: Scalars['String'];
  value: Scalars['String'];
  description: Scalars['String'];
};

export type VehicleReportType = {
  __typename?: 'vehicleReportType';
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
  todo: Scalars['Boolean'];
  icon: Scalars['String'];
  description: Scalars['String'];
  status: Scalars['String'];
  select: Array<Maybe<VehicleReportSelectType>>;
  images: Array<Maybe<VehicleReportImage>>;
};

export type VinDetailsMethod = {
  __typename?: 'vinDetailsMethod';
  makeName: Scalars['String'];
  modelName: Scalars['String'];
  modelYear: Scalars['String'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AcodeIcon: ResolverTypeWrapper<AcodeIcon>;
  String: ResolverTypeWrapper<Scalars['String']>;
  ActionInput: ActionInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AddDeductListItem: ResolverTypeWrapper<AddDeductListItem>;
  AdjustedByInput: AdjustedByInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AdjustedByType: ResolverTypeWrapper<AdjustedByType>;
  AdjustedPricingInput: AdjustedPricingInput;
  AdjustedPricingType: ResolverTypeWrapper<AdjustedPricingType>;
  AnalitycMethod: ResolverTypeWrapper<AnalitycMethod>;
  Application: ResolverTypeWrapper<Application>;
  ApplicationInput: ApplicationInput;
  Appraisal: ResolverTypeWrapper<Appraisal>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AppraisalComments: ResolverTypeWrapper<AppraisalComments>;
  AppraisalCommentsInput: AppraisalCommentsInput;
  AppraisalCondition: ResolverTypeWrapper<AppraisalCondition>;
  AppraisalConditionInput: AppraisalConditionInput;
  AppraisalConditions: ResolverTypeWrapper<AppraisalConditions>;
  AppraisalConditionsInput: AppraisalConditionsInput;
  AppraisalConditionsResponse: ResolverTypeWrapper<AppraisalConditionsResponse>;
  AppraisalInput: AppraisalInput;
  AppraisalInventory: ResolverTypeWrapper<AppraisalInventory>;
  AppraisalMethod: ResolverTypeWrapper<AppraisalMethod>;
  AppraisalPhoto: ResolverTypeWrapper<AppraisalPhoto>;
  AppraisalPhotoInput: AppraisalPhotoInput;
  AppraisalTireThread: ResolverTypeWrapper<AppraisalTireThread>;
  AppraisalTireThreadInput: AppraisalTireThreadInput;
  AppraisalTireThreadSecond: ResolverTypeWrapper<AppraisalTireThreadSecond>;
  AppraisalTireThreadSecondInput: AppraisalTireThreadSecondInput;
  AppraisalUpdateInput: AppraisalUpdateInput;
  AppraisalUpdateWithSocketInput: AppraisalUpdateWithSocketInput;
  AppraisalVAuto: ResolverTypeWrapper<AppraisalVAuto>;
  AppraisalVehByVinMethod: ResolverTypeWrapper<AppraisalVehByVinMethod>;
  AppraisalVehByVinResult: ResolverTypeWrapper<AppraisalVehByVinResult>;
  AppraisalVehByVinType: ResolverTypeWrapper<AppraisalVehByVinType>;
  AppraisalsConditionMethod: ResolverTypeWrapper<AppraisalsConditionMethod>;
  AppraisalsCountMethod: ResolverTypeWrapper<AppraisalsCountMethod>;
  AppraisalsCounts: ResolverTypeWrapper<AppraisalsCounts>;
  AppraisalsMethod: ResolverTypeWrapper<AppraisalsMethod>;
  AppraiserVAuto: ResolverTypeWrapper<AppraiserVAuto>;
  AppriselVehicleForAnalyticsMethod: ResolverTypeWrapper<AppriselVehicleForAnalyticsMethod>;
  AppriselVehicleForAnalyticsType: ResolverTypeWrapper<AppriselVehicleForAnalyticsType>;
  AppriselVehicleInput: AppriselVehicleInput;
  AppriselVehicleMethod: ResolverTypeWrapper<AppriselVehicleMethod>;
  AppriselVehicleType: ResolverTypeWrapper<AppriselVehicleType>;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentInput: AttachmentInput;
  Auth: ResolverTypeWrapper<Auth>;
  AuthUser: ResolverTypeWrapper<AuthUser>;
  BadgeListSmallType: ResolverTypeWrapper<BadgeListSmallType>;
  BadgesListType: ResolverTypeWrapper<BadgesListType>;
  BasePriceItem: ResolverTypeWrapper<BasePriceItem>;
  BbInfoValuesInput: BbInfoValuesInput;
  BbInfoValuesMethod: ResolverTypeWrapper<BbInfoValuesMethod>;
  BbInfoValuesType: ResolverTypeWrapper<BbInfoValuesType>;
  BodyTypeItem: ResolverTypeWrapper<BodyTypeItem>;
  CarGurusByVIN: ResolverTypeWrapper<CarGurusByVin>;
  CarGurusByVINInput: CarGurusByVinInput;
  CarPart: ResolverTypeWrapper<CarPart>;
  CarSide: ResolverTypeWrapper<CarSide>;
  CarfaxInput: CarfaxInput;
  CarfaxType: ResolverTypeWrapper<CarfaxType>;
  Carproof: ResolverTypeWrapper<Carproof>;
  CarproofMethod: ResolverTypeWrapper<CarproofMethod>;
  CarproofVPN: ResolverTypeWrapper<CarproofVpn>;
  CarproofVPNMethod: ResolverTypeWrapper<CarproofVpnMethod>;
  CarstoryByVINType: ResolverTypeWrapper<CarstoryByVinType>;
  CarstoryType: ResolverTypeWrapper<CarstoryType>;
  ChromeDivision: ResolverTypeWrapper<ChromeDivision>;
  ChromeModelYear: ResolverTypeWrapper<ChromeModelYear>;
  ChromeModels: ResolverTypeWrapper<ChromeModels>;
  ChromeStyles: ResolverTypeWrapper<ChromeStyles>;
  CloneAppraisalInput: CloneAppraisalInput;
  CommentsInput: CommentsInput;
  CompleteVehicleUserEvaluationInput: CompleteVehicleUserEvaluationInput;
  CompleteVehicleUserEvaluationMethod: ResolverTypeWrapper<CompleteVehicleUserEvaluationMethod>;
  Countries: Countries;
  CreateMonkaiInspectionInput: CreateMonkaiInspectionInput;
  CreateMonkaiInspectionResponse: ResolverTypeWrapper<CreateMonkaiInspectionResponse>;
  CreateMonkaiInspectionResult: ResolverTypeWrapper<CreateMonkaiInspectionResult>;
  CreatePaveSessionInput: CreatePaveSessionInput;
  CreatePhysicalEvaluationMethod: ResolverTypeWrapper<CreatePhysicalEvaluationMethod>;
  Damage: ResolverTypeWrapper<Damage>;
  DamageAction: ResolverTypeWrapper<DamageAction>;
  DamageInput: DamageInput;
  DashStatisticsListMethod: ResolverTypeWrapper<DashStatisticsListMethod>;
  DashStatisticsMethod: ResolverTypeWrapper<DashStatisticsMethod>;
  DashStatisticsType: ResolverTypeWrapper<DashStatisticsType>;
  DataFullCurrentStyleElement: ResolverTypeWrapper<DataFullCurrentStyleElement>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Dealership: ResolverTypeWrapper<Dealership>;
  DecodeBBItemInput: DecodeBbItemInput;
  DecodeBBItemType: ResolverTypeWrapper<DecodeBbItemType>;
  DecodeVinBBResult: ResolverTypeWrapper<DecodeVinBbResult>;
  DecodeVinBlackBookMethod: ResolverTypeWrapper<DecodeVinBlackBookMethod>;
  DecodeVinMethod: ResolverTypeWrapper<DecodeVinMethod>;
  DecodeVinNewMethod: ResolverTypeWrapper<DecodeVinNewMethod>;
  DecodeVinNewResult: ResolverTypeWrapper<DecodeVinNewResult>;
  DecodeVinResult: ResolverTypeWrapper<DecodeVinResult>;
  DefoltElementBBDecode: ResolverTypeWrapper<DefoltElementBbDecode>;
  DefoltElementColorDecode: ResolverTypeWrapper<DefoltElementColorDecode>;
  DefoltElementDecode: ResolverTypeWrapper<DefoltElementDecode>;
  Detail: ResolverTypeWrapper<Detail>;
  DetailInput: DetailInput;
  Device: ResolverTypeWrapper<Device>;
  DisclosuresInput: DisclosuresInput;
  DisclosuresType: ResolverTypeWrapper<DisclosuresType>;
  DisclosuresUSInput: DisclosuresUsInput;
  DisclosuresUSType: ResolverTypeWrapper<DisclosuresUsType>;
  Distances: Distances;
  DivisionsMethod: ResolverTypeWrapper<DivisionsMethod>;
  DrivErrorInput: DrivErrorInput;
  DrivErrorType: ResolverTypeWrapper<DrivErrorType>;
  DrivlyEmailInput: DrivlyEmailInput;
  EventParams: ResolverTypeWrapper<EventParams>;
  EventParamsType: ResolverTypeWrapper<EventParamsType>;
  EventParamsValue: ResolverTypeWrapper<EventParamsValue>;
  EventParamsValueType: ResolverTypeWrapper<EventParamsValueType>;
  ExteriorColor: ResolverTypeWrapper<ExteriorColor>;
  ExteriorColorElement: ResolverTypeWrapper<ExteriorColorElement>;
  FeatureForMyResponse: ResolverTypeWrapper<FeatureForMyResponse>;
  FeatureInputResponse: ResolverTypeWrapper<FeatureInputResponse>;
  FeatureResponse: ResolverTypeWrapper<FeatureResponse>;
  FilterQueryInput: FilterQueryInput;
  FollowUp: ResolverTypeWrapper<FollowUp>;
  FollowUpInput: FollowUpInput;
  Geo: ResolverTypeWrapper<Geo>;
  GeoType: ResolverTypeWrapper<GeoType>;
  GetAppraisalWithPricingDataByVinResult: ResolverTypeWrapper<GetAppraisalWithPricingDataByVinResult>;
  GetCarproofMethod: ResolverTypeWrapper<GetCarproofMethod>;
  GetCarproofType: ResolverTypeWrapper<GetCarproofType>;
  GetCarstoryByVINMethod: ResolverTypeWrapper<GetCarstoryByVinMethod>;
  GetListForBedStartCompleteMethod: ResolverTypeWrapper<GetListForBedStartCompleteMethod>;
  GetListForBedStartCompleteOne: ResolverTypeWrapper<GetListForBedStartCompleteOne>;
  GetListForBedStartCompleteOneMethod: ResolverTypeWrapper<GetListForBedStartCompleteOneMethod>;
  GetListForBedVinDecodeMethod: ResolverTypeWrapper<GetListForBedVinDecodeMethod>;
  GetMonkaiInspectionResponse: ResolverTypeWrapper<GetMonkaiInspectionResponse>;
  GetMonkaiInspectionResult: ResolverTypeWrapper<GetMonkaiInspectionResult>;
  GetMonkaiTokenResponse: ResolverTypeWrapper<GetMonkaiTokenResponse>;
  GlaEvalItem: ResolverTypeWrapper<GlaEvalItem>;
  GlaEvalResponse: ResolverTypeWrapper<GlaEvalResponse>;
  GlaFollowItem: ResolverTypeWrapper<GlaFollowItem>;
  GlaFollowResponse: ResolverTypeWrapper<GlaFollowResponse>;
  GlaItem: ResolverTypeWrapper<GlaItem>;
  GlaResponse: ResolverTypeWrapper<GlaResponse>;
  InfoForUserAnalitycMethod: ResolverTypeWrapper<InfoForUserAnalitycMethod>;
  InteriorColor: ResolverTypeWrapper<InteriorColor>;
  ItemSubscriptionResult: ResolverTypeWrapper<ItemSubscriptionResult>;
  JsonAppraisalInput: JsonAppraisalInput;
  Label: ResolverTypeWrapper<Label>;
  LabelInput: LabelInput;
  Languages: Languages;
  Licenseplate: ResolverTypeWrapper<Licenseplate>;
  LicenseplateMethod: ResolverTypeWrapper<LicenseplateMethod>;
  ListProblemStartAndComplete: ResolverTypeWrapper<ListProblemStartAndComplete>;
  ListiFeatureResponse: ResolverTypeWrapper<ListiFeatureResponse>;
  ListiFeatureResult: ResolverTypeWrapper<ListiFeatureResult>;
  ListiUsersFeatureResponse: ResolverTypeWrapper<ListiUsersFeatureResponse>;
  ListiUsersFeatureResult: ResolverTypeWrapper<ListiUsersFeatureResult>;
  Listing: ResolverTypeWrapper<Listing>;
  ListingComments: ResolverTypeWrapper<ListingComments>;
  ListingCommentsInput: ListingCommentsInput;
  ListingInput: ListingInput;
  ListingMethod: ResolverTypeWrapper<ListingMethod>;
  ListingStatusResult: ResolverTypeWrapper<ListingStatusResult>;
  ListingsCountMethod: ResolverTypeWrapper<ListingsCountMethod>;
  ListingsCreateResult: ResolverTypeWrapper<ListingsCreateResult>;
  ListingsMethod: ResolverTypeWrapper<ListingsMethod>;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  LocationMethod: ResolverTypeWrapper<LocationMethod>;
  ManheimDataByVinMethod: ResolverTypeWrapper<ManheimDataByVinMethod>;
  ManheimDataByVinResult: ResolverTypeWrapper<ManheimDataByVinResult>;
  ManheimDescriptionType: ResolverTypeWrapper<ManheimDescriptionType>;
  ManheimInput: ManheimInput;
  ManheimItemDescriptionInput: ManheimItemDescriptionInput;
  ManheimItemDescriptionType: ResolverTypeWrapper<ManheimItemDescriptionType>;
  ManheimItemInput: ManheimItemInput;
  ManheimItemType: ResolverTypeWrapper<ManheimItemType>;
  ManheimItemWholesaleInput: ManheimItemWholesaleInput;
  ManheimItemWholesaleType: ResolverTypeWrapper<ManheimItemWholesaleType>;
  ManheimType: ResolverTypeWrapper<ManheimType>;
  ManheimVehicleType: ResolverTypeWrapper<ManheimVehicleType>;
  Messaage: ResolverTypeWrapper<Messaage>;
  MessaagesMethod: ResolverTypeWrapper<MessaagesMethod>;
  ModelYearsMethod: ResolverTypeWrapper<ModelYearsMethod>;
  ModelsMethod: ResolverTypeWrapper<ModelsMethod>;
  MonkaiImage: ResolverTypeWrapper<MonkaiImage>;
  MonkaiImageInput: MonkaiImageInput;
  MonkaiVehicleInfoType: ResolverTypeWrapper<MonkaiVehicleInfoType>;
  Mutation: ResolverTypeWrapper<{}>;
  NotificationCountMethod: ResolverTypeWrapper<NotificationCountMethod>;
  NotificationListItem: ResolverTypeWrapper<NotificationListItem>;
  NotificationListItemSource: ResolverTypeWrapper<NotificationListItemSource>;
  NotificationListMethod: ResolverTypeWrapper<NotificationListMethod>;
  NotificationNameMethod: ResolverTypeWrapper<NotificationNameMethod>;
  NotificationReadInput: NotificationReadInput;
  NotificationReadMethod: ResolverTypeWrapper<NotificationReadMethod>;
  OffersByVIN: ResolverTypeWrapper<OffersByVin>;
  OffersByVINInput: OffersByVinInput;
  OptionsElement: ResolverTypeWrapper<OptionsElement>;
  OptionsElementBB: ResolverTypeWrapper<OptionsElementBb>;
  OptionsElementInput: OptionsElementInput;
  OptionsVinDecodeBB: ResolverTypeWrapper<OptionsVinDecodeBb>;
  OptionsVinDecodeBBInput: OptionsVinDecodeBbInput;
  Package: ResolverTypeWrapper<Package>;
  PackageMethod: ResolverTypeWrapper<PackageMethod>;
  PackageStyle: ResolverTypeWrapper<PackageStyle>;
  PeriodPriceInput: PeriodPriceInput;
  PeriodPriceType: ResolverTypeWrapper<PeriodPriceType>;
  PermissionsType: ResolverTypeWrapper<PermissionsType>;
  Photo: ResolverTypeWrapper<Photo>;
  PhotoMethod: ResolverTypeWrapper<PhotoMethod>;
  Pin: ResolverTypeWrapper<Pin>;
  PinInput: PinInput;
  PrivacyInfo: ResolverTypeWrapper<PrivacyInfo>;
  Query: ResolverTypeWrapper<{}>;
  ReatailPricesInput: ReatailPricesInput;
  ReatailPricesType: ResolverTypeWrapper<ReatailPricesType>;
  RefreshTokenInput: RefreshTokenInput;
  RefreshTokenMethod: ResolverTypeWrapper<RefreshTokenMethod>;
  RequestDateTimeType: ResolverTypeWrapper<RequestDateTimeType>;
  Restart: Restart;
  Roles: ResolverTypeWrapper<Roles>;
  SentEmailInput: SentEmailInput;
  SentEmailMethod: ResolverTypeWrapper<SentEmailMethod>;
  SetSubscriptionInput: SetSubscriptionInput;
  SetSubscriptionResult: ResolverTypeWrapper<SetSubscriptionResult>;
  SignInInput: SignInInput;
  SignInMethod: ResolverTypeWrapper<SignInMethod>;
  SignInput: SignInput;
  SignUpInput: SignUpInput;
  StartMonkaiInspectionInput: StartMonkaiInspectionInput;
  StatusCountResponse: ResolverTypeWrapper<StatusCountResponse>;
  StockImageItem: ResolverTypeWrapper<StockImageItem>;
  StyleElementItem: ResolverTypeWrapper<StyleElementItem>;
  StyleItem: ResolverTypeWrapper<StyleItem>;
  StylesMethod: ResolverTypeWrapper<StylesMethod>;
  StylesNewBlackBook: ResolverTypeWrapper<StylesNewBlackBook>;
  SubOptionsList: ResolverTypeWrapper<SubOptionsList>;
  SubOptionsListInput: SubOptionsListInput;
  SubOptionsPrice: ResolverTypeWrapper<SubOptionsPrice>;
  SubOptionsPriceInput: SubOptionsPriceInput;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionMethod: ResolverTypeWrapper<SubscriptionMethod>;
  TokenResult: ResolverTypeWrapper<TokenResult>;
  TradeinPricesInput: TradeinPricesInput;
  TradeinPricesType: ResolverTypeWrapper<TradeinPricesType>;
  TrafficSource: ResolverTypeWrapper<TrafficSource>;
  TranslationLabelInput: TranslationLabelInput;
  TranslationLabelType: ResolverTypeWrapper<TranslationLabelType>;
  TrimsNewBlackBook: ResolverTypeWrapper<TrimsNewBlackBook>;
  TurnOnForMy: ResolverTypeWrapper<TurnOnForMy>;
  UpdateListingInput: UpdateListingInput;
  UpdateListingMethod: ResolverTypeWrapper<UpdateListingMethod>;
  UpdateListingStatusInput: UpdateListingStatusInput;
  UpdatePhysicalEvaluationMethod: ResolverTypeWrapper<UpdatePhysicalEvaluationMethod>;
  UpdateStatusInput: UpdateStatusInput;
  UpdateStatusResponse: ResolverTypeWrapper<UpdateStatusResponse>;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UrlTypeInput: UrlTypeInput;
  User: ResolverTypeWrapper<User>;
  UserById: ResolverTypeWrapper<UserById>;
  UserByIdMethod: ResolverTypeWrapper<UserByIdMethod>;
  UserInput: UserInput;
  UserListAnalityc: ResolverTypeWrapper<UserListAnalityc>;
  UserListAnalitycMethod: ResolverTypeWrapper<UserListAnalitycMethod>;
  UserLtv: ResolverTypeWrapper<UserLtv>;
  UserProperties: ResolverTypeWrapper<UserProperties>;
  UserPropertiesValues: ResolverTypeWrapper<UserPropertiesValues>;
  UserStaisticDataType: ResolverTypeWrapper<UserStaisticDataType>;
  VAutoCheckMethod: ResolverTypeWrapper<VAutoCheckMethod>;
  VehicleInfoType: ResolverTypeWrapper<VehicleInfoType>;
  VehicleListDashStatistics: ResolverTypeWrapper<VehicleListDashStatistics>;
  VehicleReportImage: ResolverTypeWrapper<VehicleReportImage>;
  Vin: ResolverTypeWrapper<Vin>;
  VinMethod: ResolverTypeWrapper<VinMethod>;
  VinStyle: ResolverTypeWrapper<VinStyle>;
  VinStyleBasePrice: ResolverTypeWrapper<VinStyleBasePrice>;
  VinStyleBodyType: ResolverTypeWrapper<VinStyleBodyType>;
  VinStyleDivision: ResolverTypeWrapper<VinStyleDivision>;
  VinStyleMarketClass: ResolverTypeWrapper<VinStyleMarketClass>;
  VinStyleMethod: ResolverTypeWrapper<VinStyleMethod>;
  VinStyleModel: ResolverTypeWrapper<VinStyleModel>;
  VinStyleResult: ResolverTypeWrapper<VinStyleResult>;
  VinStyleStyle: ResolverTypeWrapper<VinStyleStyle>;
  VinStyleSubdivision: ResolverTypeWrapper<VinStyleSubdivision>;
  VirtualByVinType: ResolverTypeWrapper<VirtualByVinType>;
  VirtualVehByVinMethod: ResolverTypeWrapper<VirtualVehByVinMethod>;
  VirtualVehByVinType: ResolverTypeWrapper<VirtualVehByVinType>;
  WebInfo: ResolverTypeWrapper<WebInfo>;
  WholeSalePricesInput: WholeSalePricesInput;
  WholeSalePricesType: ResolverTypeWrapper<WholeSalePricesType>;
  WholesaleType: ResolverTypeWrapper<WholesaleType>;
  featureInput: FeatureInput;
  newBlackBookDecoderMethod: ResolverTypeWrapper<NewBlackBookDecoderMethod>;
  newBlackBookDetailDecoderMethod: ResolverTypeWrapper<NewBlackBookDetailDecoderMethod>;
  vehicleReportInput: VehicleReportInput;
  vehicleReportSelectInput: VehicleReportSelectInput;
  vehicleReportSelectType: ResolverTypeWrapper<VehicleReportSelectType>;
  vehicleReportType: ResolverTypeWrapper<VehicleReportType>;
  vinDetailsMethod: ResolverTypeWrapper<VinDetailsMethod>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AcodeIcon: AcodeIcon;
  String: Scalars['String'];
  ActionInput: ActionInput;
  Float: Scalars['Float'];
  AddDeductListItem: AddDeductListItem;
  AdjustedByInput: AdjustedByInput;
  Int: Scalars['Int'];
  AdjustedByType: AdjustedByType;
  AdjustedPricingInput: AdjustedPricingInput;
  AdjustedPricingType: AdjustedPricingType;
  AnalitycMethod: AnalitycMethod;
  Application: Application;
  ApplicationInput: ApplicationInput;
  Appraisal: Appraisal;
  Boolean: Scalars['Boolean'];
  AppraisalComments: AppraisalComments;
  AppraisalCommentsInput: AppraisalCommentsInput;
  AppraisalCondition: AppraisalCondition;
  AppraisalConditionInput: AppraisalConditionInput;
  AppraisalConditions: AppraisalConditions;
  AppraisalConditionsInput: AppraisalConditionsInput;
  AppraisalConditionsResponse: AppraisalConditionsResponse;
  AppraisalInput: AppraisalInput;
  AppraisalInventory: AppraisalInventory;
  AppraisalMethod: AppraisalMethod;
  AppraisalPhoto: AppraisalPhoto;
  AppraisalPhotoInput: AppraisalPhotoInput;
  AppraisalTireThread: AppraisalTireThread;
  AppraisalTireThreadInput: AppraisalTireThreadInput;
  AppraisalTireThreadSecond: AppraisalTireThreadSecond;
  AppraisalTireThreadSecondInput: AppraisalTireThreadSecondInput;
  AppraisalUpdateInput: AppraisalUpdateInput;
  AppraisalUpdateWithSocketInput: AppraisalUpdateWithSocketInput;
  AppraisalVAuto: AppraisalVAuto;
  AppraisalVehByVinMethod: AppraisalVehByVinMethod;
  AppraisalVehByVinResult: AppraisalVehByVinResult;
  AppraisalVehByVinType: AppraisalVehByVinType;
  AppraisalsConditionMethod: AppraisalsConditionMethod;
  AppraisalsCountMethod: AppraisalsCountMethod;
  AppraisalsCounts: AppraisalsCounts;
  AppraisalsMethod: AppraisalsMethod;
  AppraiserVAuto: AppraiserVAuto;
  AppriselVehicleForAnalyticsMethod: AppriselVehicleForAnalyticsMethod;
  AppriselVehicleForAnalyticsType: AppriselVehicleForAnalyticsType;
  AppriselVehicleInput: AppriselVehicleInput;
  AppriselVehicleMethod: AppriselVehicleMethod;
  AppriselVehicleType: AppriselVehicleType;
  Attachment: Attachment;
  AttachmentInput: AttachmentInput;
  Auth: Auth;
  AuthUser: AuthUser;
  BadgeListSmallType: BadgeListSmallType;
  BadgesListType: BadgesListType;
  BasePriceItem: BasePriceItem;
  BbInfoValuesInput: BbInfoValuesInput;
  BbInfoValuesMethod: BbInfoValuesMethod;
  BbInfoValuesType: BbInfoValuesType;
  BodyTypeItem: BodyTypeItem;
  CarGurusByVIN: CarGurusByVin;
  CarGurusByVINInput: CarGurusByVinInput;
  CarPart: CarPart;
  CarSide: CarSide;
  CarfaxInput: CarfaxInput;
  CarfaxType: CarfaxType;
  Carproof: Carproof;
  CarproofMethod: CarproofMethod;
  CarproofVPN: CarproofVpn;
  CarproofVPNMethod: CarproofVpnMethod;
  CarstoryByVINType: CarstoryByVinType;
  CarstoryType: CarstoryType;
  ChromeDivision: ChromeDivision;
  ChromeModelYear: ChromeModelYear;
  ChromeModels: ChromeModels;
  ChromeStyles: ChromeStyles;
  CloneAppraisalInput: CloneAppraisalInput;
  CommentsInput: CommentsInput;
  CompleteVehicleUserEvaluationInput: CompleteVehicleUserEvaluationInput;
  CompleteVehicleUserEvaluationMethod: CompleteVehicleUserEvaluationMethod;
  CreateMonkaiInspectionInput: CreateMonkaiInspectionInput;
  CreateMonkaiInspectionResponse: CreateMonkaiInspectionResponse;
  CreateMonkaiInspectionResult: CreateMonkaiInspectionResult;
  CreatePaveSessionInput: CreatePaveSessionInput;
  CreatePhysicalEvaluationMethod: CreatePhysicalEvaluationMethod;
  Damage: Damage;
  DamageAction: DamageAction;
  DamageInput: DamageInput;
  DashStatisticsListMethod: DashStatisticsListMethod;
  DashStatisticsMethod: DashStatisticsMethod;
  DashStatisticsType: DashStatisticsType;
  DataFullCurrentStyleElement: DataFullCurrentStyleElement;
  Date: Scalars['Date'];
  Dealership: Dealership;
  DecodeBBItemInput: DecodeBbItemInput;
  DecodeBBItemType: DecodeBbItemType;
  DecodeVinBBResult: DecodeVinBbResult;
  DecodeVinBlackBookMethod: DecodeVinBlackBookMethod;
  DecodeVinMethod: DecodeVinMethod;
  DecodeVinNewMethod: DecodeVinNewMethod;
  DecodeVinNewResult: DecodeVinNewResult;
  DecodeVinResult: DecodeVinResult;
  DefoltElementBBDecode: DefoltElementBbDecode;
  DefoltElementColorDecode: DefoltElementColorDecode;
  DefoltElementDecode: DefoltElementDecode;
  Detail: Detail;
  DetailInput: DetailInput;
  Device: Device;
  DisclosuresInput: DisclosuresInput;
  DisclosuresType: DisclosuresType;
  DisclosuresUSInput: DisclosuresUsInput;
  DisclosuresUSType: DisclosuresUsType;
  DivisionsMethod: DivisionsMethod;
  DrivErrorInput: DrivErrorInput;
  DrivErrorType: DrivErrorType;
  DrivlyEmailInput: DrivlyEmailInput;
  EventParams: EventParams;
  EventParamsType: EventParamsType;
  EventParamsValue: EventParamsValue;
  EventParamsValueType: EventParamsValueType;
  ExteriorColor: ExteriorColor;
  ExteriorColorElement: ExteriorColorElement;
  FeatureForMyResponse: FeatureForMyResponse;
  FeatureInputResponse: FeatureInputResponse;
  FeatureResponse: FeatureResponse;
  FilterQueryInput: FilterQueryInput;
  FollowUp: FollowUp;
  FollowUpInput: FollowUpInput;
  Geo: Geo;
  GeoType: GeoType;
  GetAppraisalWithPricingDataByVinResult: GetAppraisalWithPricingDataByVinResult;
  GetCarproofMethod: GetCarproofMethod;
  GetCarproofType: GetCarproofType;
  GetCarstoryByVINMethod: GetCarstoryByVinMethod;
  GetListForBedStartCompleteMethod: GetListForBedStartCompleteMethod;
  GetListForBedStartCompleteOne: GetListForBedStartCompleteOne;
  GetListForBedStartCompleteOneMethod: GetListForBedStartCompleteOneMethod;
  GetListForBedVinDecodeMethod: GetListForBedVinDecodeMethod;
  GetMonkaiInspectionResponse: GetMonkaiInspectionResponse;
  GetMonkaiInspectionResult: GetMonkaiInspectionResult;
  GetMonkaiTokenResponse: GetMonkaiTokenResponse;
  GlaEvalItem: GlaEvalItem;
  GlaEvalResponse: GlaEvalResponse;
  GlaFollowItem: GlaFollowItem;
  GlaFollowResponse: GlaFollowResponse;
  GlaItem: GlaItem;
  GlaResponse: GlaResponse;
  InfoForUserAnalitycMethod: InfoForUserAnalitycMethod;
  InteriorColor: InteriorColor;
  ItemSubscriptionResult: ItemSubscriptionResult;
  JsonAppraisalInput: JsonAppraisalInput;
  Label: Label;
  LabelInput: LabelInput;
  Licenseplate: Licenseplate;
  LicenseplateMethod: LicenseplateMethod;
  ListProblemStartAndComplete: ListProblemStartAndComplete;
  ListiFeatureResponse: ListiFeatureResponse;
  ListiFeatureResult: ListiFeatureResult;
  ListiUsersFeatureResponse: ListiUsersFeatureResponse;
  ListiUsersFeatureResult: ListiUsersFeatureResult;
  Listing: Listing;
  ListingComments: ListingComments;
  ListingCommentsInput: ListingCommentsInput;
  ListingInput: ListingInput;
  ListingMethod: ListingMethod;
  ListingStatusResult: ListingStatusResult;
  ListingsCountMethod: ListingsCountMethod;
  ListingsCreateResult: ListingsCreateResult;
  ListingsMethod: ListingsMethod;
  Location: Location;
  LocationInput: LocationInput;
  LocationMethod: LocationMethod;
  ManheimDataByVinMethod: ManheimDataByVinMethod;
  ManheimDataByVinResult: ManheimDataByVinResult;
  ManheimDescriptionType: ManheimDescriptionType;
  ManheimInput: ManheimInput;
  ManheimItemDescriptionInput: ManheimItemDescriptionInput;
  ManheimItemDescriptionType: ManheimItemDescriptionType;
  ManheimItemInput: ManheimItemInput;
  ManheimItemType: ManheimItemType;
  ManheimItemWholesaleInput: ManheimItemWholesaleInput;
  ManheimItemWholesaleType: ManheimItemWholesaleType;
  ManheimType: ManheimType;
  ManheimVehicleType: ManheimVehicleType;
  Messaage: Messaage;
  MessaagesMethod: MessaagesMethod;
  ModelYearsMethod: ModelYearsMethod;
  ModelsMethod: ModelsMethod;
  MonkaiImage: MonkaiImage;
  MonkaiImageInput: MonkaiImageInput;
  MonkaiVehicleInfoType: MonkaiVehicleInfoType;
  Mutation: {};
  NotificationCountMethod: NotificationCountMethod;
  NotificationListItem: NotificationListItem;
  NotificationListItemSource: NotificationListItemSource;
  NotificationListMethod: NotificationListMethod;
  NotificationNameMethod: NotificationNameMethod;
  NotificationReadInput: NotificationReadInput;
  NotificationReadMethod: NotificationReadMethod;
  OffersByVIN: OffersByVin;
  OffersByVINInput: OffersByVinInput;
  OptionsElement: OptionsElement;
  OptionsElementBB: OptionsElementBb;
  OptionsElementInput: OptionsElementInput;
  OptionsVinDecodeBB: OptionsVinDecodeBb;
  OptionsVinDecodeBBInput: OptionsVinDecodeBbInput;
  Package: Package;
  PackageMethod: PackageMethod;
  PackageStyle: PackageStyle;
  PeriodPriceInput: PeriodPriceInput;
  PeriodPriceType: PeriodPriceType;
  PermissionsType: PermissionsType;
  Photo: Photo;
  PhotoMethod: PhotoMethod;
  Pin: Pin;
  PinInput: PinInput;
  PrivacyInfo: PrivacyInfo;
  Query: {};
  ReatailPricesInput: ReatailPricesInput;
  ReatailPricesType: ReatailPricesType;
  RefreshTokenInput: RefreshTokenInput;
  RefreshTokenMethod: RefreshTokenMethod;
  RequestDateTimeType: RequestDateTimeType;
  Restart: Restart;
  Roles: Roles;
  SentEmailInput: SentEmailInput;
  SentEmailMethod: SentEmailMethod;
  SetSubscriptionInput: SetSubscriptionInput;
  SetSubscriptionResult: SetSubscriptionResult;
  SignInInput: SignInInput;
  SignInMethod: SignInMethod;
  SignInput: SignInput;
  SignUpInput: SignUpInput;
  StartMonkaiInspectionInput: StartMonkaiInspectionInput;
  StatusCountResponse: StatusCountResponse;
  StockImageItem: StockImageItem;
  StyleElementItem: StyleElementItem;
  StyleItem: StyleItem;
  StylesMethod: StylesMethod;
  StylesNewBlackBook: StylesNewBlackBook;
  SubOptionsList: SubOptionsList;
  SubOptionsListInput: SubOptionsListInput;
  SubOptionsPrice: SubOptionsPrice;
  SubOptionsPriceInput: SubOptionsPriceInput;
  Subscription: {};
  SubscriptionMethod: SubscriptionMethod;
  TokenResult: TokenResult;
  TradeinPricesInput: TradeinPricesInput;
  TradeinPricesType: TradeinPricesType;
  TrafficSource: TrafficSource;
  TranslationLabelInput: TranslationLabelInput;
  TranslationLabelType: TranslationLabelType;
  TrimsNewBlackBook: TrimsNewBlackBook;
  TurnOnForMy: TurnOnForMy;
  UpdateListingInput: UpdateListingInput;
  UpdateListingMethod: UpdateListingMethod;
  UpdateListingStatusInput: UpdateListingStatusInput;
  UpdatePhysicalEvaluationMethod: UpdatePhysicalEvaluationMethod;
  UpdateStatusInput: UpdateStatusInput;
  UpdateStatusResponse: UpdateStatusResponse;
  Upload: Scalars['Upload'];
  UrlTypeInput: UrlTypeInput;
  User: User;
  UserById: UserById;
  UserByIdMethod: UserByIdMethod;
  UserInput: UserInput;
  UserListAnalityc: UserListAnalityc;
  UserListAnalitycMethod: UserListAnalitycMethod;
  UserLtv: UserLtv;
  UserProperties: UserProperties;
  UserPropertiesValues: UserPropertiesValues;
  UserStaisticDataType: UserStaisticDataType;
  VAutoCheckMethod: VAutoCheckMethod;
  VehicleInfoType: VehicleInfoType;
  VehicleListDashStatistics: VehicleListDashStatistics;
  VehicleReportImage: VehicleReportImage;
  Vin: Vin;
  VinMethod: VinMethod;
  VinStyle: VinStyle;
  VinStyleBasePrice: VinStyleBasePrice;
  VinStyleBodyType: VinStyleBodyType;
  VinStyleDivision: VinStyleDivision;
  VinStyleMarketClass: VinStyleMarketClass;
  VinStyleMethod: VinStyleMethod;
  VinStyleModel: VinStyleModel;
  VinStyleResult: VinStyleResult;
  VinStyleStyle: VinStyleStyle;
  VinStyleSubdivision: VinStyleSubdivision;
  VirtualByVinType: VirtualByVinType;
  VirtualVehByVinMethod: VirtualVehByVinMethod;
  VirtualVehByVinType: VirtualVehByVinType;
  WebInfo: WebInfo;
  WholeSalePricesInput: WholeSalePricesInput;
  WholeSalePricesType: WholeSalePricesType;
  WholesaleType: WholesaleType;
  featureInput: FeatureInput;
  newBlackBookDecoderMethod: NewBlackBookDecoderMethod;
  newBlackBookDetailDecoderMethod: NewBlackBookDetailDecoderMethod;
  vehicleReportInput: VehicleReportInput;
  vehicleReportSelectInput: VehicleReportSelectInput;
  vehicleReportSelectType: VehicleReportSelectType;
  vehicleReportType: VehicleReportType;
  vinDetailsMethod: VinDetailsMethod;
};

export type ClientDirectiveArgs = {  };

export type ClientDirectiveResolver<Result, Parent, ContextType = any, Args = ClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RestDirectiveArgs = {   type: Scalars['String'];
  path: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  bodySerializer?: Maybe<Scalars['String']>;
  endpoint: Scalars['String']; };

export type RestDirectiveResolver<Result, Parent, ContextType = any, Args = RestDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AcodeIconResolvers<ContextType = any, ParentType extends ResolversParentTypes['AcodeIcon'] = ResolversParentTypes['AcodeIcon']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddDeductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddDeductListItem'] = ResolversParentTypes['AddDeductListItem']> = {
  auto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid12?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid24?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid30?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid36?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid42?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid48?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid60?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid72?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rough?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xclean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdjustedByTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdjustedByType'] = ResolversParentTypes['AdjustedByType']> = {
  Grade?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdjustedPricingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdjustedPricingType'] = ResolversParentTypes['AdjustedPricingType']> = {
  wholesale?: Resolver<ResolversTypes['ManheimItemWholesaleType'], ParentType, ContextType>;
  adjustedBy?: Resolver<ResolversTypes['AdjustedByType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalitycMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalitycMethod'] = ResolversParentTypes['AnalitycMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Application'] = ResolversParentTypes['Application']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Appraisal'] = ResolversParentTypes['Appraisal']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vAutoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  carfax?: Resolver<Maybe<ResolversTypes['CarfaxType']>, ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licenseState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licensePlate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPurchased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  odometerMiles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  traction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exteriorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interiorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vehicleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vehicleReport?: Resolver<Array<Maybe<ResolversTypes['vehicleReportType']>>, ParentType, ContextType>;
  noticeByManagers?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trimName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custHomePhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custPostalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPhotosCertifyUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  disclosures?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  disclosuresUS?: Resolver<Array<ResolversTypes['DisclosuresUSType']>, ParentType, ContextType>;
  offerAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  offerPrinted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offeredPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formulaPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custAmountOwed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custDriverLicense?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  attachments?: Resolver<Array<ResolversTypes['Attachment']>, ParentType, ContextType>;
  saleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stock?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photos?: Resolver<Array<ResolversTypes['AppraisalPhoto']>, ParentType, ContextType>;
  conditionReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inspectionReportLinkOtherService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditionReportStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditioningStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['AppraisalCondition']>, ParentType, ContextType>;
  optionsVinDecode?: Resolver<Array<ResolversTypes['SubOptionsList']>, ParentType, ContextType>;
  optionsVinDecodeBB?: Resolver<Array<ResolversTypes['DecodeBBItemType']>, ParentType, ContextType>;
  monkaiInspectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  monkaiInspectionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photosMonkaiInspections?: Resolver<Array<ResolversTypes['MonkaiImage']>, ParentType, ContextType>;
  manheimData?: Resolver<Maybe<ResolversTypes['ManheimType']>, ParentType, ContextType>;
  drivlyDataOffers?: Resolver<Maybe<ResolversTypes['OffersByVIN']>, ParentType, ContextType>;
  blackbookData?: Resolver<Maybe<ResolversTypes['BbInfoValuesType']>, ParentType, ContextType>;
  drivlyDataGurus?: Resolver<Maybe<ResolversTypes['CarGurusByVIN']>, ParentType, ContextType>;
  userEvaluationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  odometerReplaced?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  claims?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  commerciallyUsed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  disclosuresWork?: Resolver<Array<ResolversTypes['AppraisalCondition']>, ParentType, ContextType>;
  emissionSystemIntact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eraInvoiceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  eraSalesCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exitStrategyType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  factoryBuyback?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  floodDamage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasConditionReport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inspectionReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConditionReportComplete?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaseReturn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  parkingZoneSegmentation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previouslyDamaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  publicId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recall?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesperson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salvaged?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraiser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submitted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  started?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approvedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessingStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  authorized?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorizedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paveSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paveSessionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challenged?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengedByLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengeResolved?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rep?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inventory?: Resolver<Maybe<ResolversTypes['AppraisalInventory']>, ParentType, ContextType>;
  tireThread?: Resolver<ResolversTypes['AppraisalTireThread'], ParentType, ContextType>;
  sourceNameReport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['AppraisalComments'], ParentType, ContextType>;
  application?: Resolver<Maybe<ResolversTypes['Application']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalCommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalComments'] = ResolversParentTypes['AppraisalComments']> = {
  client?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  document?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraiser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalCondition'] = ResolversParentTypes['AppraisalCondition']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translationLabel?: Resolver<Maybe<ResolversTypes['TranslationLabelType']>, ParentType, ContextType>;
  photos?: Resolver<Array<ResolversTypes['AppraisalPhoto']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  todo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pin?: Resolver<ResolversTypes['Pin'], ParentType, ContextType>;
  detail?: Resolver<ResolversTypes['Detail'], ParentType, ContextType>;
  isArbitrageDamege?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  workStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workStartedById?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conditioned?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditionedById?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mapped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalConditionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalConditions'] = ResolversParentTypes['AppraisalConditions']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vehicleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stock?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  saleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  odometerMiles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  traction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licenseState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licensePlate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  closer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rep?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerPrinted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submitted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  started?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approvedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessingStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  authorized?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorizedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditioningStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challenged?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengedById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengedByLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challengeResolved?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesperson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraiser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaseReturn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custHomePhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custPostalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custDriverLicense?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custAmountOwed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vAutoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerAsSeen?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['AppraisalCondition']>, ParentType, ContextType>;
  annotations?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disclosures?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  disclosuresWork?: Resolver<Array<ResolversTypes['AppraisalCondition']>, ParentType, ContextType>;
  vehicleReport?: Resolver<Array<Maybe<ResolversTypes['vehicleReportType']>>, ParentType, ContextType>;
  photos?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tireThread?: Resolver<ResolversTypes['AppraisalTireThread'], ParentType, ContextType>;
  parkingZoneSegmentation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasConditionReport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditionReportStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConditionReportComplete?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conditionReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inspectionReportLinkOtherService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inspectionReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exteriorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interiorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalConditionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalConditionsResponse'] = ResolversParentTypes['AppraisalConditionsResponse']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['AppraisalConditions'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalInventoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalInventory'] = ResolversParentTypes['AppraisalInventory']> = {
  Stock?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dashboardUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalMethod'] = ResolversParentTypes['AppraisalMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Appraisal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalPhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalPhoto'] = ResolversParentTypes['AppraisalPhoto']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalTireThreadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalTireThread'] = ResolversParentTypes['AppraisalTireThread']> = {
  frontLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  frontRight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rearLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rearRight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mountedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondSet?: Resolver<Maybe<ResolversTypes['AppraisalTireThreadSecond']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<Maybe<ResolversTypes['AppraisalPhoto']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalTireThreadSecondResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalTireThreadSecond'] = ResolversParentTypes['AppraisalTireThreadSecond']> = {
  frontLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  frontRight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rearLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rearRight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  season?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mountedOn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalVAutoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalVAuto'] = ResolversParentTypes['AppraisalVAuto']> = {
  Appraiser?: Resolver<Maybe<ResolversTypes['AppraiserVAuto']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalVehByVinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalVehByVinMethod'] = ResolversParentTypes['AppraisalVehByVinMethod']> = {
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['AppraisalVehByVinResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalVehByVinResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalVehByVinResult'] = ResolversParentTypes['AppraisalVehByVinResult']> = {
  virtual?: Resolver<Array<ResolversTypes['VirtualByVinType']>, ParentType, ContextType>;
  physical?: Resolver<Array<ResolversTypes['AppraisalVehByVinType']>, ParentType, ContextType>;
  condition?: Resolver<Array<ResolversTypes['AppraisalVehByVinType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalVehByVinTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalVehByVinType'] = ResolversParentTypes['AppraisalVehByVinType']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  appraisalValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custHomePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custPostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPurchased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalsConditionMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalsConditionMethod'] = ResolversParentTypes['AppraisalsConditionMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['AppraisalConditions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalsCountMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalsCountMethod'] = ResolversParentTypes['AppraisalsCountMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalsCountsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalsCounts'] = ResolversParentTypes['AppraisalsCounts']> = {
  pending?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  evaluation?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  evaluating?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  assessment?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  claim?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraisalsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraisalsMethod'] = ResolversParentTypes['AppraisalsMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Appraisal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppraiserVAutoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppraiserVAuto'] = ResolversParentTypes['AppraiserVAuto']> = {
  Email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  LastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppriselVehicleForAnalyticsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppriselVehicleForAnalyticsMethod'] = ResolversParentTypes['AppriselVehicleForAnalyticsMethod']> = {
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['AppriselVehicleForAnalyticsType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppriselVehicleForAnalyticsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppriselVehicleForAnalyticsType'] = ResolversParentTypes['AppriselVehicleForAnalyticsType']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formulaPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchased?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inventoryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drivlyDataOffers?: Resolver<Maybe<ResolversTypes['OffersByVIN']>, ParentType, ContextType>;
  blackbookData?: Resolver<Maybe<ResolversTypes['BbInfoValuesType']>, ParentType, ContextType>;
  drivlyDataGurus?: Resolver<Maybe<ResolversTypes['CarGurusByVIN']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppriselVehicleMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppriselVehicleMethod'] = ResolversParentTypes['AppriselVehicleMethod']> = {
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['AppriselVehicleType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppriselVehicleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppriselVehicleType'] = ResolversParentTypes['AppriselVehicleType']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interiorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  odometerMiles?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  traction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exteriorColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custHomePhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custPostalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disclosuresUS?: Resolver<Array<ResolversTypes['DisclosuresType']>, ParentType, ContextType>;
  optionsVinDecodeBB?: Resolver<Array<ResolversTypes['OptionsVinDecodeBB']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  physicalEvaluationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  physicalEvaluationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEvaluationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPurchased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  created?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth'] = ResolversParentTypes['Auth']> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthUser'] = ResolversParentTypes['AuthUser']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Countries'], ParentType, ContextType>;
  dealership?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgeListSmallTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BadgeListSmallType'] = ResolversParentTypes['BadgeListSmallType']> = {
  BadgeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  BadgeType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BadgeImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgesListTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BadgesListType'] = ResolversParentTypes['BadgesListType']> = {
  BadgeList?: Resolver<Array<ResolversTypes['BadgeListSmallType']>, ParentType, ContextType>;
  BadgesImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  HasBadge?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  VhrReportUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  VIN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResultCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ResultMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BasePriceItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['BasePriceItem'] = ResolversParentTypes['BasePriceItem']> = {
  unknown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  msrp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  destination?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BbInfoValuesMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['BbInfoValuesMethod'] = ResolversParentTypes['BbInfoValuesMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['BbInfoValuesType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BbInfoValuesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BbInfoValuesType'] = ResolversParentTypes['BbInfoValuesType']> = {
  reatailPrices?: Resolver<Maybe<ResolversTypes['ReatailPricesType']>, ParentType, ContextType>;
  wholeSalePrices?: Resolver<Maybe<ResolversTypes['WholeSalePricesType']>, ParentType, ContextType>;
  tradeinPrices?: Resolver<Maybe<ResolversTypes['TradeinPricesType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BodyTypeItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['BodyTypeItem'] = ResolversParentTypes['BodyTypeItem']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarGurusByVinResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarGurusByVIN'] = ResolversParentTypes['CarGurusByVIN']> = {
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  retail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateParty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarPartResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarPart'] = ResolversParentTypes['CarPart']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['Label'], ParentType, ContextType>;
  top?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bottom?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  left?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  right?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarSideResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarSide'] = ResolversParentTypes['CarSide']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  side?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parts?: Resolver<Array<ResolversTypes['CarPart']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarfaxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarfaxType'] = ResolversParentTypes['CarfaxType']> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarproofResolvers<ContextType = any, ParentType extends ResolversParentTypes['Carproof'] = ResolversParentTypes['Carproof']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reportId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lang?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarproofMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarproofMethod'] = ResolversParentTypes['CarproofMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Carproof'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarproofVpnResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarproofVPN'] = ResolversParentTypes['CarproofVPN']> = {
  AccountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BadgesList?: Resolver<Array<ResolversTypes['BadgesListType']>, ParentType, ContextType>;
  Language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  LogoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResultCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ResultMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarproofVpnMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarproofVPNMethod'] = ResolversParentTypes['CarproofVPNMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['CarproofVPN'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarstoryByVinTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarstoryByVINType'] = ResolversParentTypes['CarstoryByVINType']> = {
  carstory?: Resolver<ResolversTypes['CarstoryType'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarstoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarstoryType'] = ResolversParentTypes['CarstoryType']> = {
  vehicleInfo?: Resolver<ResolversTypes['VehicleInfoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromeDivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromeDivision'] = ResolversParentTypes['ChromeDivision']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromeModelYearResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromeModelYear'] = ResolversParentTypes['ChromeModelYear']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromeModelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromeModels'] = ResolversParentTypes['ChromeModels']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromeStylesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromeStyles'] = ResolversParentTypes['ChromeStyles']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompleteVehicleUserEvaluationMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompleteVehicleUserEvaluationMethod'] = ResolversParentTypes['CompleteVehicleUserEvaluationMethod']> = {
  evaluationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMonkaiInspectionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMonkaiInspectionResponse'] = ResolversParentTypes['CreateMonkaiInspectionResponse']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['CreateMonkaiInspectionResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMonkaiInspectionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMonkaiInspectionResult'] = ResolversParentTypes['CreateMonkaiInspectionResult']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  object_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePhysicalEvaluationMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePhysicalEvaluationMethod'] = ResolversParentTypes['CreatePhysicalEvaluationMethod']> = {
  result?: Resolver<ResolversTypes['Appraisal'], ParentType, ContextType>;
  exists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  modified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DamageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Damage'] = ResolversParentTypes['Damage']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  side?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['Label'], ParentType, ContextType>;
  bucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['DamageAction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DamageActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DamageAction'] = ResolversParentTypes['DamageAction']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['Label'], ParentType, ContextType>;
  deval_low?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deval_high?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repair_low?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repair_high?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashStatisticsListMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashStatisticsListMethod'] = ResolversParentTypes['DashStatisticsListMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['VehicleListDashStatistics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashStatisticsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashStatisticsMethod'] = ResolversParentTypes['DashStatisticsMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['DashStatisticsType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashStatisticsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashStatisticsType'] = ResolversParentTypes['DashStatisticsType']> = {
  user?: Resolver<ResolversTypes['UserStaisticDataType'], ParentType, ContextType>;
  purchased?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  purchasedIds?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  appraisalIds?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  priceToMarket?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  costToMarket?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  average?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataFullCurrentStyleElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataFullCurrentStyleElement'] = ResolversParentTypes['DataFullCurrentStyleElement']> = {
  currentStyleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  drivetrain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestModelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestMakeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestStyleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestTrimName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cylinders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bodyStyle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionDesc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drivetrainDesc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exteriorColor?: Resolver<Array<ResolversTypes['ExteriorColor']>, ParentType, ContextType>;
  interiorColor?: Resolver<Array<ResolversTypes['InteriorColor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DealershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dealership'] = ResolversParentTypes['Dealership']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeBbItemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeBBItemType'] = ResolversParentTypes['DecodeBBItemType']> = {
  auto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid12?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid24?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid30?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid36?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid42?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid48?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid60?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resid72?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rough?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xclean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinBbResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinBBResult'] = ResolversParentTypes['DecodeVinBBResult']> = {
  makeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestTrimName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  trims?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  engines?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  bodyStyles?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  tractions?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  transmissions?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  exteriorColors?: Resolver<Array<ResolversTypes['DefoltElementColorDecode']>, ParentType, ContextType>;
  interiorColors?: Resolver<Array<ResolversTypes['DefoltElementColorDecode']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['OptionsElementBB']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinBlackBookMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinBlackBookMethod'] = ResolversParentTypes['DecodeVinBlackBookMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['DecodeVinBBResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinMethod'] = ResolversParentTypes['DecodeVinMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['DecodeVinResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinNewMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinNewMethod'] = ResolversParentTypes['DecodeVinNewMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['DecodeVinNewResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinNewResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinNewResult'] = ResolversParentTypes['DecodeVinNewResult']> = {
  makeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestTrimName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  trims?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  engines?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  bodyStyles?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  tractions?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  transmissions?: Resolver<Array<ResolversTypes['DefoltElementDecode']>, ParentType, ContextType>;
  exteriorColors?: Resolver<Array<ResolversTypes['DefoltElementColorDecode']>, ParentType, ContextType>;
  interiorColors?: Resolver<Array<ResolversTypes['DefoltElementColorDecode']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['OptionsElement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecodeVinResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecodeVinResult'] = ResolversParentTypes['DecodeVinResult']> = {
  countStyles?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['StyleItem']>, ParentType, ContextType>;
  dataFullCurrentStyle?: Resolver<ResolversTypes['DataFullCurrentStyleElement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefoltElementBbDecodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DefoltElementBBDecode'] = ResolversParentTypes['DefoltElementBBDecode']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefoltElementColorDecodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DefoltElementColorDecode'] = ResolversParentTypes['DefoltElementColorDecode']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rgbValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefoltElementDecodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DefoltElementDecode'] = ResolversParentTypes['DefoltElementDecode']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['Detail'] = ResolversParentTypes['Detail']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mustAttach?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile_brand_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile_model_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile_marketing_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile_os_hardware_model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operating_system?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operating_system_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendor_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertising_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_limited_ad_tracking?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time_zone_offset_seconds?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  browser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  browser_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  web_info?: Resolver<ResolversTypes['WebInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisclosuresTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisclosuresType'] = ResolversParentTypes['DisclosuresType']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  children?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisclosuresUsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisclosuresUSType'] = ResolversParentTypes['DisclosuresUSType']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DivisionsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['DivisionsMethod'] = ResolversParentTypes['DivisionsMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ChromeDivision']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrivErrorTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrivErrorType'] = ResolversParentTypes['DrivErrorType']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventParamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventParams'] = ResolversParentTypes['EventParams']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['EventParamsValue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventParamsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventParamsType'] = ResolversParentTypes['EventParamsType']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['EventParamsValue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventParamsValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventParamsValue'] = ResolversParentTypes['EventParamsValue']> = {
  string_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  int_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  float_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  double_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventParamsValueTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventParamsValueType'] = ResolversParentTypes['EventParamsValueType']> = {
  string_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  int_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExteriorColorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExteriorColor'] = ResolversParentTypes['ExteriorColor']> = {
  genericColor?: Resolver<Array<ResolversTypes['ExteriorColorElement']>, ParentType, ContextType>;
  styleId?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  installed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colorCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rgbValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExteriorColorElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExteriorColorElement'] = ResolversParentTypes['ExteriorColorElement']> = {
  installed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureForMyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureForMyResponse'] = ResolversParentTypes['FeatureForMyResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['TurnOnForMy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureInputResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureInputResponse'] = ResolversParentTypes['FeatureInputResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['ListiFeatureResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureResponse'] = ResolversParentTypes['FeatureResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['ListiFeatureResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowUpResolvers<ContextType = any, ParentType extends ResolversParentTypes['FollowUp'] = ResolversParentTypes['FollowUp']> = {
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geo'] = ResolversParentTypes['Geo']> = {
  continent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sub_continent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metro?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoType'] = ResolversParentTypes['GeoType']> = {
  continent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sub_continent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metro?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAppraisalWithPricingDataByVinResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAppraisalWithPricingDataByVinResult'] = ResolversParentTypes['GetAppraisalWithPricingDataByVinResult']> = {
  Appraisal?: Resolver<ResolversTypes['AppraisalVAuto'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCarproofMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCarproofMethod'] = ResolversParentTypes['GetCarproofMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['GetCarproofType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCarproofTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCarproofType'] = ResolversParentTypes['GetCarproofType']> = {
  InvoicedTo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MobileReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MobileReportLinkHideLiens?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ReportLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ReportLinkHideLiens?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ReportNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ReportType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ReportTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Year?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCarstoryByVinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCarstoryByVINMethod'] = ResolversParentTypes['GetCarstoryByVINMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['CarstoryByVINType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetListForBedStartCompleteMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetListForBedStartCompleteMethod'] = ResolversParentTypes['GetListForBedStartCompleteMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ListProblemStartAndComplete']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetListForBedStartCompleteOneResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetListForBedStartCompleteOne'] = ResolversParentTypes['GetListForBedStartCompleteOne']> = {
  event_params?: Resolver<Array<ResolversTypes['EventParamsType']>, ParentType, ContextType>;
  geo?: Resolver<ResolversTypes['GeoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetListForBedStartCompleteOneMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetListForBedStartCompleteOneMethod'] = ResolversParentTypes['GetListForBedStartCompleteOneMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['GetListForBedStartCompleteOne']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetListForBedVinDecodeMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetListForBedVinDecodeMethod'] = ResolversParentTypes['GetListForBedVinDecodeMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ListProblemStartAndComplete']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetMonkaiInspectionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetMonkaiInspectionResponse'] = ResolversParentTypes['GetMonkaiInspectionResponse']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['GetMonkaiInspectionResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetMonkaiInspectionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetMonkaiInspectionResult'] = ResolversParentTypes['GetMonkaiInspectionResult']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vehId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  monkaiInspectionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monkaiInspectionStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photosMonkaiInspections?: Resolver<Array<ResolversTypes['MonkaiImage']>, ParentType, ContextType>;
  monkaiVehicleInfo?: Resolver<Array<Maybe<ResolversTypes['MonkaiVehicleInfoType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetMonkaiTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetMonkaiTokenResponse'] = ResolversParentTypes['GetMonkaiTokenResponse']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaEvalItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaEvalItem'] = ResolversParentTypes['GlaEvalItem']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  diff?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaEvalResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaEvalResponse'] = ResolversParentTypes['GlaEvalResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['GlaEvalItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaFollowItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaFollowItem'] = ResolversParentTypes['GlaFollowItem']> = {
  booked?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  called?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  none?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priced?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  smsSent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaFollowResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaFollowResponse'] = ResolversParentTypes['GlaFollowResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['GlaFollowItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaItem'] = ResolversParentTypes['GlaItem']> = {
  totalAppraisal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAsking?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGap?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlaResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlaResponse'] = ResolversParentTypes['GlaResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['GlaItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InfoForUserAnalitycMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['InfoForUserAnalitycMethod'] = ResolversParentTypes['InfoForUserAnalitycMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InteriorColorResolvers<ContextType = any, ParentType extends ResolversParentTypes['InteriorColor'] = ResolversParentTypes['InteriorColor']> = {
  genericColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  styleId?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  installed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colorCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rgbValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemSubscriptionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemSubscriptionResult'] = ResolversParentTypes['ItemSubscriptionResult']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  emitter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medium?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Label'] = ResolversParentTypes['Label']> = {
  en?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LicenseplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Licenseplate'] = ResolversParentTypes['Licenseplate']> = {
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LicenseplateMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['LicenseplateMethod'] = ResolversParentTypes['LicenseplateMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Licenseplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListProblemStartAndCompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListProblemStartAndComplete'] = ResolversParentTypes['ListProblemStartAndComplete']> = {
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  problem_with_start_vehicle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  problem_with_complete_vehicle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListiFeatureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListiFeatureResponse'] = ResolversParentTypes['ListiFeatureResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ListiFeatureResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListiFeatureResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListiFeatureResult'] = ResolversParentTypes['ListiFeatureResult']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListiUsersFeatureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListiUsersFeatureResponse'] = ResolversParentTypes['ListiUsersFeatureResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ListiUsersFeatureResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListiUsersFeatureResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListiUsersFeatureResult'] = ResolversParentTypes['ListiUsersFeatureResult']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  googleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  segmentation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  samAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enableFeature?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Listing'] = ResolversParentTypes['Listing']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  segmentation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formulaPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originSource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  odometerMiles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  askingValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custSmsNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followUps?: Resolver<Array<ResolversTypes['FollowUp']>, ParentType, ContextType>;
  disclosures?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['ListingComments'], ParentType, ContextType>;
  vAutoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preliminaryValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submitted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  started?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  autoSmsSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  previouslyDamaged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingCommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingComments'] = ResolversParentTypes['ListingComments']> = {
  client?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraiser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  document?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingMethod'] = ResolversParentTypes['ListingMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingStatusResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingStatusResult'] = ResolversParentTypes['ListingStatusResult']> = {
  modified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingsCountMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingsCountMethod'] = ResolversParentTypes['ListingsCountMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingsCreateResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingsCreateResult'] = ResolversParentTypes['ListingsCreateResult']> = {
  result?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListingsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingsMethod'] = ResolversParentTypes['ListingsMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Listing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approbation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brandingPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  estimationAutoSmsContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nomadGroup?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segmentation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  periodPrice?: Resolver<Array<ResolversTypes['PeriodPriceType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationMethod'] = ResolversParentTypes['LocationMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimDataByVinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimDataByVinMethod'] = ResolversParentTypes['ManheimDataByVinMethod']> = {
  result?: Resolver<ResolversTypes['ManheimDataByVinResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimDataByVinResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimDataByVinResult'] = ResolversParentTypes['ManheimDataByVinResult']> = {
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ManheimVehicleType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimDescriptionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimDescriptionType'] = ResolversParentTypes['ManheimDescriptionType']> = {
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subSeries?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimItemDescriptionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimItemDescriptionType'] = ResolversParentTypes['ManheimItemDescriptionType']> = {
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subSeries?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimItemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimItemType'] = ResolversParentTypes['ManheimItemType']> = {
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extendedCoverage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['ManheimItemDescriptionType'], ParentType, ContextType>;
  wholesale?: Resolver<ResolversTypes['ManheimItemWholesaleType'], ParentType, ContextType>;
  averageOdometer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  odometerUnits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adjustedPricing?: Resolver<ResolversTypes['AdjustedPricingType'], ParentType, ContextType>;
  averageGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sampleSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimItemWholesaleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimItemWholesaleType'] = ResolversParentTypes['ManheimItemWholesaleType']> = {
  above?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  below?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimType'] = ResolversParentTypes['ManheimType']> = {
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ManheimItemType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManheimVehicleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManheimVehicleType'] = ResolversParentTypes['ManheimVehicleType']> = {
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extendedCoverage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['ManheimDescriptionType'], ParentType, ContextType>;
  wholesale?: Resolver<ResolversTypes['WholesaleType'], ParentType, ContextType>;
  averageOdometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageGrade?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adjustedPricing?: Resolver<ResolversTypes['AdjustedPricingType'], ParentType, ContextType>;
  odometerUnits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sampleSize?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestedDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  returnedDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bestMatch?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessaageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Messaage'] = ResolversParentTypes['Messaage']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read_by?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessaagesMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessaagesMethod'] = ResolversParentTypes['MessaagesMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Messaage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelYearsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModelYearsMethod'] = ResolversParentTypes['ModelYearsMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ChromeModelYear']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModelsMethod'] = ResolversParentTypes['ModelsMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ChromeModels']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonkaiImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonkaiImage'] = ResolversParentTypes['MonkaiImage']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  srcMonkai?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonkaiVehicleInfoTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonkaiVehicleInfoType'] = ResolversParentTypes['MonkaiVehicleInfoType']> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  car_registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date_of_circulation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duplicate_keys?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expertise_requested?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exterior_cleanliness?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interior_cleanliness?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  object_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner_info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repair_estimate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trade_in_offer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicle_quotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicle_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  bbInfoValues?: Resolver<ResolversTypes['BbInfoValuesMethod'], ParentType, ContextType, RequireFields<MutationBbInfoValuesArgs, 'input'>>;
  cloneAppraisal?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<MutationCloneAppraisalArgs, 'input'>>;
  completeVehicleUserEvaluation?: Resolver<ResolversTypes['CompleteVehicleUserEvaluationMethod'], ParentType, ContextType, RequireFields<MutationCompleteVehicleUserEvaluationArgs, 'id' | 'input'>>;
  createAppraisal?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<MutationCreateAppraisalArgs, 'input'>>;
  createAppraisalConditions?: Resolver<ResolversTypes['AppraisalConditionsResponse'], ParentType, ContextType, RequireFields<MutationCreateAppraisalConditionsArgs, 'input'>>;
  createDamage?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationCreateDamageArgs, 'input'>>;
  createDamageAction?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationCreateDamageActionArgs, 'input'>>;
  createEmptyMonkaiInspection?: Resolver<Maybe<ResolversTypes['CreateMonkaiInspectionResponse']>, ParentType, ContextType, RequireFields<MutationCreateEmptyMonkaiInspectionArgs, 'input'>>;
  createListing?: Resolver<ResolversTypes['ListingsCreateResult'], ParentType, ContextType, RequireFields<MutationCreateListingArgs, 'input'>>;
  createMonkaiInspection?: Resolver<Maybe<ResolversTypes['CreateMonkaiInspectionResponse']>, ParentType, ContextType, RequireFields<MutationCreateMonkaiInspectionArgs, 'input'>>;
  createPaveSession?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<MutationCreatePaveSessionArgs, never>>;
  createPhysicalEvaluation?: Resolver<ResolversTypes['CreatePhysicalEvaluationMethod'], ParentType, ContextType, RequireFields<MutationCreatePhysicalEvaluationArgs, 'input'>>;
  dashStatistics?: Resolver<ResolversTypes['DashStatisticsMethod'], ParentType, ContextType, RequireFields<MutationDashStatisticsArgs, never>>;
  deleteDamage?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationDeleteDamageArgs, 'id' | 'userId'>>;
  deleteDamageAction?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationDeleteDamageActionArgs, 'id' | 'userId'>>;
  featureUpdate?: Resolver<ResolversTypes['FeatureInputResponse'], ParentType, ContextType, RequireFields<MutationFeatureUpdateArgs, 'id' | 'input'>>;
  getCarstoryByVIN?: Resolver<ResolversTypes['GetCarstoryByVINMethod'], ParentType, ContextType, RequireFields<MutationGetCarstoryByVinArgs, 'input'>>;
  notificationRead?: Resolver<ResolversTypes['NotificationReadMethod'], ParentType, ContextType, RequireFields<MutationNotificationReadArgs, never>>;
  restartAppraisal?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<MutationRestartAppraisalArgs, never>>;
  sentEmail?: Resolver<ResolversTypes['SentEmailMethod'], ParentType, ContextType, RequireFields<MutationSentEmailArgs, 'input'>>;
  setSubscription?: Resolver<ResolversTypes['SetSubscriptionResult'], ParentType, ContextType, RequireFields<MutationSetSubscriptionArgs, never>>;
  signIn?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType, RequireFields<MutationSignInArgs, 'input'>>;
  signUp?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType, RequireFields<MutationSignUpArgs, 'input'>>;
  startMonkaiInspection?: Resolver<Maybe<ResolversTypes['CreateMonkaiInspectionResponse']>, ParentType, ContextType, RequireFields<MutationStartMonkaiInspectionArgs, 'input'>>;
  updateAppraisal?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<MutationUpdateAppraisalArgs, 'input'>>;
  updateAppraisalConditions?: Resolver<ResolversTypes['AppraisalConditionsResponse'], ParentType, ContextType, RequireFields<MutationUpdateAppraisalConditionsArgs, 'id' | 'input'>>;
  updateDamage?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationUpdateDamageArgs, 'id' | 'input'>>;
  updateDamageAction?: Resolver<ResolversTypes['Damage'], ParentType, ContextType, RequireFields<MutationUpdateDamageActionArgs, 'id' | 'input'>>;
  updateListing?: Resolver<ResolversTypes['UpdateListingMethod'], ParentType, ContextType, RequireFields<MutationUpdateListingArgs, 'input' | 'id'>>;
  updateListingStatus?: Resolver<ResolversTypes['ListingStatusResult'], ParentType, ContextType, RequireFields<MutationUpdateListingStatusArgs, 'input' | 'id'>>;
  updateLocations?: Resolver<ResolversTypes['LocationMethod'], ParentType, ContextType, RequireFields<MutationUpdateLocationsArgs, 'id' | 'input'>>;
  updatePhysicalEvaluation?: Resolver<ResolversTypes['UpdatePhysicalEvaluationMethod'], ParentType, ContextType, RequireFields<MutationUpdatePhysicalEvaluationArgs, 'id' | 'input'>>;
  updateStatus?: Resolver<ResolversTypes['UpdateStatusResponse'], ParentType, ContextType, RequireFields<MutationUpdateStatusArgs, 'id' | 'input'>>;
  updateVehicleUserEvaluation?: Resolver<ResolversTypes['AppriselVehicleType'], ParentType, ContextType, RequireFields<MutationUpdateVehicleUserEvaluationArgs, 'id' | 'input'>>;
  uploadAppraisalPhoto?: Resolver<ResolversTypes['PhotoMethod'], ParentType, ContextType, RequireFields<MutationUploadAppraisalPhotoArgs, 'input'>>;
  uploadAppraisalPhotoReactive?: Resolver<ResolversTypes['PhotoMethod'], ParentType, ContextType, RequireFields<MutationUploadAppraisalPhotoReactiveArgs, 'id' | 'input'>>;
  userUpdate?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUserUpdateArgs, 'id' | 'input'>>;
};

export type NotificationCountMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationCountMethod'] = ResolversParentTypes['NotificationCountMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationListItem'] = ResolversParentTypes['NotificationListItem']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['NotificationListItemSource'], ParentType, ContextType>;
  read_by?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  received_by?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationListItemSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationListItemSource'] = ResolversParentTypes['NotificationListItemSource']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationListMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationListMethod'] = ResolversParentTypes['NotificationListMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['NotificationListItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationNameMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationNameMethod'] = ResolversParentTypes['NotificationNameMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationReadMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationReadMethod'] = ResolversParentTypes['NotificationReadMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OffersByVinResolvers<ContextType = any, ParentType extends ResolversParentTypes['OffersByVIN'] = ResolversParentTypes['OffersByVIN']> = {
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vroomPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vroomGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vroomUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vroomError?: Resolver<Maybe<ResolversTypes['DrivErrorType']>, ParentType, ContextType>;
  carvanaPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  carvanaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carvanaError?: Resolver<Maybe<ResolversTypes['DrivErrorType']>, ParentType, ContextType>;
  carmaxPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  carmaxUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carmaxDeclineReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carmaxError?: Resolver<Maybe<ResolversTypes['DrivErrorType']>, ParentType, ContextType>;
  retry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionsElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionsElement'] = ResolversParentTypes['OptionsElement']> = {
  nameGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subOptions?: Resolver<Array<ResolversTypes['SubOptionsList']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionsElementBbResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionsElementBB'] = ResolversParentTypes['OptionsElementBB']> = {
  addDeductList?: Resolver<Array<Maybe<ResolversTypes['AddDeductListItem']>>, ParentType, ContextType>;
  styles?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionsVinDecodeBbResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionsVinDecodeBB'] = ResolversParentTypes['OptionsVinDecodeBB']> = {
  auto?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  avg?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clean?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resid12?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid24?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid30?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid36?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid42?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid48?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid60?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resid72?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rough?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uoc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xclean?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package']> = {
  style?: Resolver<ResolversTypes['PackageStyle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageMethod'] = ResolversParentTypes['PackageMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Package'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageStyleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageStyle'] = ResolversParentTypes['PackageStyle']> = {
  styleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trimName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  divisionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  consumerFriendlyBodyType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consumerFriendlyDrivetrain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consumerFriendlyStyleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  consumerFriendlyModelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PeriodPriceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PeriodPriceType'] = ResolversParentTypes['PeriodPriceType']> = {
  percent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionsType'] = ResolversParentTypes['PermissionsType']> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  module?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resources?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Photo'] = ResolversParentTypes['Photo']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoMethod'] = ResolversParentTypes['PhotoMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Photo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PinResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pin'] = ResolversParentTypes['Pin']> = {
  x?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mapName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivacyInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrivacyInfo'] = ResolversParentTypes['PrivacyInfo']> = {
  analytics_storage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ads_storage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uses_transient_token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  analytic?: Resolver<ResolversTypes['AnalitycMethod'], ParentType, ContextType, RequireFields<QueryAnalyticArgs, 'eventName' | 'date'>>;
  appraisal?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<QueryAppraisalArgs, 'id' | 'withInventory'>>;
  appraisalVehByIdCore?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<QueryAppraisalVehByIdCoreArgs, 'id'>>;
  appraisalVehByIdWithInventoryCore?: Resolver<ResolversTypes['AppraisalMethod'], ParentType, ContextType, RequireFields<QueryAppraisalVehByIdWithInventoryCoreArgs, 'id'>>;
  appraisalVehByVin?: Resolver<ResolversTypes['AppraisalVehByVinMethod'], ParentType, ContextType, RequireFields<QueryAppraisalVehByVinArgs, 'vin'>>;
  appraisals?: Resolver<ResolversTypes['AppraisalsMethod'], ParentType, ContextType, RequireFields<QueryAppraisalsArgs, 'statuses' | 'withInventory' | 'location' | 'size'>>;
  appraisalsCondition?: Resolver<ResolversTypes['AppraisalsConditionMethod'], ParentType, ContextType, RequireFields<QueryAppraisalsConditionArgs, 'statuses' | 'withInventory' | 'location' | 'size'>>;
  appraisalsCore?: Resolver<ResolversTypes['AppraisalsMethod'], ParentType, ContextType, RequireFields<QueryAppraisalsCoreArgs, 'statuses' | 'withInventory' | 'location' | 'size'>>;
  appraisalsCount?: Resolver<ResolversTypes['AppraisalsCounts'], ParentType, ContextType>;
  appraisalsNus?: Resolver<ResolversTypes['AppraisalsMethod'], ParentType, ContextType, RequireFields<QueryAppraisalsNusArgs, 'ids' | 'size'>>;
  assessmentAppraisalsCount?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  carSides?: Resolver<Array<ResolversTypes['CarSide']>, ParentType, ContextType, RequireFields<QueryCarSidesArgs, 'variant'>>;
  carproof?: Resolver<ResolversTypes['CarproofMethod'], ParentType, ContextType, RequireFields<QueryCarproofArgs, 'vin'>>;
  claimAppraisalsCount?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  damages?: Resolver<Array<ResolversTypes['Damage']>, ParentType, ContextType, RequireFields<QueryDamagesArgs, 'variant' | 'part' | 'side'>>;
  dashStatisticsBoard?: Resolver<ResolversTypes['DashStatisticsMethod'], ParentType, ContextType, RequireFields<QueryDashStatisticsBoardArgs, 'select'>>;
  dashStatisticsPrice?: Resolver<ResolversTypes['DashStatisticsListMethod'], ParentType, ContextType, RequireFields<QueryDashStatisticsPriceArgs, 'ids' | 'select'>>;
  dealerships?: Resolver<Array<ResolversTypes['Dealership']>, ParentType, ContextType>;
  decodeVin?: Resolver<ResolversTypes['DecodeVinMethod'], ParentType, ContextType, RequireFields<QueryDecodeVinArgs, 'vin' | 'country'>>;
  decodeVinBlackBook?: Resolver<ResolversTypes['DecodeVinBlackBookMethod'], ParentType, ContextType, RequireFields<QueryDecodeVinBlackBookArgs, 'vin' | 'country'>>;
  decodeVinNew?: Resolver<ResolversTypes['DecodeVinNewMethod'], ParentType, ContextType, RequireFields<QueryDecodeVinNewArgs, 'vin'>>;
  divisions?: Resolver<ResolversTypes['DivisionsMethod'], ParentType, ContextType, RequireFields<QueryDivisionsArgs, 'country'>>;
  evaluatingAppraisalsCount?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  evaluatingListingsCount?: Resolver<ResolversTypes['ListingsCountMethod'], ParentType, ContextType, RequireFields<QueryEvaluatingListingsCountArgs, 'start' | 'end'>>;
  evaluationAppraisalsCount?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  evaluationListingsCount?: Resolver<ResolversTypes['ListingsCountMethod'], ParentType, ContextType, RequireFields<QueryEvaluationListingsCountArgs, 'start' | 'end'>>;
  feature?: Resolver<ResolversTypes['FeatureResponse'], ParentType, ContextType, RequireFields<QueryFeatureArgs, 'id'>>;
  featureForMy?: Resolver<ResolversTypes['FeatureForMyResponse'], ParentType, ContextType>;
  getAppraisalConditions?: Resolver<ResolversTypes['AppraisalConditionsResponse'], ParentType, ContextType, RequireFields<QueryGetAppraisalConditionsArgs, 'id'>>;
  getAuthUser?: Resolver<ResolversTypes['AuthUser'], ParentType, ContextType>;
  getCarGurusByVIN?: Resolver<ResolversTypes['CarGurusByVIN'], ParentType, ContextType, RequireFields<QueryGetCarGurusByVinArgs, 'vin'>>;
  getCarfax?: Resolver<ResolversTypes['GetCarproofMethod'], ParentType, ContextType, RequireFields<QueryGetCarfaxArgs, 'vin'>>;
  getCarfaxVPN?: Resolver<ResolversTypes['CarproofVPNMethod'], ParentType, ContextType, RequireFields<QueryGetCarfaxVpnArgs, 'vin' | 'lang'>>;
  getListForBedStartComplete?: Resolver<Maybe<ResolversTypes['GetListForBedStartCompleteMethod']>, ParentType, ContextType, RequireFields<QueryGetListForBedStartCompleteArgs, 'date'>>;
  getListForBedStartCompleteOne?: Resolver<Maybe<ResolversTypes['GetListForBedStartCompleteOneMethod']>, ParentType, ContextType, RequireFields<QueryGetListForBedStartCompleteOneArgs, 'date' | 'email'>>;
  getMonkaiInspection?: Resolver<ResolversTypes['GetMonkaiInspectionResponse'], ParentType, ContextType, RequireFields<QueryGetMonkaiInspectionArgs, 'id'>>;
  getMonkaiToken?: Resolver<ResolversTypes['GetMonkaiTokenResponse'], ParentType, ContextType, RequireFields<QueryGetMonkaiTokenArgs, never>>;
  getOffersByVIN?: Resolver<ResolversTypes['OffersByVIN'], ParentType, ContextType, RequireFields<QueryGetOffersByVinArgs, 'vin' | 'mileage' | 'zipCode'>>;
  getOneVehicleUserEvaluation?: Resolver<ResolversTypes['AppriselVehicleType'], ParentType, ContextType, RequireFields<QueryGetOneVehicleUserEvaluationArgs, never>>;
  getPropertyCount?: Resolver<ResolversTypes['StatusCountResponse'], ParentType, ContextType, RequireFields<QueryGetPropertyCountArgs, 'service' | 'filter' | 'prop' | 'value'>>;
  getSlaEval?: Resolver<ResolversTypes['GlaEvalResponse'], ParentType, ContextType, RequireFields<QueryGetSlaEvalArgs, 'service' | 'filter'>>;
  getSlaFollow?: Resolver<ResolversTypes['GlaFollowResponse'], ParentType, ContextType, RequireFields<QueryGetSlaFollowArgs, 'service' | 'filter'>>;
  getSlaGap?: Resolver<ResolversTypes['GlaResponse'], ParentType, ContextType, RequireFields<QueryGetSlaGapArgs, 'service' | 'filter'>>;
  getStatusCount?: Resolver<ResolversTypes['StatusCountResponse'], ParentType, ContextType, RequireFields<QueryGetStatusCountArgs, 'service' | 'status' | 'filter'>>;
  getSubscriptions?: Resolver<ResolversTypes['SubscriptionMethod'], ParentType, ContextType>;
  getUserEvaluationList?: Resolver<ResolversTypes['AppriselVehicleMethod'], ParentType, ContextType, RequireFields<QueryGetUserEvaluationListArgs, 'location' | 'page' | 'limit' | 'select' | 'search' | 'statuses'>>;
  getUserEvaluationListForAnalytics?: Resolver<ResolversTypes['AppriselVehicleForAnalyticsMethod'], ParentType, ContextType, RequireFields<QueryGetUserEvaluationListForAnalyticsArgs, 'location' | 'limit' | 'select' | 'statuses'>>;
  infoForUserAnalityc?: Resolver<ResolversTypes['InfoForUserAnalitycMethod'], ParentType, ContextType, RequireFields<QueryInfoForUserAnalitycArgs, 'eventName' | 'date' | 'email'>>;
  licenseplate?: Resolver<Maybe<ResolversTypes['LicenseplateMethod']>, ParentType, ContextType, RequireFields<QueryLicenseplateArgs, 'plate' | 'state'>>;
  listUsersAnalytic?: Resolver<ResolversTypes['UserListAnalitycMethod'], ParentType, ContextType, RequireFields<QueryListUsersAnalyticArgs, 'eventName' | 'date'>>;
  listiFeature?: Resolver<ResolversTypes['ListiFeatureResponse'], ParentType, ContextType>;
  listiUsersFeature?: Resolver<ResolversTypes['ListiUsersFeatureResponse'], ParentType, ContextType, RequireFields<QueryListiUsersFeatureArgs, 'id'>>;
  listing?: Resolver<ResolversTypes['ListingMethod'], ParentType, ContextType, RequireFields<QueryListingArgs, 'id'>>;
  listings?: Resolver<ResolversTypes['ListingsMethod'], ParentType, ContextType, RequireFields<QueryListingsArgs, 'statuses' | 'location' | 'search' | 'size' | 'page'>>;
  locations?: Resolver<ResolversTypes['LocationMethod'], ParentType, ContextType>;
  manheimDataByVin?: Resolver<Maybe<ResolversTypes['ManheimDataByVinMethod']>, ParentType, ContextType, RequireFields<QueryManheimDataByVinArgs, 'vin' | 'odometer' | 'grade'>>;
  messaages?: Resolver<ResolversTypes['MessaagesMethod'], ParentType, ContextType, RequireFields<QueryMessaagesArgs, 'channel'>>;
  modelYears?: Resolver<ResolversTypes['ModelYearsMethod'], ParentType, ContextType>;
  models?: Resolver<ResolversTypes['ModelsMethod'], ParentType, ContextType, RequireFields<QueryModelsArgs, 'country'>>;
  newBlackBookDecoder?: Resolver<ResolversTypes['newBlackBookDecoderMethod'], ParentType, ContextType, RequireFields<QueryNewBlackBookDecoderArgs, 'vin'>>;
  newBlackBookDetailDecoder?: Resolver<ResolversTypes['newBlackBookDetailDecoderMethod'], ParentType, ContextType, RequireFields<QueryNewBlackBookDetailDecoderArgs, 'id'>>;
  notificationCount?: Resolver<ResolversTypes['NotificationCountMethod'], ParentType, ContextType>;
  notificationList?: Resolver<ResolversTypes['NotificationListMethod'], ParentType, ContextType>;
  notificationTitle?: Resolver<ResolversTypes['NotificationNameMethod'], ParentType, ContextType, RequireFields<QueryNotificationTitleArgs, 'id' | 'source'>>;
  package?: Resolver<ResolversTypes['PackageMethod'], ParentType, ContextType, RequireFields<QueryPackageArgs, 'styleId' | 'country'>>;
  pendingAppraisalsCount?: Resolver<ResolversTypes['AppraisalsCountMethod'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Roles']>, ParentType, ContextType>;
  styles?: Resolver<ResolversTypes['StylesMethod'], ParentType, ContextType, RequireFields<QueryStylesArgs, 'country'>>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  userById?: Resolver<ResolversTypes['UserByIdMethod'], ParentType, ContextType, RequireFields<QueryUserByIdArgs, 'id'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  vAutoCheckCore?: Resolver<ResolversTypes['VAutoCheckMethod'], ParentType, ContextType, RequireFields<QueryVAutoCheckCoreArgs, 'vin'>>;
  vin?: Resolver<ResolversTypes['VinMethod'], ParentType, ContextType, RequireFields<QueryVinArgs, 'vin' | 'country'>>;
  vinDetails?: Resolver<ResolversTypes['vinDetailsMethod'], ParentType, ContextType, RequireFields<QueryVinDetailsArgs, 'vin'>>;
  vinStyle?: Resolver<ResolversTypes['VinStyleMethod'], ParentType, ContextType, RequireFields<QueryVinStyleArgs, 'id' | 'country'>>;
  virtualVehByVin?: Resolver<ResolversTypes['VirtualVehByVinMethod'], ParentType, ContextType, RequireFields<QueryVirtualVehByVinArgs, 'vin'>>;
};

export type ReatailPricesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReatailPricesType'] = ResolversParentTypes['ReatailPricesType']> = {
  adjustedRetailAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedRetailClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedRetailRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedRetailXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseRetailAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseRetailClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseRetailRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseRetailXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefreshTokenMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshTokenMethod'] = ResolversParentTypes['RefreshTokenMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['TokenResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestDateTimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestDateTimeType'] = ResolversParentTypes['RequestDateTimeType']> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone_type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Roles'] = ResolversParentTypes['Roles']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['PermissionsType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SentEmailMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SentEmailMethod'] = ResolversParentTypes['SentEmailMethod']> = {
  modified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetSubscriptionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetSubscriptionResult'] = ResolversParentTypes['SetSubscriptionResult']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ItemSubscriptionResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignInMethod'] = ResolversParentTypes['SignInMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['TokenResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatusCountResponse'] = ResolversParentTypes['StatusCountResponse']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockImageItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockImageItem'] = ResolversParentTypes['StockImageItem']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  height?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StyleElementItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['StyleElementItem'] = ResolversParentTypes['StyleElementItem']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StyleItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['StyleItem'] = ResolversParentTypes['StyleItem']> = {
  division?: Resolver<ResolversTypes['StyleElementItem'], ParentType, ContextType>;
  subdivision?: Resolver<ResolversTypes['StyleElementItem'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['StyleElementItem'], ParentType, ContextType>;
  basePrice?: Resolver<ResolversTypes['BasePriceItem'], ParentType, ContextType>;
  bodyType?: Resolver<Array<ResolversTypes['BodyTypeItem']>, ParentType, ContextType>;
  marketClass?: Resolver<ResolversTypes['StyleElementItem'], ParentType, ContextType>;
  stockImage?: Resolver<ResolversTypes['StockImageItem'], ParentType, ContextType>;
  mediaGallery?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  acode?: Resolver<Array<Maybe<ResolversTypes['AcodeIcon']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameWoTrim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mfrModelCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fleetOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  modelFleet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passDoors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  altModelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  altStyleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  altBodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drivetrain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StylesMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['StylesMethod'] = ResolversParentTypes['StylesMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['ChromeStyles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StylesNewBlackBookResolvers<ContextType = any, ParentType extends ResolversParentTypes['StylesNewBlackBook'] = ResolversParentTypes['StylesNewBlackBook']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubOptionsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubOptionsList'] = ResolversParentTypes['SubOptionsList']> = {
  styleId?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['SubOptionsPrice'], ParentType, ContextType>;
  optionKindId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chromeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubOptionsPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubOptionsPrice'] = ResolversParentTypes['SubOptionsPrice']> = {
  invoiceMax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  invoiceMin?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  msrpMax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  msrpMin?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unknown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  activeMediums?: SubscriptionResolver<Array<ResolversTypes['String']>, "activeMediums", ParentType, ContextType>;
  app_info?: SubscriptionResolver<ResolversTypes['String'], "app_info", ParentType, ContextType>;
  device?: SubscriptionResolver<ResolversTypes['Device'], "device", ParentType, ContextType>;
  ecommerce?: SubscriptionResolver<ResolversTypes['String'], "ecommerce", ParentType, ContextType>;
  emitter?: SubscriptionResolver<ResolversTypes['String'], "emitter", ParentType, ContextType>;
  event?: SubscriptionResolver<ResolversTypes['String'], "event", ParentType, ContextType>;
  event_bundle_sequence_id?: SubscriptionResolver<ResolversTypes['Int'], "event_bundle_sequence_id", ParentType, ContextType>;
  event_date?: SubscriptionResolver<ResolversTypes['String'], "event_date", ParentType, ContextType>;
  event_dimensions?: SubscriptionResolver<ResolversTypes['String'], "event_dimensions", ParentType, ContextType>;
  event_name?: SubscriptionResolver<ResolversTypes['String'], "event_name", ParentType, ContextType>;
  event_params?: SubscriptionResolver<Array<ResolversTypes['EventParams']>, "event_params", ParentType, ContextType>;
  event_previous_timestamp?: SubscriptionResolver<ResolversTypes['Int'], "event_previous_timestamp", ParentType, ContextType>;
  event_server_timestamp_offset?: SubscriptionResolver<ResolversTypes['Int'], "event_server_timestamp_offset", ParentType, ContextType>;
  event_timestamp?: SubscriptionResolver<ResolversTypes['Int'], "event_timestamp", ParentType, ContextType>;
  event_value_in_usd?: SubscriptionResolver<ResolversTypes['String'], "event_value_in_usd", ParentType, ContextType>;
  geo?: SubscriptionResolver<ResolversTypes['Geo'], "geo", ParentType, ContextType>;
  items?: SubscriptionResolver<ResolversTypes['String'], "items", ParentType, ContextType>;
  label?: SubscriptionResolver<ResolversTypes['String'], "label", ParentType, ContextType>;
  name?: SubscriptionResolver<ResolversTypes['String'], "name", ParentType, ContextType>;
  platform?: SubscriptionResolver<ResolversTypes['String'], "platform", ParentType, ContextType>;
  privacy_info?: SubscriptionResolver<ResolversTypes['PrivacyInfo'], "privacy_info", ParentType, ContextType>;
  stream_id?: SubscriptionResolver<ResolversTypes['String'], "stream_id", ParentType, ContextType>;
  traffic_source?: SubscriptionResolver<ResolversTypes['TrafficSource'], "traffic_source", ParentType, ContextType>;
  user_first_touch_timestamp?: SubscriptionResolver<ResolversTypes['Int'], "user_first_touch_timestamp", ParentType, ContextType>;
  user_id?: SubscriptionResolver<ResolversTypes['String'], "user_id", ParentType, ContextType>;
  user_ltv?: SubscriptionResolver<ResolversTypes['UserLtv'], "user_ltv", ParentType, ContextType>;
  user_properties?: SubscriptionResolver<Array<ResolversTypes['UserProperties']>, "user_properties", ParentType, ContextType>;
  user_pseudo_id?: SubscriptionResolver<ResolversTypes['Int'], "user_pseudo_id", ParentType, ContextType>;
  user_rules?: SubscriptionResolver<Array<ResolversTypes['String']>, "user_rules", ParentType, ContextType>;
  user_types?: SubscriptionResolver<Array<ResolversTypes['String']>, "user_types", ParentType, ContextType>;
};

export type SubscriptionMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionMethod'] = ResolversParentTypes['SubscriptionMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenResult'] = ResolversParentTypes['TokenResult']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TradeinPricesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TradeinPricesType'] = ResolversParentTypes['TradeinPricesType']> = {
  adjustedTradeinAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedTradeinClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedTradeinRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedTradeinXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseTradeinAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseTradeinClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseTradeinRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseTradeinXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrafficSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrafficSource'] = ResolversParentTypes['TrafficSource']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medium?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationLabelTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TranslationLabelType'] = ResolversParentTypes['TranslationLabelType']> = {
  en?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrimsNewBlackBookResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrimsNewBlackBook'] = ResolversParentTypes['TrimsNewBlackBook']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  styles?: Resolver<Array<ResolversTypes['StylesNewBlackBook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TurnOnForMyResolvers<ContextType = any, ParentType extends ResolversParentTypes['TurnOnForMy'] = ResolversParentTypes['TurnOnForMy']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateListingMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateListingMethod'] = ResolversParentTypes['UpdateListingMethod']> = {
  modified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  virtual?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePhysicalEvaluationMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePhysicalEvaluationMethod'] = ResolversParentTypes['UpdatePhysicalEvaluationMethod']> = {
  evaluation?: Resolver<ResolversTypes['Appraisal'], ParentType, ContextType>;
  modified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateStatusResponse'] = ResolversParentTypes['UpdateStatusResponse']> = {
  modified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  dealership?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onlineAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserByIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserById'] = ResolversParentTypes['UserById']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserByIdMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserByIdMethod'] = ResolversParentTypes['UserByIdMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['UserById'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserListAnalitycResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserListAnalityc'] = ResolversParentTypes['UserListAnalityc']> = {
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserListAnalitycMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserListAnalitycMethod'] = ResolversParentTypes['UserListAnalitycMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['UserListAnalityc']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLtvResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLtv'] = ResolversParentTypes['UserLtv']> = {
  revenue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProperties'] = ResolversParentTypes['UserProperties']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['UserPropertiesValues'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPropertiesValuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPropertiesValues'] = ResolversParentTypes['UserPropertiesValues']> = {
  string_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  int_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  float_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  double_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  set_timestamp_micros?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStaisticDataTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStaisticDataType'] = ResolversParentTypes['UserStaisticDataType']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dealership?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onlineAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VAutoCheckMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VAutoCheckMethod'] = ResolversParentTypes['VAutoCheckMethod']> = {
  GetAppraisalWithPricingDataByVinResult?: Resolver<ResolversTypes['GetAppraisalWithPricingDataByVinResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleInfoTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleInfoType'] = ResolversParentTypes['VehicleInfoType']> = {
  bedStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bodyStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cabStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditionTimestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confidenceScore4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  driveType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engineDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engineDisplacement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exteriorColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fuel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  generation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interiorColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mileageTimestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mpg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mpgCity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mpgHighway?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subGeneration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTrim?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transmission?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transmissionDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trim?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wheelbase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleListDashStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleListDashStatistics'] = ResolversParentTypes['VehicleListDashStatistics']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  submitted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odometer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  odometerMiles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photos?: Resolver<Array<ResolversTypes['AppraisalPhoto']>, ParentType, ContextType>;
  paveSessionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  traction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessingStarted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assessedById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  eraSalesCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPurchased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleReportImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleReportImage'] = ResolversParentTypes['VehicleReportImage']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vin'] = ResolversParentTypes['Vin']> = {
  bodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  msrp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  style?: Resolver<Array<ResolversTypes['VinStyle']>, ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinMethod'] = ResolversParentTypes['VinMethod']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['Vin'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyle'] = ResolversParentTypes['VinStyle']> = {
  division?: Resolver<ResolversTypes['VinStyleDivision'], ParentType, ContextType>;
  subdivision?: Resolver<ResolversTypes['VinStyleSubdivision'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['VinStyleModel'], ParentType, ContextType>;
  basePrice?: Resolver<ResolversTypes['VinStyleBasePrice'], ParentType, ContextType>;
  bodyType?: Resolver<Maybe<ResolversTypes['VinStyleBodyType']>, ParentType, ContextType>;
  marketClass?: Resolver<Maybe<ResolversTypes['VinStyleMarketClass']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameWoTrim?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mfrModelCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fleetOnly?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelFleet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passDoors?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  altBodyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drivetrain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleBasePriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleBasePrice'] = ResolversParentTypes['VinStyleBasePrice']> = {
  unknown?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  msrp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  destination?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleBodyTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleBodyType'] = ResolversParentTypes['VinStyleBodyType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleDivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleDivision'] = ResolversParentTypes['VinStyleDivision']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleMarketClassResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleMarketClass'] = ResolversParentTypes['VinStyleMarketClass']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleMethod'] = ResolversParentTypes['VinStyleMethod']> = {
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['VinStyleResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleModel'] = ResolversParentTypes['VinStyleModel']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleResult'] = ResolversParentTypes['VinStyleResult']> = {
  dataFullCurrentStyle?: Resolver<ResolversTypes['DataFullCurrentStyleElement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleStyleResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleStyle'] = ResolversParentTypes['VinStyleStyle']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinStyleSubdivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VinStyleSubdivision'] = ResolversParentTypes['VinStyleSubdivision']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualByVinTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VirtualByVinType'] = ResolversParentTypes['VirtualByVinType']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offeredValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appraisalValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualVehByVinMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VirtualVehByVinMethod'] = ResolversParentTypes['VirtualVehByVinMethod']> = {
  last?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<Array<ResolversTypes['VirtualVehByVinType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualVehByVinTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VirtualVehByVinType'] = ResolversParentTypes['VirtualVehByVinType']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  appraisalValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offeredValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custHomePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custPostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPurchased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebInfo'] = ResolversParentTypes['WebInfo']> = {
  browser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  browser_version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WholeSalePricesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WholeSalePricesType'] = ResolversParentTypes['WholeSalePricesType']> = {
  adjustedWholeAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedWholeClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedWholeRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adjustedWholeXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseWholeAvg?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseWholeClean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseWholeRough?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baseWholeXclean?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WholesaleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WholesaleType'] = ResolversParentTypes['WholesaleType']> = {
  above?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  average?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  below?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewBlackBookDecoderMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['newBlackBookDecoderMethod'] = ResolversParentTypes['newBlackBookDecoderMethod']> = {
  makeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trims?: Resolver<Array<ResolversTypes['TrimsNewBlackBook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewBlackBookDetailDecoderMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['newBlackBookDetailDecoderMethod'] = ResolversParentTypes['newBlackBookDetailDecoderMethod']> = {
  makeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCylinderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  traction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmission?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  style?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trims?: Resolver<Array<ResolversTypes['TrimsNewBlackBook']>, ParentType, ContextType>;
  exteriorColor?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  interiorColors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleReportSelectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['vehicleReportSelectType'] = ResolversParentTypes['vehicleReportSelectType']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleReportTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['vehicleReportType'] = ResolversParentTypes['vehicleReportType']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  todo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  select?: Resolver<Array<Maybe<ResolversTypes['vehicleReportSelectType']>>, ParentType, ContextType>;
  images?: Resolver<Array<Maybe<ResolversTypes['VehicleReportImage']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VinDetailsMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['vinDetailsMethod'] = ResolversParentTypes['vinDetailsMethod']> = {
  makeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AcodeIcon?: AcodeIconResolvers<ContextType>;
  AddDeductListItem?: AddDeductListItemResolvers<ContextType>;
  AdjustedByType?: AdjustedByTypeResolvers<ContextType>;
  AdjustedPricingType?: AdjustedPricingTypeResolvers<ContextType>;
  AnalitycMethod?: AnalitycMethodResolvers<ContextType>;
  Application?: ApplicationResolvers<ContextType>;
  Appraisal?: AppraisalResolvers<ContextType>;
  AppraisalComments?: AppraisalCommentsResolvers<ContextType>;
  AppraisalCondition?: AppraisalConditionResolvers<ContextType>;
  AppraisalConditions?: AppraisalConditionsResolvers<ContextType>;
  AppraisalConditionsResponse?: AppraisalConditionsResponseResolvers<ContextType>;
  AppraisalInventory?: AppraisalInventoryResolvers<ContextType>;
  AppraisalMethod?: AppraisalMethodResolvers<ContextType>;
  AppraisalPhoto?: AppraisalPhotoResolvers<ContextType>;
  AppraisalTireThread?: AppraisalTireThreadResolvers<ContextType>;
  AppraisalTireThreadSecond?: AppraisalTireThreadSecondResolvers<ContextType>;
  AppraisalVAuto?: AppraisalVAutoResolvers<ContextType>;
  AppraisalVehByVinMethod?: AppraisalVehByVinMethodResolvers<ContextType>;
  AppraisalVehByVinResult?: AppraisalVehByVinResultResolvers<ContextType>;
  AppraisalVehByVinType?: AppraisalVehByVinTypeResolvers<ContextType>;
  AppraisalsConditionMethod?: AppraisalsConditionMethodResolvers<ContextType>;
  AppraisalsCountMethod?: AppraisalsCountMethodResolvers<ContextType>;
  AppraisalsCounts?: AppraisalsCountsResolvers<ContextType>;
  AppraisalsMethod?: AppraisalsMethodResolvers<ContextType>;
  AppraiserVAuto?: AppraiserVAutoResolvers<ContextType>;
  AppriselVehicleForAnalyticsMethod?: AppriselVehicleForAnalyticsMethodResolvers<ContextType>;
  AppriselVehicleForAnalyticsType?: AppriselVehicleForAnalyticsTypeResolvers<ContextType>;
  AppriselVehicleMethod?: AppriselVehicleMethodResolvers<ContextType>;
  AppriselVehicleType?: AppriselVehicleTypeResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  Auth?: AuthResolvers<ContextType>;
  AuthUser?: AuthUserResolvers<ContextType>;
  BadgeListSmallType?: BadgeListSmallTypeResolvers<ContextType>;
  BadgesListType?: BadgesListTypeResolvers<ContextType>;
  BasePriceItem?: BasePriceItemResolvers<ContextType>;
  BbInfoValuesMethod?: BbInfoValuesMethodResolvers<ContextType>;
  BbInfoValuesType?: BbInfoValuesTypeResolvers<ContextType>;
  BodyTypeItem?: BodyTypeItemResolvers<ContextType>;
  CarGurusByVIN?: CarGurusByVinResolvers<ContextType>;
  CarPart?: CarPartResolvers<ContextType>;
  CarSide?: CarSideResolvers<ContextType>;
  CarfaxType?: CarfaxTypeResolvers<ContextType>;
  Carproof?: CarproofResolvers<ContextType>;
  CarproofMethod?: CarproofMethodResolvers<ContextType>;
  CarproofVPN?: CarproofVpnResolvers<ContextType>;
  CarproofVPNMethod?: CarproofVpnMethodResolvers<ContextType>;
  CarstoryByVINType?: CarstoryByVinTypeResolvers<ContextType>;
  CarstoryType?: CarstoryTypeResolvers<ContextType>;
  ChromeDivision?: ChromeDivisionResolvers<ContextType>;
  ChromeModelYear?: ChromeModelYearResolvers<ContextType>;
  ChromeModels?: ChromeModelsResolvers<ContextType>;
  ChromeStyles?: ChromeStylesResolvers<ContextType>;
  CompleteVehicleUserEvaluationMethod?: CompleteVehicleUserEvaluationMethodResolvers<ContextType>;
  CreateMonkaiInspectionResponse?: CreateMonkaiInspectionResponseResolvers<ContextType>;
  CreateMonkaiInspectionResult?: CreateMonkaiInspectionResultResolvers<ContextType>;
  CreatePhysicalEvaluationMethod?: CreatePhysicalEvaluationMethodResolvers<ContextType>;
  Damage?: DamageResolvers<ContextType>;
  DamageAction?: DamageActionResolvers<ContextType>;
  DashStatisticsListMethod?: DashStatisticsListMethodResolvers<ContextType>;
  DashStatisticsMethod?: DashStatisticsMethodResolvers<ContextType>;
  DashStatisticsType?: DashStatisticsTypeResolvers<ContextType>;
  DataFullCurrentStyleElement?: DataFullCurrentStyleElementResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Dealership?: DealershipResolvers<ContextType>;
  DecodeBBItemType?: DecodeBbItemTypeResolvers<ContextType>;
  DecodeVinBBResult?: DecodeVinBbResultResolvers<ContextType>;
  DecodeVinBlackBookMethod?: DecodeVinBlackBookMethodResolvers<ContextType>;
  DecodeVinMethod?: DecodeVinMethodResolvers<ContextType>;
  DecodeVinNewMethod?: DecodeVinNewMethodResolvers<ContextType>;
  DecodeVinNewResult?: DecodeVinNewResultResolvers<ContextType>;
  DecodeVinResult?: DecodeVinResultResolvers<ContextType>;
  DefoltElementBBDecode?: DefoltElementBbDecodeResolvers<ContextType>;
  DefoltElementColorDecode?: DefoltElementColorDecodeResolvers<ContextType>;
  DefoltElementDecode?: DefoltElementDecodeResolvers<ContextType>;
  Detail?: DetailResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  DisclosuresType?: DisclosuresTypeResolvers<ContextType>;
  DisclosuresUSType?: DisclosuresUsTypeResolvers<ContextType>;
  DivisionsMethod?: DivisionsMethodResolvers<ContextType>;
  DrivErrorType?: DrivErrorTypeResolvers<ContextType>;
  EventParams?: EventParamsResolvers<ContextType>;
  EventParamsType?: EventParamsTypeResolvers<ContextType>;
  EventParamsValue?: EventParamsValueResolvers<ContextType>;
  EventParamsValueType?: EventParamsValueTypeResolvers<ContextType>;
  ExteriorColor?: ExteriorColorResolvers<ContextType>;
  ExteriorColorElement?: ExteriorColorElementResolvers<ContextType>;
  FeatureForMyResponse?: FeatureForMyResponseResolvers<ContextType>;
  FeatureInputResponse?: FeatureInputResponseResolvers<ContextType>;
  FeatureResponse?: FeatureResponseResolvers<ContextType>;
  FollowUp?: FollowUpResolvers<ContextType>;
  Geo?: GeoResolvers<ContextType>;
  GeoType?: GeoTypeResolvers<ContextType>;
  GetAppraisalWithPricingDataByVinResult?: GetAppraisalWithPricingDataByVinResultResolvers<ContextType>;
  GetCarproofMethod?: GetCarproofMethodResolvers<ContextType>;
  GetCarproofType?: GetCarproofTypeResolvers<ContextType>;
  GetCarstoryByVINMethod?: GetCarstoryByVinMethodResolvers<ContextType>;
  GetListForBedStartCompleteMethod?: GetListForBedStartCompleteMethodResolvers<ContextType>;
  GetListForBedStartCompleteOne?: GetListForBedStartCompleteOneResolvers<ContextType>;
  GetListForBedStartCompleteOneMethod?: GetListForBedStartCompleteOneMethodResolvers<ContextType>;
  GetListForBedVinDecodeMethod?: GetListForBedVinDecodeMethodResolvers<ContextType>;
  GetMonkaiInspectionResponse?: GetMonkaiInspectionResponseResolvers<ContextType>;
  GetMonkaiInspectionResult?: GetMonkaiInspectionResultResolvers<ContextType>;
  GetMonkaiTokenResponse?: GetMonkaiTokenResponseResolvers<ContextType>;
  GlaEvalItem?: GlaEvalItemResolvers<ContextType>;
  GlaEvalResponse?: GlaEvalResponseResolvers<ContextType>;
  GlaFollowItem?: GlaFollowItemResolvers<ContextType>;
  GlaFollowResponse?: GlaFollowResponseResolvers<ContextType>;
  GlaItem?: GlaItemResolvers<ContextType>;
  GlaResponse?: GlaResponseResolvers<ContextType>;
  InfoForUserAnalitycMethod?: InfoForUserAnalitycMethodResolvers<ContextType>;
  InteriorColor?: InteriorColorResolvers<ContextType>;
  ItemSubscriptionResult?: ItemSubscriptionResultResolvers<ContextType>;
  Label?: LabelResolvers<ContextType>;
  Licenseplate?: LicenseplateResolvers<ContextType>;
  LicenseplateMethod?: LicenseplateMethodResolvers<ContextType>;
  ListProblemStartAndComplete?: ListProblemStartAndCompleteResolvers<ContextType>;
  ListiFeatureResponse?: ListiFeatureResponseResolvers<ContextType>;
  ListiFeatureResult?: ListiFeatureResultResolvers<ContextType>;
  ListiUsersFeatureResponse?: ListiUsersFeatureResponseResolvers<ContextType>;
  ListiUsersFeatureResult?: ListiUsersFeatureResultResolvers<ContextType>;
  Listing?: ListingResolvers<ContextType>;
  ListingComments?: ListingCommentsResolvers<ContextType>;
  ListingMethod?: ListingMethodResolvers<ContextType>;
  ListingStatusResult?: ListingStatusResultResolvers<ContextType>;
  ListingsCountMethod?: ListingsCountMethodResolvers<ContextType>;
  ListingsCreateResult?: ListingsCreateResultResolvers<ContextType>;
  ListingsMethod?: ListingsMethodResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationMethod?: LocationMethodResolvers<ContextType>;
  ManheimDataByVinMethod?: ManheimDataByVinMethodResolvers<ContextType>;
  ManheimDataByVinResult?: ManheimDataByVinResultResolvers<ContextType>;
  ManheimDescriptionType?: ManheimDescriptionTypeResolvers<ContextType>;
  ManheimItemDescriptionType?: ManheimItemDescriptionTypeResolvers<ContextType>;
  ManheimItemType?: ManheimItemTypeResolvers<ContextType>;
  ManheimItemWholesaleType?: ManheimItemWholesaleTypeResolvers<ContextType>;
  ManheimType?: ManheimTypeResolvers<ContextType>;
  ManheimVehicleType?: ManheimVehicleTypeResolvers<ContextType>;
  Messaage?: MessaageResolvers<ContextType>;
  MessaagesMethod?: MessaagesMethodResolvers<ContextType>;
  ModelYearsMethod?: ModelYearsMethodResolvers<ContextType>;
  ModelsMethod?: ModelsMethodResolvers<ContextType>;
  MonkaiImage?: MonkaiImageResolvers<ContextType>;
  MonkaiVehicleInfoType?: MonkaiVehicleInfoTypeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NotificationCountMethod?: NotificationCountMethodResolvers<ContextType>;
  NotificationListItem?: NotificationListItemResolvers<ContextType>;
  NotificationListItemSource?: NotificationListItemSourceResolvers<ContextType>;
  NotificationListMethod?: NotificationListMethodResolvers<ContextType>;
  NotificationNameMethod?: NotificationNameMethodResolvers<ContextType>;
  NotificationReadMethod?: NotificationReadMethodResolvers<ContextType>;
  OffersByVIN?: OffersByVinResolvers<ContextType>;
  OptionsElement?: OptionsElementResolvers<ContextType>;
  OptionsElementBB?: OptionsElementBbResolvers<ContextType>;
  OptionsVinDecodeBB?: OptionsVinDecodeBbResolvers<ContextType>;
  Package?: PackageResolvers<ContextType>;
  PackageMethod?: PackageMethodResolvers<ContextType>;
  PackageStyle?: PackageStyleResolvers<ContextType>;
  PeriodPriceType?: PeriodPriceTypeResolvers<ContextType>;
  PermissionsType?: PermissionsTypeResolvers<ContextType>;
  Photo?: PhotoResolvers<ContextType>;
  PhotoMethod?: PhotoMethodResolvers<ContextType>;
  Pin?: PinResolvers<ContextType>;
  PrivacyInfo?: PrivacyInfoResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ReatailPricesType?: ReatailPricesTypeResolvers<ContextType>;
  RefreshTokenMethod?: RefreshTokenMethodResolvers<ContextType>;
  RequestDateTimeType?: RequestDateTimeTypeResolvers<ContextType>;
  Roles?: RolesResolvers<ContextType>;
  SentEmailMethod?: SentEmailMethodResolvers<ContextType>;
  SetSubscriptionResult?: SetSubscriptionResultResolvers<ContextType>;
  SignInMethod?: SignInMethodResolvers<ContextType>;
  StatusCountResponse?: StatusCountResponseResolvers<ContextType>;
  StockImageItem?: StockImageItemResolvers<ContextType>;
  StyleElementItem?: StyleElementItemResolvers<ContextType>;
  StyleItem?: StyleItemResolvers<ContextType>;
  StylesMethod?: StylesMethodResolvers<ContextType>;
  StylesNewBlackBook?: StylesNewBlackBookResolvers<ContextType>;
  SubOptionsList?: SubOptionsListResolvers<ContextType>;
  SubOptionsPrice?: SubOptionsPriceResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionMethod?: SubscriptionMethodResolvers<ContextType>;
  TokenResult?: TokenResultResolvers<ContextType>;
  TradeinPricesType?: TradeinPricesTypeResolvers<ContextType>;
  TrafficSource?: TrafficSourceResolvers<ContextType>;
  TranslationLabelType?: TranslationLabelTypeResolvers<ContextType>;
  TrimsNewBlackBook?: TrimsNewBlackBookResolvers<ContextType>;
  TurnOnForMy?: TurnOnForMyResolvers<ContextType>;
  UpdateListingMethod?: UpdateListingMethodResolvers<ContextType>;
  UpdatePhysicalEvaluationMethod?: UpdatePhysicalEvaluationMethodResolvers<ContextType>;
  UpdateStatusResponse?: UpdateStatusResponseResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserById?: UserByIdResolvers<ContextType>;
  UserByIdMethod?: UserByIdMethodResolvers<ContextType>;
  UserListAnalityc?: UserListAnalitycResolvers<ContextType>;
  UserListAnalitycMethod?: UserListAnalitycMethodResolvers<ContextType>;
  UserLtv?: UserLtvResolvers<ContextType>;
  UserProperties?: UserPropertiesResolvers<ContextType>;
  UserPropertiesValues?: UserPropertiesValuesResolvers<ContextType>;
  UserStaisticDataType?: UserStaisticDataTypeResolvers<ContextType>;
  VAutoCheckMethod?: VAutoCheckMethodResolvers<ContextType>;
  VehicleInfoType?: VehicleInfoTypeResolvers<ContextType>;
  VehicleListDashStatistics?: VehicleListDashStatisticsResolvers<ContextType>;
  VehicleReportImage?: VehicleReportImageResolvers<ContextType>;
  Vin?: VinResolvers<ContextType>;
  VinMethod?: VinMethodResolvers<ContextType>;
  VinStyle?: VinStyleResolvers<ContextType>;
  VinStyleBasePrice?: VinStyleBasePriceResolvers<ContextType>;
  VinStyleBodyType?: VinStyleBodyTypeResolvers<ContextType>;
  VinStyleDivision?: VinStyleDivisionResolvers<ContextType>;
  VinStyleMarketClass?: VinStyleMarketClassResolvers<ContextType>;
  VinStyleMethod?: VinStyleMethodResolvers<ContextType>;
  VinStyleModel?: VinStyleModelResolvers<ContextType>;
  VinStyleResult?: VinStyleResultResolvers<ContextType>;
  VinStyleStyle?: VinStyleStyleResolvers<ContextType>;
  VinStyleSubdivision?: VinStyleSubdivisionResolvers<ContextType>;
  VirtualByVinType?: VirtualByVinTypeResolvers<ContextType>;
  VirtualVehByVinMethod?: VirtualVehByVinMethodResolvers<ContextType>;
  VirtualVehByVinType?: VirtualVehByVinTypeResolvers<ContextType>;
  WebInfo?: WebInfoResolvers<ContextType>;
  WholeSalePricesType?: WholeSalePricesTypeResolvers<ContextType>;
  WholesaleType?: WholesaleTypeResolvers<ContextType>;
  newBlackBookDecoderMethod?: NewBlackBookDecoderMethodResolvers<ContextType>;
  newBlackBookDetailDecoderMethod?: NewBlackBookDetailDecoderMethodResolvers<ContextType>;
  vehicleReportSelectType?: VehicleReportSelectTypeResolvers<ContextType>;
  vehicleReportType?: VehicleReportTypeResolvers<ContextType>;
  vinDetailsMethod?: VinDetailsMethodResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  client?: ClientDirectiveResolver<any, any, ContextType>;
  rest?: RestDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export type GetListForBedVinDecodeQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetListForBedVinDecodeQuery = (
  { __typename?: 'Query' }
  & { getListForBedStartComplete?: Maybe<(
    { __typename?: 'GetListForBedStartCompleteMethod' }
    & { result: Array<(
      { __typename?: 'ListProblemStartAndComplete' }
      & Pick<ListProblemStartAndComplete, 'user_id' | 'problem_with_start_vehicle' | 'problem_with_complete_vehicle'>
    )> }
  )> }
);

export type GetListForBedStartCompleteQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetListForBedStartCompleteQuery = (
  { __typename?: 'Query' }
  & { getListForBedStartComplete?: Maybe<(
    { __typename?: 'GetListForBedStartCompleteMethod' }
    & { result: Array<(
      { __typename?: 'ListProblemStartAndComplete' }
      & Pick<ListProblemStartAndComplete, 'user_id' | 'problem_with_start_vehicle' | 'problem_with_complete_vehicle'>
    )> }
  )> }
);

export type GetListForBedStartCompleteOneQueryVariables = Exact<{
  date: Scalars['String'];
  email: Scalars['String'];
}>;


export type GetListForBedStartCompleteOneQuery = (
  { __typename?: 'Query' }
  & { getListForBedStartCompleteOne?: Maybe<(
    { __typename?: 'GetListForBedStartCompleteOneMethod' }
    & { result: Array<(
      { __typename?: 'GetListForBedStartCompleteOne' }
      & { event_params: Array<(
        { __typename?: 'EventParamsType' }
        & Pick<EventParamsType, 'key'>
        & { value: (
          { __typename?: 'EventParamsValue' }
          & Pick<EventParamsValue, 'string_value' | 'int_value' | 'float_value' | 'double_value'>
        ) }
      )>, geo: (
        { __typename?: 'GeoType' }
        & Pick<GeoType, 'continent' | 'country' | 'region' | 'city' | 'sub_continent' | 'metro'>
      ) }
    )> }
  )> }
);

export type GetAnalyticQueryVariables = Exact<{
  eventName: Scalars['String'];
  date: Scalars['String'];
}>;


export type GetAnalyticQuery = (
  { __typename?: 'Query' }
  & { analytic: (
    { __typename?: 'AnalitycMethod' }
    & { result: Array<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'event_date' | 'event_timestamp' | 'event_name' | 'event_previous_timestamp' | 'event_value_in_usd' | 'event_bundle_sequence_id' | 'event_server_timestamp_offset' | 'user_id' | 'user_pseudo_id' | 'user_first_touch_timestamp' | 'app_info' | 'stream_id' | 'platform' | 'event_dimensions' | 'ecommerce' | 'items'>
      & { event_params: Array<(
        { __typename?: 'EventParams' }
        & Pick<EventParams, 'key'>
        & { value: (
          { __typename?: 'EventParamsValue' }
          & Pick<EventParamsValue, 'string_value' | 'int_value' | 'float_value' | 'double_value'>
        ) }
      )>, privacy_info: (
        { __typename?: 'PrivacyInfo' }
        & Pick<PrivacyInfo, 'analytics_storage' | 'ads_storage' | 'uses_transient_token'>
      ), user_properties: Array<(
        { __typename?: 'UserProperties' }
        & Pick<UserProperties, 'key'>
        & { value: (
          { __typename?: 'UserPropertiesValues' }
          & Pick<UserPropertiesValues, 'string_value' | 'int_value' | 'float_value' | 'double_value' | 'set_timestamp_micros'>
        ) }
      )>, user_ltv: (
        { __typename?: 'UserLtv' }
        & Pick<UserLtv, 'revenue' | 'currency'>
      ), device: (
        { __typename?: 'Device' }
        & Pick<Device, 'category' | 'mobile_brand_name' | 'mobile_model_name' | 'mobile_marketing_name' | 'mobile_os_hardware_model' | 'operating_system' | 'operating_system_version' | 'vendor_id' | 'advertising_id' | 'language' | 'is_limited_ad_tracking' | 'time_zone_offset_seconds' | 'browser' | 'browser_version'>
        & { web_info: (
          { __typename?: 'WebInfo' }
          & Pick<WebInfo, 'browser' | 'browser_version' | 'hostname'>
        ) }
      ), geo: (
        { __typename?: 'Geo' }
        & Pick<Geo, 'continent' | 'country' | 'region' | 'city' | 'sub_continent' | 'metro'>
      ), traffic_source: (
        { __typename?: 'TrafficSource' }
        & Pick<TrafficSource, 'name' | 'medium' | 'source'>
      ) }
    )> }
  ) }
);

export type GetUserListAnalyticQueryVariables = Exact<{
  eventName: Scalars['String'];
  date: Scalars['String'];
}>;


export type GetUserListAnalyticQuery = (
  { __typename?: 'Query' }
  & { listUsersAnalytic: (
    { __typename?: 'UserListAnalitycMethod' }
    & { result: Array<(
      { __typename?: 'UserListAnalityc' }
      & Pick<UserListAnalityc, 'user_id'>
    )> }
  ) }
);

export type InfoForUserAnalitycQueryVariables = Exact<{
  eventName: Scalars['String'];
  date: Scalars['String'];
  email: Scalars['String'];
}>;


export type InfoForUserAnalitycQuery = (
  { __typename?: 'Query' }
  & { infoForUserAnalityc: (
    { __typename?: 'InfoForUserAnalitycMethod' }
    & { result: Array<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'event_date' | 'event_timestamp' | 'event_name' | 'event_previous_timestamp' | 'event_value_in_usd' | 'event_bundle_sequence_id' | 'event_server_timestamp_offset' | 'user_id' | 'user_pseudo_id' | 'user_first_touch_timestamp' | 'app_info' | 'stream_id' | 'platform' | 'event_dimensions' | 'ecommerce' | 'items'>
      & { event_params: Array<(
        { __typename?: 'EventParams' }
        & Pick<EventParams, 'key'>
        & { value: (
          { __typename?: 'EventParamsValue' }
          & Pick<EventParamsValue, 'string_value' | 'int_value' | 'float_value' | 'double_value'>
        ) }
      )>, privacy_info: (
        { __typename?: 'PrivacyInfo' }
        & Pick<PrivacyInfo, 'analytics_storage' | 'ads_storage' | 'uses_transient_token'>
      ), user_properties: Array<(
        { __typename?: 'UserProperties' }
        & Pick<UserProperties, 'key'>
        & { value: (
          { __typename?: 'UserPropertiesValues' }
          & Pick<UserPropertiesValues, 'string_value' | 'int_value' | 'float_value' | 'double_value' | 'set_timestamp_micros'>
        ) }
      )>, user_ltv: (
        { __typename?: 'UserLtv' }
        & Pick<UserLtv, 'revenue' | 'currency'>
      ), device: (
        { __typename?: 'Device' }
        & Pick<Device, 'category' | 'mobile_brand_name' | 'mobile_model_name' | 'mobile_marketing_name' | 'mobile_os_hardware_model' | 'operating_system' | 'operating_system_version' | 'vendor_id' | 'advertising_id' | 'language' | 'is_limited_ad_tracking' | 'time_zone_offset_seconds' | 'browser' | 'browser_version'>
        & { web_info: (
          { __typename?: 'WebInfo' }
          & Pick<WebInfo, 'browser' | 'browser_version' | 'hostname'>
        ) }
      ), geo: (
        { __typename?: 'Geo' }
        & Pick<Geo, 'continent' | 'country' | 'region' | 'city' | 'sub_continent' | 'metro'>
      ), traffic_source: (
        { __typename?: 'TrafficSource' }
        & Pick<TrafficSource, 'name' | 'medium' | 'source'>
      ) }
    )> }
  ) }
);

export type GetUserEvaluationListForAnalyticsQueryVariables = Exact<{
  location: Scalars['String'];
  limit: Scalars['Int'];
  select: Scalars['String'];
  statuses: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetUserEvaluationListForAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getUserEvaluationListForAnalytics: (
    { __typename?: 'AppriselVehicleForAnalyticsMethod' }
    & Pick<AppriselVehicleForAnalyticsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'AppriselVehicleForAnalyticsType' }
      & Pick<AppriselVehicleForAnalyticsType, 'id' | '_id' | 'created' | 'completed' | 'vin' | 'appraisalValue' | 'offeredValue' | 'formulaPrice' | 'purchased' | 'inventoryDate'>
      & { drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )> }
    )> }
  ) }
);

export type GetAppraisalConditionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAppraisalConditionsQuery = (
  { __typename?: 'Query' }
  & { getAppraisalConditions: (
    { __typename?: 'AppraisalConditionsResponse' }
    & Pick<AppraisalConditionsResponse, 'operation'>
    & { result: (
      { __typename?: 'AppraisalConditions' }
      & Pick<AppraisalConditions, 'id' | 'created' | 'updated' | 'location' | 'source' | 'status' | 'type' | 'origin' | 'vehicleType' | 'vin' | 'stock' | 'saleType' | 'modelYear' | 'make' | 'model' | 'series' | 'odometer' | 'odometerMiles' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'licenseState' | 'licensePlate' | 'owner' | 'ownerId' | 'closer' | 'closerId' | 'rep' | 'repId' | 'offerPrinted' | 'submitted' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'conditioningStarted' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'salesperson' | 'appraiser' | 'leaseReturn' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'custDriverLicense' | 'custAmountOwed' | 'vAutoId' | 'appraisalValue' | 'offeredValue' | 'offerAsSeen' | 'annotations' | 'disclosures' | 'photos' | 'parkingZoneSegmentation' | 'hasConditionReport' | 'conditionReportStatus' | 'isConditionReportComplete' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'inspectionReportLink' | 'styleId' | 'exteriorColor' | 'interiorColor'>
      & { conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ) }
    ) }
  ) }
);

export type CreateAppraisalConditionsMutationVariables = Exact<{
  input: AppraisalConditionsInput;
}>;


export type CreateAppraisalConditionsMutation = (
  { __typename?: 'Mutation' }
  & { createAppraisalConditions: (
    { __typename?: 'AppraisalConditionsResponse' }
    & Pick<AppraisalConditionsResponse, 'operation'>
    & { result: (
      { __typename?: 'AppraisalConditions' }
      & Pick<AppraisalConditions, 'id' | 'created' | 'updated' | 'location' | 'source' | 'status' | 'type' | 'origin' | 'vehicleType' | 'vin' | 'stock' | 'saleType' | 'modelYear' | 'make' | 'model' | 'series' | 'odometer' | 'odometerMiles' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'licenseState' | 'licensePlate' | 'owner' | 'ownerId' | 'closer' | 'closerId' | 'rep' | 'repId' | 'offerPrinted' | 'submitted' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'conditioningStarted' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'salesperson' | 'appraiser' | 'leaseReturn' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'custDriverLicense' | 'custAmountOwed' | 'vAutoId' | 'appraisalValue' | 'offeredValue' | 'offerAsSeen' | 'annotations' | 'disclosures' | 'photos' | 'parkingZoneSegmentation' | 'hasConditionReport' | 'conditionReportStatus' | 'isConditionReportComplete' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'inspectionReportLink'>
      & { conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ) }
    ) }
  ) }
);

export type UpdateAppraisalConditionsMutationVariables = Exact<{
  id: Scalars['Int'];
  input: AppraisalConditionsInput;
}>;


export type UpdateAppraisalConditionsMutation = (
  { __typename?: 'Mutation' }
  & { updateAppraisalConditions: (
    { __typename?: 'AppraisalConditionsResponse' }
    & Pick<AppraisalConditionsResponse, 'operation'>
    & { result: (
      { __typename?: 'AppraisalConditions' }
      & Pick<AppraisalConditions, 'id' | 'created' | 'updated' | 'location' | 'source' | 'status' | 'type' | 'origin' | 'vehicleType' | 'vin' | 'stock' | 'saleType' | 'modelYear' | 'make' | 'model' | 'series' | 'odometer' | 'odometerMiles' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'licenseState' | 'licensePlate' | 'owner' | 'ownerId' | 'closer' | 'closerId' | 'rep' | 'repId' | 'offerPrinted' | 'submitted' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'conditioningStarted' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'salesperson' | 'appraiser' | 'leaseReturn' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'custDriverLicense' | 'custAmountOwed' | 'vAutoId' | 'appraisalValue' | 'offeredValue' | 'offerAsSeen' | 'annotations' | 'disclosures' | 'photos' | 'parkingZoneSegmentation' | 'hasConditionReport' | 'conditionReportStatus' | 'isConditionReportComplete' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'inspectionReportLink'>
      & { conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ) }
    ) }
  ) }
);

export type AppraisalsConditionQueryVariables = Exact<{
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
}>;


export type AppraisalsConditionQuery = (
  { __typename?: 'Query' }
  & { appraisalsCondition: (
    { __typename?: 'AppraisalsConditionMethod' }
    & Pick<AppraisalsConditionMethod, 'last'>
    & { result: Array<(
      { __typename?: 'AppraisalConditions' }
      & Pick<AppraisalConditions, 'id' | 'created' | 'updated' | 'location' | 'source' | 'status' | 'type' | 'origin' | 'vehicleType' | 'vin' | 'stock' | 'saleType' | 'modelYear' | 'make' | 'model' | 'series' | 'odometer' | 'odometerMiles' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'licenseState' | 'licensePlate' | 'owner' | 'ownerId' | 'closer' | 'closerId' | 'rep' | 'repId' | 'offerPrinted' | 'submitted' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'conditioningStarted' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'salesperson' | 'appraiser' | 'leaseReturn' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'custDriverLicense' | 'custAmountOwed' | 'vAutoId' | 'appraisalValue' | 'offeredValue' | 'offerAsSeen' | 'annotations' | 'disclosures' | 'photos' | 'parkingZoneSegmentation' | 'hasConditionReport' | 'conditionReportStatus' | 'isConditionReportComplete' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'inspectionReportLink'>
      & { conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById'>
        & { photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ) }
    )> }
  ) }
);

export type UpdateStatusVehicleMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateStatusInput;
}>;


export type UpdateStatusVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateStatus: (
    { __typename?: 'UpdateStatusResponse' }
    & Pick<UpdateStatusResponse, 'modified'>
  ) }
);

export type GetMonkaiTokenQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMonkaiTokenQuery = (
  { __typename?: 'Query' }
  & { getMonkaiToken: (
    { __typename?: 'GetMonkaiTokenResponse' }
    & Pick<GetMonkaiTokenResponse, 'result'>
  ) }
);

export type AppraisalsQueryVariables = Exact<{
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
}>;


export type AppraisalsQuery = (
  { __typename?: 'Query' }
  & { appraisals: (
    { __typename?: 'AppraisalsMethod' }
    & Pick<AppraisalsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id' | 'odometer' | 'odometerMiles' | 'location' | 'appraisalValue' | 'offeredValue' | 'modelYear' | 'make' | 'model' | 'series' | 'status' | 'source' | 'origin' | 'traction' | 'transmissionType' | 'originDate' | 'vin' | 'isPurchased' | 'saleType' | 'stock' | 'noticeByManagers' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'assessedById' | 'assessingStarted' | 'created' | 'updated' | 'submitted' | 'eraSalesCode' | 'paveSessionStatus'>
      & { photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )> }
    )> }
  ) }
);

export type AppraisalsNusQueryVariables = Exact<{
  ids: Scalars['String'];
  location: Scalars['String'];
  search: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
}>;


export type AppraisalsNusQuery = (
  { __typename?: 'Query' }
  & { appraisalsNus: (
    { __typename?: 'AppraisalsMethod' }
    & Pick<AppraisalsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id' | 'odometer' | 'odometerMiles' | 'location' | 'parkingZoneSegmentation' | 'appraisalValue' | 'offeredValue' | 'modelYear' | 'make' | 'model' | 'series' | 'status' | 'source' | 'origin' | 'traction' | 'transmissionType' | 'originDate' | 'vin' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'isPurchased' | 'saleType' | 'stock' | 'noticeByManagers' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'assessedById' | 'assessingStarted' | 'created' | 'updated' | 'submitted' | 'eraSalesCode' | 'paveSessionStatus'>
      & { vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )> }
    )> }
  ) }
);

export type CreateMonkaiInspectionMutationVariables = Exact<{
  input: CreateMonkaiInspectionInput;
}>;


export type CreateMonkaiInspectionMutation = (
  { __typename?: 'Mutation' }
  & { createMonkaiInspection?: Maybe<(
    { __typename?: 'CreateMonkaiInspectionResponse' }
    & Pick<CreateMonkaiInspectionResponse, 'operation'>
    & { result: (
      { __typename?: 'CreateMonkaiInspectionResult' }
      & Pick<CreateMonkaiInspectionResult, 'id' | 'object_type'>
    ) }
  )> }
);

export type StartMonkaiInspectionMutationVariables = Exact<{
  input: StartMonkaiInspectionInput;
}>;


export type StartMonkaiInspectionMutation = (
  { __typename?: 'Mutation' }
  & { startMonkaiInspection?: Maybe<(
    { __typename?: 'CreateMonkaiInspectionResponse' }
    & Pick<CreateMonkaiInspectionResponse, 'operation'>
    & { result: (
      { __typename?: 'CreateMonkaiInspectionResult' }
      & Pick<CreateMonkaiInspectionResult, 'id' | 'object_type'>
    ) }
  )> }
);

export type CreateEmptyMonkaiInspectionMutationVariables = Exact<{
  input: CreateMonkaiInspectionInput;
}>;


export type CreateEmptyMonkaiInspectionMutation = (
  { __typename?: 'Mutation' }
  & { createEmptyMonkaiInspection?: Maybe<(
    { __typename?: 'CreateMonkaiInspectionResponse' }
    & Pick<CreateMonkaiInspectionResponse, 'operation'>
    & { result: (
      { __typename?: 'CreateMonkaiInspectionResult' }
      & Pick<CreateMonkaiInspectionResult, 'id' | 'object_type'>
    ) }
  )> }
);

export type AppraisalQueryVariables = Exact<{
  id: Scalars['String'];
  withInventory: Scalars['Boolean'];
}>;


export type AppraisalQuery = (
  { __typename?: 'Query' }
  & { appraisal: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'vin' | 'licenseState' | 'licensePlate' | 'isPurchased' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, inventory?: Maybe<(
        { __typename?: 'AppraisalInventory' }
        & Pick<AppraisalInventory, 'Stock' | 'dashboardUrl'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type GetMonkaiInspectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMonkaiInspectionQuery = (
  { __typename?: 'Query' }
  & { getMonkaiInspection: (
    { __typename?: 'GetMonkaiInspectionResponse' }
    & { result: (
      { __typename?: 'GetMonkaiInspectionResult' }
      & Pick<GetMonkaiInspectionResult, 'monkaiInspectionId' | 'monkaiInspectionStatus'>
      & { photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, monkaiVehicleInfo: Array<Maybe<(
        { __typename?: 'MonkaiVehicleInfoType' }
        & Pick<MonkaiVehicleInfoType, 'brand' | 'car_registration' | 'color' | 'created_at' | 'date_of_circulation' | 'deleted_at' | 'duplicate_keys' | 'expertise_requested' | 'exterior_cleanliness' | 'id' | 'interior_cleanliness' | 'market_value' | 'mileage' | 'model' | 'object_type' | 'owner_info' | 'plate' | 'repair_estimate' | 'trade_in_offer' | 'vehicle_quotation' | 'vehicle_type' | 'vin'>
      )>> }
    ) }
  ) }
);

export type UploadAppraisalPhotoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadAppraisalPhotoMutation = (
  { __typename?: 'Mutation' }
  & { uploadAppraisalPhoto: (
    { __typename?: 'PhotoMethod' }
    & { result: (
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'src' | 'zone'>
    ) }
  ) }
);

export type UploadAppraisalPhotoReactiveMutationVariables = Exact<{
  id: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type UploadAppraisalPhotoReactiveMutation = (
  { __typename?: 'Mutation' }
  & { uploadAppraisalPhotoReactive: (
    { __typename?: 'PhotoMethod' }
    & { result: (
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'src' | 'zone'>
    ) }
  ) }
);

export type UpdateAppraisalMutationVariables = Exact<{
  input: JsonAppraisalInput;
}>;


export type UpdateAppraisalMutation = (
  { __typename?: 'Mutation' }
  & { updateAppraisal: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'vin' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type RestartAppraisalMutationVariables = Exact<{
  input: Restart;
}>;


export type RestartAppraisalMutation = (
  { __typename?: 'Mutation' }
  & { restartAppraisal: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'vin' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type CreateAppraisalMutationVariables = Exact<{
  input: JsonAppraisalInput;
}>;


export type CreateAppraisalMutation = (
  { __typename?: 'Mutation' }
  & { createAppraisal: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id' | 'vin'>
    ) }
  ) }
);

export type CloneAppraisalMutationVariables = Exact<{
  input: JsonAppraisalInput;
}>;


export type CloneAppraisalMutation = (
  { __typename?: 'Mutation' }
  & { cloneAppraisal: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id'>
    ) }
  ) }
);

export type AppraisalsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalsCountQuery = (
  { __typename?: 'Query' }
  & { pendingAppraisalsCount: (
    { __typename?: 'AppraisalsCountMethod' }
    & Pick<AppraisalsCountMethod, 'result'>
  ), evaluationAppraisalsCount: (
    { __typename?: 'AppraisalsCountMethod' }
    & Pick<AppraisalsCountMethod, 'result'>
  ), evaluatingAppraisalsCount: (
    { __typename?: 'AppraisalsCountMethod' }
    & Pick<AppraisalsCountMethod, 'result'>
  ), assessmentAppraisalsCount: (
    { __typename?: 'AppraisalsCountMethod' }
    & Pick<AppraisalsCountMethod, 'result'>
  ), claimAppraisalsCount: (
    { __typename?: 'AppraisalsCountMethod' }
    & Pick<AppraisalsCountMethod, 'result'>
  ) }
);

export type CreatePaveSessionMutationVariables = Exact<{
  input: CreatePaveSessionInput;
}>;


export type CreatePaveSessionMutation = (
  { __typename?: 'Mutation' }
  & { createPaveSession: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'vin' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type GetAuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthUserQuery = (
  { __typename?: 'Query' }
  & { getAuthUser: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'country' | 'dealership' | 'roles' | 'permissions'>
  ) }
);

export type SingInMutationVariables = Exact<{
  input: SignInInput;
}>;


export type SingInMutation = (
  { __typename?: 'Mutation' }
  & { signIn?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'token'>
  )> }
);

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'token'>
  )> }
);

export type GetCarstoryByVinMutationVariables = Exact<{
  input: UrlTypeInput;
}>;


export type GetCarstoryByVinMutation = (
  { __typename?: 'Mutation' }
  & { getCarstoryByVIN: (
    { __typename?: 'GetCarstoryByVINMethod' }
    & { result: (
      { __typename?: 'CarstoryByVINType' }
      & Pick<CarstoryByVinType, 'error'>
      & { carstory: (
        { __typename?: 'CarstoryType' }
        & { vehicleInfo: (
          { __typename?: 'VehicleInfoType' }
          & Pick<VehicleInfoType, 'bedStyle' | 'bodyStyle' | 'cabStyle' | 'condition' | 'conditionTimestamp' | 'confidenceScore4' | 'doors' | 'driveType' | 'engine' | 'engineDescription' | 'engineDisplacement' | 'exteriorColor' | 'fuel' | 'generation' | 'interiorColor' | 'make' | 'mileage' | 'mileageTimestamp' | 'model' | 'mpg' | 'mpgCity' | 'mpgHighway' | 'subGeneration' | 'subModel' | 'subTrim' | 'transmission' | 'transmissionDetails' | 'trim' | 'vin' | 'wheelbase' | 'year'>
        ) }
      ) }
    ) }
  ) }
);

export type GetCarGurusByVinQueryVariables = Exact<{
  vin: Scalars['String'];
  mileage?: Maybe<Scalars['String']>;
}>;


export type GetCarGurusByVinQuery = (
  { __typename?: 'Query' }
  & { getCarGurusByVIN: (
    { __typename?: 'CarGurusByVIN' }
    & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
  ) }
);

export type GetOffersByVinQueryVariables = Exact<{
  vin: Scalars['String'];
  mileage: Scalars['String'];
  zipCode: Scalars['String'];
}>;


export type GetOffersByVinQuery = (
  { __typename?: 'Query' }
  & { getOffersByVIN: (
    { __typename?: 'OffersByVIN' }
    & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
    & { vroomError?: Maybe<(
      { __typename?: 'DrivErrorType' }
      & Pick<DrivErrorType, 'error'>
    )>, carvanaError?: Maybe<(
      { __typename?: 'DrivErrorType' }
      & Pick<DrivErrorType, 'error'>
    )>, carmaxError?: Maybe<(
      { __typename?: 'DrivErrorType' }
      & Pick<DrivErrorType, 'error'>
    )> }
  ) }
);

export type BbInfoValuesMutationVariables = Exact<{
  input: BbInfoValuesInput;
}>;


export type BbInfoValuesMutation = (
  { __typename?: 'Mutation' }
  & { bbInfoValues: (
    { __typename?: 'BbInfoValuesMethod' }
    & { result: (
      { __typename?: 'BbInfoValuesType' }
      & { reatailPrices?: Maybe<(
        { __typename?: 'ReatailPricesType' }
        & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
      )>, wholeSalePrices?: Maybe<(
        { __typename?: 'WholeSalePricesType' }
        & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
      )>, tradeinPrices?: Maybe<(
        { __typename?: 'TradeinPricesType' }
        & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
      )> }
    ) }
  ) }
);

export type CarproofQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type CarproofQuery = (
  { __typename?: 'Query' }
  & { carproof: (
    { __typename?: 'CarproofMethod' }
    & { result: (
      { __typename?: 'Carproof' }
      & Pick<Carproof, 'url' | 'reportId' | 'vin' | 'lang'>
    ) }
  ) }
);

export type GetCarproofQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type GetCarproofQuery = (
  { __typename?: 'Query' }
  & { getCarfax: (
    { __typename?: 'GetCarproofMethod' }
    & { result: (
      { __typename?: 'GetCarproofType' }
      & Pick<GetCarproofType, 'InvoicedTo' | 'Make' | 'MobileReportLink' | 'MobileReportLinkHideLiens' | 'Model' | 'Reference' | 'ReportLink' | 'ReportLinkHideLiens' | 'ReportNumber' | 'ReportType' | 'ReportTypeName' | 'Vin' | 'Year'>
    ) }
  ) }
);

export type CarproofVpnQueryVariables = Exact<{
  vin: Scalars['String'];
  lang: Scalars['String'];
}>;


export type CarproofVpnQuery = (
  { __typename?: 'Query' }
  & { getCarfaxVPN: (
    { __typename?: 'CarproofVPNMethod' }
    & { result: (
      { __typename?: 'CarproofVPN' }
      & Pick<CarproofVpn, 'Language' | 'LogoUrl' | 'ResultCode' | 'ResultMessage'>
      & { BadgesList: Array<(
        { __typename?: 'BadgesListType' }
        & Pick<BadgesListType, 'BadgesImageUrl' | 'HasBadge' | 'VhrReportUrl' | 'VIN' | 'ResultCode' | 'ResultMessage'>
        & { BadgeList: Array<(
          { __typename?: 'BadgeListSmallType' }
          & Pick<BadgeListSmallType, 'BadgeName' | 'BadgeType' | 'BadgeImageUrl'>
        )> }
      )> }
    ) }
  ) }
);

export type ModelYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type ModelYearsQuery = (
  { __typename?: 'Query' }
  & { modelYears: (
    { __typename?: 'ModelYearsMethod' }
    & { result: Array<(
      { __typename?: 'ChromeModelYear' }
      & Pick<ChromeModelYear, 'id' | 'name'>
    )> }
  ) }
);

export type DivisionsQueryVariables = Exact<{
  modelYearId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
}>;


export type DivisionsQuery = (
  { __typename?: 'Query' }
  & { divisions: (
    { __typename?: 'DivisionsMethod' }
    & { result: Array<(
      { __typename?: 'ChromeDivision' }
      & Pick<ChromeDivision, 'id' | 'name'>
    )> }
  ) }
);

export type ModelsQueryVariables = Exact<{
  modelYearId?: Maybe<Scalars['Int']>;
  divisionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
}>;


export type ModelsQuery = (
  { __typename?: 'Query' }
  & { models: (
    { __typename?: 'ModelsMethod' }
    & { result: Array<(
      { __typename?: 'ChromeModels' }
      & Pick<ChromeModels, 'id' | 'name'>
    )> }
  ) }
);

export type StylesQueryVariables = Exact<{
  modelId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Languages>;
  country: Countries;
}>;


export type StylesQuery = (
  { __typename?: 'Query' }
  & { styles: (
    { __typename?: 'StylesMethod' }
    & { result: Array<(
      { __typename?: 'ChromeStyles' }
      & Pick<ChromeStyles, 'id' | 'name'>
    )> }
  ) }
);

export type ConditionSidesQueryVariables = Exact<{
  variant: Scalars['String'];
}>;


export type ConditionSidesQuery = (
  { __typename?: 'Query' }
  & { carSides: Array<(
    { __typename?: 'CarSide' }
    & Pick<CarSide, 'id' | 'side' | 'image'>
    & { parts: Array<(
      { __typename?: 'CarPart' }
      & Pick<CarPart, 'id' | 'name' | 'top' | 'bottom' | 'left' | 'right'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  )> }
);

export type ConditionDamagesQueryVariables = Exact<{
  variant: Scalars['String'];
  part: Scalars['String'];
  side: Scalars['String'];
}>;


export type ConditionDamagesQuery = (
  { __typename?: 'Query' }
  & { damages: Array<(
    { __typename?: 'Damage' }
    & Pick<Damage, 'id' | 'side' | 'bucket'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'name' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  )> }
);

export type CreateConditionDamageMutationVariables = Exact<{
  input: DamageInput;
}>;


export type CreateConditionDamageMutation = (
  { __typename?: 'Mutation' }
  & { createDamage: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id' | 'side' | 'bucket'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type CreateConditionDamageActionMutationVariables = Exact<{
  input: ActionInput;
}>;


export type CreateConditionDamageActionMutation = (
  { __typename?: 'Mutation' }
  & { createDamageAction: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id' | 'side'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type CreateDamageActionMutationVariables = Exact<{
  input: ActionInput;
}>;


export type CreateDamageActionMutation = (
  { __typename?: 'Mutation' }
  & { createDamageAction: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type UpdateConditionDamageMutationVariables = Exact<{
  id: Scalars['String'];
  input: DamageInput;
}>;


export type UpdateConditionDamageMutation = (
  { __typename?: 'Mutation' }
  & { updateDamage: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id' | 'bucket'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type UpdateConditionDamageActionMutationVariables = Exact<{
  id: Scalars['String'];
  input: ActionInput;
}>;


export type UpdateConditionDamageActionMutation = (
  { __typename?: 'Mutation' }
  & { updateDamageAction: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type DeleteConditionDamageMutationVariables = Exact<{
  id: Scalars['String'];
  userId: Scalars['String'];
}>;


export type DeleteConditionDamageMutation = (
  { __typename?: 'Mutation' }
  & { deleteDamage: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id' | 'bucket'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type DeleteConditionDamageActionMutationVariables = Exact<{
  id: Scalars['String'];
  userId: Scalars['String'];
}>;


export type DeleteConditionDamageActionMutation = (
  { __typename?: 'Mutation' }
  & { deleteDamageAction: (
    { __typename?: 'Damage' }
    & Pick<Damage, 'id'>
    & { labels: (
      { __typename?: 'Label' }
      & Pick<Label, 'en' | 'fr'>
    ), actions: Array<(
      { __typename?: 'DamageAction' }
      & Pick<DamageAction, 'id' | 'deval_low' | 'deval_high' | 'repair_low' | 'repair_high'>
      & { labels: (
        { __typename?: 'Label' }
        & Pick<Label, 'en' | 'fr'>
      ) }
    )> }
  ) }
);

export type GetUserEvaluationListQueryVariables = Exact<{
  location: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  select: Scalars['String'];
  search: Scalars['String'];
  statuses: Scalars['String'];
}>;


export type GetUserEvaluationListQuery = (
  { __typename?: 'Query' }
  & { getUserEvaluationList: (
    { __typename?: 'AppriselVehicleMethod' }
    & Pick<AppriselVehicleMethod, 'last'>
    & { result: Array<(
      { __typename?: 'AppriselVehicleType' }
      & Pick<AppriselVehicleType, '_id' | 'interiorColor' | 'location' | 'vin' | 'origin' | 'odometer' | 'odometerMiles' | 'modelYear' | 'make' | 'model' | 'series' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'exteriorColor' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'styleId' | 'created_at' | 'updated_at' | 'status' | 'physicalEvaluationId' | 'physicalEvaluationDate' | 'isPurchased'>
      & { disclosuresUS: Array<(
        { __typename?: 'DisclosuresType' }
        & Pick<DisclosuresType, 'name' | 'text' | 'value' | 'children'>
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'OptionsVinDecodeBB' }
        & Pick<OptionsVinDecodeBb, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )> }
    )> }
  ) }
);

export type GetOneVehicleUserEvaluationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOneVehicleUserEvaluationQuery = (
  { __typename?: 'Query' }
  & { getOneVehicleUserEvaluation: (
    { __typename?: 'AppriselVehicleType' }
    & Pick<AppriselVehicleType, '_id' | 'interiorColor' | 'location' | 'vin' | 'origin' | 'odometer' | 'odometerMiles' | 'modelYear' | 'make' | 'model' | 'series' | 'bodyType' | 'engineCylinderCount' | 'engineCylinderLabel' | 'transmissionType' | 'traction' | 'exteriorColor' | 'custFirstName' | 'custLastName' | 'custHomePhone' | 'custEmail' | 'custAddress' | 'custCity' | 'custState' | 'custPostalCode' | 'styleId' | 'created_at' | 'updated_at' | 'status' | 'physicalEvaluationId' | 'physicalEvaluationDate'>
    & { disclosuresUS: Array<(
      { __typename?: 'DisclosuresType' }
      & Pick<DisclosuresType, 'name' | 'text' | 'value' | 'children'>
    )>, optionsVinDecodeBB: Array<(
      { __typename?: 'OptionsVinDecodeBB' }
      & Pick<OptionsVinDecodeBb, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
    )> }
  ) }
);

export type AppraisalsCoreQueryVariables = Exact<{
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  purchased?: Maybe<Scalars['Boolean']>;
}>;


export type AppraisalsCoreQuery = (
  { __typename?: 'Query' }
  & { appraisalsCore: (
    { __typename?: 'AppraisalsMethod' }
    & Pick<AppraisalsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id' | 'odometer' | 'odometerMiles' | 'location' | 'appraisalValue' | 'offeredValue' | 'modelYear' | 'make' | 'model' | 'series' | 'status' | 'origin' | 'traction' | 'transmissionType' | 'vin' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'created' | 'submitted' | 'paveSessionStatus' | 'assessingStarted' | 'assessedById' | 'eraSalesCode' | 'isPurchased'>
      & { photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )> }
    )> }
  ) }
);

export type AppraisalsPurchasedCoreQueryVariables = Exact<{
  statuses: Scalars['String'];
  withInventory: Scalars['Boolean'];
  location: Scalars['String'];
  search: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
}>;


export type AppraisalsPurchasedCoreQuery = (
  { __typename?: 'Query' }
  & { appraisalsCore: (
    { __typename?: 'AppraisalsMethod' }
    & Pick<AppraisalsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, 'id' | 'odometer' | 'odometerMiles' | 'location' | 'appraisalValue' | 'offeredValue' | 'modelYear' | 'make' | 'model' | 'series' | 'status' | 'origin' | 'traction' | 'transmissionType' | 'vin' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'created' | 'submitted' | 'paveSessionStatus' | 'assessingStarted' | 'assessedById' | 'eraSalesCode' | 'isPurchased'>
      & { photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )> }
    )> }
  ) }
);

export type AppraisalVehByIdCoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppraisalVehByIdCoreQuery = (
  { __typename?: 'Query' }
  & { appraisalVehByIdCore: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'vin' | 'licenseState' | 'licensePlate' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'isPurchased' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, inventory?: Maybe<(
        { __typename?: 'AppraisalInventory' }
        & Pick<AppraisalInventory, 'Stock' | 'dashboardUrl'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type AppraisalVehByIdWithInventoryCoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppraisalVehByIdWithInventoryCoreQuery = (
  { __typename?: 'Query' }
  & { appraisalVehByIdWithInventoryCore: (
    { __typename?: 'AppraisalMethod' }
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id' | 'vAutoId' | 'appraisalValue' | 'location' | 'status' | 'type' | 'isPurchased' | 'vin' | 'licenseState' | 'licensePlate' | 'origin' | 'odometer' | 'odometerMiles' | 'styleId' | 'modelYear' | 'make' | 'model' | 'series' | 'engineCylinderCount' | 'engineCylinderLabel' | 'bodyType' | 'transmissionType' | 'originDate' | 'traction' | 'exteriorColor' | 'interiorColor' | 'vehicleType' | 'trimName' | 'saleType' | 'stock' | 'noticeByManagers' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'isPhotosCertifyUser' | 'disclosures' | 'offerAsSeen' | 'offerPrinted' | 'offeredValue' | 'offeredPrice' | 'formulaPrice' | 'custAmountOwed' | 'custDriverLicense' | 'options' | 'conditionReportLink' | 'inspectionReportLinkOtherService' | 'conditionReportStatus' | 'conditioningStarted' | 'odometerReplaced' | 'claims' | 'commerciallyUsed' | 'commonId' | 'emissionSystemIntact' | 'eraInvoiceAmount' | 'eraSalesCode' | 'exitStrategyType' | 'factoryBuyback' | 'floodDamage' | 'hasConditionReport' | 'inspectionReportLink' | 'isConditionReportComplete' | 'leaseReturn' | 'parkingZoneSegmentation' | 'previouslyDamaged' | 'publicId' | 'recall' | 'salesperson' | 'salvaged' | 'source' | 'appraiser' | 'submitted' | 'created' | 'updated' | 'expired' | 'monkaiInspectionId' | 'monkaiInspectionStatus' | 'userEvaluationId' | 'owner' | 'ownerId' | 'started' | 'startedById' | 'completed' | 'completedById' | 'approved' | 'approvedById' | 'assessingStarted' | 'assessed' | 'assessedById' | 'authorized' | 'authorizedById' | 'closer' | 'closerId' | 'paveSessionId' | 'paveSessionStatus' | 'challenged' | 'challengedById' | 'challengedByLocation' | 'challengeResolved' | 'rep' | 'repId' | 'sourceNameReport'>
      & { carfax?: Maybe<(
        { __typename?: 'CarfaxType' }
        & Pick<CarfaxType, 'link' | 'date'>
      )>, vehicleReport: Array<Maybe<(
        { __typename?: 'vehicleReportType' }
        & Pick<VehicleReportType, 'id' | 'key' | 'name' | 'todo' | 'icon' | 'description' | 'status'>
        & { select: Array<Maybe<(
          { __typename?: 'vehicleReportSelectType' }
          & Pick<VehicleReportSelectType, 'name' | 'value' | 'description'>
        )>>, images: Array<Maybe<(
          { __typename?: 'VehicleReportImage' }
          & Pick<VehicleReportImage, 'id' | 'src' | 'zone'>
        )>> }
      )>>, disclosuresUS: Array<(
        { __typename?: 'DisclosuresUSType' }
        & Pick<DisclosuresUsType, 'name' | 'text' | 'value' | 'children'>
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'created' | 'type' | 'src' | 'text' | 'name' | 'user_id'>
      )>, photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )>, conditions: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, optionsVinDecode: Array<(
        { __typename?: 'SubOptionsList' }
        & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
        & { price: (
          { __typename?: 'SubOptionsPrice' }
          & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
        ) }
      )>, optionsVinDecodeBB: Array<(
        { __typename?: 'DecodeBBItemType' }
        & Pick<DecodeBbItemType, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
      )>, disclosuresWork: Array<(
        { __typename?: 'AppraisalCondition' }
        & Pick<AppraisalCondition, 'name' | 'label' | 'text' | 'order' | 'todo' | 'pending' | 'workStarted' | 'workStartedById' | 'conditioned' | 'conditionedById' | 'isArbitrageDamege' | 'mapped'>
        & { translationLabel?: Maybe<(
          { __typename?: 'TranslationLabelType' }
          & Pick<TranslationLabelType, 'en' | 'fr'>
        )>, photos: Array<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>, pin: (
          { __typename?: 'Pin' }
          & Pick<Pin, 'x' | 'y' | 'mapName'>
        ), detail: (
          { __typename?: 'Detail' }
          & Pick<Detail, 'name' | 'label' | 'mustAttach' | 'order' | 'value'>
        ) }
      )>, photosMonkaiInspections: Array<(
        { __typename?: 'MonkaiImage' }
        & Pick<MonkaiImage, 'id' | 'src' | 'srcMonkai'>
      )>, manheimData?: Maybe<(
        { __typename?: 'ManheimType' }
        & Pick<ManheimType, 'href' | 'count'>
        & { items: Array<(
          { __typename?: 'ManheimItemType' }
          & Pick<ManheimItemType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'odometerUnits' | 'averageGrade' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate'>
          & { description: (
            { __typename?: 'ManheimItemDescriptionType' }
            & Pick<ManheimItemDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
          ), wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedPricing: (
            { __typename?: 'AdjustedPricingType' }
            & { wholesale: (
              { __typename?: 'ManheimItemWholesaleType' }
              & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
            ), adjustedBy: (
              { __typename?: 'AdjustedByType' }
              & Pick<AdjustedByType, 'Grade' | 'Odometer'>
            ) }
          ) }
        )> }
      )>, drivlyDataOffers?: Maybe<(
        { __typename?: 'OffersByVIN' }
        & Pick<OffersByVin, 'vin' | 'year' | 'make' | 'model' | 'mileage' | 'vroomPrice' | 'vroomGrade' | 'vroomUrl' | 'carvanaPrice' | 'carvanaUrl' | 'carmaxPrice' | 'carmaxUrl' | 'carmaxDeclineReason' | 'retry'>
        & { vroomError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carvanaError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )>, carmaxError?: Maybe<(
          { __typename?: 'DrivErrorType' }
          & Pick<DrivErrorType, 'error'>
        )> }
      )>, blackbookData?: Maybe<(
        { __typename?: 'BbInfoValuesType' }
        & { reatailPrices?: Maybe<(
          { __typename?: 'ReatailPricesType' }
          & Pick<ReatailPricesType, 'adjustedRetailAvg' | 'adjustedRetailClean' | 'adjustedRetailRough' | 'adjustedRetailXclean' | 'baseRetailAvg' | 'baseRetailClean' | 'baseRetailRough' | 'baseRetailXclean'>
        )>, wholeSalePrices?: Maybe<(
          { __typename?: 'WholeSalePricesType' }
          & Pick<WholeSalePricesType, 'adjustedWholeAvg' | 'adjustedWholeClean' | 'adjustedWholeRough' | 'adjustedWholeXclean' | 'baseWholeAvg' | 'baseWholeClean' | 'baseWholeRough' | 'baseWholeXclean'>
        )>, tradeinPrices?: Maybe<(
          { __typename?: 'TradeinPricesType' }
          & Pick<TradeinPricesType, 'adjustedTradeinAvg' | 'adjustedTradeinClean' | 'adjustedTradeinRough' | 'adjustedTradeinXclean' | 'baseTradeinAvg' | 'baseTradeinClean' | 'baseTradeinRough' | 'baseTradeinXclean'>
        )> }
      )>, drivlyDataGurus?: Maybe<(
        { __typename?: 'CarGurusByVIN' }
        & Pick<CarGurusByVin, 'vin' | 'retail' | 'privateParty' | 'trade' | 'error'>
      )>, inventory?: Maybe<(
        { __typename?: 'AppraisalInventory' }
        & Pick<AppraisalInventory, 'Stock' | 'dashboardUrl'>
      )>, tireThread: (
        { __typename?: 'AppraisalTireThread' }
        & Pick<AppraisalTireThread, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        & { secondSet?: Maybe<(
          { __typename?: 'AppraisalTireThreadSecond' }
          & Pick<AppraisalTireThreadSecond, 'frontLeft' | 'frontRight' | 'rearLeft' | 'rearRight' | 'mountedOn' | 'season'>
        )>, photos?: Maybe<Array<Maybe<(
          { __typename?: 'AppraisalPhoto' }
          & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
        )>>> }
      ), comments: (
        { __typename?: 'AppraisalComments' }
        & Pick<AppraisalComments, 'client' | 'document' | 'appraiser'>
      ), application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'hostname' | 'version'>
      )> }
    ) }
  ) }
);

export type UpdateVehicleUserEvaluationMutationVariables = Exact<{
  id: Scalars['String'];
  input: AppriselVehicleInput;
}>;


export type UpdateVehicleUserEvaluationMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleUserEvaluation: (
    { __typename?: 'AppriselVehicleType' }
    & Pick<AppriselVehicleType, '_id'>
  ) }
);

export type CompleteVehicleUserEvaluationMutationVariables = Exact<{
  id: Scalars['String'];
  input: CompleteVehicleUserEvaluationInput;
}>;


export type CompleteVehicleUserEvaluationMutation = (
  { __typename?: 'Mutation' }
  & { completeVehicleUserEvaluation: (
    { __typename?: 'CompleteVehicleUserEvaluationMethod' }
    & Pick<CompleteVehicleUserEvaluationMethod, 'evaluationId' | 'price'>
  ) }
);

export type UpdatePhysicalEvaluationMutationVariables = Exact<{
  id: Scalars['String'];
  input: AppraisalUpdateWithSocketInput;
}>;


export type UpdatePhysicalEvaluationMutation = (
  { __typename?: 'Mutation' }
  & { updatePhysicalEvaluation: (
    { __typename?: 'UpdatePhysicalEvaluationMethod' }
    & Pick<UpdatePhysicalEvaluationMethod, 'modified'>
    & { evaluation: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id'>
    ) }
  ) }
);

export type CreatePhysicalEvaluationMutationVariables = Exact<{
  input: AppraisalUpdateInput;
}>;


export type CreatePhysicalEvaluationMutation = (
  { __typename?: 'Mutation' }
  & { createPhysicalEvaluation: (
    { __typename?: 'CreatePhysicalEvaluationMethod' }
    & Pick<CreatePhysicalEvaluationMethod, 'exists'>
    & { result: (
      { __typename?: 'Appraisal' }
      & Pick<Appraisal, '_id' | 'id'>
    ) }
  ) }
);

export type VAutoCheckCoreQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type VAutoCheckCoreQuery = (
  { __typename?: 'Query' }
  & { vAutoCheckCore: (
    { __typename?: 'VAutoCheckMethod' }
    & { GetAppraisalWithPricingDataByVinResult: (
      { __typename?: 'GetAppraisalWithPricingDataByVinResult' }
      & { Appraisal: (
        { __typename?: 'AppraisalVAuto' }
        & { Appraiser?: Maybe<(
          { __typename?: 'AppraiserVAuto' }
          & Pick<AppraiserVAuto, 'Email' | 'FirstName' | 'LastName' | 'UserName'>
        )> }
      ) }
    ) }
  ) }
);

export type SentEmailWithPriceDrivlyMutationVariables = Exact<{
  input: SentEmailInput;
}>;


export type SentEmailWithPriceDrivlyMutation = (
  { __typename?: 'Mutation' }
  & { sentEmail: (
    { __typename?: 'SentEmailMethod' }
    & Pick<SentEmailMethod, 'modified'>
  ) }
);

export type AppraisalVehByVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type AppraisalVehByVinQuery = (
  { __typename?: 'Query' }
  & { appraisalVehByVin: (
    { __typename?: 'AppraisalVehByVinMethod' }
    & { result?: Maybe<(
      { __typename?: 'AppraisalVehByVinResult' }
      & { virtual: Array<(
        { __typename?: 'VirtualByVinType' }
        & Pick<VirtualByVinType, '_id' | 'id' | 'vin' | 'location' | 'modelYear' | 'make' | 'model' | 'series' | 'offeredValue' | 'created' | 'appraisalValue'>
      )>, physical: Array<(
        { __typename?: 'AppraisalVehByVinType' }
        & Pick<AppraisalVehByVinType, '_id' | 'id' | 'appraisalValue' | 'offeredValue' | 'location' | 'status' | 'vin' | 'modelYear' | 'make' | 'model' | 'series' | 'custFirstName' | 'custLastName' | 'custEmail' | 'custHomePhone' | 'custAddress' | 'custCity' | 'custPostalCode' | 'custState' | 'completed' | 'created'>
      )>, condition: Array<(
        { __typename?: 'AppraisalVehByVinType' }
        & Pick<AppraisalVehByVinType, 'id'>
      )> }
    )> }
  ) }
);

export type VirtualVehByVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type VirtualVehByVinQuery = (
  { __typename?: 'Query' }
  & { virtualVehByVin: (
    { __typename?: 'VirtualVehByVinMethod' }
    & { result: Array<(
      { __typename?: 'VirtualVehByVinType' }
      & Pick<VirtualVehByVinType, '_id' | 'id' | 'vin' | 'appraisalValue' | 'completed' | 'location' | 'make' | 'model' | 'modelYear'>
    )> }
  ) }
);

export type GetStatusCountQueryVariables = Exact<{
  service: Scalars['String'];
  status: Scalars['String'];
  filter: Scalars['String'];
}>;


export type GetStatusCountQuery = (
  { __typename?: 'Query' }
  & { getStatusCount: (
    { __typename?: 'StatusCountResponse' }
    & Pick<StatusCountResponse, 'operation' | 'result'>
  ) }
);

export type GetGapCountQueryVariables = Exact<{
  service: Scalars['String'];
  filter: Scalars['String'];
}>;


export type GetGapCountQuery = (
  { __typename?: 'Query' }
  & { getSlaGap: (
    { __typename?: 'GlaResponse' }
    & Pick<GlaResponse, 'operation'>
    & { result: Array<(
      { __typename?: 'GlaItem' }
      & Pick<GlaItem, 'totalAppraisal' | 'totalAsking' | 'totalGap' | 'count' | 'name'>
    )> }
  ) }
);

export type GetPropertCountQueryVariables = Exact<{
  service: Scalars['String'];
  value: Scalars['String'];
  prop: Scalars['String'];
  filter: Scalars['String'];
}>;


export type GetPropertCountQuery = (
  { __typename?: 'Query' }
  & { getPropertyCount: (
    { __typename?: 'StatusCountResponse' }
    & Pick<StatusCountResponse, 'operation' | 'result'>
  ) }
);

export type GetFollowCountQueryVariables = Exact<{
  service: Scalars['String'];
  filter: Scalars['String'];
}>;


export type GetFollowCountQuery = (
  { __typename?: 'Query' }
  & { getSlaFollow: (
    { __typename?: 'GlaFollowResponse' }
    & Pick<GlaFollowResponse, 'operation'>
    & { result: Array<(
      { __typename?: 'GlaFollowItem' }
      & Pick<GlaFollowItem, 'booked' | 'called' | 'count' | 'none' | 'priced' | 'smsSent' | 'name'>
    )> }
  ) }
);

export type GetEvalCountQueryVariables = Exact<{
  service: Scalars['String'];
  filter: Scalars['String'];
}>;


export type GetEvalCountQuery = (
  { __typename?: 'Query' }
  & { getSlaEval: (
    { __typename?: 'GlaEvalResponse' }
    & Pick<GlaEvalResponse, 'operation'>
    & { result: Array<(
      { __typename?: 'GlaEvalItem' }
      & Pick<GlaEvalItem, 'count' | 'diff' | 'total' | 'name'>
    )> }
  ) }
);

export type VinStyleQueryVariables = Exact<{
  id: Scalars['Int'];
  country: Countries;
}>;


export type VinStyleQuery = (
  { __typename?: 'Query' }
  & { vinStyle: (
    { __typename?: 'VinStyleMethod' }
    & Pick<VinStyleMethod, 'operation'>
    & { result: (
      { __typename?: 'VinStyleResult' }
      & { dataFullCurrentStyle: (
        { __typename?: 'DataFullCurrentStyleElement' }
        & Pick<DataFullCurrentStyleElement, 'currentStyleId' | 'drivetrain' | 'modelYear' | 'bestModelName' | 'bestMakeName' | 'bestStyleName' | 'bestTrimName' | 'cylinders' | 'bodyStyle' | 'transmissionTypeId' | 'transmissionDesc' | 'drivetrainDesc'>
        & { exteriorColor: Array<(
          { __typename?: 'ExteriorColor' }
          & Pick<ExteriorColor, 'styleId' | 'installed' | 'colorCode' | 'colorName' | 'rgbValue'>
          & { genericColor: Array<(
            { __typename?: 'ExteriorColorElement' }
            & Pick<ExteriorColorElement, 'installed' | 'name' | 'primary'>
          )> }
        )>, interiorColor: Array<(
          { __typename?: 'InteriorColor' }
          & Pick<InteriorColor, 'genericColor' | 'styleId' | 'installed' | 'colorCode' | 'colorName' | 'rgbValue'>
        )> }
      ) }
    ) }
  ) }
);

export type DecodeVinQueryVariables = Exact<{
  vin: Scalars['String'];
  country: Countries;
}>;


export type DecodeVinQuery = (
  { __typename?: 'Query' }
  & { decodeVin: (
    { __typename?: 'DecodeVinMethod' }
    & Pick<DecodeVinMethod, 'operation'>
    & { result: (
      { __typename?: 'DecodeVinResult' }
      & Pick<DecodeVinResult, 'countStyles'>
      & { styles: Array<(
        { __typename?: 'StyleItem' }
        & Pick<StyleItem, 'mediaGallery' | 'id' | 'modelYear' | 'name' | 'nameWoTrim' | 'trim' | 'mfrModelCode' | 'fleetOnly' | 'modelFleet' | 'passDoors' | 'altModelName' | 'altStyleName' | 'altBodyType' | 'drivetrain'>
        & { division: (
          { __typename?: 'StyleElementItem' }
          & Pick<StyleElementItem, 'value' | 'id'>
        ), subdivision: (
          { __typename?: 'StyleElementItem' }
          & Pick<StyleElementItem, 'value' | 'id'>
        ), model: (
          { __typename?: 'StyleElementItem' }
          & Pick<StyleElementItem, 'value' | 'id'>
        ), basePrice: (
          { __typename?: 'BasePriceItem' }
          & Pick<BasePriceItem, 'unknown' | 'invoice' | 'msrp' | 'destination'>
        ), bodyType: Array<(
          { __typename?: 'BodyTypeItem' }
          & Pick<BodyTypeItem, 'value' | 'id' | 'primary'>
        )>, marketClass: (
          { __typename?: 'StyleElementItem' }
          & Pick<StyleElementItem, 'value' | 'id'>
        ), stockImage: (
          { __typename?: 'StockImageItem' }
          & Pick<StockImageItem, 'url' | 'width' | 'height' | 'filename'>
        ), acode: Array<Maybe<(
          { __typename?: 'AcodeIcon' }
          & Pick<AcodeIcon, 'value' | 'logic'>
        )>> }
      )>, dataFullCurrentStyle: (
        { __typename?: 'DataFullCurrentStyleElement' }
        & Pick<DataFullCurrentStyleElement, 'currentStyleId' | 'drivetrain' | 'modelYear' | 'bestModelName' | 'bestMakeName' | 'bestStyleName' | 'bestTrimName' | 'cylinders' | 'bodyStyle' | 'transmissionTypeId' | 'transmissionDesc' | 'drivetrainDesc'>
        & { exteriorColor: Array<(
          { __typename?: 'ExteriorColor' }
          & Pick<ExteriorColor, 'styleId' | 'installed' | 'colorCode' | 'colorName' | 'rgbValue'>
          & { genericColor: Array<(
            { __typename?: 'ExteriorColorElement' }
            & Pick<ExteriorColorElement, 'installed' | 'name' | 'primary'>
          )> }
        )>, interiorColor: Array<(
          { __typename?: 'InteriorColor' }
          & Pick<InteriorColor, 'genericColor' | 'styleId' | 'installed' | 'colorCode' | 'colorName' | 'rgbValue'>
        )> }
      ) }
    ) }
  ) }
);

export type DecodeNewVinQueryVariables = Exact<{
  vin: Scalars['String'];
  country: Countries;
}>;


export type DecodeNewVinQuery = (
  { __typename?: 'Query' }
  & { decodeVinNew: (
    { __typename?: 'DecodeVinNewMethod' }
    & Pick<DecodeVinNewMethod, 'operation'>
    & { result: (
      { __typename?: 'DecodeVinNewResult' }
      & Pick<DecodeVinNewResult, 'makeName' | 'modelName' | 'modelYear' | 'bestTrimName' | 'styles'>
      & { engines: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, trims: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, bodyStyles: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, tractions: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, transmissions: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, options: Array<(
        { __typename?: 'OptionsElement' }
        & Pick<OptionsElement, 'nameGroup'>
        & { subOptions: Array<(
          { __typename?: 'SubOptionsList' }
          & Pick<SubOptionsList, 'styleId' | 'optionKindId' | 'chromeCode' | 'description'>
          & { price: (
            { __typename?: 'SubOptionsPrice' }
            & Pick<SubOptionsPrice, 'invoiceMax' | 'invoiceMin' | 'msrpMax' | 'msrpMin' | 'unknown'>
          ) }
        )> }
      )>, exteriorColors: Array<(
        { __typename?: 'DefoltElementColorDecode' }
        & Pick<DefoltElementColorDecode, 'code' | 'label' | 'value' | 'rgbValue' | 'styles'>
      )>, interiorColors: Array<(
        { __typename?: 'DefoltElementColorDecode' }
        & Pick<DefoltElementColorDecode, 'code' | 'label' | 'value' | 'rgbValue' | 'styles'>
      )> }
    ) }
  ) }
);

export type DecodeBlackBookVinQueryVariables = Exact<{
  vin: Scalars['String'];
  country: Countries;
}>;


export type DecodeBlackBookVinQuery = (
  { __typename?: 'Query' }
  & { decodeVinBlackBook: (
    { __typename?: 'DecodeVinBlackBookMethod' }
    & Pick<DecodeVinBlackBookMethod, 'operation'>
    & { result: (
      { __typename?: 'DecodeVinBBResult' }
      & Pick<DecodeVinBbResult, 'makeName' | 'modelName' | 'modelYear' | 'bestTrimName' | 'styles'>
      & { engines: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, trims: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, bodyStyles: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, tractions: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, transmissions: Array<(
        { __typename?: 'DefoltElementDecode' }
        & Pick<DefoltElementDecode, 'label' | 'value' | 'styles'>
      )>, options: Array<(
        { __typename?: 'OptionsElementBB' }
        & Pick<OptionsElementBb, 'styles'>
        & { addDeductList: Array<Maybe<(
          { __typename?: 'AddDeductListItem' }
          & Pick<AddDeductListItem, 'auto' | 'avg' | 'clean' | 'name' | 'resid12' | 'resid24' | 'resid30' | 'resid36' | 'resid42' | 'resid48' | 'resid60' | 'resid72' | 'rough' | 'uoc' | 'xclean'>
        )>> }
      )>, exteriorColors: Array<(
        { __typename?: 'DefoltElementColorDecode' }
        & Pick<DefoltElementColorDecode, 'code' | 'label' | 'value' | 'rgbValue' | 'styles'>
      )>, interiorColors: Array<(
        { __typename?: 'DefoltElementColorDecode' }
        & Pick<DefoltElementColorDecode, 'code' | 'label' | 'value' | 'rgbValue' | 'styles'>
      )> }
    ) }
  ) }
);

export type NewBlackBookDecoderQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type NewBlackBookDecoderQuery = (
  { __typename?: 'Query' }
  & { newBlackBookDecoder: (
    { __typename?: 'newBlackBookDecoderMethod' }
    & { trims: Array<(
      { __typename?: 'TrimsNewBlackBook' }
      & Pick<TrimsNewBlackBook, 'value' | 'label'>
      & { styles: Array<(
        { __typename?: 'StylesNewBlackBook' }
        & Pick<StylesNewBlackBook, 'value' | 'label'>
      )> }
    )> }
  ) }
);

export type NewBlackBookDetailDecoderQueryVariables = Exact<{
  id: Scalars['String'];
  series?: Maybe<Scalars['String']>;
}>;


export type NewBlackBookDetailDecoderQuery = (
  { __typename?: 'Query' }
  & { newBlackBookDetailDecoder: (
    { __typename?: 'newBlackBookDetailDecoderMethod' }
    & Pick<NewBlackBookDetailDecoderMethod, 'makeName' | 'modelName' | 'modelYear' | 'engine' | 'engineCylinderCount' | 'traction' | 'transmission'>
    & { trims: Array<(
      { __typename?: 'TrimsNewBlackBook' }
      & Pick<TrimsNewBlackBook, 'value' | 'label'>
      & { styles: Array<(
        { __typename?: 'StylesNewBlackBook' }
        & Pick<StylesNewBlackBook, 'value' | 'label'>
      )> }
    )> }
  ) }
);

export type VinDetailsQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type VinDetailsQuery = (
  { __typename?: 'Query' }
  & { vinDetails: (
    { __typename?: 'vinDetailsMethod' }
    & Pick<VinDetailsMethod, 'makeName' | 'modelName' | 'modelYear'>
  ) }
);

export type AllFeatureForMyQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFeatureForMyQuery = (
  { __typename?: 'Query' }
  & { featureForMy: (
    { __typename?: 'FeatureForMyResponse' }
    & { result: Array<(
      { __typename?: 'TurnOnForMy' }
      & Pick<TurnOnForMy, '_id' | 'name' | 'activate' | 'isDefault'>
    )> }
  ) }
);

export type ListiUsersFeatureQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ListiUsersFeatureQuery = (
  { __typename?: 'Query' }
  & { listiUsersFeature: (
    { __typename?: 'ListiUsersFeatureResponse' }
    & { result: Array<(
      { __typename?: 'ListiUsersFeatureResult' }
      & Pick<ListiUsersFeatureResult, '_id' | 'id' | 'email' | 'password' | 'firstName' | 'lastName' | 'phone' | 'type' | 'googleId' | 'teams' | 'roles' | 'segmentation' | 'samAccountName' | 'deleted' | 'enableFeature' | 'isDefault'>
    )> }
  ) }
);

export type ListiFeatureQueryVariables = Exact<{ [key: string]: never; }>;


export type ListiFeatureQuery = (
  { __typename?: 'Query' }
  & { listiFeature: (
    { __typename?: 'ListiFeatureResponse' }
    & { result: Array<(
      { __typename?: 'ListiFeatureResult' }
      & Pick<ListiFeatureResult, '_id' | 'name' | 'activate' | 'description' | 'userIds' | 'created' | 'updated' | 'isDefault'>
    )> }
  ) }
);

export type FeatureQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FeatureQuery = (
  { __typename?: 'Query' }
  & { feature: (
    { __typename?: 'FeatureResponse' }
    & { result: (
      { __typename?: 'ListiFeatureResult' }
      & Pick<ListiFeatureResult, '_id' | 'name' | 'activate' | 'description' | 'userIds' | 'created' | 'updated'>
    ) }
  ) }
);

export type UpdateFeatureMutationVariables = Exact<{
  id: Scalars['String'];
  input: FeatureInput;
}>;


export type UpdateFeatureMutation = (
  { __typename?: 'Mutation' }
  & { featureUpdate: (
    { __typename?: 'FeatureInputResponse' }
    & { result: (
      { __typename?: 'ListiFeatureResult' }
      & Pick<ListiFeatureResult, '_id' | 'name' | 'activate' | 'description' | 'userIds'>
    ) }
  ) }
);

export type ListingsQueryVariables = Exact<{
  statuses: Scalars['String'];
  location: Scalars['String'];
  search: Scalars['String'];
  size: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ListingsQuery = (
  { __typename?: 'Query' }
  & { listings: (
    { __typename?: 'ListingsMethod' }
    & Pick<ListingsMethod, 'last'>
    & { result: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'odometer' | 'odometerMiles' | 'location' | 'status' | 'offeredValue' | 'appraisalValue' | 'referenceTitle' | 'referenceId' | 'vAutoId' | 'vin' | 'modelYear' | 'make' | 'model' | 'series' | 'custName' | 'custSmsNumber' | 'submitted' | 'completed' | 'started' | 'updated' | 'created' | 'owner' | 'ownerId' | 'completedBy' | 'completedById' | 'startedById'>
    )> }
  ) }
);

export type ListingQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ListingQuery = (
  { __typename?: 'Query' }
  & { listing: (
    { __typename?: 'ListingMethod' }
    & { result: (
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'segmentation' | 'formulaPrice' | 'completedById' | 'startedById' | 'modelYear' | 'model' | 'make' | 'series' | 'origin' | 'originSource' | 'odometer' | 'odometerMiles' | 'vin' | 'referenceId' | 'referenceLink' | 'referenceTitle' | 'askingValue' | 'disclosures' | 'custName' | 'custSmsNumber' | 'vAutoId' | 'preliminaryValue' | 'appraisalValue' | 'location' | 'status' | 'submitted' | 'completed' | 'started' | 'updated' | 'created' | 'owner' | 'ownerId' | 'completedBy' | 'offeredValue' | 'previouslyDamaged' | 'autoSmsSent'>
      & { followUps: Array<(
        { __typename?: 'FollowUp' }
        & Pick<FollowUp, 'created' | 'name' | 'text' | 'type' | 'user_id'>
      )>, comments: (
        { __typename?: 'ListingComments' }
        & Pick<ListingComments, 'client' | 'appraiser' | 'document'>
      ) }
    ) }
  ) }
);

export type UpdateListingMutationVariables = Exact<{
  input: UpdateListingInput;
  id: Scalars['Int'];
}>;


export type UpdateListingMutation = (
  { __typename?: 'Mutation' }
  & { updateListing: (
    { __typename?: 'UpdateListingMethod' }
    & { virtual: (
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'modelYear' | 'model' | 'make' | 'series' | 'origin' | 'odometer' | 'odometerMiles' | 'vin' | 'referenceId' | 'referenceLink' | 'referenceTitle' | 'askingValue' | 'disclosures' | 'custName' | 'custSmsNumber' | 'vAutoId' | 'preliminaryValue' | 'appraisalValue' | 'location' | 'status' | 'submitted' | 'completed' | 'started' | 'updated' | 'created' | 'owner' | 'ownerId' | 'completedBy' | 'completedById' | 'startedById' | 'offeredValue' | 'previouslyDamaged' | 'autoSmsSent'>
      & { followUps: Array<(
        { __typename?: 'FollowUp' }
        & Pick<FollowUp, 'created' | 'name' | 'text' | 'type' | 'user_id'>
      )>, comments: (
        { __typename?: 'ListingComments' }
        & Pick<ListingComments, 'client' | 'appraiser' | 'document'>
      ) }
    ) }
  ) }
);

export type UpdateListingStatusMutationVariables = Exact<{
  input: UpdateListingStatusInput;
  id: Scalars['Int'];
}>;


export type UpdateListingStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateListingStatus: (
    { __typename?: 'ListingStatusResult' }
    & Pick<ListingStatusResult, 'modified'>
  ) }
);

export type CreateListingMutationVariables = Exact<{
  input: ListingInput;
}>;


export type CreateListingMutation = (
  { __typename?: 'Mutation' }
  & { createListing: (
    { __typename?: 'ListingsCreateResult' }
    & { result: (
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'modelYear' | 'model' | 'make' | 'series' | 'origin' | 'odometer' | 'odometerMiles' | 'vin' | 'referenceId' | 'referenceLink' | 'referenceTitle' | 'askingValue' | 'disclosures' | 'custName' | 'custSmsNumber' | 'vAutoId' | 'preliminaryValue' | 'appraisalValue' | 'location' | 'status' | 'submitted' | 'completed' | 'started' | 'updated' | 'created' | 'owner' | 'ownerId' | 'completedBy' | 'completedById' | 'startedById' | 'offeredValue' | 'previouslyDamaged' | 'autoSmsSent'>
      & { followUps: Array<(
        { __typename?: 'FollowUp' }
        & Pick<FollowUp, 'created' | 'name' | 'text' | 'type' | 'user_id'>
      )>, comments: (
        { __typename?: 'ListingComments' }
        & Pick<ListingComments, 'client' | 'appraiser' | 'document'>
      ) }
    ) }
  ) }
);

export type ListingsCountQueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
}>;


export type ListingsCountQuery = (
  { __typename?: 'Query' }
  & { evaluationListingsCount: (
    { __typename?: 'ListingsCountMethod' }
    & Pick<ListingsCountMethod, 'result'>
  ), evaluatingListingsCount: (
    { __typename?: 'ListingsCountMethod' }
    & Pick<ListingsCountMethod, 'result'>
  ) }
);

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationMethod' }
    & { result: Array<(
      { __typename?: 'Location' }
      & Pick<Location, '_id' | 'address' | 'address1' | 'approbation' | 'brandingPath' | 'city' | 'country' | 'description' | 'estimationAutoSmsContent' | 'nomadGroup' | 'postalCode' | 'segmentation' | 'state'>
      & { periodPrice: Array<(
        { __typename?: 'PeriodPriceType' }
        & Pick<PeriodPriceType, 'percent' | 'startPrice' | 'endPrice'>
      )> }
    )> }
  ) }
);

export type UpdateLocationsMutationVariables = Exact<{
  id: Scalars['String'];
  input: LocationInput;
}>;


export type UpdateLocationsMutation = (
  { __typename?: 'Mutation' }
  & { updateLocations: (
    { __typename?: 'LocationMethod' }
    & { result: Array<(
      { __typename?: 'Location' }
      & Pick<Location, '_id' | 'address' | 'address1' | 'approbation' | 'brandingPath' | 'city' | 'country' | 'description' | 'estimationAutoSmsContent' | 'nomadGroup' | 'postalCode' | 'segmentation' | 'state'>
      & { periodPrice: Array<(
        { __typename?: 'PeriodPriceType' }
        & Pick<PeriodPriceType, 'percent' | 'startPrice' | 'endPrice'>
      )> }
    )> }
  ) }
);

export type ManheimDataByVinQueryVariables = Exact<{
  vin: Scalars['String'];
  odometer: Scalars['Int'];
  grade: Scalars['String'];
}>;


export type ManheimDataByVinQuery = (
  { __typename?: 'Query' }
  & { manheimDataByVin?: Maybe<(
    { __typename?: 'ManheimDataByVinMethod' }
    & { result: (
      { __typename?: 'ManheimDataByVinResult' }
      & Pick<ManheimDataByVinResult, 'href' | 'count'>
      & { items: Array<(
        { __typename?: 'ManheimVehicleType' }
        & Pick<ManheimVehicleType, 'href' | 'extendedCoverage' | 'averageOdometer' | 'averageGrade' | 'odometerUnits' | 'currency' | 'sampleSize' | 'requestedDate' | 'returnedDate' | 'bestMatch'>
        & { description: (
          { __typename?: 'ManheimDescriptionType' }
          & Pick<ManheimDescriptionType, 'year' | 'make' | 'model' | 'trim' | 'subSeries'>
        ), wholesale: (
          { __typename?: 'WholesaleType' }
          & Pick<WholesaleType, 'above' | 'average' | 'below'>
        ), adjustedPricing: (
          { __typename?: 'AdjustedPricingType' }
          & { wholesale: (
            { __typename?: 'ManheimItemWholesaleType' }
            & Pick<ManheimItemWholesaleType, 'above' | 'average' | 'below'>
          ), adjustedBy: (
            { __typename?: 'AdjustedByType' }
            & Pick<AdjustedByType, 'Grade' | 'Odometer'>
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type NotificationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationCountQuery = (
  { __typename?: 'Query' }
  & { notificationCount: (
    { __typename?: 'NotificationCountMethod' }
    & Pick<NotificationCountMethod, 'result'>
  ) }
);

export type NotificationListQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationListQuery = (
  { __typename?: 'Query' }
  & { notificationList: (
    { __typename?: 'NotificationListMethod' }
    & { result: Array<(
      { __typename?: 'NotificationListItem' }
      & Pick<NotificationListItem, '_id' | 'id' | 'created' | 'group' | 'text' | 'read_by' | 'received_by'>
      & { source: (
        { __typename?: 'NotificationListItemSource' }
        & Pick<NotificationListItemSource, 'type' | 'id'>
      ) }
    )> }
  ) }
);

export type NotificationNameQueryVariables = Exact<{
  id: Scalars['Int'];
  source: Scalars['String'];
}>;


export type NotificationNameQuery = (
  { __typename?: 'Query' }
  & { notificationTitle: (
    { __typename?: 'NotificationNameMethod' }
    & Pick<NotificationNameMethod, 'result'>
  ) }
);

export type NotificationReadMutationVariables = Exact<{
  input?: Maybe<NotificationReadInput>;
}>;


export type NotificationReadMutation = (
  { __typename?: 'Mutation' }
  & { notificationRead: (
    { __typename?: 'NotificationReadMethod' }
    & Pick<NotificationReadMethod, 'result'>
  ) }
);

export type UserByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserByIdQuery = (
  { __typename?: 'Query' }
  & { userById: (
    { __typename?: 'UserByIdMethod' }
    & { result: (
      { __typename?: 'UserById' }
      & Pick<UserById, 'id' | 'firstName' | 'lastName' | 'phoneNumber'>
    ) }
  ) }
);

export type VinQueryVariables = Exact<{
  vin: Scalars['String'];
  lang?: Maybe<Languages>;
  country: Countries;
}>;


export type VinQuery = (
  { __typename?: 'Query' }
  & { vin: (
    { __typename?: 'VinMethod' }
    & { result: (
      { __typename?: 'Vin' }
      & Pick<Vin, 'bodyType' | 'engineCylinderCount' | 'make' | 'model' | 'modelYear' | 'msrp' | 'series' | 'transmissionType'>
      & { style: Array<(
        { __typename?: 'VinStyle' }
        & Pick<VinStyle, 'id' | 'modelYear' | 'name' | 'nameWoTrim' | 'trim' | 'mfrModelCode' | 'fleetOnly' | 'modelFleet' | 'passDoors' | 'altBodyType' | 'drivetrain'>
        & { division: (
          { __typename?: 'VinStyleDivision' }
          & Pick<VinStyleDivision, 'id' | 'value'>
        ), subdivision: (
          { __typename?: 'VinStyleSubdivision' }
          & Pick<VinStyleSubdivision, 'id' | 'value'>
        ), model: (
          { __typename?: 'VinStyleModel' }
          & Pick<VinStyleModel, 'id' | 'value'>
        ), basePrice: (
          { __typename?: 'VinStyleBasePrice' }
          & Pick<VinStyleBasePrice, 'unknown' | 'msrp' | 'destination'>
        ), bodyType?: Maybe<(
          { __typename?: 'VinStyleBodyType' }
          & Pick<VinStyleBodyType, 'id' | 'value' | 'primary'>
        )>, marketClass?: Maybe<(
          { __typename?: 'VinStyleMarketClass' }
          & Pick<VinStyleMarketClass, 'id' | 'value' | 'primary'>
        )> }
      )> }
    ) }
  ) }
);

export type PackageQueryVariables = Exact<{
  styleId: Scalars['String'];
  lang?: Maybe<Languages>;
  country: Countries;
}>;


export type PackageQuery = (
  { __typename?: 'Query' }
  & { package: (
    { __typename?: 'PackageMethod' }
    & { result: (
      { __typename?: 'Package' }
      & { style: (
        { __typename?: 'PackageStyle' }
        & Pick<PackageStyle, 'styleId' | 'trimName' | 'modelYear' | 'divisionName' | 'consumerFriendlyBodyType' | 'consumerFriendlyDrivetrain' | 'consumerFriendlyStyleName' | 'consumerFriendlyModelName'>
      ) }
    ) }
  ) }
);

export type LicenseplateQueryVariables = Exact<{
  plate: Scalars['String'];
  state: Scalars['String'];
}>;


export type LicenseplateQuery = (
  { __typename?: 'Query' }
  & { licenseplate?: Maybe<(
    { __typename?: 'LicenseplateMethod' }
    & { result: (
      { __typename?: 'Licenseplate' }
      & Pick<Licenseplate, 'vin'>
    ) }
  )> }
);

export type MessaagesQueryVariables = Exact<{
  channel: Scalars['String'];
}>;


export type MessaagesQuery = (
  { __typename?: 'Query' }
  & { messaages: (
    { __typename?: 'MessaagesMethod' }
    & { result: Array<(
      { __typename?: 'Messaage' }
      & Pick<Messaage, '_id' | 'channel' | 'created' | 'read_by' | 'text' | 'type' | 'user_id' | 'user_name'>
    )> }
  ) }
);

export type DashStatisticsPriceQueryVariables = Exact<{
  ids: Scalars['String'];
  select: Scalars['String'];
}>;


export type DashStatisticsPriceQuery = (
  { __typename?: 'Query' }
  & { dashStatisticsPrice: (
    { __typename?: 'DashStatisticsListMethod' }
    & { result: Array<(
      { __typename?: 'VehicleListDashStatistics' }
      & Pick<VehicleListDashStatistics, 'id' | 'vin' | 'location' | 'created' | 'ownerId' | 'submitted' | 'completedById' | 'completed' | 'appraisalValue' | 'offeredValue' | 'odometer' | 'odometerMiles' | 'status' | 'origin' | 'paveSessionStatus' | 'modelYear' | 'make' | 'model' | 'series' | 'traction' | 'transmissionType' | 'assessingStarted' | 'assessedById' | 'eraSalesCode' | 'isPurchased'>
      & { photos: Array<(
        { __typename?: 'AppraisalPhoto' }
        & Pick<AppraisalPhoto, 'id' | 'src' | 'zone'>
      )> }
    )> }
  ) }
);

export type SalesManagerStatisticsQueryVariables = Exact<{
  select: Scalars['String'];
}>;


export type SalesManagerStatisticsQuery = (
  { __typename?: 'Query' }
  & { dashStatisticsBoard: (
    { __typename?: 'DashStatisticsMethod' }
    & { result: Array<(
      { __typename?: 'DashStatisticsType' }
      & Pick<DashStatisticsType, 'purchased' | 'purchasedIds' | 'appraisalIds' | 'priceToMarket' | 'costToMarket' | 'average' | 'count'>
      & { user: (
        { __typename?: 'UserStaisticDataType' }
        & Pick<UserStaisticDataType, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
      ) }
    )> }
  ) }
);

export type AppraisalPriceStatisticsQueryVariables = Exact<{
  select: Scalars['String'];
}>;


export type AppraisalPriceStatisticsQuery = (
  { __typename?: 'Query' }
  & { dashStatisticsBoard: (
    { __typename?: 'DashStatisticsMethod' }
    & { result: Array<(
      { __typename?: 'DashStatisticsType' }
      & Pick<DashStatisticsType, 'purchased' | 'purchasedIds' | 'appraisalIds' | 'priceToMarket' | 'costToMarket' | 'average' | 'count'>
      & { user: (
        { __typename?: 'UserStaisticDataType' }
        & Pick<UserStaisticDataType, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
      ) }
    )> }
  ) }
);

export type DashStatisticsMutationVariables = Exact<{
  input?: Maybe<FilterQueryInput>;
}>;


export type DashStatisticsMutation = (
  { __typename?: 'Mutation' }
  & { dashStatistics: (
    { __typename?: 'DashStatisticsMethod' }
    & { result: Array<(
      { __typename?: 'DashStatisticsType' }
      & Pick<DashStatisticsType, 'purchased' | 'purchasedIds' | 'appraisalIds' | 'priceToMarket' | 'costToMarket' | 'average' | 'count'>
      & { user: (
        { __typename?: 'UserStaisticDataType' }
        & Pick<UserStaisticDataType, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
      ) }
    )> }
  ) }
);

export type SetSubscriptionMutationVariables = Exact<{
  input: SetSubscriptionInput;
}>;


export type SetSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { setSubscription: (
    { __typename?: 'SetSubscriptionResult' }
    & { result: Array<(
      { __typename?: 'ItemSubscriptionResult' }
      & Pick<ItemSubscriptionResult, 'active' | 'emitter' | 'event' | 'medium' | 'options' | 'subscriber'>
    )> }
  ) }
);

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { getSubscriptions: (
    { __typename?: 'SubscriptionMethod' }
    & { result: Array<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'activeMediums' | 'emitter' | 'event' | 'label' | 'name' | 'user_rules' | 'user_types'>
    )> }
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'country' | 'onlineAt'>
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
  ) }
);

export type UserPublicQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserPublicQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
  ) }
);

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UserInput;
}>;


export type UserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'dealership' | 'roles' | 'country' | 'onlineAt'>
  ) }
);

export type RoelsQueryVariables = Exact<{ [key: string]: never; }>;


export type RoelsQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Roles' }
    & Pick<Roles, 'id' | 'slug' | 'name'>
  )> }
);

export type DealershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type DealershipsQuery = (
  { __typename?: 'Query' }
  & { dealerships: Array<(
    { __typename?: 'Dealership' }
    & Pick<Dealership, 'id' | 'slug' | 'name' | 'country'>
  )> }
);


export const GetListForBedVinDecodeDocument = gql`
    query GetListForBedVinDecode($date: String!) {
  getListForBedStartComplete(date: $date) @rest(type: "GetListForBedStartCompleteMethod", path: "user/analytic-count-by-user-event?eventNames=bad_vin_decode&date={args.date}", method: "GET", endpoint: "backend") {
    result {
      user_id
      problem_with_start_vehicle
      problem_with_complete_vehicle
    }
  }
}
    `;

/**
 * __useGetListForBedVinDecodeQuery__
 *
 * To run a query within a React component, call `useGetListForBedVinDecodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListForBedVinDecodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListForBedVinDecodeQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetListForBedVinDecodeQuery(baseOptions: Apollo.QueryHookOptions<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables> & ({ variables: GetListForBedVinDecodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>(GetListForBedVinDecodeDocument, options);
      }
export function useGetListForBedVinDecodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>(GetListForBedVinDecodeDocument, options);
        }
export function useGetListForBedVinDecodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>(GetListForBedVinDecodeDocument, options);
        }
export type GetListForBedVinDecodeQueryHookResult = ReturnType<typeof useGetListForBedVinDecodeQuery>;
export type GetListForBedVinDecodeLazyQueryHookResult = ReturnType<typeof useGetListForBedVinDecodeLazyQuery>;
export type GetListForBedVinDecodeSuspenseQueryHookResult = ReturnType<typeof useGetListForBedVinDecodeSuspenseQuery>;
export type GetListForBedVinDecodeQueryResult = Apollo.QueryResult<GetListForBedVinDecodeQuery, GetListForBedVinDecodeQueryVariables>;
export const GetListForBedStartCompleteDocument = gql`
    query GetListForBedStartComplete($date: String!) {
  getListForBedStartComplete(date: $date) @rest(type: "GetListForBedStartCompleteMethod", path: "/private/analytics/users-counts-by-events?eventNames=problem_with_start_vehicle,problem_with_complete_vehicle&date={args.date}", method: "GET", endpoint: "core-service") {
    result {
      user_id
      problem_with_start_vehicle
      problem_with_complete_vehicle
    }
  }
}
    `;

/**
 * __useGetListForBedStartCompleteQuery__
 *
 * To run a query within a React component, call `useGetListForBedStartCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListForBedStartCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListForBedStartCompleteQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetListForBedStartCompleteQuery(baseOptions: Apollo.QueryHookOptions<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables> & ({ variables: GetListForBedStartCompleteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>(GetListForBedStartCompleteDocument, options);
      }
export function useGetListForBedStartCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>(GetListForBedStartCompleteDocument, options);
        }
export function useGetListForBedStartCompleteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>(GetListForBedStartCompleteDocument, options);
        }
export type GetListForBedStartCompleteQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteQuery>;
export type GetListForBedStartCompleteLazyQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteLazyQuery>;
export type GetListForBedStartCompleteSuspenseQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteSuspenseQuery>;
export type GetListForBedStartCompleteQueryResult = Apollo.QueryResult<GetListForBedStartCompleteQuery, GetListForBedStartCompleteQueryVariables>;
export const GetListForBedStartCompleteOneDocument = gql`
    query GetListForBedStartCompleteOne($date: String!, $email: String!) {
  getListForBedStartCompleteOne(date: $date, email: $email) @rest(type: "GetListForBedStartCompleteOneMethod", path: "/private/analytics/events-by-email?eventNames=problem_with_start_vehicle,problem_with_complete_vehicle&date={args.date}&email={args.email}", method: "GET", endpoint: "core-service") {
    result {
      event_params {
        key
        value {
          string_value
          int_value
          float_value
          double_value
        }
      }
      geo {
        continent
        country
        region
        city
        sub_continent
        metro
      }
    }
  }
}
    `;

/**
 * __useGetListForBedStartCompleteOneQuery__
 *
 * To run a query within a React component, call `useGetListForBedStartCompleteOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListForBedStartCompleteOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListForBedStartCompleteOneQuery({
 *   variables: {
 *      date: // value for 'date'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetListForBedStartCompleteOneQuery(baseOptions: Apollo.QueryHookOptions<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables> & ({ variables: GetListForBedStartCompleteOneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>(GetListForBedStartCompleteOneDocument, options);
      }
export function useGetListForBedStartCompleteOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>(GetListForBedStartCompleteOneDocument, options);
        }
export function useGetListForBedStartCompleteOneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>(GetListForBedStartCompleteOneDocument, options);
        }
export type GetListForBedStartCompleteOneQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteOneQuery>;
export type GetListForBedStartCompleteOneLazyQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteOneLazyQuery>;
export type GetListForBedStartCompleteOneSuspenseQueryHookResult = ReturnType<typeof useGetListForBedStartCompleteOneSuspenseQuery>;
export type GetListForBedStartCompleteOneQueryResult = Apollo.QueryResult<GetListForBedStartCompleteOneQuery, GetListForBedStartCompleteOneQueryVariables>;
export const GetAnalyticDocument = gql`
    query GetAnalytic($eventName: String!, $date: String!) {
  analytic(eventName: $eventName, date: $date) @rest(type: "AnalitycMethod", path: "/private/analytics/events-by-email?eventNames={args.eventName}&date={args.date}", method: "GET", endpoint: "core-service") {
    result {
      event_date
      event_timestamp
      event_name
      event_params {
        key
        value {
          string_value
          int_value
          float_value
          double_value
        }
      }
      event_previous_timestamp
      event_value_in_usd
      event_bundle_sequence_id
      event_server_timestamp_offset
      user_id
      user_pseudo_id
      privacy_info {
        analytics_storage
        ads_storage
        uses_transient_token
      }
      user_properties {
        key
        value {
          string_value
          int_value
          float_value
          double_value
          set_timestamp_micros
        }
      }
      user_first_touch_timestamp
      user_ltv {
        revenue
        currency
      }
      device {
        category
        mobile_brand_name
        mobile_model_name
        mobile_marketing_name
        mobile_os_hardware_model
        operating_system
        operating_system_version
        vendor_id
        advertising_id
        language
        is_limited_ad_tracking
        time_zone_offset_seconds
        browser
        browser_version
        web_info {
          browser
          browser_version
          hostname
        }
      }
      geo {
        continent
        country
        region
        city
        sub_continent
        metro
      }
      app_info
      traffic_source {
        name
        medium
        source
      }
      stream_id
      platform
      event_dimensions
      ecommerce
      items
    }
  }
}
    `;

/**
 * __useGetAnalyticQuery__
 *
 * To run a query within a React component, call `useGetAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticQuery({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAnalyticQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyticQuery, GetAnalyticQueryVariables> & ({ variables: GetAnalyticQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyticQuery, GetAnalyticQueryVariables>(GetAnalyticDocument, options);
      }
export function useGetAnalyticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticQuery, GetAnalyticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyticQuery, GetAnalyticQueryVariables>(GetAnalyticDocument, options);
        }
export function useGetAnalyticSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAnalyticQuery, GetAnalyticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAnalyticQuery, GetAnalyticQueryVariables>(GetAnalyticDocument, options);
        }
export type GetAnalyticQueryHookResult = ReturnType<typeof useGetAnalyticQuery>;
export type GetAnalyticLazyQueryHookResult = ReturnType<typeof useGetAnalyticLazyQuery>;
export type GetAnalyticSuspenseQueryHookResult = ReturnType<typeof useGetAnalyticSuspenseQuery>;
export type GetAnalyticQueryResult = Apollo.QueryResult<GetAnalyticQuery, GetAnalyticQueryVariables>;
export const GetUserListAnalyticDocument = gql`
    query GetUserListAnalytic($eventName: String!, $date: String!) {
  listUsersAnalytic(eventName: $eventName, date: $date) @rest(type: "UserListAnalitycMethod", path: "/private/analytics/users-by-event?eventName={args.eventName}&date={args.date}", method: "GET", endpoint: "core-service") {
    result {
      user_id
    }
  }
}
    `;

/**
 * __useGetUserListAnalyticQuery__
 *
 * To run a query within a React component, call `useGetUserListAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListAnalyticQuery({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetUserListAnalyticQuery(baseOptions: Apollo.QueryHookOptions<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables> & ({ variables: GetUserListAnalyticQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>(GetUserListAnalyticDocument, options);
      }
export function useGetUserListAnalyticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>(GetUserListAnalyticDocument, options);
        }
export function useGetUserListAnalyticSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>(GetUserListAnalyticDocument, options);
        }
export type GetUserListAnalyticQueryHookResult = ReturnType<typeof useGetUserListAnalyticQuery>;
export type GetUserListAnalyticLazyQueryHookResult = ReturnType<typeof useGetUserListAnalyticLazyQuery>;
export type GetUserListAnalyticSuspenseQueryHookResult = ReturnType<typeof useGetUserListAnalyticSuspenseQuery>;
export type GetUserListAnalyticQueryResult = Apollo.QueryResult<GetUserListAnalyticQuery, GetUserListAnalyticQueryVariables>;
export const InfoForUserAnalitycDocument = gql`
    query InfoForUserAnalityc($eventName: String!, $date: String!, $email: String!) {
  infoForUserAnalityc(eventName: $eventName, date: $date, email: $email) @rest(type: "InfoForUserAnalitycMethod", path: "/private/analytics/events-by-email?eventNames={args.eventName}&date={args.date}&email={args.email}", method: "GET", endpoint: "core-service") {
    result {
      event_date
      event_timestamp
      event_name
      event_params {
        key
        value {
          string_value
          int_value
          float_value
          double_value
        }
      }
      event_previous_timestamp
      event_value_in_usd
      event_bundle_sequence_id
      event_server_timestamp_offset
      user_id
      user_pseudo_id
      privacy_info {
        analytics_storage
        ads_storage
        uses_transient_token
      }
      user_properties {
        key
        value {
          string_value
          int_value
          float_value
          double_value
          set_timestamp_micros
        }
      }
      user_first_touch_timestamp
      user_ltv {
        revenue
        currency
      }
      device {
        category
        mobile_brand_name
        mobile_model_name
        mobile_marketing_name
        mobile_os_hardware_model
        operating_system
        operating_system_version
        vendor_id
        advertising_id
        language
        is_limited_ad_tracking
        time_zone_offset_seconds
        browser
        browser_version
        web_info {
          browser
          browser_version
          hostname
        }
      }
      geo {
        continent
        country
        region
        city
        sub_continent
        metro
      }
      app_info
      traffic_source {
        name
        medium
        source
      }
      stream_id
      platform
      event_dimensions
      ecommerce
      items
    }
  }
}
    `;

/**
 * __useInfoForUserAnalitycQuery__
 *
 * To run a query within a React component, call `useInfoForUserAnalitycQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoForUserAnalitycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoForUserAnalitycQuery({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      date: // value for 'date'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInfoForUserAnalitycQuery(baseOptions: Apollo.QueryHookOptions<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables> & ({ variables: InfoForUserAnalitycQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>(InfoForUserAnalitycDocument, options);
      }
export function useInfoForUserAnalitycLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>(InfoForUserAnalitycDocument, options);
        }
export function useInfoForUserAnalitycSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>(InfoForUserAnalitycDocument, options);
        }
export type InfoForUserAnalitycQueryHookResult = ReturnType<typeof useInfoForUserAnalitycQuery>;
export type InfoForUserAnalitycLazyQueryHookResult = ReturnType<typeof useInfoForUserAnalitycLazyQuery>;
export type InfoForUserAnalitycSuspenseQueryHookResult = ReturnType<typeof useInfoForUserAnalitycSuspenseQuery>;
export type InfoForUserAnalitycQueryResult = Apollo.QueryResult<InfoForUserAnalitycQuery, InfoForUserAnalitycQueryVariables>;
export const GetUserEvaluationListForAnalyticsDocument = gql`
    query getUserEvaluationListForAnalytics($location: String!, $limit: Int!, $select: String!, $statuses: String!, $startDate: String!, $endDate: String!) {
  getUserEvaluationListForAnalytics(
    location: $location
    limit: $limit
    select: $select
    statuses: $statuses
    startDate: $startDate
    endDate: $endDate
  ) @rest(type: "AppriselVehicleForAnalyticsMethod", path: "/private/analytics/statistics?location={args.location}&limit={args.limit}&select={args.select}&statuses={args.statuses}&from={args.startDate}&to={args.endDate}", method: "GET", endpoint: "core-service") {
    last
    result {
      id
      _id
      created
      completed
      vin
      appraisalValue
      offeredValue
      formulaPrice
      purchased
      inventoryDate
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
    }
  }
}
    `;

/**
 * __useGetUserEvaluationListForAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetUserEvaluationListForAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEvaluationListForAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEvaluationListForAnalyticsQuery({
 *   variables: {
 *      location: // value for 'location'
 *      limit: // value for 'limit'
 *      select: // value for 'select'
 *      statuses: // value for 'statuses'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetUserEvaluationListForAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables> & ({ variables: GetUserEvaluationListForAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>(GetUserEvaluationListForAnalyticsDocument, options);
      }
export function useGetUserEvaluationListForAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>(GetUserEvaluationListForAnalyticsDocument, options);
        }
export function useGetUserEvaluationListForAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>(GetUserEvaluationListForAnalyticsDocument, options);
        }
export type GetUserEvaluationListForAnalyticsQueryHookResult = ReturnType<typeof useGetUserEvaluationListForAnalyticsQuery>;
export type GetUserEvaluationListForAnalyticsLazyQueryHookResult = ReturnType<typeof useGetUserEvaluationListForAnalyticsLazyQuery>;
export type GetUserEvaluationListForAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetUserEvaluationListForAnalyticsSuspenseQuery>;
export type GetUserEvaluationListForAnalyticsQueryResult = Apollo.QueryResult<GetUserEvaluationListForAnalyticsQuery, GetUserEvaluationListForAnalyticsQueryVariables>;
export const GetAppraisalConditionsDocument = gql`
    query GetAppraisalConditions($id: String!) {
  getAppraisalConditions(id: $id) @rest(type: "AppraisalConditionsResponse", path: "appraisal-conditions/{args.id}", method: "GET", endpoint: "backend") {
    operation
    result {
      id
      created
      updated
      location
      source
      status
      type
      origin
      vehicleType
      vin
      stock
      saleType
      modelYear
      make
      model
      series
      odometer
      odometerMiles
      bodyType
      engineCylinderCount
      engineCylinderLabel
      transmissionType
      traction
      licenseState
      licensePlate
      owner
      ownerId
      closer
      closerId
      rep
      repId
      offerPrinted
      submitted
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      salesperson
      appraiser
      leaseReturn
      custFirstName
      custLastName
      custHomePhone
      custEmail
      custAddress
      custCity
      custState
      custPostalCode
      custDriverLicense
      custAmountOwed
      vAutoId
      appraisalValue
      offeredValue
      offerAsSeen
      conditions {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      annotations
      disclosures
      disclosuresWork {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      photos
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      parkingZoneSegmentation
      hasConditionReport
      conditionReportStatus
      isConditionReportComplete
      conditionReportLink
      inspectionReportLinkOtherService
      inspectionReportLink
      styleId
      exteriorColor
      interiorColor
    }
  }
}
    `;

/**
 * __useGetAppraisalConditionsQuery__
 *
 * To run a query within a React component, call `useGetAppraisalConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalConditionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppraisalConditionsQuery(baseOptions: Apollo.QueryHookOptions<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables> & ({ variables: GetAppraisalConditionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>(GetAppraisalConditionsDocument, options);
      }
export function useGetAppraisalConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>(GetAppraisalConditionsDocument, options);
        }
export function useGetAppraisalConditionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>(GetAppraisalConditionsDocument, options);
        }
export type GetAppraisalConditionsQueryHookResult = ReturnType<typeof useGetAppraisalConditionsQuery>;
export type GetAppraisalConditionsLazyQueryHookResult = ReturnType<typeof useGetAppraisalConditionsLazyQuery>;
export type GetAppraisalConditionsSuspenseQueryHookResult = ReturnType<typeof useGetAppraisalConditionsSuspenseQuery>;
export type GetAppraisalConditionsQueryResult = Apollo.QueryResult<GetAppraisalConditionsQuery, GetAppraisalConditionsQueryVariables>;
export const CreateAppraisalConditionsDocument = gql`
    mutation CreateAppraisalConditions($input: AppraisalConditionsInput!) {
  createAppraisalConditions(input: $input) @rest(type: "AppraisalConditionsResponse", path: "appraisal-conditions", method: "POST", endpoint: "backend") {
    operation
    result {
      id
      created
      updated
      location
      source
      status
      type
      origin
      vehicleType
      vin
      stock
      saleType
      modelYear
      make
      model
      series
      odometer
      odometerMiles
      bodyType
      engineCylinderCount
      engineCylinderLabel
      transmissionType
      traction
      licenseState
      licensePlate
      owner
      ownerId
      closer
      closerId
      rep
      repId
      offerPrinted
      submitted
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      salesperson
      appraiser
      leaseReturn
      custFirstName
      custLastName
      custHomePhone
      custEmail
      custAddress
      custCity
      custState
      custPostalCode
      custDriverLicense
      custAmountOwed
      vAutoId
      appraisalValue
      offeredValue
      offerAsSeen
      conditions {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      annotations
      disclosures
      disclosuresWork {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      photos
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      parkingZoneSegmentation
      hasConditionReport
      conditionReportStatus
      isConditionReportComplete
      conditionReportLink
      inspectionReportLinkOtherService
      inspectionReportLink
    }
  }
}
    `;
export type CreateAppraisalConditionsMutationFn = Apollo.MutationFunction<CreateAppraisalConditionsMutation, CreateAppraisalConditionsMutationVariables>;

/**
 * __useCreateAppraisalConditionsMutation__
 *
 * To run a mutation, you first call `useCreateAppraisalConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppraisalConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppraisalConditionsMutation, { data, loading, error }] = useCreateAppraisalConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppraisalConditionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppraisalConditionsMutation, CreateAppraisalConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppraisalConditionsMutation, CreateAppraisalConditionsMutationVariables>(CreateAppraisalConditionsDocument, options);
      }
export type CreateAppraisalConditionsMutationHookResult = ReturnType<typeof useCreateAppraisalConditionsMutation>;
export type CreateAppraisalConditionsMutationResult = Apollo.MutationResult<CreateAppraisalConditionsMutation>;
export type CreateAppraisalConditionsMutationOptions = Apollo.BaseMutationOptions<CreateAppraisalConditionsMutation, CreateAppraisalConditionsMutationVariables>;
export const UpdateAppraisalConditionsDocument = gql`
    mutation UpdateAppraisalConditions($id: Int!, $input: AppraisalConditionsInput!) {
  updateAppraisalConditions(id: $id, input: $input) @rest(type: "AppraisalConditionsResponse", path: "appraisal-conditions/{args.id}", method: "PATCH", endpoint: "backend") {
    operation
    result {
      id
      created
      updated
      location
      source
      status
      type
      origin
      vehicleType
      vin
      stock
      saleType
      modelYear
      make
      model
      series
      odometer
      odometerMiles
      bodyType
      engineCylinderCount
      engineCylinderLabel
      transmissionType
      traction
      licenseState
      licensePlate
      owner
      ownerId
      closer
      closerId
      rep
      repId
      offerPrinted
      submitted
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      salesperson
      appraiser
      leaseReturn
      custFirstName
      custLastName
      custHomePhone
      custEmail
      custAddress
      custCity
      custState
      custPostalCode
      custDriverLicense
      custAmountOwed
      vAutoId
      appraisalValue
      offeredValue
      offerAsSeen
      conditions {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      annotations
      disclosures
      disclosuresWork {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      photos
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      parkingZoneSegmentation
      hasConditionReport
      conditionReportStatus
      isConditionReportComplete
      conditionReportLink
      inspectionReportLinkOtherService
      inspectionReportLink
    }
  }
}
    `;
export type UpdateAppraisalConditionsMutationFn = Apollo.MutationFunction<UpdateAppraisalConditionsMutation, UpdateAppraisalConditionsMutationVariables>;

/**
 * __useUpdateAppraisalConditionsMutation__
 *
 * To run a mutation, you first call `useUpdateAppraisalConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppraisalConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppraisalConditionsMutation, { data, loading, error }] = useUpdateAppraisalConditionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppraisalConditionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppraisalConditionsMutation, UpdateAppraisalConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppraisalConditionsMutation, UpdateAppraisalConditionsMutationVariables>(UpdateAppraisalConditionsDocument, options);
      }
export type UpdateAppraisalConditionsMutationHookResult = ReturnType<typeof useUpdateAppraisalConditionsMutation>;
export type UpdateAppraisalConditionsMutationResult = Apollo.MutationResult<UpdateAppraisalConditionsMutation>;
export type UpdateAppraisalConditionsMutationOptions = Apollo.BaseMutationOptions<UpdateAppraisalConditionsMutation, UpdateAppraisalConditionsMutationVariables>;
export const AppraisalsConditionDocument = gql`
    query AppraisalsCondition($statuses: String!, $withInventory: Boolean!, $location: String!, $search: String!, $page: Int!, $size: Int!) {
  appraisalsCondition(
    statuses: $statuses
    location: $location
    withInventory: $withInventory
    search: $search
    page: $page
    size: $size
  ) @rest(type: "AppraisalsConditionMethod", path: "appraisal-conditions?statuses={args.statuses}&location={args.location}&inventory={args.withInventory}&search={args.search}&page={args.page}&limit={args.size}", method: "GET", endpoint: "backend") {
    last
    result {
      id
      created
      updated
      location
      source
      status
      type
      origin
      vehicleType
      vin
      stock
      saleType
      modelYear
      make
      model
      series
      odometer
      odometerMiles
      bodyType
      engineCylinderCount
      engineCylinderLabel
      transmissionType
      traction
      licenseState
      licensePlate
      owner
      ownerId
      closer
      closerId
      rep
      repId
      offerPrinted
      submitted
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      salesperson
      appraiser
      leaseReturn
      custFirstName
      custLastName
      custHomePhone
      custEmail
      custAddress
      custCity
      custState
      custPostalCode
      custDriverLicense
      custAmountOwed
      vAutoId
      appraisalValue
      offeredValue
      offerAsSeen
      conditions {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      annotations
      disclosures
      disclosuresWork {
        name
        label
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
      }
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      photos
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      parkingZoneSegmentation
      hasConditionReport
      conditionReportStatus
      isConditionReportComplete
      conditionReportLink
      inspectionReportLinkOtherService
      inspectionReportLink
    }
  }
}
    `;

/**
 * __useAppraisalsConditionQuery__
 *
 * To run a query within a React component, call `useAppraisalsConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsConditionQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      withInventory: // value for 'withInventory'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useAppraisalsConditionQuery(baseOptions: Apollo.QueryHookOptions<AppraisalsConditionQuery, AppraisalsConditionQueryVariables> & ({ variables: AppraisalsConditionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>(AppraisalsConditionDocument, options);
      }
export function useAppraisalsConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>(AppraisalsConditionDocument, options);
        }
export function useAppraisalsConditionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>(AppraisalsConditionDocument, options);
        }
export type AppraisalsConditionQueryHookResult = ReturnType<typeof useAppraisalsConditionQuery>;
export type AppraisalsConditionLazyQueryHookResult = ReturnType<typeof useAppraisalsConditionLazyQuery>;
export type AppraisalsConditionSuspenseQueryHookResult = ReturnType<typeof useAppraisalsConditionSuspenseQuery>;
export type AppraisalsConditionQueryResult = Apollo.QueryResult<AppraisalsConditionQuery, AppraisalsConditionQueryVariables>;
export const UpdateStatusVehicleDocument = gql`
    mutation UpdateStatusVehicle($id: String!, $input: UpdateStatusInput!) {
  updateStatus(id: $id, input: $input) @rest(type: "UpdateStatusResponse", path: "/private/physical-evaluation/status/{args.id}", method: "PATCH", endpoint: "core-service") {
    modified
  }
}
    `;
export type UpdateStatusVehicleMutationFn = Apollo.MutationFunction<UpdateStatusVehicleMutation, UpdateStatusVehicleMutationVariables>;

/**
 * __useUpdateStatusVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateStatusVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusVehicleMutation, { data, loading, error }] = useUpdateStatusVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStatusVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStatusVehicleMutation, UpdateStatusVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStatusVehicleMutation, UpdateStatusVehicleMutationVariables>(UpdateStatusVehicleDocument, options);
      }
export type UpdateStatusVehicleMutationHookResult = ReturnType<typeof useUpdateStatusVehicleMutation>;
export type UpdateStatusVehicleMutationResult = Apollo.MutationResult<UpdateStatusVehicleMutation>;
export type UpdateStatusVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateStatusVehicleMutation, UpdateStatusVehicleMutationVariables>;
export const GetMonkaiTokenDocument = gql`
    query GetMonkaiToken($id: String!) {
  getMonkaiToken(input: $id) @rest(type: "GetMonkaiInspectionResponse", path: "appraisals/token-monkai?vehId={args.id}", method: "GET", endpoint: "backend") {
    result
  }
}
    `;

/**
 * __useGetMonkaiTokenQuery__
 *
 * To run a query within a React component, call `useGetMonkaiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonkaiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonkaiTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMonkaiTokenQuery(baseOptions: Apollo.QueryHookOptions<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables> & ({ variables: GetMonkaiTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>(GetMonkaiTokenDocument, options);
      }
export function useGetMonkaiTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>(GetMonkaiTokenDocument, options);
        }
export function useGetMonkaiTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>(GetMonkaiTokenDocument, options);
        }
export type GetMonkaiTokenQueryHookResult = ReturnType<typeof useGetMonkaiTokenQuery>;
export type GetMonkaiTokenLazyQueryHookResult = ReturnType<typeof useGetMonkaiTokenLazyQuery>;
export type GetMonkaiTokenSuspenseQueryHookResult = ReturnType<typeof useGetMonkaiTokenSuspenseQuery>;
export type GetMonkaiTokenQueryResult = Apollo.QueryResult<GetMonkaiTokenQuery, GetMonkaiTokenQueryVariables>;
export const AppraisalsDocument = gql`
    query Appraisals($statuses: String!, $withInventory: Boolean!, $location: String!, $search: String!, $page: Int!, $size: Int!) {
  appraisals(
    statuses: $statuses
    location: $location
    withInventory: $withInventory
    search: $search
    page: $page
    size: $size
  ) @rest(type: "AppraisalsMethod", path: "appraisals/nus?statuses={args.statuses}&location={args.location}&inventory={args.withInventory}&search={args.search}&page={args.page}&limit={args.size}", method: "GET", endpoint: "backend") {
    last
    result {
      id
      odometer
      odometerMiles
      location
      appraisalValue
      offeredValue
      modelYear
      make
      model
      series
      status
      source
      origin
      traction
      transmissionType
      originDate
      vin
      isPurchased
      saleType
      stock
      noticeByManagers
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      assessedById
      assessingStarted
      created
      updated
      submitted
      eraSalesCode
      paveSessionStatus
      photos {
        id
        src
        zone
      }
    }
  }
}
    `;

/**
 * __useAppraisalsQuery__
 *
 * To run a query within a React component, call `useAppraisalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      withInventory: // value for 'withInventory'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useAppraisalsQuery(baseOptions: Apollo.QueryHookOptions<AppraisalsQuery, AppraisalsQueryVariables> & ({ variables: AppraisalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsQuery, AppraisalsQueryVariables>(AppraisalsDocument, options);
      }
export function useAppraisalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsQuery, AppraisalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsQuery, AppraisalsQueryVariables>(AppraisalsDocument, options);
        }
export function useAppraisalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsQuery, AppraisalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsQuery, AppraisalsQueryVariables>(AppraisalsDocument, options);
        }
export type AppraisalsQueryHookResult = ReturnType<typeof useAppraisalsQuery>;
export type AppraisalsLazyQueryHookResult = ReturnType<typeof useAppraisalsLazyQuery>;
export type AppraisalsSuspenseQueryHookResult = ReturnType<typeof useAppraisalsSuspenseQuery>;
export type AppraisalsQueryResult = Apollo.QueryResult<AppraisalsQuery, AppraisalsQueryVariables>;
export const AppraisalsNusDocument = gql`
    query AppraisalsNus($ids: String!, $location: String!, $search: String!, $page: Int!, $size: Int!) {
  appraisalsNus(
    ids: $ids
    location: $location
    search: $search
    page: $page
    size: $size
  ) @rest(type: "AppraisalsMethod", path: "appraisals/nus?{args.ids}&location={args.location}&search={args.search}&page={args.page}&limit={args.size}", method: "GET", endpoint: "backend") {
    last
    result {
      id
      odometer
      odometerMiles
      location
      parkingZoneSegmentation
      appraisalValue
      offeredValue
      modelYear
      make
      model
      series
      status
      source
      origin
      traction
      transmissionType
      originDate
      vin
      custFirstName
      custLastName
      custHomePhone
      isPurchased
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      assessedById
      assessingStarted
      created
      updated
      submitted
      eraSalesCode
      paveSessionStatus
      photos {
        id
        src
        zone
      }
    }
  }
}
    `;

/**
 * __useAppraisalsNusQuery__
 *
 * To run a query within a React component, call `useAppraisalsNusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsNusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsNusQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useAppraisalsNusQuery(baseOptions: Apollo.QueryHookOptions<AppraisalsNusQuery, AppraisalsNusQueryVariables> & ({ variables: AppraisalsNusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsNusQuery, AppraisalsNusQueryVariables>(AppraisalsNusDocument, options);
      }
export function useAppraisalsNusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsNusQuery, AppraisalsNusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsNusQuery, AppraisalsNusQueryVariables>(AppraisalsNusDocument, options);
        }
export function useAppraisalsNusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsNusQuery, AppraisalsNusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsNusQuery, AppraisalsNusQueryVariables>(AppraisalsNusDocument, options);
        }
export type AppraisalsNusQueryHookResult = ReturnType<typeof useAppraisalsNusQuery>;
export type AppraisalsNusLazyQueryHookResult = ReturnType<typeof useAppraisalsNusLazyQuery>;
export type AppraisalsNusSuspenseQueryHookResult = ReturnType<typeof useAppraisalsNusSuspenseQuery>;
export type AppraisalsNusQueryResult = Apollo.QueryResult<AppraisalsNusQuery, AppraisalsNusQueryVariables>;
export const CreateMonkaiInspectionDocument = gql`
    mutation CreateMonkaiInspection($input: CreateMonkaiInspectionInput!) {
  createMonkaiInspection(input: $input) @rest(type: "CreateMonkaiInspectionResponse", path: "appraisals/create-monkai-inspection", method: "POST", endpoint: "backend") {
    operation
    result {
      id
      object_type
    }
  }
}
    `;
export type CreateMonkaiInspectionMutationFn = Apollo.MutationFunction<CreateMonkaiInspectionMutation, CreateMonkaiInspectionMutationVariables>;

/**
 * __useCreateMonkaiInspectionMutation__
 *
 * To run a mutation, you first call `useCreateMonkaiInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonkaiInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonkaiInspectionMutation, { data, loading, error }] = useCreateMonkaiInspectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMonkaiInspectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMonkaiInspectionMutation, CreateMonkaiInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMonkaiInspectionMutation, CreateMonkaiInspectionMutationVariables>(CreateMonkaiInspectionDocument, options);
      }
export type CreateMonkaiInspectionMutationHookResult = ReturnType<typeof useCreateMonkaiInspectionMutation>;
export type CreateMonkaiInspectionMutationResult = Apollo.MutationResult<CreateMonkaiInspectionMutation>;
export type CreateMonkaiInspectionMutationOptions = Apollo.BaseMutationOptions<CreateMonkaiInspectionMutation, CreateMonkaiInspectionMutationVariables>;
export const StartMonkaiInspectionDocument = gql`
    mutation StartMonkaiInspection($input: StartMonkaiInspectionInput!) {
  startMonkaiInspection(input: $input) @rest(type: "CreateMonkaiInspectionResponse", path: "appraisals/start-monkai-inspection", method: "POST", endpoint: "backend") {
    operation
    result {
      id
      object_type
    }
  }
}
    `;
export type StartMonkaiInspectionMutationFn = Apollo.MutationFunction<StartMonkaiInspectionMutation, StartMonkaiInspectionMutationVariables>;

/**
 * __useStartMonkaiInspectionMutation__
 *
 * To run a mutation, you first call `useStartMonkaiInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMonkaiInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMonkaiInspectionMutation, { data, loading, error }] = useStartMonkaiInspectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartMonkaiInspectionMutation(baseOptions?: Apollo.MutationHookOptions<StartMonkaiInspectionMutation, StartMonkaiInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartMonkaiInspectionMutation, StartMonkaiInspectionMutationVariables>(StartMonkaiInspectionDocument, options);
      }
export type StartMonkaiInspectionMutationHookResult = ReturnType<typeof useStartMonkaiInspectionMutation>;
export type StartMonkaiInspectionMutationResult = Apollo.MutationResult<StartMonkaiInspectionMutation>;
export type StartMonkaiInspectionMutationOptions = Apollo.BaseMutationOptions<StartMonkaiInspectionMutation, StartMonkaiInspectionMutationVariables>;
export const CreateEmptyMonkaiInspectionDocument = gql`
    mutation CreateEmptyMonkaiInspection($input: CreateMonkaiInspectionInput!) {
  createEmptyMonkaiInspection(input: $input) @rest(type: "CreateMonkaiInspectionResponse", path: "appraisals/create-empty-monkai-inspection", method: "POST", endpoint: "backend") {
    operation
    result {
      id
      object_type
    }
  }
}
    `;
export type CreateEmptyMonkaiInspectionMutationFn = Apollo.MutationFunction<CreateEmptyMonkaiInspectionMutation, CreateEmptyMonkaiInspectionMutationVariables>;

/**
 * __useCreateEmptyMonkaiInspectionMutation__
 *
 * To run a mutation, you first call `useCreateEmptyMonkaiInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyMonkaiInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyMonkaiInspectionMutation, { data, loading, error }] = useCreateEmptyMonkaiInspectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmptyMonkaiInspectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyMonkaiInspectionMutation, CreateEmptyMonkaiInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyMonkaiInspectionMutation, CreateEmptyMonkaiInspectionMutationVariables>(CreateEmptyMonkaiInspectionDocument, options);
      }
export type CreateEmptyMonkaiInspectionMutationHookResult = ReturnType<typeof useCreateEmptyMonkaiInspectionMutation>;
export type CreateEmptyMonkaiInspectionMutationResult = Apollo.MutationResult<CreateEmptyMonkaiInspectionMutation>;
export type CreateEmptyMonkaiInspectionMutationOptions = Apollo.BaseMutationOptions<CreateEmptyMonkaiInspectionMutation, CreateEmptyMonkaiInspectionMutationVariables>;
export const AppraisalDocument = gql`
    query Appraisal($id: String!, $withInventory: Boolean!) {
  appraisal(id: $id, withInventory: $withInventory) @rest(type: "AppraisalMethod", path: "appraisals/nus?id={args.id}&inventory={args.withInventory}", method: "GET", endpoint: "backend") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      vin
      licenseState
      licensePlate
      isPurchased
      origin
      odometer
      odometerMiles
      styleId
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      inventory {
        Stock
        dashboardUrl
      }
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;

/**
 * __useAppraisalQuery__
 *
 * To run a query within a React component, call `useAppraisalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withInventory: // value for 'withInventory'
 *   },
 * });
 */
export function useAppraisalQuery(baseOptions: Apollo.QueryHookOptions<AppraisalQuery, AppraisalQueryVariables> & ({ variables: AppraisalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalQuery, AppraisalQueryVariables>(AppraisalDocument, options);
      }
export function useAppraisalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalQuery, AppraisalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalQuery, AppraisalQueryVariables>(AppraisalDocument, options);
        }
export function useAppraisalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalQuery, AppraisalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalQuery, AppraisalQueryVariables>(AppraisalDocument, options);
        }
export type AppraisalQueryHookResult = ReturnType<typeof useAppraisalQuery>;
export type AppraisalLazyQueryHookResult = ReturnType<typeof useAppraisalLazyQuery>;
export type AppraisalSuspenseQueryHookResult = ReturnType<typeof useAppraisalSuspenseQuery>;
export type AppraisalQueryResult = Apollo.QueryResult<AppraisalQuery, AppraisalQueryVariables>;
export const GetMonkaiInspectionDocument = gql`
    query GetMonkaiInspection($id: String!) {
  getMonkaiInspection(id: $id) @rest(type: "GetMonkaiInspectionResponse", path: "appraisals/inspection-by-vehicule/{args.id}", method: "GET", endpoint: "backend") {
    result {
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      monkaiVehicleInfo {
        brand
        car_registration
        color
        created_at
        date_of_circulation
        deleted_at
        duplicate_keys
        expertise_requested
        exterior_cleanliness
        id
        interior_cleanliness
        market_value
        mileage
        model
        object_type
        owner_info
        plate
        repair_estimate
        trade_in_offer
        vehicle_quotation
        vehicle_type
        vin
      }
    }
  }
}
    `;

/**
 * __useGetMonkaiInspectionQuery__
 *
 * To run a query within a React component, call `useGetMonkaiInspectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonkaiInspectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonkaiInspectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMonkaiInspectionQuery(baseOptions: Apollo.QueryHookOptions<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables> & ({ variables: GetMonkaiInspectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>(GetMonkaiInspectionDocument, options);
      }
export function useGetMonkaiInspectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>(GetMonkaiInspectionDocument, options);
        }
export function useGetMonkaiInspectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>(GetMonkaiInspectionDocument, options);
        }
export type GetMonkaiInspectionQueryHookResult = ReturnType<typeof useGetMonkaiInspectionQuery>;
export type GetMonkaiInspectionLazyQueryHookResult = ReturnType<typeof useGetMonkaiInspectionLazyQuery>;
export type GetMonkaiInspectionSuspenseQueryHookResult = ReturnType<typeof useGetMonkaiInspectionSuspenseQuery>;
export type GetMonkaiInspectionQueryResult = Apollo.QueryResult<GetMonkaiInspectionQuery, GetMonkaiInspectionQueryVariables>;
export const UploadAppraisalPhotoDocument = gql`
    mutation UploadAppraisalPhoto($file: Upload!) {
  uploadAppraisalPhoto(input: $file) @rest(type: "PhotoMethod", path: "appraisals/photo", method: "POST", endpoint: "backend") {
    result {
      id
      src
      zone
    }
  }
}
    `;
export type UploadAppraisalPhotoMutationFn = Apollo.MutationFunction<UploadAppraisalPhotoMutation, UploadAppraisalPhotoMutationVariables>;

/**
 * __useUploadAppraisalPhotoMutation__
 *
 * To run a mutation, you first call `useUploadAppraisalPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAppraisalPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAppraisalPhotoMutation, { data, loading, error }] = useUploadAppraisalPhotoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAppraisalPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UploadAppraisalPhotoMutation, UploadAppraisalPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAppraisalPhotoMutation, UploadAppraisalPhotoMutationVariables>(UploadAppraisalPhotoDocument, options);
      }
export type UploadAppraisalPhotoMutationHookResult = ReturnType<typeof useUploadAppraisalPhotoMutation>;
export type UploadAppraisalPhotoMutationResult = Apollo.MutationResult<UploadAppraisalPhotoMutation>;
export type UploadAppraisalPhotoMutationOptions = Apollo.BaseMutationOptions<UploadAppraisalPhotoMutation, UploadAppraisalPhotoMutationVariables>;
export const UploadAppraisalPhotoReactiveDocument = gql`
    mutation UploadAppraisalPhotoReactive($id: Int!, $file: Upload!) {
  uploadAppraisalPhotoReactive(id: $id, input: $file) @rest(type: "PhotoMethod", path: "/private/physical-evaluation/image/{args.id}", method: "PATCH", endpoint: "core-service") {
    result {
      id
      src
      zone
    }
  }
}
    `;
export type UploadAppraisalPhotoReactiveMutationFn = Apollo.MutationFunction<UploadAppraisalPhotoReactiveMutation, UploadAppraisalPhotoReactiveMutationVariables>;

/**
 * __useUploadAppraisalPhotoReactiveMutation__
 *
 * To run a mutation, you first call `useUploadAppraisalPhotoReactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAppraisalPhotoReactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAppraisalPhotoReactiveMutation, { data, loading, error }] = useUploadAppraisalPhotoReactiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAppraisalPhotoReactiveMutation(baseOptions?: Apollo.MutationHookOptions<UploadAppraisalPhotoReactiveMutation, UploadAppraisalPhotoReactiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAppraisalPhotoReactiveMutation, UploadAppraisalPhotoReactiveMutationVariables>(UploadAppraisalPhotoReactiveDocument, options);
      }
export type UploadAppraisalPhotoReactiveMutationHookResult = ReturnType<typeof useUploadAppraisalPhotoReactiveMutation>;
export type UploadAppraisalPhotoReactiveMutationResult = Apollo.MutationResult<UploadAppraisalPhotoReactiveMutation>;
export type UploadAppraisalPhotoReactiveMutationOptions = Apollo.BaseMutationOptions<UploadAppraisalPhotoReactiveMutation, UploadAppraisalPhotoReactiveMutationVariables>;
export const UpdateAppraisalDocument = gql`
    mutation UpdateAppraisal($input: JsonAppraisalInput!) {
  updateAppraisal(input: $input) @rest(type: "AppraisalMethod", path: "appraisals/update", method: "POST", endpoint: "backend") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      vin
      origin
      odometer
      odometerMiles
      styleId
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;
export type UpdateAppraisalMutationFn = Apollo.MutationFunction<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>;

/**
 * __useUpdateAppraisalMutation__
 *
 * To run a mutation, you first call `useUpdateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppraisalMutation, { data, loading, error }] = useUpdateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>(UpdateAppraisalDocument, options);
      }
export type UpdateAppraisalMutationHookResult = ReturnType<typeof useUpdateAppraisalMutation>;
export type UpdateAppraisalMutationResult = Apollo.MutationResult<UpdateAppraisalMutation>;
export type UpdateAppraisalMutationOptions = Apollo.BaseMutationOptions<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>;
export const RestartAppraisalDocument = gql`
    mutation RestartAppraisal($input: Restart!) {
  restartAppraisal(input: $input) @rest(type: "AppraisalMethod", path: "appraisals/restart", method: "POST", endpoint: "backend") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      vin
      origin
      odometer
      odometerMiles
      styleId
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;
export type RestartAppraisalMutationFn = Apollo.MutationFunction<RestartAppraisalMutation, RestartAppraisalMutationVariables>;

/**
 * __useRestartAppraisalMutation__
 *
 * To run a mutation, you first call `useRestartAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartAppraisalMutation, { data, loading, error }] = useRestartAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<RestartAppraisalMutation, RestartAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartAppraisalMutation, RestartAppraisalMutationVariables>(RestartAppraisalDocument, options);
      }
export type RestartAppraisalMutationHookResult = ReturnType<typeof useRestartAppraisalMutation>;
export type RestartAppraisalMutationResult = Apollo.MutationResult<RestartAppraisalMutation>;
export type RestartAppraisalMutationOptions = Apollo.BaseMutationOptions<RestartAppraisalMutation, RestartAppraisalMutationVariables>;
export const CreateAppraisalDocument = gql`
    mutation CreateAppraisal($input: JsonAppraisalInput!) {
  createAppraisal(input: $input) @rest(type: "AppraisalMethod", path: "appraisals/add", method: "POST", endpoint: "backend") {
    result {
      id
      vin
    }
  }
}
    `;
export type CreateAppraisalMutationFn = Apollo.MutationFunction<CreateAppraisalMutation, CreateAppraisalMutationVariables>;

/**
 * __useCreateAppraisalMutation__
 *
 * To run a mutation, you first call `useCreateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppraisalMutation, { data, loading, error }] = useCreateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppraisalMutation, CreateAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppraisalMutation, CreateAppraisalMutationVariables>(CreateAppraisalDocument, options);
      }
export type CreateAppraisalMutationHookResult = ReturnType<typeof useCreateAppraisalMutation>;
export type CreateAppraisalMutationResult = Apollo.MutationResult<CreateAppraisalMutation>;
export type CreateAppraisalMutationOptions = Apollo.BaseMutationOptions<CreateAppraisalMutation, CreateAppraisalMutationVariables>;
export const CloneAppraisalDocument = gql`
    mutation CloneAppraisal($input: JsonAppraisalInput!) {
  cloneAppraisal(input: $input) @rest(type: "AppraisalMethod", path: "appraisals/clone", method: "POST", endpoint: "backend") {
    result {
      id
    }
  }
}
    `;
export type CloneAppraisalMutationFn = Apollo.MutationFunction<CloneAppraisalMutation, CloneAppraisalMutationVariables>;

/**
 * __useCloneAppraisalMutation__
 *
 * To run a mutation, you first call `useCloneAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneAppraisalMutation, { data, loading, error }] = useCloneAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<CloneAppraisalMutation, CloneAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneAppraisalMutation, CloneAppraisalMutationVariables>(CloneAppraisalDocument, options);
      }
export type CloneAppraisalMutationHookResult = ReturnType<typeof useCloneAppraisalMutation>;
export type CloneAppraisalMutationResult = Apollo.MutationResult<CloneAppraisalMutation>;
export type CloneAppraisalMutationOptions = Apollo.BaseMutationOptions<CloneAppraisalMutation, CloneAppraisalMutationVariables>;
export const AppraisalsCountDocument = gql`
    query appraisalsCount {
  pendingAppraisalsCount @rest(type: "AppraisalsCountMethod", path: "/private/physical-evaluation/count/pending", method: "GET", endpoint: "core-service") {
    result
  }
  evaluationAppraisalsCount @rest(type: "AppraisalsCountMethod", path: "/private/physical-evaluation/count/evaluation", method: "GET", endpoint: "core-service") {
    result
  }
  evaluatingAppraisalsCount @rest(type: "AppraisalsCountMethod", path: "/private/physical-evaluation/count/evaluating", method: "GET", endpoint: "core-service") {
    result
  }
  assessmentAppraisalsCount @rest(type: "AppraisalsCountMethod", path: "/private/physical-evaluation/count/assessment", method: "GET", endpoint: "core-service") {
    result
  }
  claimAppraisalsCount @rest(type: "AppraisalsCountMethod", path: "/private/physical-evaluation/count/claim", method: "GET", endpoint: "core-service") {
    result
  }
}
    `;

/**
 * __useAppraisalsCountQuery__
 *
 * To run a query within a React component, call `useAppraisalsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalsCountQuery(baseOptions?: Apollo.QueryHookOptions<AppraisalsCountQuery, AppraisalsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsCountQuery, AppraisalsCountQueryVariables>(AppraisalsCountDocument, options);
      }
export function useAppraisalsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsCountQuery, AppraisalsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsCountQuery, AppraisalsCountQueryVariables>(AppraisalsCountDocument, options);
        }
export function useAppraisalsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsCountQuery, AppraisalsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsCountQuery, AppraisalsCountQueryVariables>(AppraisalsCountDocument, options);
        }
export type AppraisalsCountQueryHookResult = ReturnType<typeof useAppraisalsCountQuery>;
export type AppraisalsCountLazyQueryHookResult = ReturnType<typeof useAppraisalsCountLazyQuery>;
export type AppraisalsCountSuspenseQueryHookResult = ReturnType<typeof useAppraisalsCountSuspenseQuery>;
export type AppraisalsCountQueryResult = Apollo.QueryResult<AppraisalsCountQuery, AppraisalsCountQueryVariables>;
export const CreatePaveSessionDocument = gql`
    mutation CreatePaveSession($input: CreatePaveSessionInput!) {
  createPaveSession(input: $input) @rest(type: "AppraisalMethod", path: "appraisals/create-pave-session", method: "POST", endpoint: "backend") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      vin
      origin
      odometer
      odometerMiles
      styleId
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;
export type CreatePaveSessionMutationFn = Apollo.MutationFunction<CreatePaveSessionMutation, CreatePaveSessionMutationVariables>;

/**
 * __useCreatePaveSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaveSessionMutation, { data, loading, error }] = useCreatePaveSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaveSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaveSessionMutation, CreatePaveSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaveSessionMutation, CreatePaveSessionMutationVariables>(CreatePaveSessionDocument, options);
      }
export type CreatePaveSessionMutationHookResult = ReturnType<typeof useCreatePaveSessionMutation>;
export type CreatePaveSessionMutationResult = Apollo.MutationResult<CreatePaveSessionMutation>;
export type CreatePaveSessionMutationOptions = Apollo.BaseMutationOptions<CreatePaveSessionMutation, CreatePaveSessionMutationVariables>;
export const GetAuthUserDocument = gql`
    query getAuthUser {
  getAuthUser @rest(type: "Get", path: "/auth", method: "GET", endpoint: "user-service") {
    id
    firstName
    lastName
    email
    phoneNumber
    country
    dealership
    roles
    permissions
  }
}
    `;

/**
 * __useGetAuthUserQuery__
 *
 * To run a query within a React component, call `useGetAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthUserQuery, GetAuthUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthUserQuery, GetAuthUserQueryVariables>(GetAuthUserDocument, options);
      }
export function useGetAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthUserQuery, GetAuthUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthUserQuery, GetAuthUserQueryVariables>(GetAuthUserDocument, options);
        }
export function useGetAuthUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAuthUserQuery, GetAuthUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAuthUserQuery, GetAuthUserQueryVariables>(GetAuthUserDocument, options);
        }
export type GetAuthUserQueryHookResult = ReturnType<typeof useGetAuthUserQuery>;
export type GetAuthUserLazyQueryHookResult = ReturnType<typeof useGetAuthUserLazyQuery>;
export type GetAuthUserSuspenseQueryHookResult = ReturnType<typeof useGetAuthUserSuspenseQuery>;
export type GetAuthUserQueryResult = Apollo.QueryResult<GetAuthUserQuery, GetAuthUserQueryVariables>;
export const SingInDocument = gql`
    mutation singIn($input: SignInInput!) {
  signIn(input: $input) @rest(type: "Auth", path: "/auth/sign-in", method: "POST", endpoint: "user-service") {
    token
  }
}
    `;
export type SingInMutationFn = Apollo.MutationFunction<SingInMutation, SingInMutationVariables>;

/**
 * __useSingInMutation__
 *
 * To run a mutation, you first call `useSingInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singInMutation, { data, loading, error }] = useSingInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSingInMutation(baseOptions?: Apollo.MutationHookOptions<SingInMutation, SingInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingInMutation, SingInMutationVariables>(SingInDocument, options);
      }
export type SingInMutationHookResult = ReturnType<typeof useSingInMutation>;
export type SingInMutationResult = Apollo.MutationResult<SingInMutation>;
export type SingInMutationOptions = Apollo.BaseMutationOptions<SingInMutation, SingInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($input: SignUpInput!) {
  signUp(input: $input) @rest(type: "Auth", path: "/auth/sign-up", method: "POST", endpoint: "user-service") {
    token
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const GetCarstoryByVinDocument = gql`
    mutation GetCarstoryByVIN($input: UrlTypeInput!) {
  getCarstoryByVIN(input: $input) @rest(type: "UrlTypeInput", path: "vin/analize-drivly/", method: "POST", endpoint: "backend") {
    result {
      carstory {
        vehicleInfo {
          bedStyle
          bodyStyle
          cabStyle
          condition
          conditionTimestamp
          confidenceScore4
          doors
          driveType
          engine
          engineDescription
          engineDisplacement
          exteriorColor
          fuel
          generation
          interiorColor
          make
          mileage
          mileageTimestamp
          model
          mpg
          mpgCity
          mpgHighway
          subGeneration
          subModel
          subTrim
          transmission
          transmissionDetails
          trim
          vin
          wheelbase
          year
        }
      }
      error
    }
  }
}
    `;
export type GetCarstoryByVinMutationFn = Apollo.MutationFunction<GetCarstoryByVinMutation, GetCarstoryByVinMutationVariables>;

/**
 * __useGetCarstoryByVinMutation__
 *
 * To run a mutation, you first call `useGetCarstoryByVinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCarstoryByVinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCarstoryByVinMutation, { data, loading, error }] = useGetCarstoryByVinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarstoryByVinMutation(baseOptions?: Apollo.MutationHookOptions<GetCarstoryByVinMutation, GetCarstoryByVinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCarstoryByVinMutation, GetCarstoryByVinMutationVariables>(GetCarstoryByVinDocument, options);
      }
export type GetCarstoryByVinMutationHookResult = ReturnType<typeof useGetCarstoryByVinMutation>;
export type GetCarstoryByVinMutationResult = Apollo.MutationResult<GetCarstoryByVinMutation>;
export type GetCarstoryByVinMutationOptions = Apollo.BaseMutationOptions<GetCarstoryByVinMutation, GetCarstoryByVinMutationVariables>;
export const GetCarGurusByVinDocument = gql`
    query GetCarGurusByVIN($vin: String!, $mileage: String) {
  getCarGurusByVIN(vin: $vin, mileage: $mileage) @rest(type: "CarGurusByVINMethod", path: "/private/services/gurus?vin={args.vin}&mileage={args.mileage}", method: "GET", endpoint: "core-service") {
    vin
    retail
    privateParty
    trade
    error
  }
}
    `;

/**
 * __useGetCarGurusByVinQuery__
 *
 * To run a query within a React component, call `useGetCarGurusByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarGurusByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarGurusByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      mileage: // value for 'mileage'
 *   },
 * });
 */
export function useGetCarGurusByVinQuery(baseOptions: Apollo.QueryHookOptions<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables> & ({ variables: GetCarGurusByVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>(GetCarGurusByVinDocument, options);
      }
export function useGetCarGurusByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>(GetCarGurusByVinDocument, options);
        }
export function useGetCarGurusByVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>(GetCarGurusByVinDocument, options);
        }
export type GetCarGurusByVinQueryHookResult = ReturnType<typeof useGetCarGurusByVinQuery>;
export type GetCarGurusByVinLazyQueryHookResult = ReturnType<typeof useGetCarGurusByVinLazyQuery>;
export type GetCarGurusByVinSuspenseQueryHookResult = ReturnType<typeof useGetCarGurusByVinSuspenseQuery>;
export type GetCarGurusByVinQueryResult = Apollo.QueryResult<GetCarGurusByVinQuery, GetCarGurusByVinQueryVariables>;
export const GetOffersByVinDocument = gql`
    query GetOffersByVIN($vin: String!, $mileage: String!, $zipCode: String!) {
  getOffersByVIN(vin: $vin, mileage: $mileage, zipCode: $zipCode) @rest(type: "OffersByVIN", path: "/private/services/drivly?vin={args.vin}&mileage={args.mileage}&zipCode={args.zipCode}", method: "GET", endpoint: "core-service") {
    vin
    year
    make
    model
    mileage
    vroomPrice
    vroomGrade
    vroomUrl
    vroomError {
      error
    }
    carvanaPrice
    carvanaUrl
    carvanaError {
      error
    }
    carmaxPrice
    carmaxUrl
    carmaxDeclineReason
    carmaxError {
      error
    }
    retry
  }
}
    `;

/**
 * __useGetOffersByVinQuery__
 *
 * To run a query within a React component, call `useGetOffersByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      mileage: // value for 'mileage'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useGetOffersByVinQuery(baseOptions: Apollo.QueryHookOptions<GetOffersByVinQuery, GetOffersByVinQueryVariables> & ({ variables: GetOffersByVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOffersByVinQuery, GetOffersByVinQueryVariables>(GetOffersByVinDocument, options);
      }
export function useGetOffersByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOffersByVinQuery, GetOffersByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOffersByVinQuery, GetOffersByVinQueryVariables>(GetOffersByVinDocument, options);
        }
export function useGetOffersByVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOffersByVinQuery, GetOffersByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOffersByVinQuery, GetOffersByVinQueryVariables>(GetOffersByVinDocument, options);
        }
export type GetOffersByVinQueryHookResult = ReturnType<typeof useGetOffersByVinQuery>;
export type GetOffersByVinLazyQueryHookResult = ReturnType<typeof useGetOffersByVinLazyQuery>;
export type GetOffersByVinSuspenseQueryHookResult = ReturnType<typeof useGetOffersByVinSuspenseQuery>;
export type GetOffersByVinQueryResult = Apollo.QueryResult<GetOffersByVinQuery, GetOffersByVinQueryVariables>;
export const BbInfoValuesDocument = gql`
    mutation BbInfoValues($input: BbInfoValuesInput!) {
  bbInfoValues(input: $input) @rest(type: "BbInfoValuesInput", path: "vin/bb-info-values", method: "POST", endpoint: "backend") {
    result {
      reatailPrices {
        adjustedRetailAvg
        adjustedRetailClean
        adjustedRetailRough
        adjustedRetailXclean
        baseRetailAvg
        baseRetailClean
        baseRetailRough
        baseRetailXclean
      }
      wholeSalePrices {
        adjustedWholeAvg
        adjustedWholeClean
        adjustedWholeRough
        adjustedWholeXclean
        baseWholeAvg
        baseWholeClean
        baseWholeRough
        baseWholeXclean
      }
      tradeinPrices {
        adjustedTradeinAvg
        adjustedTradeinClean
        adjustedTradeinRough
        adjustedTradeinXclean
        baseTradeinAvg
        baseTradeinClean
        baseTradeinRough
        baseTradeinXclean
      }
    }
  }
}
    `;
export type BbInfoValuesMutationFn = Apollo.MutationFunction<BbInfoValuesMutation, BbInfoValuesMutationVariables>;

/**
 * __useBbInfoValuesMutation__
 *
 * To run a mutation, you first call `useBbInfoValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBbInfoValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bbInfoValuesMutation, { data, loading, error }] = useBbInfoValuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBbInfoValuesMutation(baseOptions?: Apollo.MutationHookOptions<BbInfoValuesMutation, BbInfoValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BbInfoValuesMutation, BbInfoValuesMutationVariables>(BbInfoValuesDocument, options);
      }
export type BbInfoValuesMutationHookResult = ReturnType<typeof useBbInfoValuesMutation>;
export type BbInfoValuesMutationResult = Apollo.MutationResult<BbInfoValuesMutation>;
export type BbInfoValuesMutationOptions = Apollo.BaseMutationOptions<BbInfoValuesMutation, BbInfoValuesMutationVariables>;
export const CarproofDocument = gql`
    query Carproof($vin: String!) {
  carproof(vin: $vin) @rest(type: "CarproofMethod", path: "/carproof/{args.vin}?lang=fr", method: "GET", endpoint: "webservice") {
    result {
      url
      reportId
      vin
      lang
    }
  }
}
    `;

/**
 * __useCarproofQuery__
 *
 * To run a query within a React component, call `useCarproofQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarproofQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarproofQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useCarproofQuery(baseOptions: Apollo.QueryHookOptions<CarproofQuery, CarproofQueryVariables> & ({ variables: CarproofQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarproofQuery, CarproofQueryVariables>(CarproofDocument, options);
      }
export function useCarproofLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarproofQuery, CarproofQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarproofQuery, CarproofQueryVariables>(CarproofDocument, options);
        }
export function useCarproofSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CarproofQuery, CarproofQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CarproofQuery, CarproofQueryVariables>(CarproofDocument, options);
        }
export type CarproofQueryHookResult = ReturnType<typeof useCarproofQuery>;
export type CarproofLazyQueryHookResult = ReturnType<typeof useCarproofLazyQuery>;
export type CarproofSuspenseQueryHookResult = ReturnType<typeof useCarproofSuspenseQuery>;
export type CarproofQueryResult = Apollo.QueryResult<CarproofQuery, CarproofQueryVariables>;
export const GetCarproofDocument = gql`
    query GetCarproof($vin: String!) {
  getCarfax(vin: $vin) @rest(type: "GetCarproofMethod", path: "appraisals/get-carfax/{args.vin}", method: "GET", endpoint: "backend") {
    result {
      InvoicedTo
      Make
      MobileReportLink
      MobileReportLinkHideLiens
      Model
      Reference
      ReportLink
      ReportLinkHideLiens
      ReportNumber
      ReportType
      ReportTypeName
      Vin
      Year
    }
  }
}
    `;

/**
 * __useGetCarproofQuery__
 *
 * To run a query within a React component, call `useGetCarproofQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarproofQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarproofQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useGetCarproofQuery(baseOptions: Apollo.QueryHookOptions<GetCarproofQuery, GetCarproofQueryVariables> & ({ variables: GetCarproofQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarproofQuery, GetCarproofQueryVariables>(GetCarproofDocument, options);
      }
export function useGetCarproofLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarproofQuery, GetCarproofQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarproofQuery, GetCarproofQueryVariables>(GetCarproofDocument, options);
        }
export function useGetCarproofSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCarproofQuery, GetCarproofQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCarproofQuery, GetCarproofQueryVariables>(GetCarproofDocument, options);
        }
export type GetCarproofQueryHookResult = ReturnType<typeof useGetCarproofQuery>;
export type GetCarproofLazyQueryHookResult = ReturnType<typeof useGetCarproofLazyQuery>;
export type GetCarproofSuspenseQueryHookResult = ReturnType<typeof useGetCarproofSuspenseQuery>;
export type GetCarproofQueryResult = Apollo.QueryResult<GetCarproofQuery, GetCarproofQueryVariables>;
export const CarproofVpnDocument = gql`
    query CarproofVPN($vin: String!, $lang: String!) {
  getCarfaxVPN(vin: $vin, lang: $lang) @rest(type: "CarproofMethod", path: "appraisals/info-carfax/{args.vin}/{args.lang}", method: "GET", endpoint: "backend") {
    result {
      BadgesList {
        BadgeList {
          BadgeName
          BadgeType
          BadgeImageUrl
        }
        BadgesImageUrl
        HasBadge
        VhrReportUrl
        VIN
        ResultCode
        ResultMessage
      }
      Language
      LogoUrl
      ResultCode
      ResultMessage
    }
  }
}
    `;

/**
 * __useCarproofVpnQuery__
 *
 * To run a query within a React component, call `useCarproofVpnQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarproofVpnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarproofVpnQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCarproofVpnQuery(baseOptions: Apollo.QueryHookOptions<CarproofVpnQuery, CarproofVpnQueryVariables> & ({ variables: CarproofVpnQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarproofVpnQuery, CarproofVpnQueryVariables>(CarproofVpnDocument, options);
      }
export function useCarproofVpnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarproofVpnQuery, CarproofVpnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarproofVpnQuery, CarproofVpnQueryVariables>(CarproofVpnDocument, options);
        }
export function useCarproofVpnSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CarproofVpnQuery, CarproofVpnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CarproofVpnQuery, CarproofVpnQueryVariables>(CarproofVpnDocument, options);
        }
export type CarproofVpnQueryHookResult = ReturnType<typeof useCarproofVpnQuery>;
export type CarproofVpnLazyQueryHookResult = ReturnType<typeof useCarproofVpnLazyQuery>;
export type CarproofVpnSuspenseQueryHookResult = ReturnType<typeof useCarproofVpnSuspenseQuery>;
export type CarproofVpnQueryResult = Apollo.QueryResult<CarproofVpnQuery, CarproofVpnQueryVariables>;
export const ModelYearsDocument = gql`
    query ModelYears {
  modelYears @rest(type: "ModelYearsMethod", path: "chrome/getmodelyears/", method: "GET", endpoint: "backend") {
    result {
      id
      name
    }
  }
}
    `;

/**
 * __useModelYearsQuery__
 *
 * To run a query within a React component, call `useModelYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelYearsQuery(baseOptions?: Apollo.QueryHookOptions<ModelYearsQuery, ModelYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelYearsQuery, ModelYearsQueryVariables>(ModelYearsDocument, options);
      }
export function useModelYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelYearsQuery, ModelYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelYearsQuery, ModelYearsQueryVariables>(ModelYearsDocument, options);
        }
export function useModelYearsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ModelYearsQuery, ModelYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ModelYearsQuery, ModelYearsQueryVariables>(ModelYearsDocument, options);
        }
export type ModelYearsQueryHookResult = ReturnType<typeof useModelYearsQuery>;
export type ModelYearsLazyQueryHookResult = ReturnType<typeof useModelYearsLazyQuery>;
export type ModelYearsSuspenseQueryHookResult = ReturnType<typeof useModelYearsSuspenseQuery>;
export type ModelYearsQueryResult = Apollo.QueryResult<ModelYearsQuery, ModelYearsQueryVariables>;
export const DivisionsDocument = gql`
    query Divisions($modelYearId: Int, $lang: Languages, $country: Countries!) {
  divisions(modelYearId: $modelYearId, lang: $lang, country: $country) @rest(type: "DivisionsMethod", path: "chrome/getdivisions/{args.modelYearId}?lang={args.lang}&country={args.country}", method: "GET", endpoint: "backend") {
    result {
      id
      name
    }
  }
}
    `;

/**
 * __useDivisionsQuery__
 *
 * To run a query within a React component, call `useDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivisionsQuery({
 *   variables: {
 *      modelYearId: // value for 'modelYearId'
 *      lang: // value for 'lang'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useDivisionsQuery(baseOptions: Apollo.QueryHookOptions<DivisionsQuery, DivisionsQueryVariables> & ({ variables: DivisionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DivisionsQuery, DivisionsQueryVariables>(DivisionsDocument, options);
      }
export function useDivisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DivisionsQuery, DivisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DivisionsQuery, DivisionsQueryVariables>(DivisionsDocument, options);
        }
export function useDivisionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DivisionsQuery, DivisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DivisionsQuery, DivisionsQueryVariables>(DivisionsDocument, options);
        }
export type DivisionsQueryHookResult = ReturnType<typeof useDivisionsQuery>;
export type DivisionsLazyQueryHookResult = ReturnType<typeof useDivisionsLazyQuery>;
export type DivisionsSuspenseQueryHookResult = ReturnType<typeof useDivisionsSuspenseQuery>;
export type DivisionsQueryResult = Apollo.QueryResult<DivisionsQuery, DivisionsQueryVariables>;
export const ModelsDocument = gql`
    query Models($modelYearId: Int, $divisionId: Int, $lang: Languages, $country: Countries!) {
  models(
    modelYearId: $modelYearId
    divisionId: $divisionId
    lang: $lang
    country: $country
  ) @rest(type: "ModelsMethod", path: "chrome/getmodels/{args.modelYearId}/{args.divisionId}?lang={args.lang}&country={args.country}", method: "GET", endpoint: "backend") {
    result {
      id
      name
    }
  }
}
    `;

/**
 * __useModelsQuery__
 *
 * To run a query within a React component, call `useModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelsQuery({
 *   variables: {
 *      modelYearId: // value for 'modelYearId'
 *      divisionId: // value for 'divisionId'
 *      lang: // value for 'lang'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useModelsQuery(baseOptions: Apollo.QueryHookOptions<ModelsQuery, ModelsQueryVariables> & ({ variables: ModelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
      }
export function useModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
        }
export function useModelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
        }
export type ModelsQueryHookResult = ReturnType<typeof useModelsQuery>;
export type ModelsLazyQueryHookResult = ReturnType<typeof useModelsLazyQuery>;
export type ModelsSuspenseQueryHookResult = ReturnType<typeof useModelsSuspenseQuery>;
export type ModelsQueryResult = Apollo.QueryResult<ModelsQuery, ModelsQueryVariables>;
export const StylesDocument = gql`
    query Styles($modelId: Int, $lang: Languages, $country: Countries!) {
  styles(modelId: $modelId, lang: $lang, country: $country) @rest(type: "StylesMethod", path: "chrome/getstyles/{args.modelId}?lang={args.lang}&country={args.country}", method: "GET", endpoint: "backend") {
    result {
      id
      name
    }
  }
}
    `;

/**
 * __useStylesQuery__
 *
 * To run a query within a React component, call `useStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStylesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      lang: // value for 'lang'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useStylesQuery(baseOptions: Apollo.QueryHookOptions<StylesQuery, StylesQueryVariables> & ({ variables: StylesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StylesQuery, StylesQueryVariables>(StylesDocument, options);
      }
export function useStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StylesQuery, StylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StylesQuery, StylesQueryVariables>(StylesDocument, options);
        }
export function useStylesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StylesQuery, StylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StylesQuery, StylesQueryVariables>(StylesDocument, options);
        }
export type StylesQueryHookResult = ReturnType<typeof useStylesQuery>;
export type StylesLazyQueryHookResult = ReturnType<typeof useStylesLazyQuery>;
export type StylesSuspenseQueryHookResult = ReturnType<typeof useStylesSuspenseQuery>;
export type StylesQueryResult = Apollo.QueryResult<StylesQuery, StylesQueryVariables>;
export const ConditionSidesDocument = gql`
    query conditionSides($variant: String!) {
  carSides(variant: $variant) {
    id
    side
    image
    parts {
      id
      name
      labels {
        en
        fr
      }
      top
      bottom
      left
      right
    }
  }
}
    `;

/**
 * __useConditionSidesQuery__
 *
 * To run a query within a React component, call `useConditionSidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionSidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionSidesQuery({
 *   variables: {
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useConditionSidesQuery(baseOptions: Apollo.QueryHookOptions<ConditionSidesQuery, ConditionSidesQueryVariables> & ({ variables: ConditionSidesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConditionSidesQuery, ConditionSidesQueryVariables>(ConditionSidesDocument, options);
      }
export function useConditionSidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConditionSidesQuery, ConditionSidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConditionSidesQuery, ConditionSidesQueryVariables>(ConditionSidesDocument, options);
        }
export function useConditionSidesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConditionSidesQuery, ConditionSidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConditionSidesQuery, ConditionSidesQueryVariables>(ConditionSidesDocument, options);
        }
export type ConditionSidesQueryHookResult = ReturnType<typeof useConditionSidesQuery>;
export type ConditionSidesLazyQueryHookResult = ReturnType<typeof useConditionSidesLazyQuery>;
export type ConditionSidesSuspenseQueryHookResult = ReturnType<typeof useConditionSidesSuspenseQuery>;
export type ConditionSidesQueryResult = Apollo.QueryResult<ConditionSidesQuery, ConditionSidesQueryVariables>;
export const ConditionDamagesDocument = gql`
    query conditionDamages($variant: String!, $part: String!, $side: String!) {
  damages(variant: $variant, part: $part, side: $side) {
    id
    side
    labels {
      en
      fr
    }
    bucket
    actions {
      id
      name
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;

/**
 * __useConditionDamagesQuery__
 *
 * To run a query within a React component, call `useConditionDamagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionDamagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionDamagesQuery({
 *   variables: {
 *      variant: // value for 'variant'
 *      part: // value for 'part'
 *      side: // value for 'side'
 *   },
 * });
 */
export function useConditionDamagesQuery(baseOptions: Apollo.QueryHookOptions<ConditionDamagesQuery, ConditionDamagesQueryVariables> & ({ variables: ConditionDamagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConditionDamagesQuery, ConditionDamagesQueryVariables>(ConditionDamagesDocument, options);
      }
export function useConditionDamagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConditionDamagesQuery, ConditionDamagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConditionDamagesQuery, ConditionDamagesQueryVariables>(ConditionDamagesDocument, options);
        }
export function useConditionDamagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConditionDamagesQuery, ConditionDamagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConditionDamagesQuery, ConditionDamagesQueryVariables>(ConditionDamagesDocument, options);
        }
export type ConditionDamagesQueryHookResult = ReturnType<typeof useConditionDamagesQuery>;
export type ConditionDamagesLazyQueryHookResult = ReturnType<typeof useConditionDamagesLazyQuery>;
export type ConditionDamagesSuspenseQueryHookResult = ReturnType<typeof useConditionDamagesSuspenseQuery>;
export type ConditionDamagesQueryResult = Apollo.QueryResult<ConditionDamagesQuery, ConditionDamagesQueryVariables>;
export const CreateConditionDamageDocument = gql`
    mutation createConditionDamage($input: DamageInput!) {
  createDamage(input: $input) {
    id
    side
    labels {
      en
      fr
    }
    bucket
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type CreateConditionDamageMutationFn = Apollo.MutationFunction<CreateConditionDamageMutation, CreateConditionDamageMutationVariables>;

/**
 * __useCreateConditionDamageMutation__
 *
 * To run a mutation, you first call `useCreateConditionDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionDamageMutation, { data, loading, error }] = useCreateConditionDamageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConditionDamageMutation(baseOptions?: Apollo.MutationHookOptions<CreateConditionDamageMutation, CreateConditionDamageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConditionDamageMutation, CreateConditionDamageMutationVariables>(CreateConditionDamageDocument, options);
      }
export type CreateConditionDamageMutationHookResult = ReturnType<typeof useCreateConditionDamageMutation>;
export type CreateConditionDamageMutationResult = Apollo.MutationResult<CreateConditionDamageMutation>;
export type CreateConditionDamageMutationOptions = Apollo.BaseMutationOptions<CreateConditionDamageMutation, CreateConditionDamageMutationVariables>;
export const CreateConditionDamageActionDocument = gql`
    mutation createConditionDamageAction($input: ActionInput!) {
  createDamageAction(input: $input) {
    id
    side
    labels {
      en
      fr
    }
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type CreateConditionDamageActionMutationFn = Apollo.MutationFunction<CreateConditionDamageActionMutation, CreateConditionDamageActionMutationVariables>;

/**
 * __useCreateConditionDamageActionMutation__
 *
 * To run a mutation, you first call `useCreateConditionDamageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionDamageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionDamageActionMutation, { data, loading, error }] = useCreateConditionDamageActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConditionDamageActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateConditionDamageActionMutation, CreateConditionDamageActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConditionDamageActionMutation, CreateConditionDamageActionMutationVariables>(CreateConditionDamageActionDocument, options);
      }
export type CreateConditionDamageActionMutationHookResult = ReturnType<typeof useCreateConditionDamageActionMutation>;
export type CreateConditionDamageActionMutationResult = Apollo.MutationResult<CreateConditionDamageActionMutation>;
export type CreateConditionDamageActionMutationOptions = Apollo.BaseMutationOptions<CreateConditionDamageActionMutation, CreateConditionDamageActionMutationVariables>;
export const CreateDamageActionDocument = gql`
    mutation createDamageAction($input: ActionInput!) {
  createDamageAction(input: $input) {
    id
    labels {
      en
      fr
    }
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type CreateDamageActionMutationFn = Apollo.MutationFunction<CreateDamageActionMutation, CreateDamageActionMutationVariables>;

/**
 * __useCreateDamageActionMutation__
 *
 * To run a mutation, you first call `useCreateDamageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDamageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDamageActionMutation, { data, loading, error }] = useCreateDamageActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDamageActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDamageActionMutation, CreateDamageActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDamageActionMutation, CreateDamageActionMutationVariables>(CreateDamageActionDocument, options);
      }
export type CreateDamageActionMutationHookResult = ReturnType<typeof useCreateDamageActionMutation>;
export type CreateDamageActionMutationResult = Apollo.MutationResult<CreateDamageActionMutation>;
export type CreateDamageActionMutationOptions = Apollo.BaseMutationOptions<CreateDamageActionMutation, CreateDamageActionMutationVariables>;
export const UpdateConditionDamageDocument = gql`
    mutation updateConditionDamage($id: String!, $input: DamageInput!) {
  updateDamage(id: $id, input: $input) {
    id
    labels {
      en
      fr
    }
    bucket
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type UpdateConditionDamageMutationFn = Apollo.MutationFunction<UpdateConditionDamageMutation, UpdateConditionDamageMutationVariables>;

/**
 * __useUpdateConditionDamageMutation__
 *
 * To run a mutation, you first call `useUpdateConditionDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionDamageMutation, { data, loading, error }] = useUpdateConditionDamageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConditionDamageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConditionDamageMutation, UpdateConditionDamageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConditionDamageMutation, UpdateConditionDamageMutationVariables>(UpdateConditionDamageDocument, options);
      }
export type UpdateConditionDamageMutationHookResult = ReturnType<typeof useUpdateConditionDamageMutation>;
export type UpdateConditionDamageMutationResult = Apollo.MutationResult<UpdateConditionDamageMutation>;
export type UpdateConditionDamageMutationOptions = Apollo.BaseMutationOptions<UpdateConditionDamageMutation, UpdateConditionDamageMutationVariables>;
export const UpdateConditionDamageActionDocument = gql`
    mutation updateConditionDamageAction($id: String!, $input: ActionInput!) {
  updateDamageAction(id: $id, input: $input) {
    id
    labels {
      en
      fr
    }
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type UpdateConditionDamageActionMutationFn = Apollo.MutationFunction<UpdateConditionDamageActionMutation, UpdateConditionDamageActionMutationVariables>;

/**
 * __useUpdateConditionDamageActionMutation__
 *
 * To run a mutation, you first call `useUpdateConditionDamageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionDamageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionDamageActionMutation, { data, loading, error }] = useUpdateConditionDamageActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConditionDamageActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConditionDamageActionMutation, UpdateConditionDamageActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConditionDamageActionMutation, UpdateConditionDamageActionMutationVariables>(UpdateConditionDamageActionDocument, options);
      }
export type UpdateConditionDamageActionMutationHookResult = ReturnType<typeof useUpdateConditionDamageActionMutation>;
export type UpdateConditionDamageActionMutationResult = Apollo.MutationResult<UpdateConditionDamageActionMutation>;
export type UpdateConditionDamageActionMutationOptions = Apollo.BaseMutationOptions<UpdateConditionDamageActionMutation, UpdateConditionDamageActionMutationVariables>;
export const DeleteConditionDamageDocument = gql`
    mutation deleteConditionDamage($id: String!, $userId: String!) {
  deleteDamage(id: $id, userId: $userId) {
    id
    labels {
      en
      fr
    }
    bucket
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type DeleteConditionDamageMutationFn = Apollo.MutationFunction<DeleteConditionDamageMutation, DeleteConditionDamageMutationVariables>;

/**
 * __useDeleteConditionDamageMutation__
 *
 * To run a mutation, you first call `useDeleteConditionDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionDamageMutation, { data, loading, error }] = useDeleteConditionDamageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteConditionDamageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConditionDamageMutation, DeleteConditionDamageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConditionDamageMutation, DeleteConditionDamageMutationVariables>(DeleteConditionDamageDocument, options);
      }
export type DeleteConditionDamageMutationHookResult = ReturnType<typeof useDeleteConditionDamageMutation>;
export type DeleteConditionDamageMutationResult = Apollo.MutationResult<DeleteConditionDamageMutation>;
export type DeleteConditionDamageMutationOptions = Apollo.BaseMutationOptions<DeleteConditionDamageMutation, DeleteConditionDamageMutationVariables>;
export const DeleteConditionDamageActionDocument = gql`
    mutation deleteConditionDamageAction($id: String!, $userId: String!) {
  deleteDamageAction(id: $id, userId: $userId) {
    id
    labels {
      en
      fr
    }
    actions {
      id
      labels {
        en
        fr
      }
      deval_low
      deval_high
      repair_low
      repair_high
    }
  }
}
    `;
export type DeleteConditionDamageActionMutationFn = Apollo.MutationFunction<DeleteConditionDamageActionMutation, DeleteConditionDamageActionMutationVariables>;

/**
 * __useDeleteConditionDamageActionMutation__
 *
 * To run a mutation, you first call `useDeleteConditionDamageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionDamageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionDamageActionMutation, { data, loading, error }] = useDeleteConditionDamageActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteConditionDamageActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConditionDamageActionMutation, DeleteConditionDamageActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConditionDamageActionMutation, DeleteConditionDamageActionMutationVariables>(DeleteConditionDamageActionDocument, options);
      }
export type DeleteConditionDamageActionMutationHookResult = ReturnType<typeof useDeleteConditionDamageActionMutation>;
export type DeleteConditionDamageActionMutationResult = Apollo.MutationResult<DeleteConditionDamageActionMutation>;
export type DeleteConditionDamageActionMutationOptions = Apollo.BaseMutationOptions<DeleteConditionDamageActionMutation, DeleteConditionDamageActionMutationVariables>;
export const GetUserEvaluationListDocument = gql`
    query getUserEvaluationList($location: String!, $page: Int!, $limit: Int!, $select: String!, $search: String!, $statuses: String!) {
  getUserEvaluationList(
    location: $location
    page: $page
    limit: $limit
    select: $select
    search: $search
    statuses: $statuses
  ) @rest(type: "AppriselVehicleMethod", path: "/private/user-evaluation?location={args.location}&page={args.page}&limit={args.limit}&search={args.search}&select={args.select}&status={args.statuses}", method: "GET", endpoint: "core-service") {
    last
    result {
      _id
      interiorColor
      location
      vin
      origin
      odometer
      odometerMiles
      modelYear
      make
      model
      series
      bodyType
      engineCylinderCount
      engineCylinderLabel
      transmissionType
      traction
      exteriorColor
      custFirstName
      custLastName
      custHomePhone
      custEmail
      custAddress
      custCity
      custState
      custPostalCode
      styleId
      disclosuresUS {
        name
        text
        value
        children
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      created_at
      updated_at
      status
      physicalEvaluationId
      physicalEvaluationDate
      physicalEvaluationDate
      isPurchased
    }
  }
}
    `;

/**
 * __useGetUserEvaluationListQuery__
 *
 * To run a query within a React component, call `useGetUserEvaluationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEvaluationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEvaluationListQuery({
 *   variables: {
 *      location: // value for 'location'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      select: // value for 'select'
 *      search: // value for 'search'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetUserEvaluationListQuery(baseOptions: Apollo.QueryHookOptions<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables> & ({ variables: GetUserEvaluationListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>(GetUserEvaluationListDocument, options);
      }
export function useGetUserEvaluationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>(GetUserEvaluationListDocument, options);
        }
export function useGetUserEvaluationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>(GetUserEvaluationListDocument, options);
        }
export type GetUserEvaluationListQueryHookResult = ReturnType<typeof useGetUserEvaluationListQuery>;
export type GetUserEvaluationListLazyQueryHookResult = ReturnType<typeof useGetUserEvaluationListLazyQuery>;
export type GetUserEvaluationListSuspenseQueryHookResult = ReturnType<typeof useGetUserEvaluationListSuspenseQuery>;
export type GetUserEvaluationListQueryResult = Apollo.QueryResult<GetUserEvaluationListQuery, GetUserEvaluationListQueryVariables>;
export const GetOneVehicleUserEvaluationDocument = gql`
    query getOneVehicleUserEvaluation($id: String!) {
  getOneVehicleUserEvaluation(id: $id) @rest(type: "AppriselVehicleType", path: "/private/user-evaluation/{args.id}/", method: "GET", endpoint: "core-service") {
    _id
    interiorColor
    location
    vin
    origin
    odometer
    odometerMiles
    modelYear
    make
    model
    series
    bodyType
    engineCylinderCount
    engineCylinderLabel
    transmissionType
    traction
    exteriorColor
    custFirstName
    custLastName
    custHomePhone
    custEmail
    custAddress
    custCity
    custState
    custPostalCode
    styleId
    disclosuresUS {
      name
      text
      value
      children
    }
    optionsVinDecodeBB {
      auto
      avg
      clean
      name
      resid12
      resid24
      resid30
      resid36
      resid42
      resid48
      resid60
      resid72
      rough
      uoc
      xclean
    }
    created_at
    updated_at
    status
    physicalEvaluationId
    physicalEvaluationDate
    physicalEvaluationDate
  }
}
    `;

/**
 * __useGetOneVehicleUserEvaluationQuery__
 *
 * To run a query within a React component, call `useGetOneVehicleUserEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneVehicleUserEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneVehicleUserEvaluationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneVehicleUserEvaluationQuery(baseOptions: Apollo.QueryHookOptions<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables> & ({ variables: GetOneVehicleUserEvaluationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>(GetOneVehicleUserEvaluationDocument, options);
      }
export function useGetOneVehicleUserEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>(GetOneVehicleUserEvaluationDocument, options);
        }
export function useGetOneVehicleUserEvaluationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>(GetOneVehicleUserEvaluationDocument, options);
        }
export type GetOneVehicleUserEvaluationQueryHookResult = ReturnType<typeof useGetOneVehicleUserEvaluationQuery>;
export type GetOneVehicleUserEvaluationLazyQueryHookResult = ReturnType<typeof useGetOneVehicleUserEvaluationLazyQuery>;
export type GetOneVehicleUserEvaluationSuspenseQueryHookResult = ReturnType<typeof useGetOneVehicleUserEvaluationSuspenseQuery>;
export type GetOneVehicleUserEvaluationQueryResult = Apollo.QueryResult<GetOneVehicleUserEvaluationQuery, GetOneVehicleUserEvaluationQueryVariables>;
export const AppraisalsCoreDocument = gql`
    query AppraisalsCore($statuses: String!, $withInventory: Boolean!, $location: String!, $search: String!, $page: Int!, $size: Int!, $purchased: Boolean) {
  appraisalsCore(
    statuses: $statuses
    location: $location
    withInventory: $withInventory
    search: $search
    page: $page
    size: $size
    purchased: $purchased
  ) @rest(type: "AppraisalsMethod", path: "/private/physical-evaluation?statuses={args.statuses}&location={args.location}&inventory={args.withInventory}&page={args.page}&limit={args.size}&select=id,vin,created,ownerId,submitted,completedById,completed,appraisalValue,offeredValue,location,odometer,odometerMiles,location,status,origin,photos,paveSessionStatus,modelYear,make,model,series,traction,transmissionType,assessingStarted,assessedById,assessingStarted,eraSalesCode,isPurchased&search={args.search}&purchased={args.purchased}", method: "GET", endpoint: "core-service") {
    last
    result {
      id
      odometer
      odometerMiles
      location
      appraisalValue
      offeredValue
      modelYear
      make
      model
      series
      status
      origin
      traction
      transmissionType
      vin
      ownerId
      started
      startedById
      completed
      completedById
      created
      submitted
      paveSessionStatus
      photos {
        id
        src
        zone
      }
      assessingStarted
      assessedById
      assessingStarted
      eraSalesCode
      isPurchased
    }
  }
}
    `;

/**
 * __useAppraisalsCoreQuery__
 *
 * To run a query within a React component, call `useAppraisalsCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsCoreQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      withInventory: // value for 'withInventory'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      purchased: // value for 'purchased'
 *   },
 * });
 */
export function useAppraisalsCoreQuery(baseOptions: Apollo.QueryHookOptions<AppraisalsCoreQuery, AppraisalsCoreQueryVariables> & ({ variables: AppraisalsCoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>(AppraisalsCoreDocument, options);
      }
export function useAppraisalsCoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>(AppraisalsCoreDocument, options);
        }
export function useAppraisalsCoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>(AppraisalsCoreDocument, options);
        }
export type AppraisalsCoreQueryHookResult = ReturnType<typeof useAppraisalsCoreQuery>;
export type AppraisalsCoreLazyQueryHookResult = ReturnType<typeof useAppraisalsCoreLazyQuery>;
export type AppraisalsCoreSuspenseQueryHookResult = ReturnType<typeof useAppraisalsCoreSuspenseQuery>;
export type AppraisalsCoreQueryResult = Apollo.QueryResult<AppraisalsCoreQuery, AppraisalsCoreQueryVariables>;
export const AppraisalsPurchasedCoreDocument = gql`
    query AppraisalsPurchasedCore($statuses: String!, $withInventory: Boolean!, $location: String!, $search: String!, $page: Int!, $size: Int!) {
  appraisalsCore(
    statuses: $statuses
    location: $location
    withInventory: $withInventory
    search: $search
    page: $page
    size: $size
  ) @rest(type: "AppraisalsMethod", path: "/private/physical-evaluation/purchased?statuses={args.statuses}&location={args.location}&inventory={args.withInventory}&page={args.page}&limit={args.size}&select=isPurchased,id,vin,created,ownerId,submitted,completedById,completed,appraisalValue,offeredValue,location,odometer,odometerMiles,location,status,origin,photos,paveSessionStatus,modelYear,make,model,series,traction,transmissionType,assessingStarted,assessedById,assessingStarted,eraSalesCode,isPurchased&search={args.search}", method: "GET", endpoint: "core-service") {
    last
    result {
      id
      odometer
      odometerMiles
      location
      appraisalValue
      offeredValue
      modelYear
      make
      model
      series
      status
      origin
      traction
      transmissionType
      vin
      ownerId
      started
      startedById
      completed
      completedById
      created
      submitted
      paveSessionStatus
      photos {
        id
        src
        zone
      }
      assessingStarted
      assessedById
      assessingStarted
      eraSalesCode
      isPurchased
    }
  }
}
    `;

/**
 * __useAppraisalsPurchasedCoreQuery__
 *
 * To run a query within a React component, call `useAppraisalsPurchasedCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalsPurchasedCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalsPurchasedCoreQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      withInventory: // value for 'withInventory'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useAppraisalsPurchasedCoreQuery(baseOptions: Apollo.QueryHookOptions<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables> & ({ variables: AppraisalsPurchasedCoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>(AppraisalsPurchasedCoreDocument, options);
      }
export function useAppraisalsPurchasedCoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>(AppraisalsPurchasedCoreDocument, options);
        }
export function useAppraisalsPurchasedCoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>(AppraisalsPurchasedCoreDocument, options);
        }
export type AppraisalsPurchasedCoreQueryHookResult = ReturnType<typeof useAppraisalsPurchasedCoreQuery>;
export type AppraisalsPurchasedCoreLazyQueryHookResult = ReturnType<typeof useAppraisalsPurchasedCoreLazyQuery>;
export type AppraisalsPurchasedCoreSuspenseQueryHookResult = ReturnType<typeof useAppraisalsPurchasedCoreSuspenseQuery>;
export type AppraisalsPurchasedCoreQueryResult = Apollo.QueryResult<AppraisalsPurchasedCoreQuery, AppraisalsPurchasedCoreQueryVariables>;
export const AppraisalVehByIdCoreDocument = gql`
    query AppraisalVehByIdCore($id: String!) {
  appraisalVehByIdCore(id: $id) @rest(type: "AppraisalMethod", path: "/private/physical-evaluation/{args.id}", method: "GET", endpoint: "core-service") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      vin
      licenseState
      licensePlate
      origin
      odometer
      odometerMiles
      styleId
      isPurchased
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      inventory {
        Stock
        dashboardUrl
      }
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;

/**
 * __useAppraisalVehByIdCoreQuery__
 *
 * To run a query within a React component, call `useAppraisalVehByIdCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalVehByIdCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalVehByIdCoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppraisalVehByIdCoreQuery(baseOptions: Apollo.QueryHookOptions<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables> & ({ variables: AppraisalVehByIdCoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>(AppraisalVehByIdCoreDocument, options);
      }
export function useAppraisalVehByIdCoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>(AppraisalVehByIdCoreDocument, options);
        }
export function useAppraisalVehByIdCoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>(AppraisalVehByIdCoreDocument, options);
        }
export type AppraisalVehByIdCoreQueryHookResult = ReturnType<typeof useAppraisalVehByIdCoreQuery>;
export type AppraisalVehByIdCoreLazyQueryHookResult = ReturnType<typeof useAppraisalVehByIdCoreLazyQuery>;
export type AppraisalVehByIdCoreSuspenseQueryHookResult = ReturnType<typeof useAppraisalVehByIdCoreSuspenseQuery>;
export type AppraisalVehByIdCoreQueryResult = Apollo.QueryResult<AppraisalVehByIdCoreQuery, AppraisalVehByIdCoreQueryVariables>;
export const AppraisalVehByIdWithInventoryCoreDocument = gql`
    query AppraisalVehByIdWithInventoryCore($id: String!) {
  appraisalVehByIdWithInventoryCore(id: $id) @rest(type: "AppraisalMethod", path: "/public/condition-report/{args.id}", method: "GET", endpoint: "core-service") {
    result {
      _id
      id
      vAutoId
      carfax {
        link
        date
      }
      appraisalValue
      location
      status
      type
      isPurchased
      vin
      licenseState
      licensePlate
      origin
      odometer
      odometerMiles
      styleId
      modelYear
      make
      model
      series
      engineCylinderCount
      engineCylinderLabel
      bodyType
      transmissionType
      originDate
      traction
      exteriorColor
      interiorColor
      vehicleType
      trimName
      saleType
      stock
      vehicleReport {
        id
        key
        name
        todo
        icon
        description
        status
        select {
          name
          value
          description
        }
        images {
          id
          src
          zone
        }
      }
      noticeByManagers
      custFirstName
      custLastName
      custEmail
      custHomePhone
      custAddress
      custCity
      custPostalCode
      custState
      isPhotosCertifyUser
      disclosures
      disclosuresUS {
        name
        text
        value
        children
      }
      offerAsSeen
      offerPrinted
      offeredValue
      offeredPrice
      formulaPrice
      custAmountOwed
      custDriverLicense
      attachments {
        created
        type
        src
        text
        name
        user_id
      }
      photos {
        id
        src
        zone
      }
      options
      conditionReportLink
      inspectionReportLinkOtherService
      conditionReportStatus
      conditioningStarted
      conditions {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      optionsVinDecode {
        styleId
        price {
          invoiceMax
          invoiceMin
          msrpMax
          msrpMin
          unknown
        }
        optionKindId
        chromeCode
        description
        optionKindId
      }
      optionsVinDecodeBB {
        auto
        avg
        clean
        name
        resid12
        resid24
        resid30
        resid36
        resid42
        resid48
        resid60
        resid72
        rough
        uoc
        xclean
      }
      odometerReplaced
      claims
      commerciallyUsed
      commonId
      disclosuresWork {
        name
        label
        translationLabel {
          en
          fr
        }
        photos {
          id
          src
          zone
        }
        text
        order
        todo
        pin {
          x
          y
          mapName
        }
        detail {
          name
          label
          mustAttach
          order
          value
        }
        pending
        workStarted
        workStartedById
        conditioned
        conditionedById
        isArbitrageDamege
        mapped
      }
      emissionSystemIntact
      eraInvoiceAmount
      eraSalesCode
      exitStrategyType
      factoryBuyback
      floodDamage
      hasConditionReport
      inspectionReportLink
      isConditionReportComplete
      leaseReturn
      parkingZoneSegmentation
      previouslyDamaged
      publicId
      recall
      salesperson
      salvaged
      source
      appraiser
      submitted
      created
      updated
      expired
      monkaiInspectionId
      monkaiInspectionStatus
      photosMonkaiInspections {
        id
        src
        srcMonkai
      }
      manheimData {
        href
        count
        items {
          href
          extendedCoverage
          description {
            year
            make
            model
            trim
            subSeries
          }
          wholesale {
            above
            average
            below
          }
          averageOdometer
          odometerUnits
          averageGrade
          adjustedPricing {
            wholesale {
              above
              average
              below
            }
            adjustedBy {
              Grade
              Odometer
            }
          }
          currency
          sampleSize
          requestedDate
          returnedDate
        }
      }
      drivlyDataOffers {
        vin
        year
        make
        model
        mileage
        vroomPrice
        vroomGrade
        vroomUrl
        vroomError {
          error
        }
        carvanaPrice
        carvanaUrl
        carvanaError {
          error
        }
        carmaxPrice
        carmaxUrl
        carmaxDeclineReason
        carmaxError {
          error
        }
        retry
      }
      blackbookData {
        reatailPrices {
          adjustedRetailAvg
          adjustedRetailClean
          adjustedRetailRough
          adjustedRetailXclean
          baseRetailAvg
          baseRetailClean
          baseRetailRough
          baseRetailXclean
        }
        wholeSalePrices {
          adjustedWholeAvg
          adjustedWholeClean
          adjustedWholeRough
          adjustedWholeXclean
          baseWholeAvg
          baseWholeClean
          baseWholeRough
          baseWholeXclean
        }
        tradeinPrices {
          adjustedTradeinAvg
          adjustedTradeinClean
          adjustedTradeinRough
          adjustedTradeinXclean
          baseTradeinAvg
          baseTradeinClean
          baseTradeinRough
          baseTradeinXclean
        }
      }
      drivlyDataGurus {
        vin
        retail
        privateParty
        trade
        error
      }
      userEvaluationId
      owner
      ownerId
      started
      startedById
      completed
      completedById
      approved
      approvedById
      assessingStarted
      assessed
      assessedById
      authorized
      authorizedById
      conditioningStarted
      closer
      closerId
      paveSessionId
      paveSessionStatus
      challenged
      challengedById
      challengedByLocation
      challengeResolved
      rep
      repId
      inventory {
        Stock
        dashboardUrl
      }
      tireThread {
        frontLeft
        frontRight
        rearLeft
        rearRight
        mountedOn
        season
        secondSet {
          frontLeft
          frontRight
          rearLeft
          rearRight
          mountedOn
          season
        }
        photos {
          id
          src
          zone
        }
      }
      sourceNameReport
      comments {
        client
        document
        appraiser
      }
      application {
        name
        hostname
        version
      }
    }
  }
}
    `;

/**
 * __useAppraisalVehByIdWithInventoryCoreQuery__
 *
 * To run a query within a React component, call `useAppraisalVehByIdWithInventoryCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalVehByIdWithInventoryCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalVehByIdWithInventoryCoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppraisalVehByIdWithInventoryCoreQuery(baseOptions: Apollo.QueryHookOptions<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables> & ({ variables: AppraisalVehByIdWithInventoryCoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>(AppraisalVehByIdWithInventoryCoreDocument, options);
      }
export function useAppraisalVehByIdWithInventoryCoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>(AppraisalVehByIdWithInventoryCoreDocument, options);
        }
export function useAppraisalVehByIdWithInventoryCoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>(AppraisalVehByIdWithInventoryCoreDocument, options);
        }
export type AppraisalVehByIdWithInventoryCoreQueryHookResult = ReturnType<typeof useAppraisalVehByIdWithInventoryCoreQuery>;
export type AppraisalVehByIdWithInventoryCoreLazyQueryHookResult = ReturnType<typeof useAppraisalVehByIdWithInventoryCoreLazyQuery>;
export type AppraisalVehByIdWithInventoryCoreSuspenseQueryHookResult = ReturnType<typeof useAppraisalVehByIdWithInventoryCoreSuspenseQuery>;
export type AppraisalVehByIdWithInventoryCoreQueryResult = Apollo.QueryResult<AppraisalVehByIdWithInventoryCoreQuery, AppraisalVehByIdWithInventoryCoreQueryVariables>;
export const UpdateVehicleUserEvaluationDocument = gql`
    mutation UpdateVehicleUserEvaluation($id: String!, $input: AppriselVehicleInput!) {
  updateVehicleUserEvaluation(id: $id, input: $input) @rest(type: "AppriselVehicleType", path: "/private/user-evaluation/{args.id}", method: "PATCH", endpoint: "core-service") {
    _id
  }
}
    `;
export type UpdateVehicleUserEvaluationMutationFn = Apollo.MutationFunction<UpdateVehicleUserEvaluationMutation, UpdateVehicleUserEvaluationMutationVariables>;

/**
 * __useUpdateVehicleUserEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleUserEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleUserEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleUserEvaluationMutation, { data, loading, error }] = useUpdateVehicleUserEvaluationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleUserEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleUserEvaluationMutation, UpdateVehicleUserEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleUserEvaluationMutation, UpdateVehicleUserEvaluationMutationVariables>(UpdateVehicleUserEvaluationDocument, options);
      }
export type UpdateVehicleUserEvaluationMutationHookResult = ReturnType<typeof useUpdateVehicleUserEvaluationMutation>;
export type UpdateVehicleUserEvaluationMutationResult = Apollo.MutationResult<UpdateVehicleUserEvaluationMutation>;
export type UpdateVehicleUserEvaluationMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleUserEvaluationMutation, UpdateVehicleUserEvaluationMutationVariables>;
export const CompleteVehicleUserEvaluationDocument = gql`
    mutation CompleteVehicleUserEvaluation($id: String!, $input: CompleteVehicleUserEvaluationInput!) {
  completeVehicleUserEvaluation(id: $id, input: $input) @rest(type: "CompleteVehicleUserEvaluationMethod", path: "/private/user-evaluation/complete/{args.id}", method: "POST", endpoint: "core-service") {
    evaluationId
    price
  }
}
    `;
export type CompleteVehicleUserEvaluationMutationFn = Apollo.MutationFunction<CompleteVehicleUserEvaluationMutation, CompleteVehicleUserEvaluationMutationVariables>;

/**
 * __useCompleteVehicleUserEvaluationMutation__
 *
 * To run a mutation, you first call `useCompleteVehicleUserEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVehicleUserEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVehicleUserEvaluationMutation, { data, loading, error }] = useCompleteVehicleUserEvaluationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteVehicleUserEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteVehicleUserEvaluationMutation, CompleteVehicleUserEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteVehicleUserEvaluationMutation, CompleteVehicleUserEvaluationMutationVariables>(CompleteVehicleUserEvaluationDocument, options);
      }
export type CompleteVehicleUserEvaluationMutationHookResult = ReturnType<typeof useCompleteVehicleUserEvaluationMutation>;
export type CompleteVehicleUserEvaluationMutationResult = Apollo.MutationResult<CompleteVehicleUserEvaluationMutation>;
export type CompleteVehicleUserEvaluationMutationOptions = Apollo.BaseMutationOptions<CompleteVehicleUserEvaluationMutation, CompleteVehicleUserEvaluationMutationVariables>;
export const UpdatePhysicalEvaluationDocument = gql`
    mutation UpdatePhysicalEvaluation($id: String!, $input: AppraisalUpdateWithSocketInput!) {
  updatePhysicalEvaluation(id: $id, input: $input) @rest(type: "AppriselVehicleType", path: "/private/physical-evaluation/{args.id}/", method: "PATCH", endpoint: "core-service") {
    modified
    evaluation {
      _id
    }
  }
}
    `;
export type UpdatePhysicalEvaluationMutationFn = Apollo.MutationFunction<UpdatePhysicalEvaluationMutation, UpdatePhysicalEvaluationMutationVariables>;

/**
 * __useUpdatePhysicalEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdatePhysicalEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhysicalEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhysicalEvaluationMutation, { data, loading, error }] = useUpdatePhysicalEvaluationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhysicalEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhysicalEvaluationMutation, UpdatePhysicalEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhysicalEvaluationMutation, UpdatePhysicalEvaluationMutationVariables>(UpdatePhysicalEvaluationDocument, options);
      }
export type UpdatePhysicalEvaluationMutationHookResult = ReturnType<typeof useUpdatePhysicalEvaluationMutation>;
export type UpdatePhysicalEvaluationMutationResult = Apollo.MutationResult<UpdatePhysicalEvaluationMutation>;
export type UpdatePhysicalEvaluationMutationOptions = Apollo.BaseMutationOptions<UpdatePhysicalEvaluationMutation, UpdatePhysicalEvaluationMutationVariables>;
export const CreatePhysicalEvaluationDocument = gql`
    mutation CreatePhysicalEvaluation($input: AppraisalUpdateInput!) {
  createPhysicalEvaluation(input: $input) @rest(type: "AppriselVehicleType", path: "/private/physical-evaluation/", method: "POST", endpoint: "core-service") {
    exists
    result {
      _id
      id
    }
  }
}
    `;
export type CreatePhysicalEvaluationMutationFn = Apollo.MutationFunction<CreatePhysicalEvaluationMutation, CreatePhysicalEvaluationMutationVariables>;

/**
 * __useCreatePhysicalEvaluationMutation__
 *
 * To run a mutation, you first call `useCreatePhysicalEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhysicalEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhysicalEvaluationMutation, { data, loading, error }] = useCreatePhysicalEvaluationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePhysicalEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhysicalEvaluationMutation, CreatePhysicalEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhysicalEvaluationMutation, CreatePhysicalEvaluationMutationVariables>(CreatePhysicalEvaluationDocument, options);
      }
export type CreatePhysicalEvaluationMutationHookResult = ReturnType<typeof useCreatePhysicalEvaluationMutation>;
export type CreatePhysicalEvaluationMutationResult = Apollo.MutationResult<CreatePhysicalEvaluationMutation>;
export type CreatePhysicalEvaluationMutationOptions = Apollo.BaseMutationOptions<CreatePhysicalEvaluationMutation, CreatePhysicalEvaluationMutationVariables>;
export const VAutoCheckCoreDocument = gql`
    query VAutoCheckCore($vin: String!) {
  vAutoCheckCore(vin: $vin) @rest(type: "VAutoCheckMethod", path: "/private/physical-evaluation/vavto-check/{args.vin}", method: "GET", endpoint: "core-service") {
    GetAppraisalWithPricingDataByVinResult {
      Appraisal {
        Appraiser {
          Email
          FirstName
          LastName
          UserName
        }
      }
    }
  }
}
    `;

/**
 * __useVAutoCheckCoreQuery__
 *
 * To run a query within a React component, call `useVAutoCheckCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useVAutoCheckCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVAutoCheckCoreQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useVAutoCheckCoreQuery(baseOptions: Apollo.QueryHookOptions<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables> & ({ variables: VAutoCheckCoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>(VAutoCheckCoreDocument, options);
      }
export function useVAutoCheckCoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>(VAutoCheckCoreDocument, options);
        }
export function useVAutoCheckCoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>(VAutoCheckCoreDocument, options);
        }
export type VAutoCheckCoreQueryHookResult = ReturnType<typeof useVAutoCheckCoreQuery>;
export type VAutoCheckCoreLazyQueryHookResult = ReturnType<typeof useVAutoCheckCoreLazyQuery>;
export type VAutoCheckCoreSuspenseQueryHookResult = ReturnType<typeof useVAutoCheckCoreSuspenseQuery>;
export type VAutoCheckCoreQueryResult = Apollo.QueryResult<VAutoCheckCoreQuery, VAutoCheckCoreQueryVariables>;
export const SentEmailWithPriceDrivlyDocument = gql`
    mutation SentEmailWithPriceDrivly($input: SentEmailInput!) {
  sentEmail(input: $input) @rest(type: "SentEmailMethod", path: "/email", method: "POST", endpoint: "core-service") {
    modified
  }
}
    `;
export type SentEmailWithPriceDrivlyMutationFn = Apollo.MutationFunction<SentEmailWithPriceDrivlyMutation, SentEmailWithPriceDrivlyMutationVariables>;

/**
 * __useSentEmailWithPriceDrivlyMutation__
 *
 * To run a mutation, you first call `useSentEmailWithPriceDrivlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentEmailWithPriceDrivlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentEmailWithPriceDrivlyMutation, { data, loading, error }] = useSentEmailWithPriceDrivlyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSentEmailWithPriceDrivlyMutation(baseOptions?: Apollo.MutationHookOptions<SentEmailWithPriceDrivlyMutation, SentEmailWithPriceDrivlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SentEmailWithPriceDrivlyMutation, SentEmailWithPriceDrivlyMutationVariables>(SentEmailWithPriceDrivlyDocument, options);
      }
export type SentEmailWithPriceDrivlyMutationHookResult = ReturnType<typeof useSentEmailWithPriceDrivlyMutation>;
export type SentEmailWithPriceDrivlyMutationResult = Apollo.MutationResult<SentEmailWithPriceDrivlyMutation>;
export type SentEmailWithPriceDrivlyMutationOptions = Apollo.BaseMutationOptions<SentEmailWithPriceDrivlyMutation, SentEmailWithPriceDrivlyMutationVariables>;
export const AppraisalVehByVinDocument = gql`
    query AppraisalVehByVin($vin: String!) {
  appraisalVehByVin(vin: $vin) @rest(type: "AppraisalVehByVinMethod", path: "/private/physical-evaluation/vin/{args.vin}?select=_id,id,appraisalValue,location,status,vin,modelYear,make,model,series,custFirstName,custLastName,custEmail,custHomePhone,custAddress,custCity,custPostalCode,created,offeredValue,custStatem,completed", method: "GET", endpoint: "core-service") {
    result {
      virtual {
        _id
        id
        vin
        location
        modelYear
        make
        model
        series
        offeredValue
        created
        appraisalValue
      }
      physical {
        _id
        id
        appraisalValue
        offeredValue
        location
        status
        vin
        modelYear
        make
        model
        series
        custFirstName
        custLastName
        custEmail
        custHomePhone
        custAddress
        custCity
        custPostalCode
        custState
        completed
        offeredValue
        created
      }
      condition {
        id
      }
    }
  }
}
    `;

/**
 * __useAppraisalVehByVinQuery__
 *
 * To run a query within a React component, call `useAppraisalVehByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalVehByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalVehByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useAppraisalVehByVinQuery(baseOptions: Apollo.QueryHookOptions<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables> & ({ variables: AppraisalVehByVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>(AppraisalVehByVinDocument, options);
      }
export function useAppraisalVehByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>(AppraisalVehByVinDocument, options);
        }
export function useAppraisalVehByVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>(AppraisalVehByVinDocument, options);
        }
export type AppraisalVehByVinQueryHookResult = ReturnType<typeof useAppraisalVehByVinQuery>;
export type AppraisalVehByVinLazyQueryHookResult = ReturnType<typeof useAppraisalVehByVinLazyQuery>;
export type AppraisalVehByVinSuspenseQueryHookResult = ReturnType<typeof useAppraisalVehByVinSuspenseQuery>;
export type AppraisalVehByVinQueryResult = Apollo.QueryResult<AppraisalVehByVinQuery, AppraisalVehByVinQueryVariables>;
export const VirtualVehByVinDocument = gql`
    query VirtualVehByVin($vin: String!) {
  virtualVehByVin(vin: $vin) @rest(type: "VirtualVehByVinMethod", path: "/private/virtual/vin/{args.vin}?select=vin,id,appraisalValue,completed,location,make,model,modelYear", method: "GET", endpoint: "core-service") {
    result {
      _id
      id
      vin
      id
      appraisalValue
      completed
      location
      make
      model
      modelYear
    }
  }
}
    `;

/**
 * __useVirtualVehByVinQuery__
 *
 * To run a query within a React component, call `useVirtualVehByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualVehByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualVehByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useVirtualVehByVinQuery(baseOptions: Apollo.QueryHookOptions<VirtualVehByVinQuery, VirtualVehByVinQueryVariables> & ({ variables: VirtualVehByVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>(VirtualVehByVinDocument, options);
      }
export function useVirtualVehByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>(VirtualVehByVinDocument, options);
        }
export function useVirtualVehByVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>(VirtualVehByVinDocument, options);
        }
export type VirtualVehByVinQueryHookResult = ReturnType<typeof useVirtualVehByVinQuery>;
export type VirtualVehByVinLazyQueryHookResult = ReturnType<typeof useVirtualVehByVinLazyQuery>;
export type VirtualVehByVinSuspenseQueryHookResult = ReturnType<typeof useVirtualVehByVinSuspenseQuery>;
export type VirtualVehByVinQueryResult = Apollo.QueryResult<VirtualVehByVinQuery, VirtualVehByVinQueryVariables>;
export const GetStatusCountDocument = gql`
    query GetStatusCount($service: String!, $status: String!, $filter: String!) {
  getStatusCount(service: $service, status: $status, filter: $filter) @rest(type: "StatusCountResponse", path: "{args.service}/count/{args.status}?{args.filter}", method: "GET", endpoint: "backend") {
    operation
    result
  }
}
    `;

/**
 * __useGetStatusCountQuery__
 *
 * To run a query within a React component, call `useGetStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusCountQuery({
 *   variables: {
 *      service: // value for 'service'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStatusCountQuery(baseOptions: Apollo.QueryHookOptions<GetStatusCountQuery, GetStatusCountQueryVariables> & ({ variables: GetStatusCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusCountQuery, GetStatusCountQueryVariables>(GetStatusCountDocument, options);
      }
export function useGetStatusCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusCountQuery, GetStatusCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusCountQuery, GetStatusCountQueryVariables>(GetStatusCountDocument, options);
        }
export function useGetStatusCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStatusCountQuery, GetStatusCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatusCountQuery, GetStatusCountQueryVariables>(GetStatusCountDocument, options);
        }
export type GetStatusCountQueryHookResult = ReturnType<typeof useGetStatusCountQuery>;
export type GetStatusCountLazyQueryHookResult = ReturnType<typeof useGetStatusCountLazyQuery>;
export type GetStatusCountSuspenseQueryHookResult = ReturnType<typeof useGetStatusCountSuspenseQuery>;
export type GetStatusCountQueryResult = Apollo.QueryResult<GetStatusCountQuery, GetStatusCountQueryVariables>;
export const GetGapCountDocument = gql`
    query GetGapCount($service: String!, $filter: String!) {
  getSlaGap(service: $service, filter: $filter) @rest(type: "GlaResponse", path: "/private/analytics/{args.service}/gap?{args.filter}", method: "GET", endpoint: "core-service") {
    operation
    result {
      totalAppraisal
      totalAsking
      totalGap
      count
      name
    }
  }
}
    `;

/**
 * __useGetGapCountQuery__
 *
 * To run a query within a React component, call `useGetGapCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGapCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGapCountQuery({
 *   variables: {
 *      service: // value for 'service'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGapCountQuery(baseOptions: Apollo.QueryHookOptions<GetGapCountQuery, GetGapCountQueryVariables> & ({ variables: GetGapCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGapCountQuery, GetGapCountQueryVariables>(GetGapCountDocument, options);
      }
export function useGetGapCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGapCountQuery, GetGapCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGapCountQuery, GetGapCountQueryVariables>(GetGapCountDocument, options);
        }
export function useGetGapCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGapCountQuery, GetGapCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGapCountQuery, GetGapCountQueryVariables>(GetGapCountDocument, options);
        }
export type GetGapCountQueryHookResult = ReturnType<typeof useGetGapCountQuery>;
export type GetGapCountLazyQueryHookResult = ReturnType<typeof useGetGapCountLazyQuery>;
export type GetGapCountSuspenseQueryHookResult = ReturnType<typeof useGetGapCountSuspenseQuery>;
export type GetGapCountQueryResult = Apollo.QueryResult<GetGapCountQuery, GetGapCountQueryVariables>;
export const GetPropertCountDocument = gql`
    query GetPropertCount($service: String!, $value: String!, $prop: String!, $filter: String!) {
  getPropertyCount(service: $service, value: $value, prop: $prop, filter: $filter) @rest(type: "StatusCountResponse", path: "{args.service}/prop-count/{args.prop}/{args.value}?appraisalValue=1&{args.filter}", method: "GET", endpoint: "backend") {
    operation
    result
  }
}
    `;

/**
 * __useGetPropertCountQuery__
 *
 * To run a query within a React component, call `useGetPropertCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertCountQuery({
 *   variables: {
 *      service: // value for 'service'
 *      value: // value for 'value'
 *      prop: // value for 'prop'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPropertCountQuery(baseOptions: Apollo.QueryHookOptions<GetPropertCountQuery, GetPropertCountQueryVariables> & ({ variables: GetPropertCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertCountQuery, GetPropertCountQueryVariables>(GetPropertCountDocument, options);
      }
export function useGetPropertCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertCountQuery, GetPropertCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertCountQuery, GetPropertCountQueryVariables>(GetPropertCountDocument, options);
        }
export function useGetPropertCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPropertCountQuery, GetPropertCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertCountQuery, GetPropertCountQueryVariables>(GetPropertCountDocument, options);
        }
export type GetPropertCountQueryHookResult = ReturnType<typeof useGetPropertCountQuery>;
export type GetPropertCountLazyQueryHookResult = ReturnType<typeof useGetPropertCountLazyQuery>;
export type GetPropertCountSuspenseQueryHookResult = ReturnType<typeof useGetPropertCountSuspenseQuery>;
export type GetPropertCountQueryResult = Apollo.QueryResult<GetPropertCountQuery, GetPropertCountQueryVariables>;
export const GetFollowCountDocument = gql`
    query GetFollowCount($service: String!, $filter: String!) {
  getSlaFollow(service: $service, filter: $filter) @rest(type: "GlaFollowResponse", path: "/private/analytics/{args.service}/follow?{args.filter}", method: "GET", endpoint: "core-service") {
    operation
    result {
      booked
      called
      count
      none
      priced
      smsSent
      name
    }
  }
}
    `;

/**
 * __useGetFollowCountQuery__
 *
 * To run a query within a React component, call `useGetFollowCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowCountQuery({
 *   variables: {
 *      service: // value for 'service'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFollowCountQuery(baseOptions: Apollo.QueryHookOptions<GetFollowCountQuery, GetFollowCountQueryVariables> & ({ variables: GetFollowCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFollowCountQuery, GetFollowCountQueryVariables>(GetFollowCountDocument, options);
      }
export function useGetFollowCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFollowCountQuery, GetFollowCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFollowCountQuery, GetFollowCountQueryVariables>(GetFollowCountDocument, options);
        }
export function useGetFollowCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFollowCountQuery, GetFollowCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFollowCountQuery, GetFollowCountQueryVariables>(GetFollowCountDocument, options);
        }
export type GetFollowCountQueryHookResult = ReturnType<typeof useGetFollowCountQuery>;
export type GetFollowCountLazyQueryHookResult = ReturnType<typeof useGetFollowCountLazyQuery>;
export type GetFollowCountSuspenseQueryHookResult = ReturnType<typeof useGetFollowCountSuspenseQuery>;
export type GetFollowCountQueryResult = Apollo.QueryResult<GetFollowCountQuery, GetFollowCountQueryVariables>;
export const GetEvalCountDocument = gql`
    query GetEvalCount($service: String!, $filter: String!) {
  getSlaEval(service: $service, filter: $filter) @rest(type: "GlaEvalResponse", path: "/private/analytics/{args.service}/eval?{args.filter}", method: "GET", endpoint: "core-service") {
    operation
    result {
      count
      diff
      total
      name
    }
  }
}
    `;

/**
 * __useGetEvalCountQuery__
 *
 * To run a query within a React component, call `useGetEvalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalCountQuery({
 *   variables: {
 *      service: // value for 'service'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEvalCountQuery(baseOptions: Apollo.QueryHookOptions<GetEvalCountQuery, GetEvalCountQueryVariables> & ({ variables: GetEvalCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEvalCountQuery, GetEvalCountQueryVariables>(GetEvalCountDocument, options);
      }
export function useGetEvalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEvalCountQuery, GetEvalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEvalCountQuery, GetEvalCountQueryVariables>(GetEvalCountDocument, options);
        }
export function useGetEvalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEvalCountQuery, GetEvalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEvalCountQuery, GetEvalCountQueryVariables>(GetEvalCountDocument, options);
        }
export type GetEvalCountQueryHookResult = ReturnType<typeof useGetEvalCountQuery>;
export type GetEvalCountLazyQueryHookResult = ReturnType<typeof useGetEvalCountLazyQuery>;
export type GetEvalCountSuspenseQueryHookResult = ReturnType<typeof useGetEvalCountSuspenseQuery>;
export type GetEvalCountQueryResult = Apollo.QueryResult<GetEvalCountQuery, GetEvalCountQueryVariables>;
export const VinStyleDocument = gql`
    query VinStyle($id: Int!, $country: Countries!) {
  vinStyle(id: $id, country: $country) @rest(type: "VinStyleMethod", path: "vin/style/{args.id}/{args.country}", method: "GET", endpoint: "backend") {
    operation
    result {
      dataFullCurrentStyle {
        currentStyleId
        drivetrain
        modelYear
        bestModelName
        bestMakeName
        bestStyleName
        bestTrimName
        cylinders
        bodyStyle
        transmissionTypeId
        transmissionDesc
        drivetrainDesc
        exteriorColor {
          genericColor {
            installed
            name
            primary
          }
          styleId
          installed
          colorCode
          colorName
          rgbValue
        }
        interiorColor {
          genericColor
          styleId
          installed
          colorCode
          colorName
          rgbValue
        }
      }
    }
  }
}
    `;

/**
 * __useVinStyleQuery__
 *
 * To run a query within a React component, call `useVinStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinStyleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useVinStyleQuery(baseOptions: Apollo.QueryHookOptions<VinStyleQuery, VinStyleQueryVariables> & ({ variables: VinStyleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VinStyleQuery, VinStyleQueryVariables>(VinStyleDocument, options);
      }
export function useVinStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VinStyleQuery, VinStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VinStyleQuery, VinStyleQueryVariables>(VinStyleDocument, options);
        }
export function useVinStyleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VinStyleQuery, VinStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VinStyleQuery, VinStyleQueryVariables>(VinStyleDocument, options);
        }
export type VinStyleQueryHookResult = ReturnType<typeof useVinStyleQuery>;
export type VinStyleLazyQueryHookResult = ReturnType<typeof useVinStyleLazyQuery>;
export type VinStyleSuspenseQueryHookResult = ReturnType<typeof useVinStyleSuspenseQuery>;
export type VinStyleQueryResult = Apollo.QueryResult<VinStyleQuery, VinStyleQueryVariables>;
export const DecodeVinDocument = gql`
    query DecodeVin($vin: String!, $country: Countries!) {
  decodeVin(vin: $vin, country: $country) @rest(type: "DecodeVinMethod", path: "vin/decode/{args.vin}/{args.country}", method: "GET", endpoint: "backend") {
    operation
    result {
      countStyles
      styles {
        division {
          value
          id
        }
        subdivision {
          value
          id
        }
        model {
          value
          id
        }
        basePrice {
          unknown
          invoice
          msrp
          destination
        }
        bodyType {
          value
          id
          primary
        }
        marketClass {
          value
          id
        }
        stockImage {
          url
          width
          height
          filename
        }
        mediaGallery
        acode {
          value
          logic
        }
        id
        modelYear
        name
        nameWoTrim
        trim
        mfrModelCode
        fleetOnly
        modelFleet
        passDoors
        altModelName
        altStyleName
        altBodyType
        drivetrain
      }
      dataFullCurrentStyle {
        currentStyleId
        drivetrain
        modelYear
        bestModelName
        bestMakeName
        bestStyleName
        bestTrimName
        cylinders
        bodyStyle
        transmissionTypeId
        transmissionDesc
        drivetrainDesc
        exteriorColor {
          genericColor {
            installed
            name
            primary
          }
          styleId
          installed
          colorCode
          colorName
          rgbValue
        }
        interiorColor {
          genericColor
          styleId
          installed
          colorCode
          colorName
          rgbValue
        }
      }
    }
  }
}
    `;

/**
 * __useDecodeVinQuery__
 *
 * To run a query within a React component, call `useDecodeVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecodeVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecodeVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useDecodeVinQuery(baseOptions: Apollo.QueryHookOptions<DecodeVinQuery, DecodeVinQueryVariables> & ({ variables: DecodeVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecodeVinQuery, DecodeVinQueryVariables>(DecodeVinDocument, options);
      }
export function useDecodeVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecodeVinQuery, DecodeVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecodeVinQuery, DecodeVinQueryVariables>(DecodeVinDocument, options);
        }
export function useDecodeVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DecodeVinQuery, DecodeVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecodeVinQuery, DecodeVinQueryVariables>(DecodeVinDocument, options);
        }
export type DecodeVinQueryHookResult = ReturnType<typeof useDecodeVinQuery>;
export type DecodeVinLazyQueryHookResult = ReturnType<typeof useDecodeVinLazyQuery>;
export type DecodeVinSuspenseQueryHookResult = ReturnType<typeof useDecodeVinSuspenseQuery>;
export type DecodeVinQueryResult = Apollo.QueryResult<DecodeVinQuery, DecodeVinQueryVariables>;
export const DecodeNewVinDocument = gql`
    query DecodeNewVin($vin: String!, $country: Countries!) {
  decodeVinNew(vin: $vin, country: $country) @rest(type: "DecodeVinNewMethod", path: "vin/exp-vindecode/{args.vin}?&country={args.country}", method: "GET", endpoint: "backend") {
    operation
    result {
      makeName
      modelName
      modelYear
      bestTrimName
      styles
      engines {
        label
        value
        styles
      }
      trims {
        label
        value
        styles
      }
      bodyStyles {
        label
        value
        styles
      }
      tractions {
        label
        value
        styles
      }
      transmissions {
        label
        value
        styles
      }
      options {
        nameGroup
        subOptions {
          styleId
          price {
            invoiceMax
            invoiceMin
            msrpMax
            msrpMin
            unknown
          }
          optionKindId
          chromeCode
          description
          optionKindId
        }
      }
      exteriorColors {
        code
        label
        value
        rgbValue
        styles
      }
      interiorColors {
        code
        label
        value
        rgbValue
        styles
      }
    }
  }
}
    `;

/**
 * __useDecodeNewVinQuery__
 *
 * To run a query within a React component, call `useDecodeNewVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecodeNewVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecodeNewVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useDecodeNewVinQuery(baseOptions: Apollo.QueryHookOptions<DecodeNewVinQuery, DecodeNewVinQueryVariables> & ({ variables: DecodeNewVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecodeNewVinQuery, DecodeNewVinQueryVariables>(DecodeNewVinDocument, options);
      }
export function useDecodeNewVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecodeNewVinQuery, DecodeNewVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecodeNewVinQuery, DecodeNewVinQueryVariables>(DecodeNewVinDocument, options);
        }
export function useDecodeNewVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DecodeNewVinQuery, DecodeNewVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecodeNewVinQuery, DecodeNewVinQueryVariables>(DecodeNewVinDocument, options);
        }
export type DecodeNewVinQueryHookResult = ReturnType<typeof useDecodeNewVinQuery>;
export type DecodeNewVinLazyQueryHookResult = ReturnType<typeof useDecodeNewVinLazyQuery>;
export type DecodeNewVinSuspenseQueryHookResult = ReturnType<typeof useDecodeNewVinSuspenseQuery>;
export type DecodeNewVinQueryResult = Apollo.QueryResult<DecodeNewVinQuery, DecodeNewVinQueryVariables>;
export const DecodeBlackBookVinDocument = gql`
    query DecodeBlackBookVin($vin: String!, $country: Countries!) {
  decodeVinBlackBook(vin: $vin, country: $country) @rest(type: "DecodeVinBlackBookMethod", path: "vin/bb-vindecode/{args.vin}?country={args.country}", method: "GET", endpoint: "backend") {
    operation
    result {
      makeName
      modelName
      modelYear
      bestTrimName
      styles
      engines {
        label
        value
        styles
      }
      trims {
        label
        value
        styles
      }
      bodyStyles {
        label
        value
        styles
      }
      tractions {
        label
        value
        styles
      }
      transmissions {
        label
        value
        styles
      }
      options {
        addDeductList {
          auto
          avg
          clean
          name
          resid12
          resid24
          resid30
          resid36
          resid42
          resid48
          resid60
          resid72
          rough
          uoc
          xclean
        }
        styles
      }
      exteriorColors {
        code
        label
        value
        rgbValue
        styles
      }
      interiorColors {
        code
        label
        value
        rgbValue
        styles
      }
    }
  }
}
    `;

/**
 * __useDecodeBlackBookVinQuery__
 *
 * To run a query within a React component, call `useDecodeBlackBookVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecodeBlackBookVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecodeBlackBookVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useDecodeBlackBookVinQuery(baseOptions: Apollo.QueryHookOptions<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables> & ({ variables: DecodeBlackBookVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>(DecodeBlackBookVinDocument, options);
      }
export function useDecodeBlackBookVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>(DecodeBlackBookVinDocument, options);
        }
export function useDecodeBlackBookVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>(DecodeBlackBookVinDocument, options);
        }
export type DecodeBlackBookVinQueryHookResult = ReturnType<typeof useDecodeBlackBookVinQuery>;
export type DecodeBlackBookVinLazyQueryHookResult = ReturnType<typeof useDecodeBlackBookVinLazyQuery>;
export type DecodeBlackBookVinSuspenseQueryHookResult = ReturnType<typeof useDecodeBlackBookVinSuspenseQuery>;
export type DecodeBlackBookVinQueryResult = Apollo.QueryResult<DecodeBlackBookVinQuery, DecodeBlackBookVinQueryVariables>;
export const NewBlackBookDecoderDocument = gql`
    query NewBlackBookDecoder($vin: String!) {
  newBlackBookDecoder(vin: $vin) @rest(type: "newBlackBookDecoderMethod", path: "/private/vin/series-list?vin={args.vin}", method: "GET", endpoint: "core-service") {
    trims {
      value
      label
      styles {
        value
        label
      }
    }
  }
}
    `;

/**
 * __useNewBlackBookDecoderQuery__
 *
 * To run a query within a React component, call `useNewBlackBookDecoderQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewBlackBookDecoderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewBlackBookDecoderQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useNewBlackBookDecoderQuery(baseOptions: Apollo.QueryHookOptions<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables> & ({ variables: NewBlackBookDecoderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>(NewBlackBookDecoderDocument, options);
      }
export function useNewBlackBookDecoderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>(NewBlackBookDecoderDocument, options);
        }
export function useNewBlackBookDecoderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>(NewBlackBookDecoderDocument, options);
        }
export type NewBlackBookDecoderQueryHookResult = ReturnType<typeof useNewBlackBookDecoderQuery>;
export type NewBlackBookDecoderLazyQueryHookResult = ReturnType<typeof useNewBlackBookDecoderLazyQuery>;
export type NewBlackBookDecoderSuspenseQueryHookResult = ReturnType<typeof useNewBlackBookDecoderSuspenseQuery>;
export type NewBlackBookDecoderQueryResult = Apollo.QueryResult<NewBlackBookDecoderQuery, NewBlackBookDecoderQueryVariables>;
export const NewBlackBookDetailDecoderDocument = gql`
    query NewBlackBookDetailDecoder($id: String!, $series: String) {
  newBlackBookDetailDecoder(id: $id, series: $series) @rest(type: "newBlackBookDetailDecoderMethod", path: "/private/vin/detail?id={args.id}&series={args.series}", method: "GET", endpoint: "core-service") {
    makeName
    modelName
    modelYear
    engine
    engineCylinderCount
    traction
    transmission
    trims {
      value
      label
      styles {
        value
        label
      }
    }
  }
}
    `;

/**
 * __useNewBlackBookDetailDecoderQuery__
 *
 * To run a query within a React component, call `useNewBlackBookDetailDecoderQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewBlackBookDetailDecoderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewBlackBookDetailDecoderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      series: // value for 'series'
 *   },
 * });
 */
export function useNewBlackBookDetailDecoderQuery(baseOptions: Apollo.QueryHookOptions<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables> & ({ variables: NewBlackBookDetailDecoderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>(NewBlackBookDetailDecoderDocument, options);
      }
export function useNewBlackBookDetailDecoderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>(NewBlackBookDetailDecoderDocument, options);
        }
export function useNewBlackBookDetailDecoderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>(NewBlackBookDetailDecoderDocument, options);
        }
export type NewBlackBookDetailDecoderQueryHookResult = ReturnType<typeof useNewBlackBookDetailDecoderQuery>;
export type NewBlackBookDetailDecoderLazyQueryHookResult = ReturnType<typeof useNewBlackBookDetailDecoderLazyQuery>;
export type NewBlackBookDetailDecoderSuspenseQueryHookResult = ReturnType<typeof useNewBlackBookDetailDecoderSuspenseQuery>;
export type NewBlackBookDetailDecoderQueryResult = Apollo.QueryResult<NewBlackBookDetailDecoderQuery, NewBlackBookDetailDecoderQueryVariables>;
export const VinDetailsDocument = gql`
    query VinDetails($vin: String!) {
  vinDetails(vin: $vin) @rest(type: "vinDetails", path: "/private/vin/detail?vin={args.vin}", method: "GET", endpoint: "core-service") {
    makeName
    modelName
    modelYear
  }
}
    `;

/**
 * __useVinDetailsQuery__
 *
 * To run a query within a React component, call `useVinDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinDetailsQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useVinDetailsQuery(baseOptions: Apollo.QueryHookOptions<VinDetailsQuery, VinDetailsQueryVariables> & ({ variables: VinDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VinDetailsQuery, VinDetailsQueryVariables>(VinDetailsDocument, options);
      }
export function useVinDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VinDetailsQuery, VinDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VinDetailsQuery, VinDetailsQueryVariables>(VinDetailsDocument, options);
        }
export function useVinDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VinDetailsQuery, VinDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VinDetailsQuery, VinDetailsQueryVariables>(VinDetailsDocument, options);
        }
export type VinDetailsQueryHookResult = ReturnType<typeof useVinDetailsQuery>;
export type VinDetailsLazyQueryHookResult = ReturnType<typeof useVinDetailsLazyQuery>;
export type VinDetailsSuspenseQueryHookResult = ReturnType<typeof useVinDetailsSuspenseQuery>;
export type VinDetailsQueryResult = Apollo.QueryResult<VinDetailsQuery, VinDetailsQueryVariables>;
export const AllFeatureForMyDocument = gql`
    query AllFeatureForMy {
  featureForMy @rest(type: "FeatureForMyResponse", path: "feature/user/me", method: "GET", endpoint: "backend") {
    result {
      _id
      name
      activate
      isDefault
    }
  }
}
    `;

/**
 * __useAllFeatureForMyQuery__
 *
 * To run a query within a React component, call `useAllFeatureForMyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeatureForMyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeatureForMyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeatureForMyQuery(baseOptions?: Apollo.QueryHookOptions<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>(AllFeatureForMyDocument, options);
      }
export function useAllFeatureForMyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>(AllFeatureForMyDocument, options);
        }
export function useAllFeatureForMySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>(AllFeatureForMyDocument, options);
        }
export type AllFeatureForMyQueryHookResult = ReturnType<typeof useAllFeatureForMyQuery>;
export type AllFeatureForMyLazyQueryHookResult = ReturnType<typeof useAllFeatureForMyLazyQuery>;
export type AllFeatureForMySuspenseQueryHookResult = ReturnType<typeof useAllFeatureForMySuspenseQuery>;
export type AllFeatureForMyQueryResult = Apollo.QueryResult<AllFeatureForMyQuery, AllFeatureForMyQueryVariables>;
export const ListiUsersFeatureDocument = gql`
    query ListiUsersFeature($id: String!) {
  listiUsersFeature(id: $id) @rest(type: "ListiUsersFeatureResponse", path: "feature/users/{args.id}", method: "GET", endpoint: "backend") {
    result {
      _id
      id
      email
      password
      firstName
      lastName
      phone
      type
      googleId
      teams
      roles
      segmentation
      samAccountName
      deleted
      enableFeature
      isDefault
    }
  }
}
    `;

/**
 * __useListiUsersFeatureQuery__
 *
 * To run a query within a React component, call `useListiUsersFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useListiUsersFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListiUsersFeatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListiUsersFeatureQuery(baseOptions: Apollo.QueryHookOptions<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables> & ({ variables: ListiUsersFeatureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>(ListiUsersFeatureDocument, options);
      }
export function useListiUsersFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>(ListiUsersFeatureDocument, options);
        }
export function useListiUsersFeatureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>(ListiUsersFeatureDocument, options);
        }
export type ListiUsersFeatureQueryHookResult = ReturnType<typeof useListiUsersFeatureQuery>;
export type ListiUsersFeatureLazyQueryHookResult = ReturnType<typeof useListiUsersFeatureLazyQuery>;
export type ListiUsersFeatureSuspenseQueryHookResult = ReturnType<typeof useListiUsersFeatureSuspenseQuery>;
export type ListiUsersFeatureQueryResult = Apollo.QueryResult<ListiUsersFeatureQuery, ListiUsersFeatureQueryVariables>;
export const ListiFeatureDocument = gql`
    query ListiFeature {
  listiFeature @rest(type: "ListiFeatureResponse", path: "feature/list", method: "GET", endpoint: "backend") {
    result {
      _id
      name
      activate
      description
      userIds
      created
      updated
      isDefault
    }
  }
}
    `;

/**
 * __useListiFeatureQuery__
 *
 * To run a query within a React component, call `useListiFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useListiFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListiFeatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useListiFeatureQuery(baseOptions?: Apollo.QueryHookOptions<ListiFeatureQuery, ListiFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListiFeatureQuery, ListiFeatureQueryVariables>(ListiFeatureDocument, options);
      }
export function useListiFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListiFeatureQuery, ListiFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListiFeatureQuery, ListiFeatureQueryVariables>(ListiFeatureDocument, options);
        }
export function useListiFeatureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListiFeatureQuery, ListiFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListiFeatureQuery, ListiFeatureQueryVariables>(ListiFeatureDocument, options);
        }
export type ListiFeatureQueryHookResult = ReturnType<typeof useListiFeatureQuery>;
export type ListiFeatureLazyQueryHookResult = ReturnType<typeof useListiFeatureLazyQuery>;
export type ListiFeatureSuspenseQueryHookResult = ReturnType<typeof useListiFeatureSuspenseQuery>;
export type ListiFeatureQueryResult = Apollo.QueryResult<ListiFeatureQuery, ListiFeatureQueryVariables>;
export const FeatureDocument = gql`
    query Feature($id: String!) {
  feature(id: $id) @rest(type: "FeatureResponse", path: "feature/{args.id}", method: "GET", endpoint: "backend") {
    result {
      _id
      name
      activate
      description
      userIds
      created
      updated
    }
  }
}
    `;

/**
 * __useFeatureQuery__
 *
 * To run a query within a React component, call `useFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureQuery(baseOptions: Apollo.QueryHookOptions<FeatureQuery, FeatureQueryVariables> & ({ variables: FeatureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
      }
export function useFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
        }
export function useFeatureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
        }
export type FeatureQueryHookResult = ReturnType<typeof useFeatureQuery>;
export type FeatureLazyQueryHookResult = ReturnType<typeof useFeatureLazyQuery>;
export type FeatureSuspenseQueryHookResult = ReturnType<typeof useFeatureSuspenseQuery>;
export type FeatureQueryResult = Apollo.QueryResult<FeatureQuery, FeatureQueryVariables>;
export const UpdateFeatureDocument = gql`
    mutation UpdateFeature($id: String!, $input: featureInput!) {
  featureUpdate(id: $id, input: $input) @rest(type: "ListingMethod", path: "feature/{args.id}", method: "PATCH", endpoint: "backend") {
    result {
      _id
      name
      activate
      description
      userIds
    }
  }
}
    `;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const ListingsDocument = gql`
    query Listings($statuses: String!, $location: String!, $search: String!, $size: Int!, $page: Int!) {
  listings(
    statuses: $statuses
    location: $location
    search: $search
    page: $page
    size: $size
  ) @rest(type: "ListingsMethod", path: "/private/virtual?statuses={args.statuses}&location={args.location}&search={args.search}&page={args.page}&limit={args.size}", method: "GET", endpoint: "core-service") {
    last
    result {
      id
      odometer
      odometerMiles
      location
      status
      offeredValue
      appraisalValue
      referenceTitle
      referenceId
      vAutoId
      vin
      modelYear
      make
      model
      series
      custName
      custSmsNumber
      submitted
      completed
      started
      updated
      created
      owner
      ownerId
      completedBy
      completedById
      startedById
    }
  }
}
    `;

/**
 * __useListingsQuery__
 *
 * To run a query within a React component, call `useListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      location: // value for 'location'
 *      search: // value for 'search'
 *      size: // value for 'size'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListingsQuery(baseOptions: Apollo.QueryHookOptions<ListingsQuery, ListingsQueryVariables> & ({ variables: ListingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
      }
export function useListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingsQuery, ListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
        }
export function useListingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListingsQuery, ListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
        }
export type ListingsQueryHookResult = ReturnType<typeof useListingsQuery>;
export type ListingsLazyQueryHookResult = ReturnType<typeof useListingsLazyQuery>;
export type ListingsSuspenseQueryHookResult = ReturnType<typeof useListingsSuspenseQuery>;
export type ListingsQueryResult = Apollo.QueryResult<ListingsQuery, ListingsQueryVariables>;
export const ListingDocument = gql`
    query Listing($id: Int!) {
  listing(id: $id) @rest(type: "ListingMethod", path: "/private/virtual/{args.id}", method: "GET", endpoint: "core-service") {
    result {
      id
      segmentation
      formulaPrice
      completedById
      startedById
      modelYear
      model
      make
      series
      origin
      originSource
      odometer
      odometerMiles
      vin
      referenceId
      referenceLink
      referenceTitle
      askingValue
      disclosures
      custName
      custSmsNumber
      followUps {
        created
        name
        text
        type
        user_id
      }
      vAutoId
      preliminaryValue
      appraisalValue
      comments {
        client
        appraiser
        document
      }
      location
      status
      submitted
      completed
      started
      updated
      created
      owner
      ownerId
      completedBy
      offeredValue
      previouslyDamaged
      autoSmsSent
    }
  }
}
    `;

/**
 * __useListingQuery__
 *
 * To run a query within a React component, call `useListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListingQuery(baseOptions: Apollo.QueryHookOptions<ListingQuery, ListingQueryVariables> & ({ variables: ListingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingQuery, ListingQueryVariables>(ListingDocument, options);
      }
export function useListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingQuery, ListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingQuery, ListingQueryVariables>(ListingDocument, options);
        }
export function useListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListingQuery, ListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListingQuery, ListingQueryVariables>(ListingDocument, options);
        }
export type ListingQueryHookResult = ReturnType<typeof useListingQuery>;
export type ListingLazyQueryHookResult = ReturnType<typeof useListingLazyQuery>;
export type ListingSuspenseQueryHookResult = ReturnType<typeof useListingSuspenseQuery>;
export type ListingQueryResult = Apollo.QueryResult<ListingQuery, ListingQueryVariables>;
export const UpdateListingDocument = gql`
    mutation UpdateListing($input: UpdateListingInput!, $id: Int!) {
  updateListing(input: $input, id: $id) @rest(type: "UpdateListingMethod", path: "/private/virtual/edit/{args.id}", method: "PATCH", endpoint: "core-service") {
    virtual {
      id
      modelYear
      model
      make
      series
      origin
      odometer
      odometerMiles
      vin
      referenceId
      referenceLink
      referenceTitle
      askingValue
      disclosures
      custName
      custSmsNumber
      followUps {
        created
        name
        text
        type
        user_id
      }
      vAutoId
      preliminaryValue
      appraisalValue
      comments {
        client
        appraiser
        document
      }
      location
      status
      submitted
      completed
      started
      updated
      created
      owner
      ownerId
      completedBy
      completedById
      startedById
      offeredValue
      previouslyDamaged
      autoSmsSent
    }
  }
}
    `;
export type UpdateListingMutationFn = Apollo.MutationFunction<UpdateListingMutation, UpdateListingMutationVariables>;

/**
 * __useUpdateListingMutation__
 *
 * To run a mutation, you first call `useUpdateListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingMutation, { data, loading, error }] = useUpdateListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateListingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListingMutation, UpdateListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListingMutation, UpdateListingMutationVariables>(UpdateListingDocument, options);
      }
export type UpdateListingMutationHookResult = ReturnType<typeof useUpdateListingMutation>;
export type UpdateListingMutationResult = Apollo.MutationResult<UpdateListingMutation>;
export type UpdateListingMutationOptions = Apollo.BaseMutationOptions<UpdateListingMutation, UpdateListingMutationVariables>;
export const UpdateListingStatusDocument = gql`
    mutation UpdateListingStatus($input: UpdateListingStatusInput!, $id: Int!) {
  updateListingStatus(input: $input, id: $id) @rest(type: "ListingStatusResult", path: "/private/virtual/status/{args.id}", method: "PATCH", endpoint: "core-service") {
    modified
  }
}
    `;
export type UpdateListingStatusMutationFn = Apollo.MutationFunction<UpdateListingStatusMutation, UpdateListingStatusMutationVariables>;

/**
 * __useUpdateListingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateListingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingStatusMutation, { data, loading, error }] = useUpdateListingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateListingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListingStatusMutation, UpdateListingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListingStatusMutation, UpdateListingStatusMutationVariables>(UpdateListingStatusDocument, options);
      }
export type UpdateListingStatusMutationHookResult = ReturnType<typeof useUpdateListingStatusMutation>;
export type UpdateListingStatusMutationResult = Apollo.MutationResult<UpdateListingStatusMutation>;
export type UpdateListingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateListingStatusMutation, UpdateListingStatusMutationVariables>;
export const CreateListingDocument = gql`
    mutation CreateListing($input: ListingInput!) {
  createListing(input: $input) @rest(type: "ListingMethod", path: "/private/virtual/create", method: "POST", endpoint: "core-service") {
    result {
      id
      modelYear
      model
      make
      series
      origin
      odometer
      odometerMiles
      vin
      referenceId
      referenceLink
      referenceTitle
      askingValue
      disclosures
      custName
      custSmsNumber
      followUps {
        created
        name
        text
        type
        user_id
      }
      vAutoId
      preliminaryValue
      appraisalValue
      comments {
        client
        appraiser
        document
      }
      location
      status
      submitted
      completed
      started
      updated
      created
      owner
      ownerId
      completedBy
      completedById
      startedById
      offeredValue
      previouslyDamaged
      autoSmsSent
    }
  }
}
    `;
export type CreateListingMutationFn = Apollo.MutationFunction<CreateListingMutation, CreateListingMutationVariables>;

/**
 * __useCreateListingMutation__
 *
 * To run a mutation, you first call `useCreateListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingMutation, { data, loading, error }] = useCreateListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListingMutation(baseOptions?: Apollo.MutationHookOptions<CreateListingMutation, CreateListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListingMutation, CreateListingMutationVariables>(CreateListingDocument, options);
      }
export type CreateListingMutationHookResult = ReturnType<typeof useCreateListingMutation>;
export type CreateListingMutationResult = Apollo.MutationResult<CreateListingMutation>;
export type CreateListingMutationOptions = Apollo.BaseMutationOptions<CreateListingMutation, CreateListingMutationVariables>;
export const ListingsCountDocument = gql`
    query ListingsCount($start: String!, $end: String!) {
  evaluationListingsCount(start: $start, end: $end) @rest(type: "ListingsCountMethod", path: "/private/virtual/count/evaluation?start={args.start}&end={args.end}", method: "GET", endpoint: "core-service") {
    result
  }
  evaluatingListingsCount(start: $start, end: $end) @rest(type: "ListingsCountMethod", path: "/private/virtual/count/evaluating?start={args.start}&end={args.end}", method: "GET", endpoint: "core-service") {
    result
  }
}
    `;

/**
 * __useListingsCountQuery__
 *
 * To run a query within a React component, call `useListingsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsCountQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useListingsCountQuery(baseOptions: Apollo.QueryHookOptions<ListingsCountQuery, ListingsCountQueryVariables> & ({ variables: ListingsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingsCountQuery, ListingsCountQueryVariables>(ListingsCountDocument, options);
      }
export function useListingsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingsCountQuery, ListingsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingsCountQuery, ListingsCountQueryVariables>(ListingsCountDocument, options);
        }
export function useListingsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListingsCountQuery, ListingsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListingsCountQuery, ListingsCountQueryVariables>(ListingsCountDocument, options);
        }
export type ListingsCountQueryHookResult = ReturnType<typeof useListingsCountQuery>;
export type ListingsCountLazyQueryHookResult = ReturnType<typeof useListingsCountLazyQuery>;
export type ListingsCountSuspenseQueryHookResult = ReturnType<typeof useListingsCountSuspenseQuery>;
export type ListingsCountQueryResult = Apollo.QueryResult<ListingsCountQuery, ListingsCountQueryVariables>;
export const LocationsDocument = gql`
    query Locations {
  locations @rest(type: "LocationsMethod", path: "locations/", method: "GET", endpoint: "backend") {
    result {
      _id
      address
      address1
      approbation
      brandingPath
      city
      country
      description
      estimationAutoSmsContent
      nomadGroup
      postalCode
      segmentation
      state
      periodPrice {
        percent
        startPrice
        endPrice
      }
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export function useLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsSuspenseQueryHookResult = ReturnType<typeof useLocationsSuspenseQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const UpdateLocationsDocument = gql`
    mutation UpdateLocations($id: String!, $input: LocationInput!) {
  updateLocations(id: $id, input: $input) @rest(type: "SetSubscriptionResult", path: "locations/{args.id}", method: "PATCH", endpoint: "backend") {
    result {
      _id
      address
      address1
      approbation
      brandingPath
      city
      country
      description
      estimationAutoSmsContent
      nomadGroup
      postalCode
      segmentation
      state
      periodPrice {
        percent
        startPrice
        endPrice
      }
    }
  }
}
    `;
export type UpdateLocationsMutationFn = Apollo.MutationFunction<UpdateLocationsMutation, UpdateLocationsMutationVariables>;

/**
 * __useUpdateLocationsMutation__
 *
 * To run a mutation, you first call `useUpdateLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationsMutation, { data, loading, error }] = useUpdateLocationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationsMutation, UpdateLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationsMutation, UpdateLocationsMutationVariables>(UpdateLocationsDocument, options);
      }
export type UpdateLocationsMutationHookResult = ReturnType<typeof useUpdateLocationsMutation>;
export type UpdateLocationsMutationResult = Apollo.MutationResult<UpdateLocationsMutation>;
export type UpdateLocationsMutationOptions = Apollo.BaseMutationOptions<UpdateLocationsMutation, UpdateLocationsMutationVariables>;
export const ManheimDataByVinDocument = gql`
    query ManheimDataByVin($vin: String!, $odometer: Int!, $grade: String!) {
  manheimDataByVin(vin: $vin, odometer: $odometer, grade: $grade) @rest(type: "ManheimDataByVinMethod", path: "/private/services/manheim/valuations/{args.vin}?odometer={args.odometer}&grade={args.grade}", method: "GET", endpoint: "core-service") {
    result {
      href
      count
      items {
        href
        extendedCoverage
        description {
          year
          make
          model
          trim
          subSeries
        }
        wholesale {
          above
          average
          below
        }
        averageOdometer
        averageGrade
        adjustedPricing {
          wholesale {
            above
            average
            below
          }
          adjustedBy {
            Grade
            Odometer
          }
        }
        odometerUnits
        currency
        sampleSize
        requestedDate
        returnedDate
        bestMatch
      }
    }
  }
}
    `;

/**
 * __useManheimDataByVinQuery__
 *
 * To run a query within a React component, call `useManheimDataByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useManheimDataByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManheimDataByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      odometer: // value for 'odometer'
 *      grade: // value for 'grade'
 *   },
 * });
 */
export function useManheimDataByVinQuery(baseOptions: Apollo.QueryHookOptions<ManheimDataByVinQuery, ManheimDataByVinQueryVariables> & ({ variables: ManheimDataByVinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>(ManheimDataByVinDocument, options);
      }
export function useManheimDataByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>(ManheimDataByVinDocument, options);
        }
export function useManheimDataByVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>(ManheimDataByVinDocument, options);
        }
export type ManheimDataByVinQueryHookResult = ReturnType<typeof useManheimDataByVinQuery>;
export type ManheimDataByVinLazyQueryHookResult = ReturnType<typeof useManheimDataByVinLazyQuery>;
export type ManheimDataByVinSuspenseQueryHookResult = ReturnType<typeof useManheimDataByVinSuspenseQuery>;
export type ManheimDataByVinQueryResult = Apollo.QueryResult<ManheimDataByVinQuery, ManheimDataByVinQueryVariables>;
export const NotificationCountDocument = gql`
    query NotificationCount {
  notificationCount @rest(type: "NotificationCountMethod", path: "notices/count", method: "GET", endpoint: "backend") {
    result
  }
}
    `;

/**
 * __useNotificationCountQuery__
 *
 * To run a query within a React component, call `useNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCountQuery(baseOptions?: Apollo.QueryHookOptions<NotificationCountQuery, NotificationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationCountQuery, NotificationCountQueryVariables>(NotificationCountDocument, options);
      }
export function useNotificationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationCountQuery, NotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationCountQuery, NotificationCountQueryVariables>(NotificationCountDocument, options);
        }
export function useNotificationCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationCountQuery, NotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationCountQuery, NotificationCountQueryVariables>(NotificationCountDocument, options);
        }
export type NotificationCountQueryHookResult = ReturnType<typeof useNotificationCountQuery>;
export type NotificationCountLazyQueryHookResult = ReturnType<typeof useNotificationCountLazyQuery>;
export type NotificationCountSuspenseQueryHookResult = ReturnType<typeof useNotificationCountSuspenseQuery>;
export type NotificationCountQueryResult = Apollo.QueryResult<NotificationCountQuery, NotificationCountQueryVariables>;
export const NotificationListDocument = gql`
    query NotificationList {
  notificationList @rest(type: "NotificationListMethod", path: "notices", method: "GET", endpoint: "backend") {
    result {
      _id
      id
      created
      group
      text
      source {
        type
        id
      }
      read_by
      received_by
    }
  }
}
    `;

/**
 * __useNotificationListQuery__
 *
 * To run a query within a React component, call `useNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationListQuery(baseOptions?: Apollo.QueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
      }
export function useNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
        }
export function useNotificationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
        }
export type NotificationListQueryHookResult = ReturnType<typeof useNotificationListQuery>;
export type NotificationListLazyQueryHookResult = ReturnType<typeof useNotificationListLazyQuery>;
export type NotificationListSuspenseQueryHookResult = ReturnType<typeof useNotificationListSuspenseQuery>;
export type NotificationListQueryResult = Apollo.QueryResult<NotificationListQuery, NotificationListQueryVariables>;
export const NotificationNameDocument = gql`
    query NotificationName($id: Int!, $source: String!) {
  notificationTitle(id: $id, source: $source) @rest(type: "NotificationNameMethod", path: "{args.source}/title/{args.id}?withCust=1", method: "GET", endpoint: "backend") {
    result
  }
}
    `;

/**
 * __useNotificationNameQuery__
 *
 * To run a query within a React component, call `useNotificationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useNotificationNameQuery(baseOptions: Apollo.QueryHookOptions<NotificationNameQuery, NotificationNameQueryVariables> & ({ variables: NotificationNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationNameQuery, NotificationNameQueryVariables>(NotificationNameDocument, options);
      }
export function useNotificationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationNameQuery, NotificationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationNameQuery, NotificationNameQueryVariables>(NotificationNameDocument, options);
        }
export function useNotificationNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationNameQuery, NotificationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationNameQuery, NotificationNameQueryVariables>(NotificationNameDocument, options);
        }
export type NotificationNameQueryHookResult = ReturnType<typeof useNotificationNameQuery>;
export type NotificationNameLazyQueryHookResult = ReturnType<typeof useNotificationNameLazyQuery>;
export type NotificationNameSuspenseQueryHookResult = ReturnType<typeof useNotificationNameSuspenseQuery>;
export type NotificationNameQueryResult = Apollo.QueryResult<NotificationNameQuery, NotificationNameQueryVariables>;
export const NotificationReadDocument = gql`
    mutation NotificationRead($input: NotificationReadInput) {
  notificationRead(input: $input) @rest(type: "NotificationNameMethod", path: "notices/read", method: "POST", endpoint: "backend") {
    result
  }
}
    `;
export type NotificationReadMutationFn = Apollo.MutationFunction<NotificationReadMutation, NotificationReadMutationVariables>;

/**
 * __useNotificationReadMutation__
 *
 * To run a mutation, you first call `useNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadMutation, { data, loading, error }] = useNotificationReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<NotificationReadMutation, NotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationReadMutation, NotificationReadMutationVariables>(NotificationReadDocument, options);
      }
export type NotificationReadMutationHookResult = ReturnType<typeof useNotificationReadMutation>;
export type NotificationReadMutationResult = Apollo.MutationResult<NotificationReadMutation>;
export type NotificationReadMutationOptions = Apollo.BaseMutationOptions<NotificationReadMutation, NotificationReadMutationVariables>;
export const UserByIdDocument = gql`
    query UserById($id: Int!) {
  userById(id: $id) @rest(type: "UserByIdMethod", path: "user/id/{args.id}", method: "GET", endpoint: "backend") {
    result {
      id
      firstName
      lastName
      phoneNumber
    }
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const VinDocument = gql`
    query Vin($vin: String!, $lang: Languages, $country: Countries!) {
  vin(vin: $vin, lang: $lang, country: $country) @rest(type: "VinMethod", path: "vin/{args.vin}?lang={args.lang}&country={args.country}", method: "GET", endpoint: "backend") {
    result {
      bodyType
      engineCylinderCount
      make
      model
      modelYear
      msrp
      series
      transmissionType
      style {
        id
        modelYear
        name
        nameWoTrim
        trim
        mfrModelCode
        fleetOnly
        modelFleet
        passDoors
        altBodyType
        drivetrain
        division {
          id
          value
        }
        subdivision {
          id
          value
        }
        model {
          id
          value
        }
        basePrice {
          unknown
          msrp
          destination
        }
        bodyType {
          id
          value
          primary
        }
        marketClass {
          id
          value
          primary
        }
      }
    }
  }
}
    `;

/**
 * __useVinQuery__
 *
 * To run a query within a React component, call `useVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      lang: // value for 'lang'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useVinQuery(baseOptions: Apollo.QueryHookOptions<VinQuery, VinQueryVariables> & ({ variables: VinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VinQuery, VinQueryVariables>(VinDocument, options);
      }
export function useVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VinQuery, VinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VinQuery, VinQueryVariables>(VinDocument, options);
        }
export function useVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VinQuery, VinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VinQuery, VinQueryVariables>(VinDocument, options);
        }
export type VinQueryHookResult = ReturnType<typeof useVinQuery>;
export type VinLazyQueryHookResult = ReturnType<typeof useVinLazyQuery>;
export type VinSuspenseQueryHookResult = ReturnType<typeof useVinSuspenseQuery>;
export type VinQueryResult = Apollo.QueryResult<VinQuery, VinQueryVariables>;
export const PackageDocument = gql`
    query Package($styleId: String!, $lang: Languages, $country: Countries!) {
  package(styleId: $styleId, lang: $lang, country: $country) @rest(type: "PackageMethod", path: "vin/packages/{args.styleId}?lang={args.lang}&country={args.country}", method: "GET", endpoint: "backend") {
    result {
      style {
        styleId
        trimName
        modelYear
        divisionName
        consumerFriendlyBodyType
        consumerFriendlyDrivetrain
        consumerFriendlyStyleName
        consumerFriendlyModelName
      }
    }
  }
}
    `;

/**
 * __usePackageQuery__
 *
 * To run a query within a React component, call `usePackageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      lang: // value for 'lang'
 *      country: // value for 'country'
 *   },
 * });
 */
export function usePackageQuery(baseOptions: Apollo.QueryHookOptions<PackageQuery, PackageQueryVariables> & ({ variables: PackageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageQuery, PackageQueryVariables>(PackageDocument, options);
      }
export function usePackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageQuery, PackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageQuery, PackageQueryVariables>(PackageDocument, options);
        }
export function usePackageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PackageQuery, PackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PackageQuery, PackageQueryVariables>(PackageDocument, options);
        }
export type PackageQueryHookResult = ReturnType<typeof usePackageQuery>;
export type PackageLazyQueryHookResult = ReturnType<typeof usePackageLazyQuery>;
export type PackageSuspenseQueryHookResult = ReturnType<typeof usePackageSuspenseQuery>;
export type PackageQueryResult = Apollo.QueryResult<PackageQuery, PackageQueryVariables>;
export const LicenseplateDocument = gql`
    query Licenseplate($plate: String!, $state: String!) {
  licenseplate(plate: $plate, state: $state) @rest(type: "LicenseplateMethod", path: "licenseplate/?plate={args.plate}&state={args.state}", method: "GET", endpoint: "backend") {
    result {
      vin
    }
  }
}
    `;

/**
 * __useLicenseplateQuery__
 *
 * To run a query within a React component, call `useLicenseplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseplateQuery({
 *   variables: {
 *      plate: // value for 'plate'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useLicenseplateQuery(baseOptions: Apollo.QueryHookOptions<LicenseplateQuery, LicenseplateQueryVariables> & ({ variables: LicenseplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseplateQuery, LicenseplateQueryVariables>(LicenseplateDocument, options);
      }
export function useLicenseplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseplateQuery, LicenseplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseplateQuery, LicenseplateQueryVariables>(LicenseplateDocument, options);
        }
export function useLicenseplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LicenseplateQuery, LicenseplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LicenseplateQuery, LicenseplateQueryVariables>(LicenseplateDocument, options);
        }
export type LicenseplateQueryHookResult = ReturnType<typeof useLicenseplateQuery>;
export type LicenseplateLazyQueryHookResult = ReturnType<typeof useLicenseplateLazyQuery>;
export type LicenseplateSuspenseQueryHookResult = ReturnType<typeof useLicenseplateSuspenseQuery>;
export type LicenseplateQueryResult = Apollo.QueryResult<LicenseplateQuery, LicenseplateQueryVariables>;
export const MessaagesDocument = gql`
    query Messaages($channel: String!) {
  messaages(channel: $channel) @rest(type: "MessaagesMethod", path: "messages?channel={args.channel}", method: "GET", endpoint: "backend") {
    result {
      _id
      channel
      created
      read_by
      text
      type
      user_id
      user_name
    }
  }
}
    `;

/**
 * __useMessaagesQuery__
 *
 * To run a query within a React component, call `useMessaagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessaagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessaagesQuery({
 *   variables: {
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useMessaagesQuery(baseOptions: Apollo.QueryHookOptions<MessaagesQuery, MessaagesQueryVariables> & ({ variables: MessaagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessaagesQuery, MessaagesQueryVariables>(MessaagesDocument, options);
      }
export function useMessaagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessaagesQuery, MessaagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessaagesQuery, MessaagesQueryVariables>(MessaagesDocument, options);
        }
export function useMessaagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessaagesQuery, MessaagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessaagesQuery, MessaagesQueryVariables>(MessaagesDocument, options);
        }
export type MessaagesQueryHookResult = ReturnType<typeof useMessaagesQuery>;
export type MessaagesLazyQueryHookResult = ReturnType<typeof useMessaagesLazyQuery>;
export type MessaagesSuspenseQueryHookResult = ReturnType<typeof useMessaagesSuspenseQuery>;
export type MessaagesQueryResult = Apollo.QueryResult<MessaagesQuery, MessaagesQueryVariables>;
export const DashStatisticsPriceDocument = gql`
    query DashStatisticsPrice($ids: String!, $select: String!) {
  dashStatisticsPrice(ids: $ids, select: $select) @rest(type: "DashStatisticsListMethod", path: "/private/physical-evaluation/ids/{args.ids}?select={args.select}", method: "GET", endpoint: "core-service") {
    result {
      id
      vin
      location
      created
      ownerId
      submitted
      completedById
      completed
      appraisalValue
      offeredValue
      location
      odometer
      odometerMiles
      status
      origin
      photos {
        id
        src
        zone
      }
      paveSessionStatus
      modelYear
      make
      model
      series
      traction
      transmissionType
      assessingStarted
      assessedById
      eraSalesCode
      isPurchased
    }
  }
}
    `;

/**
 * __useDashStatisticsPriceQuery__
 *
 * To run a query within a React component, call `useDashStatisticsPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashStatisticsPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashStatisticsPriceQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      select: // value for 'select'
 *   },
 * });
 */
export function useDashStatisticsPriceQuery(baseOptions: Apollo.QueryHookOptions<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables> & ({ variables: DashStatisticsPriceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>(DashStatisticsPriceDocument, options);
      }
export function useDashStatisticsPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>(DashStatisticsPriceDocument, options);
        }
export function useDashStatisticsPriceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>(DashStatisticsPriceDocument, options);
        }
export type DashStatisticsPriceQueryHookResult = ReturnType<typeof useDashStatisticsPriceQuery>;
export type DashStatisticsPriceLazyQueryHookResult = ReturnType<typeof useDashStatisticsPriceLazyQuery>;
export type DashStatisticsPriceSuspenseQueryHookResult = ReturnType<typeof useDashStatisticsPriceSuspenseQuery>;
export type DashStatisticsPriceQueryResult = Apollo.QueryResult<DashStatisticsPriceQuery, DashStatisticsPriceQueryVariables>;
export const SalesManagerStatisticsDocument = gql`
    query SalesManagerStatistics($select: String!) {
  dashStatisticsBoard(select: $select) @rest(type: "DashStatisticsMethod", path: "/private/analytics/salesman-manager?{args.select}", method: "GET", endpoint: "core-service") {
    result {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        dealership
        roles
        country
        onlineAt
      }
      purchased
      purchasedIds
      appraisalIds
      priceToMarket
      costToMarket
      average
      count
    }
  }
}
    `;

/**
 * __useSalesManagerStatisticsQuery__
 *
 * To run a query within a React component, call `useSalesManagerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesManagerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesManagerStatisticsQuery({
 *   variables: {
 *      select: // value for 'select'
 *   },
 * });
 */
export function useSalesManagerStatisticsQuery(baseOptions: Apollo.QueryHookOptions<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables> & ({ variables: SalesManagerStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>(SalesManagerStatisticsDocument, options);
      }
export function useSalesManagerStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>(SalesManagerStatisticsDocument, options);
        }
export function useSalesManagerStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>(SalesManagerStatisticsDocument, options);
        }
export type SalesManagerStatisticsQueryHookResult = ReturnType<typeof useSalesManagerStatisticsQuery>;
export type SalesManagerStatisticsLazyQueryHookResult = ReturnType<typeof useSalesManagerStatisticsLazyQuery>;
export type SalesManagerStatisticsSuspenseQueryHookResult = ReturnType<typeof useSalesManagerStatisticsSuspenseQuery>;
export type SalesManagerStatisticsQueryResult = Apollo.QueryResult<SalesManagerStatisticsQuery, SalesManagerStatisticsQueryVariables>;
export const AppraisalPriceStatisticsDocument = gql`
    query AppraisalPriceStatistics($select: String!) {
  dashStatisticsBoard(select: $select) @rest(type: "DashStatisticsMethod", path: "/private/analytics/appraiser?{args.select}", method: "GET", endpoint: "core-service") {
    result {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        dealership
        roles
        country
        onlineAt
      }
      purchased
      purchasedIds
      appraisalIds
      priceToMarket
      costToMarket
      average
      count
    }
  }
}
    `;

/**
 * __useAppraisalPriceStatisticsQuery__
 *
 * To run a query within a React component, call `useAppraisalPriceStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalPriceStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalPriceStatisticsQuery({
 *   variables: {
 *      select: // value for 'select'
 *   },
 * });
 */
export function useAppraisalPriceStatisticsQuery(baseOptions: Apollo.QueryHookOptions<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables> & ({ variables: AppraisalPriceStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>(AppraisalPriceStatisticsDocument, options);
      }
export function useAppraisalPriceStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>(AppraisalPriceStatisticsDocument, options);
        }
export function useAppraisalPriceStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>(AppraisalPriceStatisticsDocument, options);
        }
export type AppraisalPriceStatisticsQueryHookResult = ReturnType<typeof useAppraisalPriceStatisticsQuery>;
export type AppraisalPriceStatisticsLazyQueryHookResult = ReturnType<typeof useAppraisalPriceStatisticsLazyQuery>;
export type AppraisalPriceStatisticsSuspenseQueryHookResult = ReturnType<typeof useAppraisalPriceStatisticsSuspenseQuery>;
export type AppraisalPriceStatisticsQueryResult = Apollo.QueryResult<AppraisalPriceStatisticsQuery, AppraisalPriceStatisticsQueryVariables>;
export const DashStatisticsDocument = gql`
    mutation DashStatistics($input: FilterQueryInput) {
  dashStatistics(input: $input) @rest(type: "DashStatisticsMethod", path: "user/statistic/role", method: "POST", endpoint: "backend") {
    result {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        dealership
        roles
        country
        onlineAt
      }
      purchased
      purchasedIds
      appraisalIds
      priceToMarket
      costToMarket
      average
      count
    }
  }
}
    `;
export type DashStatisticsMutationFn = Apollo.MutationFunction<DashStatisticsMutation, DashStatisticsMutationVariables>;

/**
 * __useDashStatisticsMutation__
 *
 * To run a mutation, you first call `useDashStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDashStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dashStatisticsMutation, { data, loading, error }] = useDashStatisticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<DashStatisticsMutation, DashStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DashStatisticsMutation, DashStatisticsMutationVariables>(DashStatisticsDocument, options);
      }
export type DashStatisticsMutationHookResult = ReturnType<typeof useDashStatisticsMutation>;
export type DashStatisticsMutationResult = Apollo.MutationResult<DashStatisticsMutation>;
export type DashStatisticsMutationOptions = Apollo.BaseMutationOptions<DashStatisticsMutation, DashStatisticsMutationVariables>;
export const SetSubscriptionDocument = gql`
    mutation SetSubscription($input: SetSubscriptionInput!) {
  setSubscription(input: $input) @rest(type: "SetSubscriptionResult", path: "user/subscribe", method: "POST", endpoint: "backend") {
    result {
      active
      emitter
      event
      medium
      options
      subscriber
    }
  }
}
    `;
export type SetSubscriptionMutationFn = Apollo.MutationFunction<SetSubscriptionMutation, SetSubscriptionMutationVariables>;

/**
 * __useSetSubscriptionMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionMutation, { data, loading, error }] = useSetSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<SetSubscriptionMutation, SetSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSubscriptionMutation, SetSubscriptionMutationVariables>(SetSubscriptionDocument, options);
      }
export type SetSubscriptionMutationHookResult = ReturnType<typeof useSetSubscriptionMutation>;
export type SetSubscriptionMutationResult = Apollo.MutationResult<SetSubscriptionMutation>;
export type SetSubscriptionMutationOptions = Apollo.BaseMutationOptions<SetSubscriptionMutation, SetSubscriptionMutationVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  getSubscriptions @rest(type: "SubscriptionMethod", path: "user/subscriptions/", method: "GET", endpoint: "backend") {
    result {
      activeMediums
      emitter
      event
      label
      name
      user_rules
      user_types
    }
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export function useGetSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionsSuspenseQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const UsersDocument = gql`
    query users {
  users @rest(type: "User", path: "/user/grid", method: "GET", endpoint: "user-service") {
    id
    firstName
    lastName
    email
    phoneNumber
    dealership
    country
    onlineAt
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query user($id: Int!) {
  user(id: $id) @rest(type: "User", path: "/user/{args.id}", method: "GET", endpoint: "user-service") {
    id
    firstName
    lastName
    email
    phoneNumber
    dealership
    roles
    country
    onlineAt
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserPublicDocument = gql`
    query userPublic($id: Int!) {
  user(id: $id) @rest(type: "User", path: "/auth/user/{args.id}", method: "GET", endpoint: "user-service") {
    id
    firstName
    lastName
    email
    phoneNumber
    dealership
    roles
    country
    onlineAt
  }
}
    `;

/**
 * __useUserPublicQuery__
 *
 * To run a query within a React component, call `useUserPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPublicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPublicQuery(baseOptions: Apollo.QueryHookOptions<UserPublicQuery, UserPublicQueryVariables> & ({ variables: UserPublicQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPublicQuery, UserPublicQueryVariables>(UserPublicDocument, options);
      }
export function useUserPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPublicQuery, UserPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPublicQuery, UserPublicQueryVariables>(UserPublicDocument, options);
        }
export function useUserPublicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPublicQuery, UserPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPublicQuery, UserPublicQueryVariables>(UserPublicDocument, options);
        }
export type UserPublicQueryHookResult = ReturnType<typeof useUserPublicQuery>;
export type UserPublicLazyQueryHookResult = ReturnType<typeof useUserPublicLazyQuery>;
export type UserPublicSuspenseQueryHookResult = ReturnType<typeof useUserPublicSuspenseQuery>;
export type UserPublicQueryResult = Apollo.QueryResult<UserPublicQuery, UserPublicQueryVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($id: Int!, $input: UserInput!) {
  userUpdate(id: $id, input: $input) @rest(type: "User", path: "/user/{args.id}", method: "Put", endpoint: "user-service") {
    id
    firstName
    lastName
    email
    phoneNumber
    dealership
    roles
    country
    onlineAt
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const RoelsDocument = gql`
    query roels {
  roles @rest(type: "Role", path: "/role", method: "GET", endpoint: "user-service") {
    id
    slug
    name
  }
}
    `;

/**
 * __useRoelsQuery__
 *
 * To run a query within a React component, call `useRoelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoelsQuery(baseOptions?: Apollo.QueryHookOptions<RoelsQuery, RoelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoelsQuery, RoelsQueryVariables>(RoelsDocument, options);
      }
export function useRoelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoelsQuery, RoelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoelsQuery, RoelsQueryVariables>(RoelsDocument, options);
        }
export function useRoelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RoelsQuery, RoelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RoelsQuery, RoelsQueryVariables>(RoelsDocument, options);
        }
export type RoelsQueryHookResult = ReturnType<typeof useRoelsQuery>;
export type RoelsLazyQueryHookResult = ReturnType<typeof useRoelsLazyQuery>;
export type RoelsSuspenseQueryHookResult = ReturnType<typeof useRoelsSuspenseQuery>;
export type RoelsQueryResult = Apollo.QueryResult<RoelsQuery, RoelsQueryVariables>;
export const DealershipsDocument = gql`
    query dealerships {
  dealerships @rest(type: "Dealership", path: "/dealership", method: "GET", endpoint: "user-service") {
    id
    slug
    name
    country
  }
}
    `;

/**
 * __useDealershipsQuery__
 *
 * To run a query within a React component, call `useDealershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealershipsQuery(baseOptions?: Apollo.QueryHookOptions<DealershipsQuery, DealershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealershipsQuery, DealershipsQueryVariables>(DealershipsDocument, options);
      }
export function useDealershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealershipsQuery, DealershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealershipsQuery, DealershipsQueryVariables>(DealershipsDocument, options);
        }
export function useDealershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DealershipsQuery, DealershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DealershipsQuery, DealershipsQueryVariables>(DealershipsDocument, options);
        }
export type DealershipsQueryHookResult = ReturnType<typeof useDealershipsQuery>;
export type DealershipsLazyQueryHookResult = ReturnType<typeof useDealershipsLazyQuery>;
export type DealershipsSuspenseQueryHookResult = ReturnType<typeof useDealershipsSuspenseQuery>;
export type DealershipsQueryResult = Apollo.QueryResult<DealershipsQuery, DealershipsQueryVariables>;