import React, { FC } from 'react';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IOption } from 'constants/entities/ui/types';

type Props = TextFieldProps & {
  options: IOption[];
  menuHeight?: number;
};

const SelectList: FC<Props> = ({
  label,
  placeholder,
  helperText,
  options,
  menuHeight = 250,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={typeof label === 'string' ? t(label) : label}
      helperText={t((helperText ?? '') as string)}
      placeholder={placeholder ? t(placeholder as string) : undefined}
      SelectProps={{
        onClose: () => {
          setTimeout(() => {
            (document?.activeElement as any)?.blur();
          }, 0);
        },

        MenuProps: {
          sx: { '& .MuiList-root': { maxHeight: menuHeight } },
        },
      }}
      select
      {...props}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-cy={`option-${option.value}`}
        >
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectList;
