import { create } from 'zustand';

import { Dispatch } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface AuthStore {
  startDate: Dayjs;
  endDate: Dayjs;
  location: string;

  setStartDate: Dispatch<Dayjs>;
  setEndDate: Dispatch<Dayjs>;
  setLocation: Dispatch<string>;
}

const useDashboardFiltersStore = create<AuthStore>((set) => ({
  startDate: dayjs(`${dayjs().format('YYYY-MM')}-01`),
  endDate: dayjs(`${dayjs().format('YYYY-MM')}-30`),
  location: 'none',

  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setLocation: (location) => set({ location }),
}));

export const useDashboardFilters = useDashboardFiltersStore;

export default useDashboardFiltersStore;
