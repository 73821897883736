import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { BorderColorRounded } from '@mui/icons-material';

type Props = DatePickerProps<any>;

const DatePicker: FC<Props> = ({ label, ...props }) => {
  const { t } = useTranslation();

  return (
    <MuiDatePicker
      label={typeof label === 'string' ? t(label) : label}
      format="MMM DD, YYYY"
      closeOnSelect
      slotProps={{
        openPickerIcon: { sx: { color: 'grey.700' } },
        openPickerButton: {
          sx: { '&:hover .MuiSvgIcon-root': { color: 'green.800' } },
        },
      }}
      sx={{ '& .MuiInputBase-root': { borderRadius: 4 } }}
      slots={{ openPickerIcon: BorderColorRounded }}
      {...props}
    />
  );
};

export default DatePicker;
