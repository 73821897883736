export enum UserRoles {
  ADMIN = 'ADMIN',
  DEALERSHIP_ADMIN = 'DEALERSHIP_ADMIN',
  SALESMAN = 'SALESMAN',
  APPRAISER = 'APPRAISER',
  MANAGER = 'MANAGER',
  ASSESSOR = 'ASSESSOR',
  GUEST = 'GUEST',
  COPILOT_AI = 'COPILOT_AI',
  USER_EVALUATION = 'USER_EVALUATION',
  ANALYTICS = 'ANALYTICS',
  ADMIN_PRICE = 'ADMIN-PRICE',
  BIG_QUERY_STATISTICS = 'BIG-QUERY-STATISTICS',
  MANHEIM_LIMIT_PRICE_OFF = 'MANHEIM LIMIT PRICE OFF',
}

export const Permissions = {
  PhysicalEvaluation: {
    All: 'physical-evaluation',
    AllIn: 'physical-evaluation:*',
    GetList: 'physical-evaluation:get-list',
    Create: 'physical-evaluation:create',
    GetOne: 'physical-evaluation:get-one',
    Update: 'physical-evaluation:update',
    GetReminder: 'physical-evaluation:get-reminder',
    EditUser: 'physical-evaluation:edit-user',
    Appraise: 'physical-evaluation:appraise',
    GetPrice: 'physical-evaluation:get-price',
    GetReport: 'physical-evaluation:get-report',
  },

  VirtualEvaluation: {
    All: 'virtual-evaluation',
    AllIn: 'virtual-evaluation:*',
    GetList: 'virtual-evaluation:get-list',
    Create: 'virtual-evaluation:create',
    GetOne: 'virtual-evaluation:get-one',
    Update: 'virtual-evaluation:update',
    Appraise: 'virtual-evaluation:appraise',
    GetReport: 'virtual-evaluation:get-report',
  },

  ConditionReport: {
    All: 'condition-report',
    AllIn: 'condition-report:*',
    GetList: 'condition-report:get-list',
    GetOne: 'condition-report:get-one',
    Create: 'condition-report:create',
    Update: 'condition-report:update',
    GetVehicleReport: 'condition-report:get-vehicle-report',
  },

  Work: {
    GetList: 'work:get-list',
    GetOne: 'work:get-one',
  },

  Dashboard: {
    GetGlobalStatistic: 'dashboard:get-global-statistic',
    GetOwnStatistic: 'dashboard:get-own-statistic',
  },

  Users: {
    Edit: 'users:list-edit-users',
  },
};
