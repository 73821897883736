export const fr = {
  translation: {
    openOffers: 'Offres ouvertes',
    expectation: 'En attente',
    active: 'Actif',
    waiting: 'Attente',
    drafts: 'Brouillons',
    canceled: 'Annulé',
    expired: 'Expiré',
    purchased: 'Acheté',
    all: 'Tous',
    'to do': 'À faire',
    progress: 'En cours',
    approval: 'Approbation',
    completed: 'Terminé',
    returns: 'Retours',
    conditioning: 'Conditionnement',

    'users-table': {
      name: 'Nom',
      email: 'Email',
      country: 'Pays',
      dealership: 'Concessionnaire',
      online: 'En ligne',
      actions: 'Actions',
    },

    'no-access': {
      title: 'Accès refusé',
      description: "Désolé, vous n'avez pas accès à cette page.",
      reasons: 'Raisons possibles:',
      reason1:
        "Vous n'avez pas les permissions nécessaires pour voir ce contenu.",
      reason2:
        "Vous n'êtes pas connecté. Veuillez vous connecter pour continuer.",
      reason3: 'La page a peut-être été déplacée ou supprimée.',
      'contact-support':
        "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter le support.",
    },

    camera: {
      'add-new-damage': 'Nouveaux dégâts',
    },
    'user-evaluation': {
      btn: {
        start: {
          label: 'Commencer',
          description: "Déplacez ce chat vers l'évaluation physique",
        },
        cancelled: {
          label: 'Annulé',
          description: "La voiture n'est pas active",
        },
        physicalLink: {
          label: "lien d'évaluation physique",
        },
      },
    },
    appraisal: {
      'trim-title-for-pdf': {
        label: 'Vous pouvez modifier le titre du véhicule pour le fichier PDF.',
        'for-pdf': 'Titre PDF',
        'new-name-for-pdf': 'Nouveau nom pour le pdf',
        button: {
          update: 'Mise à jour',
          cancel: 'Annuler',
        },
        massage: {
          error: "Quelque chose c'est mal passé. Merci d'essayer plus tard.",
        },
      },
      message: {
        'can-not-read-vin':
          'Désolé, mais nous ne pouvons pas scanner le VIN, essayez à nouveau ou écrivez à la main',
      },
      equipment: {
        label: 'Équipement',
      },
      manheim: {
        label: 'Manheim prices',
      },
      card: {
        traction: 'Traction',
        transmission: 'Transmission',
      },
      condition: {
        'all-damage': 'Tous les dégâts',
        begin: 'Commencé',
        'botton-clear': 'Dégager',
        'botton-send': 'Envoyer',
        completed: 'Terminé',
        'damage-active-tab': 'Dommages à certains côtés de la voiture',
        finish: 'Terminer',
        'go-to-back': 'Revenir en arrière',
        'image-require': 'Une image nécessite',
        'image-wait': "Wttendre le téléchargement de l/'image",
        'rough-estimate-of-damage': 'Estimation grossière des dommages',
        'to-fix': 'à réparer: ',
        'to-start': 'Commencer',
        total: 'Total: ',
        'will-be-repaired': 'Sera réparé',
        'modal-title': 'Supprimer Arbitrer',
        'modal-question': 'Voulez-vous vraiment supprimer cet Arbitrer?',
        'modal-yes': 'Effacer',
        'modal-now': 'Annuler',
      },
      'owner-info': 'Le propriétaire',
      owner: {
        address: {
          label: 'Adresse',
        },
        city: {
          label: 'Ville',
        },
        email: {
          label: 'Email',
          placeholder: 'Entrez le Email',
        },
        'first-name': {
          label: 'Prénom',
          required: 'Ce champ est requis',
          placeholder: 'Entrez le prénom',
        },
        'last-name': {
          label: 'Nom',
          required: 'Ce champ est requis',
          placeholder: 'Entrez le nom',
        },
        phone: {
          label: 'Téléphone',
          required: 'Ce champ est requis',
          placeholder: 'Entrez le numéro de téléphone',
        },
        post: {
          label: 'Code postal',
        },
        'postal-code': {
          label: 'Code Postal',
        },
        state: {
          label: 'Province',
        },
        location: {
          label: 'Emplacement',
          required: 'Ce champ est requis',
          placeholder: "Entrez l'emplacement",
        },
      },
      paveSessionId: {
        description:
          "Vous pouvez envoyer un lien pour l'utilisateur. L'utilisateur pourra ajouter lui-même une image.",
        'info-message':
          "Vous ne pouvez pas démarrer car l'utilisateur n'a pas envoyé d'image.",
      },
      photos: {
        'load-images-error':
          'Erreur de connexion Internet, veuillez trouver une connexion Internet et mettre le bouton!',
        pending: 'Veuillez attendre que toutes les images soient chargées',
        required:
          'Veuillez prendre des photos de la voiture. Cliquez sur le bouton de la caméra.',
        zone: {
          back: 'Arrière',
          front: 'Avant',
          odometer: 'Odomètre',
          side: 'Côté',
          vin: 'Vin',
          all: 'Pas toutes les images',
        },
      },
      reminders: {
        'amount-owed': 'Montant dû au créanciers par le client',
        'approve-director': 'Approuver par le directeur',
        attachments: 'Pièces jointes',
        carproof: {
          unverifyed: 'Carproof  disponible',
          verifyed: 'Carproof vérifié',
        },
        checklist: {
          unverifyed: 'Liste de contrôle papier à compléter et attacher',
          verifyed: 'Liste de controle attaché',
        },
        'complete-assessment': "Compléter l'évaluation",
        'contract-pdf': 'Télécharger le contrat PDF',
        contract: {
          unverifyed: 'Contrat signé à attacher',
          verifyed: 'Contrat signé et attaché',
        },
        form: {
          'comment-document': {
            label: 'Commentaire du document',
          },
          'comment-document-form': {
            label: "Commentaires de l'évaluateur",
          },
          'amount-price': {
            label: 'Montant dû au créanciers',
          },
          'driver-license-form': {
            label: 'Permis de conduire',
          },
          link: {
            label: 'Lien',
          },
          'offered-price': {
            label: 'Prix offert au client',
          },
          'offered-price-us': {
            label: 'Final Offer',
          },
          'appraisal-value': {
            label: 'Estimation du prix',
          },
          'save-status-sale': {
            label: 'Enregistrer le statut de la vente',
          },
          text: {
            label: 'Texte',
          },
          title: {
            label: 'Titre',
          },
        },
        license: {
          unverifyed: "Permis de conduire '' à vérifier",
          verifyed: "Permis de conduire 'propriétaire # {{license}}' vérifié",
        },
        'price-appraiser': "Prix à obtenir auprès de l'expert",
        'price-obtained-from-appraiser': "Prix obtenue de l'évaluateurr",
        'price-offered-director': 'Price offered by the director',
        'price-to-be-confirmed': 'Price to be confirmed by the evaluator',
        rdprm: {
          unverifyed: 'Dossier RDPRM à vérifier',
          verifyed: 'Dossier RDPRM vérifié et attaché',
        },
        reminder: 'Rappels',
        'upload-file': 'Joindre le lien',
        'upload-image': "Joindre l'image",
        'upload-link': 'Joindre Joindre le lien',
        'upload-note': 'Joindre une note',
      },
      steps: {
        condition: {
          label: 'Condition',
          subLabel: 'About 3 Min',
        },
        customer: {
          label: 'Cliente',
          subLabel: 'Environ 3 Min',
        },
        equipment: {
          label: "Informations sur l'équipement",
          subLabel: 'Environ 4 Min',
        },
        photos: {
          label: 'Pictures',
          subLabel: 'Environ 5 Min',
        },
        question: {
          label: 'Question',
          subLabel: 'Environ 4 Min',
        },
        vehicle: {
          label: 'Véhicule Information',
          subLabel: 'Environ 3 Min',
        },
        vin: {
          label: 'Décodeur VIN',
          subLabel: 'Environ 1 Min',
        },
      },
      summary: {
        color: 'Couleur',
        engine: 'Moteur',
        mileage: 'Kilométrage',
        traction: 'Traction',
        transmission: 'Transmission',
      },
      vehicle: {
        'not-found-equipment': "Pas trouvé d'équipement payant",
        'body-type': {
          label: 'Styles',
        },
        'engine-cylinder-count': {
          label: 'Nombre de cylindres',
        },
        engine: {
          label: 'Moteur',
        },
        'exterior-color': {
          label: 'Couleur extérieur',
        },
        'interior-color': {
          label: 'Couleur intérieur',
        },
        make: {
          label: 'Marque',
        },
        'model-year': {
          label: 'Année',
        },
        model: {
          label: 'Modèle',
        },
        odometer: {
          label: 'Odomètre',
          required: 'Odomètre est requis',
        },
        origin: {
          label: "Source de l'évaluation",
          required: "Source de l'évaluation est requis",
        },
        plate: {
          label: "Plaque d'immatriculation",
        },
        series: {
          label: 'Series',
        },
        state: {
          label: 'État',
        },
        style: {
          label: 'Style',
        },
        traction: {
          label: 'Traction',
        },
        'transmission-type': {
          label: 'Transmission',
        },
        vin: {
          invalid: 'NIV est pas valide',
          label: 'NIV',
          required: 'NIV est requis',
          placeholder: 'Entrez le NIV',
        },
        stock: {
          label: 'Stock',
          required: 'Stock est requis',
        },
        phone: {
          label: 'Téléphone',
          required: 'Numéro de téléphone requis',
        },
      },
    },
    assessor: {
      form: {
        bucket: 'Seau',
        edit: 'Modifier les dommages',
        new: 'Nouveaux dommages',
      },
      arbitrage: 'Arbitrer',
      arbitrageOpen: 'Arbitrer',
    },
    'body-types': {
      'cab/chassis': 'Cab/Chassis',
      'cargo Van': 'Cargo Van',
      chassis: 'Chassis',
      convertible: 'Convertible',
      coupe: 'Coupe',
      'cutaway van': 'Cutaway Van',
      hatchback: 'Hatchback',
      hearse: 'Hearse',
      limousine: 'Limousine',
      'minivan/van': 'Minivan/Van',
      other: 'Autre',
      sedan: 'Sedan',
      suv: 'VUS',
      truck: 'Camionnette',
      wagon: 'Wagon',
    },
    btn: {
      add: 'Ajouter',
      approbate: 'Soumettre pour approbation',
      approve: 'Approuver',
      back: 'Arrière',
      cancel: 'Annulé',
      checked: 'Vérifié',
      complete: 'Compléter',
      decode: 'Décoder',
      estimate: 'Estimer',
      link: 'Relier',
      origin: 'Origin',
      'generate-pdf': 'Générer un bon client',
      'customer-pdf': 'Client pdf',
      'printable-pdf': 'Imprimable pdf',
      pdf: 'PDF',
      'pdf-copy': 'PDF Copie',
      png: 'CONTRAT',
      'remote-transaction-request': 'Demande de transaction à distance PDF',
      reopen: 'Réouvrir',
      restart: 'Redémarrer',
      resubmit: 'Réévaluer',
      return: 'Retourner au vendeur',
      save: 'Sauvegarder',
      'save-images': 'Enregistrer des images',
      'upload-images-with-cache': 'Télécharger depuis le cache',
      start: 'Commencer',
      submit: 'Soumettre',
      'submit-pave': 'Soumettre PAVE',
      'take-over': 'Prendre en main',
      update: 'Mettre à jour',
    },
    charts: {
      'condition-reports': 'Rapports Condition',
      'evaluations-physiques': 'Physical assessments',
      'number-of-virtual-evaluations':
        "Gap de prix d'evaluations virtuelles par évaluateur",
      'number-of-virtual-evaluations-type-of-actions-taken':
        "Nombre d'évaluation virtuelles par type d'actions prises par les agent",
      'number-virtual-evaluations-with-price':
        "Nombre d'évaluation virtuelles ayant un prix par type d'actions prises par les agent",
      'physical-assessment-time': "Durée de l'évaluation physique par location",
      'virtual-assessment-time': "Durée de l'évaluation virtuelle par location",
      'physical-evaluations-lease': 'Évaluations physiques par location',
      'virtual-evaluations': 'Evaluations virtuelles',
      'virtual-evaluations-location': 'Evaluations virtuelles par location',
    },
    colors: {
      'beige/tan': 'Beige/Tan',
      'black metallic': 'Noir métallique',
      blue: 'Bleu',
      'blue (dark)': 'Bleu  (foncé)',
      'blue (light)': 'Bleu (clair)',
      'blue (metallic)': 'Bleu (métallique)',
      'blue metallic': 'Bleu métallique',
      'blue metallic (light)': 'Bleu métallique (pâle)',
      bronze: 'Bronze',
      brown: 'Brun',
      'brown (dark)': 'Brun (foncé)',
      'brown metallic': 'Brun métallique',
      champagne: 'Champagne',
      charcoal: 'Charbon',
      gold: 'Or',
      gray: 'Gris',
      green: 'Vert',
      'green (dark)': 'Vert (foncé)',
      'green (light)': 'Vert (pâle)',
      'green metallic': 'Vert métallique',
      maroon: "Bordeaux 'maroon'",
      orange: 'Orange',
      pearl: 'Perle',
      pewter: 'étain',
      pink: 'Rose',
      purple: 'Violet',
      'purple metallic': 'Violet métallique',
      red: 'Rouge',
      'red metallic': 'Rouge métallique',
      silver: 'Argent',
      'teal green': "Vert 'teal'",
      white: 'Blanc',
      yellow: 'Jaune',
      'yellow (light)': 'Jaune (pâle)',
    },
    common: {
      welcome: `Bienvenue dans L'évaluation`,
      'brand-title': 'Evaluation Dashboard',
      'empty-result': 'Aucun élément trouvé',
      'old-app': "Passer à l'évaluation 1.0 (ancien)",
      or: 'ou',
      'new-physical-evaluation': 'Nouvelle évaluation physique',
      'new-virtual-evaluation': 'Nouvelle évaluation virtuelle',
      'using-vin': "Pas de plaque d'immatriculation ?",
      'using-vin-link': 'Entrez le NIV',
      'using-plate': 'Pas de numéro NIV ?',
      'using-plate-link': "Entrez le numéro d'immatriculation",
      save: 'Sauvegarder',
      submit: 'Soumettre',
      upload: 'Télécharger',
      validation: {
        required: 'Ce champ est requis',
        email: "E-mail n'est pas correct",
        notZerro: 'Ne peut pas être un prix nul',
      },
      'no-data': 'Aucune donnée disponible',
      'no-data-simple': 'Pas de données',
      search: 'Recherche',
      location: 'Emplacement',
      'new-appraisal-condition': "Nouvelle condition d'évaluation",
    },
    'condition-edit': {
      action: {
        'deval-low': {
          label: 'Deval low',
        },
      },
    },
    'condition-report': {
      'active-damage': 'Un mappeur correctement',
      'active-report': 'Rapport de véhicule ACTIF',
      'all-damage': 'Tous les dommages',
      'not-decode-damage': 'Ne pas décoder les dommages',
      comments: {
        label: 'Commentaires',
      },
      price: {
        label: 'Prix',
      },
      'condition-report-link': "Rapport d'inspection de l'encan",
      create: {
        content: 'Créer {{price}}',
      },
      'front-left': {
        label: 'avant gauche',
      },
      'front-right': {
        label: 'avant droite',
      },
      'inventory-link': "Portail d'inventaire",
      'mounted-on': {
        label: 'Monté sur',
      },
      'steel-wheel': {
        label: 'Roue en acier',
      },
      'aluminium-rims': {
        label: 'Jantes en aluminium',
      },
      'steel-wheel-rims': {
        label: 'Roue en acier (rims)',
      },
      'aluminum-rims-mags': {
        label: 'Jantes en aluminium (mags)',
      },
      'seson-summer': {
        label: 'Été',
      },
      'seson-winter': {
        label: 'Hiver',
      },
      'not-active-report': 'Rapport de véhicule NON ACTIF',
      odometer: {
        label: 'Odomètre',
      },
      photos: {
        required: 'Des photos sont requises',
      },
      question: {
        ac: {
          label: 'La climatisation du véhicule fonctionnne? ',
        },
        compound: {
          label: "La voiture a besoin d'un polissage (compound)?",
        },
        engine: {
          label: 'Le moteur fait un bruit bizarre ou beaucoup de bruit?',
        },
        keys: {
          label: 'Combien de clées pour ce véhicule?',
        },
      },
      'rear-left': {
        label: 'arrière gauche',
      },
      'rear-right': {
        label: 'arrière droite',
      },
      season: {
        label: 'Monté sur',
      },
      'the-tread-button': 'Ajouter une autre paire de roues',
      'the-tread-title': 'Bande de roulement de pneu et clés',
    },

    'connecting-rod-connection': 'Biellette de raccordement',
    'damage-manage': {
      action: {
        btn: {
          create: 'Créer',
          update: 'Mettre à jour',
        },
        'deval-high': {
          label: 'Deval high',
        },
        'label-en': {
          label: 'Étiquette en anglais',
        },
        'label-fr': {
          label: 'Étiquette en français',
        },
        'repair-high': {
          label: 'Repair high',
        },
        'repair-low': {
          label: 'Repair low',
        },
      },
      buckets: {
        bodyshop: 'Bodyshop',
        detail: 'Detail',
        dsp: 'DSP',
        interiorrepair: 'Interior repair',
        mechanic: 'Mechanic',
        movetobodyshop: 'Move body shop',
        windshield: 'Windshield',
      },
      'create-damage-action': {
        content: 'Créer une action de dégâts',
      },
      'create-damage': {
        content: 'Créer des dégâts',
      },
      damage: {
        btn: {
          create: 'Créer',
          update: 'mise à jour',
        },
        bucket: {
          label: 'Recontracter le bucket',
        },
        'label-en': {
          label: 'Étiquette en anglais',
        },
        'label-fr': {
          label: 'Étiquette en français',
        },
      },
      'deval-high': {
        label: 'Substitute haut',
      },
      'deval-low': {
        label: 'Substitut faible',
      },
      label: {
        label: 'Étiqueter',
      },
      'repair-high': {
        label: 'Réparer haut',
      },
      'repair-low': {
        label: 'Réparation faible',
      },
    },
    dashboard: {
      filters: 'Filtres',
      'start-date': {
        label: 'Date de début',
      },
      'end-date': {
        label: 'Date de fin',
      },
      location: {
        label: 'Lieu',
        placeholder: 'Choisissez un lieu',
      },
      'condition-reports': 'Rapports conditions',
      'create-physical': 'Créer une évaluation physique',
      'create-virtual': 'Créer une evaluation virtuelle',
      'my-physical-evaluations': 'Mes évaluations physiques',
      'my-virtual-evaluation': 'Mes evaluation virtuelle',
      'physical-assessments': 'Évaluations physiques en cour',
      'your-options': 'Vos Options',

      'generating-report': 'Génération du rapport...',
      'loading-data': 'Chargement des données...',
    },
    'engine-exhaust-transmission-one':
      "Transmission d'échappement du moteur #1",
    'engine-exhaust-transmission-two':
      "Transmission d'échappement du moteur #2",
    error: {
      'error-internet-connection': 'Erreur de connexion internet',
      'error-load-file': 'Fichier de chargement d’erreur',
    },
    exception: {
      answer:
        'Vous devez répondre aux questions mécaniques pour pouvoir terminer',
      'error-tire':
        "Vous devez saisir l'épaisseur de la bande de roulement des pneux pour pouvoir terminer",
      price: "Le prix d'évaluation est requis",
    },
    general: {
      logout: 'Se déconnecter',
      notification: 'Notification',
      profile: 'Profil',
    },
    inspection: {
      'not-found-any-problem': "l'inspection n'a trouvé aucun problème",
      'tab-title': "Inspection de l'IA",
      'waite-create-inspection': "Waite créer l'inspection",
    },
    listing: {
      'update-error':
        "évaluation virtuelle n'est pas mise à jour. Veuillez réessayer.",
      'modal-title': "Message d'information",
      'modal-description':
        'Veuillez tourner votre appareil en position horizontale',
      'appointment-was-taken': {
        label: 'Un rendez-vous à été pris avec le client',
      },
      'appraisal-value': {
        label: 'Montant Revisé',
      },
      'appraiser-comment': {
        label: "Commentaires de l'évaluateur",
      },
      'asking-value': {
        label: 'Montant demandé',
      },
      'change-appraisal': {
        value: 'Éditer',
      },
      'client-comment': {
        label: 'Commentaires des clients',
      },
      'client-comment-form': {
        label: "Commentaires de l'évaluateur",
      },
      'customer-name': {
        label: 'Nom du client',
      },
      'customer-sms-number': {
        label: 'mobile pour envoie automatique du prix',
      },
      'customer-was-called': {
        label: 'Le client a été contacté',
      },
      'document-comment': {
        label: 'Commentaires document',
      },
      exception: {
        price: "Le prix d'évaluation est requis",
      },
      make: {
        label: 'Marque',
      },
      mileage: {
        label: 'Kilométrage',
      },
      'model-year': {
        label: 'Année',
      },
      model: {
        label: 'Modèle',
      },
      odometer: {
        label: 'Odomètre',
      },
      origin: {
        label: "Source d'évaluation",
      },
      'preliminary-value': {
        label: "Montant de l'évaluation",
      },
      'reference-id': {
        label: 'Numéro de Référence',
        placeholder: '# ref',
      },
      'reference-link': {
        label: 'Lien',
      },
      'reference-title': {
        label: 'Titre',
      },
      series: {
        label: 'Series',
      },
      steps: {
        customer: {
          label: 'Cliente',
          subLabel: 'Environ 3 Min',
        },
        general: {
          label: 'Générale',
          subLabel: 'Environ 1 Min',
        },
        question: {
          label: 'Question',
          subLabel: 'Environ 4 Min',
        },
        vehicle: {
          label: 'Véhicule Information',
          subLabel: 'Environ 3 Min',
        },
      },
      title: 'Liste',
      'v-auto-id': {
        label: 'vAuto',
      },
      vin: {
        label: 'NIV',
      },
    },
    login: {
      btn: 'Login',
      email: {
        label: 'Email',
        required: 'Email est requis',
      },
      invalid: "Votre tentative de connexion n'a pas réussi",
      password: {
        label: 'Mot de passe',
        required: 'Mot de passe requis',
      },
      title: 'Connectez-vous à votre compte',
    },
    massage: {
      'images-save': 'Images sauvegardées',
    },
    navigations: {
      'conditions-inspections': 'Inspections',
      'conditions-manager': 'Gestionnaire de conditions',
      'conditions-reports': 'Rapport de condition',
      'evaluation-analytics-label': 'Section analytique',
      'evaluation-analytics': 'Autorisation de caméra',
      'evaluation-admin': 'Rubrique administrative',
      'conditions-works': 'Travaux',
      dashboard: 'Dashboard',
      feature: 'Feature',
      'physical-evaluations': 'Listing',
      'physical-evaluations-menu': 'Évaluations physiques',
      'user-evaluation-menu': "Évaluation de l'utilisateur",
      'user-evaluation-menu-list': 'Liste des véhicules',
      'physical-evaluations-new': 'Nouvelle',
      'profile-label': 'Admin',
      'profile-list': 'Utilisateurs',
      'under-evaluations': 'En cours',
      'virtual-evaluations': 'Listing',
      'virtual-evaluations-menu': 'Évaluation Virtuelle',
      'dashboard-statistic': 'Statistiques',
      'dashboard-statistic-my': 'Mes statistiques',
      'virtual-evaluations-new': 'Nouvelle',
      'procent-settings': 'Paramètres de pourcentage',
      'appraisal-conditions-title': "Conditions d'expertise",
      'appraisal-conditions-new': 'Ajouter des conditions',
      'copilot-ai': 'Copilote IA',
      'appraisal-conditions-list': "Liste des conditions d'évaluation",
      administration: 'Administration',
      'administration-users': 'Utilisateurs',
    },
    notificationSystem: {
      PE_SUBMITTED: {
        title: 'Évaluation soumise',
        message: '{{name}} chez {{dealership}}',
      },
      PE_EVALUATED: {
        title: 'Évaluation évaluée',
        message: '{{name}} chez {{dealership}}',
      },
      PE_STARTED: {
        title: 'Début de l’évaluation',
        message: '{{name}} chez {{dealership}}',
      },
      PE_PRICE_CHANGED: {
        title: 'Évaluation Changements de prix',
        message: '{{name}} at {{dealership}}',
      },
      VE_SUBMITTED: {
        title: 'Évaluation virtuelle soumise',
        message: '{{name}} chez {{dealership}}',
      },
      VE_EVALUATED: {
        title: 'Évaluation virtuelle évaluée',
        message: '{{name}} chez {{dealership}}',
      },
      VE_STARTED: {
        title: 'Évaluation virtuelle a commencé',
        message: 'Évaluation virtuelle a commencé {{name}} chez {{dealership}}',
      },
      VE_PRICE_CHANGED: {
        title: '',

        message:
          'Évaluation virtuelle Changements de prix  {{name}} at {{dealership}}',
      },
      CR_CREATED: {
        message: "Rapport d'état créé {{name}} at {{dealership}}",
      },
      CR_APROVED: {
        title: '',

        message: "Rapport d'état approuvé {{name}} at {{dealership}}",
      },
    },
    notification: {
      email: 'Email',
      notification: 'Notification',
      sms: 'Sms',
      title: 'Abonnement',
    },
    notifications: {
      title: 'Notifications',
    },
    origins: {
      buy: 'Vendre que',
      exchange: 'échanges de',
      inventory: 'Unité âgée',
      service: 'Service en service',
    },
    pictures: {
      'photos-certify':
        'Je certifie avoir noté et photographié tous les dommages',
      back: 'dos',
      backleft: "Passer à l'évaluation 1.0 (ancien)",
      backright: 'arrière droit',
      backseat: 'siège arrière',
      board: 'planche',
      camera: 'caméra',
      damage: 'endommager {{number}}',
      dashboard: 'tableau de bord',
      front: 'avant',
      frontlateralleft: 'avant latéral gauche',
      frontlateralright: 'latéral avant droit',
      frontleft: 'avant gauche',
      frontright: 'avant droit',
      generic: 'générique {{number}}',
      interiorback: 'interior back',
      interiorfront: 'avant intérieur',
      interiorrear: 'intérieur arrière',
      left: 'la gauche',
      leftzoomback: 'zoom arrière gauche',
      leftzoomfront: 'zoom avant gauche',
      mags: 'mags',
      middlelateralleft: 'milieu latéral gauche',
      middlelateralright: 'milieu latéral droit',
      odometer: 'odomètre',
      rear: 'arrière',
      rearlateralleft: 'latéral arrière gauche',
      rearlateralright: 'latéral arrière droit',
      rearleft: 'Arrière gauche',
      rearright: 'arrière droit',
      right: 'droite',
      rightzoomback: 'zoom arrière droit',
      rightzoomfront: 'zoom avant droit',
      roof: 'toiture',
      seat: 'siège',
      tread: 'bande de roulement',
      trunk: 'tronc',
      underhood: 'sous le capot',
      vin: 'vin',
      vinplate: 'assiette de vin',
    },
    PICTURES: {
      FRONTLATERALLEFT: 'face avant gauche',
      REARLATERALLEFT: "lire côté gauche'",
      REARLATERALRIGHT: 'côté arrière droit',
    },
    profile: {
      any: {
        completed: "M'informer quand une évaluation physique est complêté",
        create: "M'informer quand une nouvelle évaluation physique est créé",
        new: "M'informer quand une nouvelle évaluation physique est prête",
      },
      appraisal: {
        any: {
          assessed: "M'informer quand un rapport de condition est complété",
          assessment: "M'informer quand un rapport de condition est ajouté",
          challenged:
            "M'informer quand une réclamation est ajouter à un rapport de condition",
          pending:
            "M'informer quand un rapport de condition pour ({{locationUser}}) est en attente d'approbation",
          resolved: "M'informer quand une réclamation atteint sa résolution",
          resubmit:
            "M'informer quand un rapport de condition est soumis en révision (nouvelle demande)",
          returned:
            "M'informer quand un rapport de condition est retourné au vendeur (à l'encan)",
        },
      },
      chat: {
        id: {
          assessing:
            "M'abonner [automatiquement] au communications du chat lorsque je commence un rapport de condition",
          claim:
            "M'abonner [automatiquement] au communications du chat lorsque je fais une réclammation sur un rapport de condition",
          created:
            "M'abonner [automatiquement] au chat d'une évaluation virtuelle lorsque je crée une inscription",
          'created-appraisal':
            "M'abonner [automatiquement] au chat d'une évaluation physique lorsque je crée une demande",
          send: "M'abonner [automatiquement] au chat d'une évaluation physique lorsque j'écrit dans le chat",
          sms: "M'abonner [automatiquement] au chat d'une évaluation virtuelle lorsque je crée une inscription",
          started:
            "M'abonner [automatiquement] au chat d'une évaluation virtuelle lorsque je commence une inscription",
          'started-appraisal':
            "M'abonner [automatiquement] au chat d'une évaluation physique lorsque je commence une demande",
        },
        listing: {
          id: {
            created:
              "M'abonner [automatiquement] au mise-à-jours d'une évaluation virtuelle lorsque je crée une inscription",
            send: "M'abonner [automatiquement] au chat d'une évaluation virtuelle lorsque j'écrit dans le chat",
            started:
              "M'abonner [automatiquement] au mise-à-jours d'une évaluation virtuelle lorsque je commence une inscription",
          },
        },
      },
      email: {
        label: 'Email',
      },
      'first-name': {
        label: 'Prénom',
      },
      id: {
        claim:
          "M'abonner [automatiquement] au mise-à-jours d'un rapport condition lorsque je fais une réclamation",
        created:
          "M'abonner [automatiquement] au mise-à-jours d'une évaluation physique lorsque je crée une demande",
        started:
          "M'abonner [automatiquement] au mise-à-jours d'une évaluation physique lorsque je commence une demande",
      },
      'last-name': {
        label: 'Nom de famille',
      },
      listing: {
        any: {
          completed: "M'informer quand une évaluation virtuelle est complêté",
          create: "M'informer quand une nouvelle évaluation virtuelle est créé",
          created:
            "M'abonner [automatiquement] au chat d'une évaluation virtuelle lorsque je crée une inscription",
          new: "M'informer quand une nouvelle évaluation virtuelle est prête",
        },
      },
      'me-page': {
        country: 'Pays',
        email: 'Email',
        language: 'Langue',
        phone: 'Téléphoner',
        region: 'Région',
        role: 'Rôle',
        segmentation: 'Segmentation',
        settings: {
          distance: 'Distance',
          language: 'Langue',
          title: 'Paramètres par défaut',
        },
        teams: 'Les équipes',
      },
      phone: {
        label: 'Téléphone',
      },
      role: 'Rôle',
      segmentation: 'Segmentation',
      teams: 'Les équipes',
    },
    question: {
      'customer-estimate': {
        label: 'Estimation visible par le client',
      },
      evaluator: {
        label: 'Évaluateur',
      },
      client: {
        label: 'Commentaire client',
      },
      'excellente-condition': {
        description:
          'La voiture a encore ses meilleures années devant elle. Elle est belle. Elle sent bon. Elle fonctionne très bien. Les employés du service au volant la complimente parfois.',
        label: 'Excellente condition',
      },
      'good-condition': {
        description:
          "La voiture n'a peut-être pas encore atteint son apogée, mais elle s'en approche. L'extérieur a sa juste part de bosses et d'ecchymoses. L'intérieur a l'air un peu terne. Les amis demandent si vous êtes sur le marché pour une nouvelle voiture d'une manière qu'on ressent un peu jugement.",
        label: 'Bonne condition',
      },
      'has-aftermarket': {
        label: 'Le véhicule a-t-il des pièces fait sur mesure?',
      },
      'has-dept': {
        label: 'Il a-t-il du financements associé à ce véhicule?',
      },
      'how-many-keys': {
        label: 'Combien de clées pour ce véhicule?',
      },
      'needs-repairs': {
        label:
          'Le véhicule a-t-il des réparations requises ou avertissements au tableau de bord?',
      },
      no: 'Non',
      'no-keys': 'Any',
      'previously-damaged': {
        label: 'Le véhicule a-t-il été précédement endomagé?',
      },
      'rugged-condition': {
        description:
          "La voiture a connu de meilleurs jours. A besoin de peinture. A besoin de réparations. A besoin de pièces. Il a quatre roues, et beaucoup de bons souvenirs, mais pas grand-chose d'autre.",
        label: 'Légèrement accidenté',
      },
      seller: {
        label: 'Vendeur',
      },
      'what-condition-car': 'Dans quel état est le véhicule?',
      yes: 'Oui',
      'engine-make-a-weird-noise':
        'Est-ce que le moteur fait un bruit bizarre / Doit-on le remplacer ?',
      'transmission-slip-work':
        'Est-ce que la transmission glisse/ fonctionne comme il faut / Doit-on la changer ?',
    },
    questionUs: {
      'other-issue': 'Autres problèmes',
      number: 'Nombre',
      'question-required': 'Toutes les questions sont obligatoires',
      other: 'Autre',
      'question-one': {
        title: 'Quelle est la condition générale de votre véhicule ?',
        option1:
          "Impécable: mécanique exceptionnelle, extérieur et intérieur sans défauts visibles, n'a pas besoin de reconditionnement.",
        option2:
          'Propre: pas de défauts majeurs au niveau mécanique et esthétique, besoin de reconditionnement mineur.',
        option3:
          "Moyen: usure normale, aura besoin d'un reconditionnement au niveau mécanique et esthétique",
        option4:
          'Usé: problemes mécaniques et esthétique sévères, besoin de réparations',
        option5:
          "Endommagé: problèmes mécaniques et esthétique majeurs, l'utilisation n'est pas sûre",
      },
      'question-two': {
        title: 'Le véhicule a-t-il déjà été accidenté ?',
      },
      'question-three': {
        title: 'Y a-t-il des problèmes mécaniques ou autres?',
        option1: 'Rien de visible',
        option2: 'Problème a/c',
        option3: 'Problème de freins',
        option4: 'Structure accidentée',
        option5: 'Options standard manquantes',
        option6: 'Odeur notable quelconque',
        option7: 'Problème de suspension',
        option8: 'Autres problèmes',
      },
      'question-four': {
        title: 'Y a-t-il un problème au niveau du moteur?',
        option1: 'Bruit venant du moteur',
        option2: 'Moteur tourne irrégulièrement',
        option3: 'Ne fonctionne pas',
      },
      'question-five': {
        title: 'Y a-t-il des problèmes de transmission?',
        option1: "L'embrayage nécessite une réparation",
        option2: 'Saute une vitesse',
        option3: "N'embraye pas",
        option4: 'Passage de vitesse difficile',
        option5: 'Transmission glisse',
      },
      'question-six': {
        title: 'Y a-t-il des panneaux qui ont besoin de carrosserie?',
        option1: '1',
        option2: '2',
        option3: '3 ou plus',
      },
      'question-seven': {
        title: 'Y a-t-il des témoins lumineux allumés?',
        option1: 'Du lumiere',
        option2: 'Des freins / abs',
        option3: 'Du 4x4',
        option4: 'Des coussins de sécurité',
        option5: 'De transmission',
        option6: 'Autre',
      },
      'question-eight': {
        title: 'Y a-t-il des panneaux avec de la rouille?',
        option1: 'Oui sur la carrosserie',
        option2: 'Oui sous la structure',
      },
      'question-nine': {
        title: 'Y a-t-il des accessoires brisés ou qui ne fonctionnent pas?',
        option1: '1',
        option2: '2',
        option3: '3 ou plus',
      },
      'question-ten': {
        title: 'La suspension a-t-elle été modifié?',
      },
      'question-eleven': {
        title: 'Le moteur a-t-il été modifié?',
      },
      'question-thirteen': {
        title:
          "Y a-t-il des déchirures, usure anormale ou taches à l'intérieur du véhicule?",
        option1: '1',
        option2: '2',
        option3: '3 ou plus',
      },
      'question-fourteen': {
        title: "Y a-t-il des pneus qui ont besoin d'être remplacés?",
        option1: '1',
        option2: '2',
        option3: '3 ou plus',
      },
    },
    settings: {
      distances: {
        km: 'Kilomètres',
        mi: 'Miles',
      },
      languages: {
        en: 'Anglaise',
        fr: 'Français',
      },
      'button-save': 'Sauvegarder les modifications',
    },
    side: {
      back: 'arrière',
      front: 'avant',
      interior: 'intérieur',
      left: 'gauche',
      right: 'droite',
      top: 'dessus',
    },
    sources: {
      buy: 'Vendre que',
      exchange: 'échanges de',
      inventory: 'Unité âgée',
      service: 'Service en service',
      takenIn: 'Pris à',
    },
    statuses: {
      acquired: 'Acheté',
      acquiring: 'Offres retenue',
      acquisition: 'Offres présenté',
      approved: 'Approuvée',
      assessed: 'Rapport Terminé',
      assessing: 'Rapport Commencé',
      assessment: 'Rapport Commandé',
      cancelled: 'Annulé',
      cloned: 'Cloné',
      closed: 'Fermé',
      conditioned: 'Conditionné',
      conditioning: 'Conditionnement',
      draft: 'Brouillon',
      evaluated: 'Offre ouverte',
      evaluating: 'En cours',
      evaluation: 'Nouvelle',
      expired: 'Offre expiré',
      pending: 'Rapport En Attente',
      rejected: 'Rejeté',
      returned: 'Retour au vendeur',
      sold: 'Vendu',
      wait: 'Attendez les images PAVE',
      waite: "Image d'attente",
    },
    track: {
      add: 'Ajouté par',
      completed: 'Évalué par',
      report: 'Rapport commencé par',
      submitted: 'Soumise par',
      scan: 'Scanner',
    },
    'traction-types': {
      '4wd': '4x4 (4WD)',
      awd: 'Intégrale (AWD)',
      fwd: 'Avant (FWD)',
      rwd: 'Arrière (RWD)',
    },
    'transmission-types': {
      automatic: 'Automatique',
      cvt: 'CVT',
      manual: 'Manual',
      manuelle: 'Manual',
    },
    'vehicle-report': {
      form: {
        'to-be-repaired': 'To be repaired',
        na: 'N/A',
        'requires-verification': 'Requires verification',
      },
      accessories: {
        label: 'Accessoires',
      },
      'brakes-fitst': {
        label: 'Freins #1',
      },
      'brakes-second': {
        label: 'Freins #2',
      },
      circle: {
        'abs-sistem': 'Système ABS',
        accompaniement: 'Accompaniment',
        'adjustable-steering-wheel': 'Volant adjustable',
        'air-filter': 'Filtre à air',
        alignment: 'Alignement',
        alternator: 'Alternateur',
        'anchor-bolt': "Boulon d'ancrage",
        'arm-suspension': 'Bras de suspension',
        'arm-suspension-right': 'Bras de suspension',
        'back-system-windscreen-washer': 'Système lave-glace',
        'battery-cable': 'Cable de batterie',
        'before-discs': 'Disques - avant',
        'before-system-windscreen-washer': 'Système lave-glace',
        bellows: 'Soufflet',
        'belt-seat-security-back': 'Ceinture de sécurité du siège arrière',
        'belt-seat-security-before': 'Ceinture de sécurité avant',
        'bench-and-seats': 'Banquette et sièges',
        binoculars: 'Jumelles',
        'blade-composite': 'Lame en composite',
        'blade-stirrup': 'étrier de lames',
        'bolts-dowels-nuts': 'Boulons, gougeons et écrous',
        'brake-fluid-level': 'Liquide de frein - niveau',
        'brake-parking': 'Frein de stationnement',
        'bus-ducts-fittings': 'Conduits/Raccords - bas',
        canalisatins: 'Pipelines',
        'canalisation-air': 'Canalisation (air)',
        'case-management': 'Boitier de direction',
        catalyst: 'Catalyseur',
        ceiling: 'Plafond',
        clutch: 'Embrayage',
        'connecting-rod-connection': 'Connexion de bielle',
        connection: 'Lien',
        'connection-rob-mating': "Bielle d'accouplement",
        'control-level': 'Levier de commande',
        'convertible-roof': 'Toit décapotable',
        cork: 'Bouchon de réservoir',
        'coupling-bar': "Barre d'accouplement",
        'cousin-inflatable': 'Coussin gonflable',
        'cousin-rubber': 'Coussin de caoutchouc',
        'cover-battery-box': 'Couvercle du coffre à batterie',
        'cylinder-wheel': 'Cylindre de roue',
        'dashboard-lighting': 'Tableau de bord (èclairage)',
        'dashboard-lighting-big': 'Lampe tèmoin',
        'daytime-running-lights-left': 'Phares de jour - gauche',
        'daytime-running-lights-right': 'Phares de jour - droit',
        defrost: 'Dégivrage',
        'direction-lights': 'Feux de direction - avant',
        'direction-lights-rear': 'Feux de direction - arrière',
        'direction-lights-left': 'Feux de direction - gauche',
        'direction-lights-right': 'Feux de direction - droit',
        discs: 'Disques - arrière',
        drums: 'Tambours - arrière',
        'ducts-fittings': 'Conduits/Raccords',
        'element-of-fixation': 'Élément de fixation',
        'engine-oil': 'Huile moteur',
        'fixing-element': 'Élément de fixation',
        flying: 'Volant',
        'four-check': 'Vérification 4x4',
        'front-windshield': 'Pare-brise - avant',
        gauge: 'Jauge',
        gimbal: 'Cardan',
        'glove-other-storage': 'Coffre à gants et autres rangements',
        'hazard-lights-back': 'Feux de dètresse - arrière',
        'hazard-lights-before': 'Feux de détresse (Avant)',
        'hazard-lights-central': "Feux d'arrêt - central",
        'hazard-lights-front': 'Feux de dètresse - avant',
        'hazard-lights-left': "Feux d'arrêt - gauche",
        'hazard-lights-left-smoll': 'Feux de dètresse - gauche',
        'hazard-lights-right': "Feux d'arrêt - droit",
        'hazard-lights-right-smoll': 'Feux de dètresse - droit',
        'head-support': 'Appui-tête',
        'headlight-cover': 'Couvre phare',
        'headlights-left': 'Phares de route - gauche',
        'headlights-right': 'Phares de route - droit',
        hinge: 'Garniture - arrière',
        'hinge-discs': 'Garniture - avant',
        honk: 'Klaxon',
        'idler-arm': 'Bras de renvoi',
        'inner-ball-joint': 'Rotule intérieure',
        'inner-end': 'Extrémité intérieure',
        'internal-sun-visor': 'Pare-soleil intèrieur',
        'jank-and-tools-rescue': 'Cric et outils de secours',
        'left-bellows': 'Soufflet - gauche',
        'left-belt-seat-security-back':
          'Ceinture de sécurité siège arrière - gauche',
        'left-belt-seat-security-before':
          'Ceinture de sécurité siège avant - gauche',
        'left-inner-ball-joint': 'Rotule intérieure - gauche',
        'left-inner-end': 'Embout intérieur - gauche',
        'left-mechanism-siege-back': 'Mécanisme de siège arrière - gauche',
        'left-mechanism-siege-before': 'Mécanisme de siège avant - gauche',
        'left-mirrir-outside': 'Rétroviseur extérieur - gauche',
        'left-outer-ball-joint': 'Rotule extérieure - gauche',
        'left-outer-end': 'Embout extérieur - droit',
        'license-plate-lights': 'Feux de plaque',
        'light-switch': 'Interrupteur',
        'light-switch-back': "Interrupteur d'éclairage (arrière)",
        'light-switch-before': 'Light switch (Before)',
        'light-switch-left': 'Light switch (Left)',
        'light-switch-right': "Interrupteur d'éclairage (Droite)",
        'lighthouses-lights-left': 'Phares antibrouillards - gauche',
        'lighthouses-lights-right': 'Phares antibrouillards - droit',
        'liquid-level': 'Niveau de liquide',
        'lock-door': 'Serrure de portière',
        'loud-speaker-back': 'Haut-parleur - arrière',
        'loud-speaker-back-right': 'haut-parleur - avant',
        'loud-speaker-before': 'Haut-parleur - avant',
        'loud-speaker-right': 'haut-parleur - arrière',
        'making-running': 'Prise de courant',
        'master-blade': 'Lame maîtresse',
        'master-cylinder': 'Maître cylindre',
        'mechanism-eiege-before': 'Mécanisme de siège avant',
        'mechanism-siege-back': 'Mécanisme de siège arrière',
        'mechanism-siege-before': 'Mécanisme de siège avant',
        'mechanism-windows': 'Méchanisme de vitres (AVG)',
        'mechanism-windows-AVD': 'Méchanisme de vitres (AVD)',
        'mechanism-windows-ARD': 'Méchanisme de vitres (ARD) ',
        'mechanism-windows-ARG': 'Méchanisme de vitres (ARG)',
        'mirrir-interior': 'Rétroviseur interieur',
        'mirrir-outside': 'Miroir extérieur',
        mudguard: 'Garde-boue',
        'oil-filter': "Filtre à l'huile",
        'oil-leak-motor': "Fuite d'huile moteur",
        'oil-leak-transmission': "Fuite d'huile transmission",
        'opening-trunk': 'Ouverture du coffre',
        'ordered-traction': 'Commande de traction (tableau de bord)',
        'outer-ball-joint': 'Rotule extérieure',
        'outer-end': 'Bras extérieur',
        'pedal-of-brake': 'Pédale de frein',
        'pipe-air': 'Tuyau (air)',
        pipeline: 'Canalisations',
        pipelines: 'Pipelines',
        'pipes-exhaust': "Tuyaux d'échamppement",
        'power-steering': 'Servodirection',
        pump: 'Pompe (servodirection)',
        quiet: 'Silencieux',
        raccerd: 'Raccerd',
        'race-brake-pedal': 'Course de pédale de frein',
        rack: 'Crémaillére',
        radio: 'Radio',
        'rear-windshield': 'Pare-brise - arrière',
        reasonator: 'Résonateur',
        reflector: 'Reflecteur',
        'remote-control-door': 'Télécommande de portière',
        'reversing-lights-left': 'Feux de recul - gauche',
        'reversing-lights-right': 'Feux de recul - droit',
        'right-bellows': 'Soufflet - droit',
        'right-belt-seat-security-before':
          'ceinture de sécurité siège arrière - droit',
        'right-inner-ball-joint': 'Rotule intérieure - droit',
        'right-inner-end': 'Embout intérieur - droit',
        'right-mechanism-siege-back': 'Droit mécanisme de siège arrière',
        'right-mechanism-siege-before': 'Mécanisme de siège arrière - droit',
        'right-mirrir-outside': 'Rétroviseur extérieur - droit',
        'right-outer-ball-joint': 'Rotule extérieure - droit',
        'right-outer-end': 'Embout extérieur - gauche',
        'rims-mags-or-steel': "Jantes/mags ou roues d'acier",
        'rocket-lever': 'Levier de fusée',
        'rolling-wheels': 'Roulement de roues ARD',
        'rolling-wheels-ARG': 'Roulement de roues ARG',
        'rolling-wheels-AVD': 'Roulement de roues AVD',
        'rolling-wheels-AVG': 'Roulement de roues AVG',
        'seats-heated': 'Sièges chauffants',
        'shock-absorbers-back': 'Amortisseurs arrière',
        'shock-absorbers-back-right': 'Amortisseurs arrière',
        'shock-absorbers-before': 'Amortisseurs avant',
        'shock-absorbers-before-right': 'Amortisseurs avant',
        'shock-management': 'Amortisseur de direction',
        'side-window': 'Vitre latérale (AVG)',
        'side-window-AVD': 'Vitre latérale (AVD)',
        'side-window-ARG': 'Vitre latérale (ARG)',
        'side-window-ARD': 'Vitre latérale (ARD)',
        'sidelights-left': 'Feux de position - gauche',
        'sidelights-right': 'Feux de position - droit',
        'sliding-joint': 'Joint coulissant',
        'spacer-wheel': 'Entretoise de roue',
        'spring-support-blade': 'Support de ressort à lame',
        springs: 'Ressorts',
        'stabilizing-bar': 'Barre stabilisatrice',
        'steering-column': 'Levier de direction',
        'steering-lever': 'Levier de direction',
        stirrups: 'étriers - avant',
        'stop-lights-left': 'Feux stop (gauche)',
        'stop-lights-right': 'Feux de stop (à droite)',
        'structure-protective': 'Structure protectrice',
        'summer-winter-tires': 'Pneus été/hiver',
        sunroof: 'Toit ouvrant',
        'support-engine': 'Support de moteur',
        'support-transmission': 'Support de transmission',
        suspension: 'Suspension',
        'suspension-pneumatic': 'Suspension pneumatique',
        'system-of-conditioning': "Système d'air climatisé",
        'system-of-heating': 'Système de chauffage',
        'system-windscreen-washer': 'Système lave-glace',
        tank: 'Réservoir',
        'tank-gasoline': "Réservoir d'essence",
        'thick-headed-electric': 'Borne électrique',
        tire: 'Pneu ARD',
        tireARG: 'Pneu ARG',
        tireAVD: 'Pneu AVD',
        tireAVG: 'Pneu AVG',
        'top-ducts-fittings': 'Conduits/Raccords - haut',
        'torsion-bar': 'Barre de torsion',
        'tree-transmission': 'Protège arbre de transmission',
        'universal-joint': 'Joint universel',
        'valve-level': 'Soupape de niveau',
        'valves-tpms-valves': 'Valves TPMS',
        windshield: 'Pare-brise',
        'wiper-back': 'Essuie-glace - arrière',
        'wiper-before': 'Essui-glace - avant',
        'witness-failure': 'Witness failure (dashboard)',
      },
      'class-and-mirrors': {
        label: 'Classe et miroirs',
      },
      'direction-first': {
        label: 'Direction #1',
      },
      'direction-second': {
        label: 'Direction #2',
      },
      'direction-third': {
        label: 'Direction #3',
      },
      'electrical-system': {
        label: 'Système électrique',
      },
      'engine-exhaust-transmission-one': {
        label: "Transmission d'échappement du moteur #1",
      },
      'engine-exhaust-transmission-two': {
        label: "Transmission d'échappement du moteur #2",
      },
      'lighting-and-signaling-first': {
        label: 'Eclairage et signalisation #1',
      },
      'lighting-and-signaling-second': {
        label: 'Eclairage et signalisation #2',
      },
      maintenance: {
        label: 'Maintenance',
      },
      'security-first': {
        label: 'Sécurité #1',
      },
      'security-second': {
        label: 'Sécurité #2',
      },
      'summer-winter-tires': 'Pneus été/hiver',
      'suspension-first': {
        label: 'Suspension #1',
      },
      'suspension-second': {
        label: 'Suspension #2',
      },
      'tires-wheels': {
        label: 'Pneus Roues',
      },
    },
    users: {
      fields: {
        ['first-name']: {
          label: 'Prénom',
        },
        ['last-name']: {
          label: 'Nom de famille',
        },

        email: {
          label: 'Email',
        },
        ['phone-number']: {
          label: 'Téléphone',
        },
        country: {
          label: 'Pays',
        },
        dealership: {
          label: 'Voiture de concession',
        },
        roels: {
          label: 'Rôles',
        },
      },
      notifications: {
        successful: 'User updated sucessful',
        error: 'User updated failed',
      },
    },
    'sign-in': {
      notifications: {
        error: `Authorization failed. Try again later`,
      },
    },
    'sign-up': {
      texts: {
        help: 'Veuillez choisir votre pays, votre concessionnaire et contacter votre responsable',
      },
      fields: {
        country: {
          label: 'Pays',
          required: 'Pays est requis',
        },
        dealership: {
          label: 'Voiture de concession',
          required: 'Concession est requis',
        },
      },
      notifications: {
        error: `Échec de l'inscription. Réessayez plus tard`,
      },
    },
  },
};
